window.checkedSequences = []
window.checkedSequencesOnForm = []

# Fetch from GenBank
window.fetchFromGenBank = ($this) ->
  wrapper = $this.closest(".tab-pane")
  gi_or_gb = wrapper.find('.gi-input').val()
  url = wrapper.find(".btn-fetch-from-genbank").data('url')

  # $elm = $(element);
  # if disabledElements == undefined
  #   disabledElements = [];

  # gi_or_gb = $elm.val();

  valid = /^[a-zA-Z][0-9]{5}([.][0-9]+){0,1}$|^[a-zA-Z]{2}[0-9]{6}([.][0-9]+){0,1}$|^[0-9]+$/.exec(gi_or_gb)
  if valid == null
    valid = false
  else
    valid = true

  if valid == false
    result = confirm("Invalid GI or Accession Number, do you want to continue?")
    if result != true
      return false

  if gi_or_gb == ""
    return false;
  # serviceURL = "http://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=nuccore&id=" + gi_or_gb + "&rettype=fasta";
  $.ajax(
    url: url
    data:
      genbank_gi: gi_or_gb
    dataType: "text"
    beforeSend: () ->
      wrapper.find(".btn-fetch-from-genbank").text("Loading")
      wrapper.find(".btn-fetch-from-genbank").attr("disabled", "disabled")
      wrapper.find(".gi-input").attr("readonly", "readonly")
      for i in [0...$this.length]
        wrapper.find($this[i]).attr("disabled", "disabled").attr("readonly", "readonly")
    complete: () ->
      wrapper.find(".btn-fetch-from-genbank").text("Fetch")
      wrapper.find(".btn-fetch-from-genbank").removeAttr("disabled")
      wrapper.find(".gi-input").removeAttr("readonly")
      for i in [0...$this.length]
        wrapper.find($this[i]).removeAttr("disabled").removeAttr("readonly")
    success: (response) ->
      wrapper.find(".sequence-success-fetch").modal("show")
      wrapper.find('.sequence-preview').val(response)
    error: () ->
      wrapper.find(".sequence-failed-fetch").modal("show")
  );

window.showIsolateSelected = () ->
  selected_count = checkedSequences.length
  $(".n-sequence-selected").html(selected_count+' Isolate selected')

window.getUniqueSequence = (inputArray) ->
  outputArray = []
  i = 0
  while i < inputArray.length
    if jQuery.inArray(inputArray[i], outputArray) == -1
      outputArray.push inputArray[i]
    i++
  outputArray

#sequence group (collection) select
window.seqCounterCheck = () ->
  if (window.checkedSequences.length > 0)
    $("#sequence_group_selection").find(".selected-size").html("#{checkedSequences.length} selected")
    $("#sequence_group_selection").removeClass("d-none")
  else
    $("#sequence_group_selection").addClass("d-none")
    # $("#collection_modal_").modal("hide")

window.checkSequenceChecked = () ->
  showIsolateSelected()
  # page = $("table").data("page")
  # $("table#sequences > tbody > tr").each () ->
  #   sequence_c = $(this).find("input[name=\"collection[sequence_ids][]\"]")
  #   sequence_val = sequence_c.val()
  #   if checkedSequences.indexOf(sequence_val) > -1
  #     sequence_c.prop("checked", true)

  wrapper = $("#sequences").find('tbody').find('tr')
  for checkSequence, index in checkedSequences
    wrapper.find("#sequence_collection_ids_#{checkSequence.id}").prop("checked", true)

window.sequenceDataTablesLoad = () ->
  url = $(".sequence-table").data('url')
  $(".sequence-table").DataTable
    'autoWidth': true
    'destroy': true
    'searching': false
    'ajax':
      'url': url
      'data': $("#sequences_filter_slide_panel").find("input, select").serializeJSON()
    'processing': true
    'serverSide': true
    'fnDrawCallback': ->
      # show data table to the top of page when switch pagination
      $('html, body').animate { scrollTop: $('body').offset().top }, 'slow'
      showHideTableColumn()
      sequenceFilterLabel()
      # $('.new-slide-panel').css 'display', 'none'
      $(".sequence-modal").modal('d-none')
      checkSequenceChecked()
      return
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-checkbox-column'
        'col-options-column'
        'col-sequence-length'
        'col-sequence-segment'
      ]
    } ]
    start: 0
    order: [[ 0, "desc" ]]

window.sequenceDataTablesReset = () ->
  table = sequenceDataTablesLoad()
  table.ajax.reload()

window.clearFormFilterSequence = () ->
  form = $("#sequences_filter_slide_panel")
  form.find("input[name='by_isolate_name[keyword]']").val("")
  form.find("input[name='by_year']").val("")
  form.find("select[name='by_lab[]']").val("")
  form.find("input[name='in_genbank']").prop('checked', false)
  form.find("input[name='is_complete_coding']").prop('checked', false)
  form.find("select[name='by_segment[]']").val("")
  form.find("select[name='by_uploader[]']").val("")
  form.find("select[name='by_accession_number[]']").val("")
  $(".filter-isolate-name-on-sequence").val("")
  $(".control-table-column").prop('checked', true)

# errors = [ [ 'field': 'this_is_field', 'message': 'this_message' ] ]
window.countErrorsCheckSequence = (check_field, errors) ->
  count = -1
  $.each errors, (i, obj) ->
    field = obj['field']
    if field is check_field
      count = 1
  return count

window.resetCheckedSequences = () ->
  window.checkedSequences = []
  $('input:checkbox[name=\'collection[sequence_ids][]\']').prop('checked', false);

window.resetCheckedSequencesOnForm = () ->
  window.checkedSequencesOnForm = []

window.renderCheckedSequences = () ->
  $sequenceTable = $("#sequences")
  $collectionList = $(".collection-list")
  $collectionList.empty()

  for checkedSequence in checkedSequencesOnForm
    sequenceId = checkedSequence.id
    sequenceName = checkedSequence.name
    sequenceSegment = checkedSequence.segment

    checkbox = "<div class='custom-control custom-checkbox'>"
    checkbox += "<input type='checkbox' id='collection_form_id_"+sequenceId+"' class='custom-control-input' name='collection[sequence_ids][]' value='"+sequenceId+"' checked>"
    checkbox += "<label class='custom-control-label' for='collection_form_id_"+sequenceId+"'>"
    checkbox += sequenceName + " <strong> segment " + sequenceSegment + "</strong>"
    checkbox += "</label>"
    checkbox += "</div>"
    $collectionListElement = $(checkbox)
    # $collectionListElement = $('<div class=\'checkbox\'><label><input id=\'collection_form_id_'+sequenceId+'\' type=\'checkbox\' name=\'collection[sequence_ids][]\' value=' + sequenceId + ' checked >'+ sequenceName + '<strong> segment ' + sequenceSegment + '</strong>' + '</label></div>')

    $collectionListElement.find("input:checkbox").click ->
      if !$(this).prop('checked')
        $(this).closest('.custom-checkbox').remove()

    $collectionList.append($collectionListElement)

window.removeCheckedSequence = (sequenceId) ->
  # $("body").on "change", "input[name='isolate_group[isolate_ids][]']", ->
  for checkSequence, index in checkedSequences
    if "#{checkSequence.id}" == "#{sequenceId}"
      window.checkedSequences.splice(index, 1)
      window.checkedSequencesOnForm.splice(index, 1)
      break
  $("#sequence_collection_ids_#{sequenceId}").prop('checked', false)

window.sequenceFilterLabel = () ->
  label = "Showing "
  label = label + filterLabelTemplate("all sequences")


  filter_wrapper = $("#sequences_filter_slide_panel")

  #year sequencing
  year_of_sequencing = filter_wrapper.find("input[name='by_year']").val()
  if year_of_sequencing.trim().length > 0
    label = label + filterLabelTemplate("sequenced in year #{year_of_sequencing}")

  # keyword
  keyword = filter_wrapper.find("input[name='by_isolate_name[keyword]']").val()
  if keyword.trim().length > 0
    label = label + filterLabelTemplate("by keyword: #{keyword}")

  # vet lab
  vet_lab = []
  filter_wrapper.find("#by_lab_ option:selected").each ->
    vet_lab.push($(this).text())
  unless vet_lab.length == 0
    label = label + filterLabelTemplate("by Vetlab: (#{vet_lab.join(', ')})")

  # In Genbank
  genbank = filter_wrapper.find("input[name='in_genbank']:checked").val()
  if genbank == "1"
    label = label + filterLabelTemplate("stored in genbank")
  else if genbank == "2"
    label = label + filterLabelTemplate("not stored in genbank")

  # segment
  segment = []
  filter_wrapper.find("#by_segment_ option:selected").each ->
    segment.push($(this).text())
  unless segment.length == 0
    label = label + filterLabelTemplate("by Segment: (#{segment.join(', ')})")

  # Is complete coding
  is_complete_coding = filter_wrapper.find("input[name='is_complete_coding']:checked").val()
  if is_complete_coding == "1"
    label = label + filterLabelTemplate("stored in complete coding")
  else if is_complete_coding == "2"
    label = label + filterLabelTemplate("not stored in complete coding")

  # uploader
  uploader = []
  filter_wrapper.find("#by_uploader_ option:selected").each ->
    uploader.push($(this).text())
  unless uploader.length == 0
    label = label + filterLabelTemplate("by Uploader: (#{uploader.join(', ')})")

  # accession_number
  accession_number = []
  filter_wrapper.find("#by_accession_number_ option:selected").each ->
    accession_number.push($(this).text())
  unless accession_number.length == 0
    label = label + filterLabelTemplate("by Uploader: (#{accession_number.join(', ')})")

  $("#data_option_detail").html(label)

ready = ->

  checkSequenceChecked()

  # $("body").on "click", "#select_all_sequences", ->
  #   has_selected = $("#sequences").find("input:checkbox[name='collection[sequence_ids][]']:checked").length

  #   $("input:checkbox[name='collection[sequence_ids][]']").prop("checked", this.checked).change()
  #   arr = window.checkedSequences
  #   new_arr = getUniqueSequence(arr)
  #   window.checkedSequences = []
  #   $.each new_arr , (k,v) ->
  #     window.checkedSequences.push(v)

  #   showIsolateSelected()

  $("body").on "click", ".genbank-isolate-checkbox", ->
    if $(".genbank-isolate-checkbox:checked").length > 0
      $("#save_isolates_replicate").removeClass("disabled")
    else
      $("#save_isolates_replicate").addClass("disabled")

  $("body").on "click", "#save_isolates_replicate", ->
    if $(this).hasClass("disabled") is false
      $("#save_isolates").click()

  # $('#create_collection_btn').prop('disabled', true);
  $("body").on "change", ".open-fasta-file", ->
    wrapper = $(this).closest(".tab-pane")
    reader = new FileReader()
    reader.onload = (e) ->
      wrapper.find(".sequence-preview").val e.target.result
      return
    reader.readAsText this.files[0]

  # $("body").on "change", "#sequence_sequence_segment_id", ->
  #   val = $(this).val()
  #   if val == "4"
  #     $(".sequence_clade").closest('.clade').removeClass "d-none"
  #   else
  #     $(".sequence_clade").closest('.clade').addClass "d-none"

  # DIPAKE
  $("body").off('keypress', '#search_isolate').on "keypress", "#search_isolate", (e) ->
    $this = $(this)
    if e.keyCode == 13
      e.preventDefault()
      searchIsolate($this)

  # DIPAKE
  $("body").off('click', '.btn-submit-search').on "click", ".btn-submit-search", (e) ->
    e.preventDefault()
    if $("#run_search_isolate").length > 0
      searchIsolate($("#run_search_isolate"))
    else
      searchIsolate($("#search_isolate"))

  $("body").on "click", ".step", () ->
    step = $(this).data "step"
    $("#sequence_tabs li:eq(" + (step) + ") a").tab("show")

  $('.sequence-version-caption').each ->
    note = $(this).attr('note')
    version = $(this).attr('version')
    $(this).popover
      title: '<b>Version ' + version + '</b>'
      content: note
      html: true
      trigger: 'hover'
    return

  # $('#collapse_seq').click ->
  #   $('sequence_versions').slideToggle( "slow" );
  # });
  $('.sequence_versions').hide()
  $('.collapse-sequence').click ->
    $this = $(this)
    sequence_id = $this.attr('href')
    $this.attr 'disabled', 'disabled'
    $('.sequence_versions[sequence-id=\'' + sequence_id + '\']').slideToggle 200, ->
      if $(this).is(':hidden')
        $this.find('.fa-caret-up').removeClass('fa-caret-up').addClass 'fa-caret-down'
      else
        $this.find('.fa-caret-down').removeClass('fa-caret-down').addClass 'fa-caret-up'
      $this.removeAttr 'disabled'
      return
    false

  $('body').off('change', 'input:checkbox[name=\'collection[sequence_ids][]\']').on 'change', 'input:checkbox[name=\'collection[sequence_ids][]\']', ->
    sequenceId = $(this).val()
    sequenceName = $(this).data('sequence')
    segment = $(this).data('segment')
    if $(this).prop('checked')
      checked_item = {
        id: sequenceId,
        name: sequenceName
        segment: segment
      }
      checkedSequences.push(checked_item)
      checkedSequencesOnForm.push(checked_item)
    else
      if $(this).closest('#sequences').length > 0
        removeCheckedSequence(sequenceId)

    renderCheckedSequences()
    seqCounterCheck()
    showIsolateSelected()

  $('body').on 'click', '.close-tab-collection', (e) ->
    e.preventDefault()
    groupId = $(this).data('id')
    if window["treeSizeTimer" + groupId]
      clearTimeout(window["treeSizeTimer" + groupId])
    $("#collection_show_#{groupId}").addClass("d-none")
    $("#sequence_list").removeClass("d-none");

  $("body").on "click", ".fetch-or-upload-opt", ->
    class_name = $(this).attr("value")
    wrapper = $(this).closest(".tab-pane")
    if class_name == "fetch_genbank"
      wrapper.find("#fetch_genbank").removeClass 'd-none'
      wrapper.find("#upload_file").addClass 'd-none'
    else if class_name == "upload_file"
      wrapper.find("#fetch_genbank").addClass 'd-none'
      wrapper.find("#upload_file").removeClass 'd-none'
    else
      wrapper.find("#fetch_genbank").removeClass 'd-none'
      wrapper.find("#upload_file").removeClass 'd-none'

  $("body").off('click', '.btn-fetch-from-genbank').on "click", ".btn-fetch-from-genbank", (e)->
    e.preventDefault()
    window.fetchFromGenBank($(this))

  $("body").off('click', '.btn-fetch-from-genbank-try-again').on "click", ".btn-fetch-from-genbank-try-again", (e)->
    # window.fetchFromGenBank(this)
    segment_id = $(this).closest('.modal').attr('data-segment-id')
    wrapper = $("#sequence_segment_tab_contents").find("#segment_#{segment_id}")
    window.fetchFromGenBank(wrapper.find(".gi-input"))
    # window.fetchFromGenBank($(this))

  $("body").off('keydown', '.gi-input').on "keydown", ".gi-input", (e)->
    wrapper = $(this).closest(".tab-pane")
    if e.keyCode == 13
      window.fetchFromGenBank(wrapper.find(".btn-fetch-from-genbank"));
      return false;

  # $("body").on "click", "input[name=\'collection[sequence_ids][]\']", ->
    # $("#create_collection_btn").removeAttr 'disabled';

  $(document).click (e) ->
    $container = $("#find_more_isolates_panel");
    if !$container.is(e.target) && $container.has(e.target).length == 0
      $("#find_more_isolates_panel").hide()

  $("body").on "click", ".sequence-header-modal-link", (e) ->
    e.preventDefault()
    sequence_header = $(this).data("content");
    $(".sequence-header-modal").html(sequence_header);
    $("#sequence_detail_modal").modal("show");

  $("body").on "change keyup", ".filter-isolate-name-on-sequence", (e)->
    if e.which is 13 or e.keyCode is 13
      value = $(this).val()
      $("#sequences_filter_slide_panel").find("input[name='by_isolate_name[keyword]']").val(value)
      sequenceDataTablesReset()

  $("body").on "click", "#submit_isolate_name", (e)->
    e.preventDefault()
    value = $(".filter-isolate-name-on-sequence").val()
    $("#sequences_filter_slide_panel").find("input[name='by_isolate_name[keyword]']").val(value)
    sequenceDataTablesReset()

  $("body").on "click", ".sequence-search-btn", (e)->
    e.preventDefault()
    sequenceDataTablesReset()

  $("body").on "click", ".clear-select-sequence", (e) ->
    e.preventDefault()
    resetCheckedSequences()
    resetCheckedSequencesOnForm()
    renderCheckedSequences()
    seqCounterCheck()
    showIsolateSelected()

  $("body").on "click", ".cancel-collection-modal", (e) ->
    e.preventDefault()
    # Reset Form New
    form_wrapper = $(this).closest('form')
    form_wrapper.find('#collection_collection_name, #collection_description').val('')
    form_wrapper.find('.form-group').removeClass('has-error')
    form_wrapper.find(".error-message").remove()

    # Reset selected item
    # resetCheckedSequencesOnForm()
    # for checkedSequence in checkedSequences
    #   window.checkedSequencesOnForm.push(checkedSequence)
    # renderCheckedSequences()

  $("body").on "click", "#sequence_sequence_segment_id", (e) ->
    segment_name = $("#sequence_sequence_segment_id :selected").text()
    unless $("#sequence_sequence_segment_id option:first").is(':selected')
      $(".segment-name").replaceWith("<strong class='segment-name'> (#{segment_name})</strong>")

  $("body").on "click", ".li-segment-header", () ->
    wrapper = $("#sequence_segment_tab_contents")
    wrapper.find(".tab-pane").removeClass("active in")
    tab_content =  $(this).attr("href").split("#")[1]
    wrapper.find("##{tab_content}").addClass("active")
    wrapper.find("##{tab_content}").addClass("in")

  $("body").off("click", ".sequence-segment-option").on "click", ".sequence-segment-option", (e) ->
    segment_id = $(this).val()
    segment_label = $(this).data('label')
    vet_lab_id = $(this).data('vet-lab-id')
    user_id = $(this).data('user-id')
    sequence_segment_standard_lengths = JSON.parse($("input[name='sequence_segment_standard_lengths']").val().replace(/=>/g, ':'))
    minimum_length = sequence_segment_standard_lengths[segment_label]['min']

    if $(this).prop('checked')
      header_tab_template = $("#header_tab_template").html()
      tab_content_template = $("#tab_content_template").html()

      template_data =
        sequence_segment_id: segment_id
        sequence_segment_label: segment_label
        id: (new Date).getTime()
        sequence_version_id: (new Date).getTime() + 1
        vet_lab_id: vet_lab_id
        user_id: user_id
        minimum_length: minimum_length

      $("#segment_data_wrapper").find("#sequence_segment_tabs").append(Mustache.render(header_tab_template, template_data));
      content_length = $("#sequence_segment_tabs").find("li").length;

      $("#segment_data_wrapper").find("#sequence_segment_tab_contents").append(Mustache.render(tab_content_template, template_data));
      if (content_length == 1)
        $("#sequence_segment_tabs").find("li:first a").click()

    else
      tab_wrapper = $("#segment_data_wrapper")
      header_wrapper = tab_wrapper.find("#sequence_segment_tabs")
      tab_content_wrapper = tab_wrapper.find("#sequence_segment_tab_contents")

      header_wrapper.find("#li_segment_#{segment_id}").remove()
      tab_content_wrapper.find("#segment_#{segment_id}").remove()

      header_wrapper.find(".li-segment-header:first").click()

  $("body").on "click", ".show-seq-detail-in-slide-panel", () ->
    sequence_id = $(this).data("id")
    $(".fasta-sequence-detail").addClass("d-none")
    $("#fasta_sequence_detail_#{sequence_id}").removeClass("d-none")

$(document).on 'turbolinks:load', ready
