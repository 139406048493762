ready = ->
  $("body").on "click", ".role-feature-checkbox", (e) ->
    allow_destroy = $(this).closest(".role-feature-block").find(".allow-destroy")
    if $(this).is(":checked")
      allow_destroy.val(0)
    else
      allow_destroy.val(1)
  
  $("body").on "click", ".radio-view-content", (e) ->
    $this = $(this)
    feature = $this.data "feature"
    group = $this.data "group"
    $(".radio-view-content-#{feature}").each () ->
      $(this).prop("checked", false)
    if group == "all"
      $(".allow-destroy-radio-partial-#{feature}").val(1)
      $(".allow-destroy-radio-all-#{feature}").val(0)
    else if group == "partial"
      $(".allow-destroy-radio-partial-#{feature}").val(0)
      $(".allow-destroy-radio-all-#{feature}").val(1)
    else if group == "both"
      $(".allow-destroy-radio-partial-#{feature}").val(1)
      $(".allow-destroy-radio-all-#{feature}").val(1)

    $this.prop("checked", true)

$(document).on 'turbolinks:load', ready