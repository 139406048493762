window.updateDownloadXlsLinkOnStaffs = () ->
  # get filter data
  serialize_params = $("#staff_filter_form").find("input, select").serializeJSON()
  params_filter = $.param(serialize_params)
  new_params_filter = params_filter.split("utf8=%E2%9C%93&").pop();
  old_url = $("#staff_download_link").attr('href')
  new_url = old_url + "?" + new_params_filter
  $("#staff_download_link").attr("href", new_url)

window.staffFilterLabel = () ->
  label = "Showing "
  label = label + filterLabelTemplate("all staffs")

  filter_wrapper = $(".filter")
  label = label + "<div class='filter-break'></div>"

  # staff name
  staff_name = filter_wrapper.find("input[name='staff_name']").val()
  if staff_name.trim().length > 0
    label = label + filterLabelTemplate("- By name: #{staff_name}")

  # user email
  user_email = filter_wrapper.find("input[name='user_email']").val()
  if user_email.trim().length > 0
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- By username/email: #{user_email}")

  # user role
  user_role = []
  filter_wrapper.find("#role_ option:selected").each ->
    user_role.push($(this).text())
  unless user_role.length == 0
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- By role: (#{user_role.join(', ')})")

  # vet lab
  vet_lab = []
  filter_wrapper.find("#staff_lab_ option:selected").each ->
    vet_lab.push($(this).text())
  unless vet_lab.length == 0
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- By institution: (#{vet_lab.join(', ')})")

  # department
  dept = []
  filter_wrapper.find("input[name='staff_department[]']:checked").each ->
    dept.push($(this).data('label'))
  unless dept.length == 0
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- By department: (#{dept.join(', ')})")

  # login data validity
  login = filter_wrapper.find("input[name='login_validity']:checked").val()
  if "#{login}" == "true"
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- Account is active")
  else if "#{login}" == "false"
    label = label + "<div class='filter-break'></div>"
    label = label + filterLabelTemplate("- Account is not active")

  $("#data_option_detail").html(label)

setTabCounter = (active_counter, inactive_counter, new_counter) ->
  $('.active-staff-counter').html(active_counter.recordsDisplay)
  $('.inactive-staff-counter').html(inactive_counter.recordsDisplay)
  $('.new-staff-counter').html(new_counter.recordsDisplay)

setCheckedList = (src) ->
  total_checked = $(".#{src}-staff-row:checked").length
  $(".delete-#{src}-staff-badge").html total_checked
  if total_checked > 0
    $(".delete-#{src}-staff").removeClass('hide')
    $("#clear_delete_staff_from_#{src}_table").removeClass('hide')
  else
    $(".delete-#{src}-staff").addClass('hide')
    $("#clear_delete_staff_from_#{src}_table").addClass('hide')

ready = ->
  $("body").on "click", ".close-staff-form-modal", (e) ->
    e.preventDefault()
    $("#staff_form_modal").modal('hide')

  $("body").on "click", ".close-tab-group", (e) ->
    e.preventDefault()
    groupId = $(this).data('id')
    part = $(this).data('part')
    console.log part
    $("#tab_#{groupId}").remove();
    $(".tab-content ##{groupId}").remove()
    $("##{part}_list").addClass 'in active'
    $('#staffs_tab a[href=\'#' + part + '_list\']').tab('show')

  if $('#staffs').length > 0
    tables = $('.staff-datatable').DataTable(
      "ordering": false,
      "dom": '<"top">rt<"bottom"i>p<"clear">'
    )

    $('body').on 'keyup', '#staff_search', () ->
      tables.tables().search($(this).val()).draw()
      setTabCounter(tables.tables([0]).page.info(), tables.tables([1]).page.info(), tables.tables([2]).page.info())

    $('body').on 'change', '.staff-filter', () ->
      tables.tables().columns($(this).data('column')).search($(this).val()).draw()
      setTabCounter(tables.tables([0]).page.info(), tables.tables([1]).page.info(), tables.tables([2]).page.info())

    $('body').on 'click', '#clear_filter_staff', (e) ->
      e.preventDefault();
      $('#staff_search').val('')
      $('.staff-filter').each () ->
        $(this).val('')
        tables.tables().columns($(this).data('column')).search('').draw()
      tables.tables().search('').draw()
      setTabCounter(tables.tables([0]).page.info(), tables.tables([1]).page.info(), tables.tables([2]).page.info())

    $('body').on 'change', '.staff-row', () ->
      src = $(this).data('src')
      id = $(this).data('id')
      is_checked = $(this).prop('checked')
      # show hide button delete
      setCheckedList(src)

      staffs = $("##{src}_staff_table").data('staff')
      if is_checked
        staffs.push(id)
      else
        index = staffs.indexOf(id);
        staffs.splice(index, 1);

      $("##{src}_staff_table").data('staff', staffs)

    $('body').on 'click', '.clear-staff-delete-selection', (e) ->
      e.preventDefault();
      src = $(this).data('src')
      $("#{src}-staff-row").prop('checked', false)
      setCheckedList(src)

    $('body').on 'click', '.delete-staff-btn', (e) ->
      e.preventDefault();
      status = $(this).data('status')
      url = $(this).data('url')
      staffs = $("##{status}_staff_table").data('staff')
      $.ajax(
        url: url
        method: 'PUT'
        data:
          staff:
            ids: staffs
      )


$(document).on 'turbolinks:load', ready