import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quizzes", "types", "choices", "template"]

  newQuiz(event) {
    const [data] = event.detail

    event.currentTarget.remove()
    this.quizzesTarget.insertAdjacentHTML("beforeend", data.content)
  }

  updateQuiz(event) {
    const [data] = event.detail
    event.currentTarget.innerHTML = data.content
  }

  onError(event) {
    document.querySelectorAll("#form-errors").forEach((el) => el.remove())

    const [data] = event.detail
    for (const [attr, errors] of Object.entries(data)) {
      var el = document.getElementById(`form-${attr}`)
      el.insertAdjacentHTML(
        "beforeend",
        `<span id="form-errors" class="text-danger">${errors[0]}</span>`
      )
    }
  }

  handleRadioButton(event) {
    let radios = this.choicesTarget.querySelectorAll(".choice-check")

    radios.forEach((radio) => {
      if (radio.id !== event.currentTarget.id && radio.type == "radio") {
        radio.checked = false
      }
    })
  }

  changeChoiceType(event) {
    const value = event.currentTarget.value
    let srcType = "radio",
      dstType = "checkbox"

    if (value === "single") {
      srcType = "checkbox"
      dstType = "radio"
    }

    this.choicesTarget.querySelectorAll(`input[type="${srcType}"]`).forEach((input) => {
      input.type = dstType
      input.checked = false
    })
  }

  addChoice(event) {
    event.preventDefault()

    const type = this.typesTarget.querySelector('input[name="quiz[type]"]:checked').value
    let content = this.templateTarget.innerHTML
    let srcType = "radio",
      dstType = "checkbox"

    if (type === "single") {
      srcType = "checkbox"
      dstType = "radio"
    }

    content = content.replace(/ANSWER_ID_TEMPLATE/g, new Date().valueOf())
    content = content.replace(srcType, dstType)
    this.choicesTarget.insertAdjacentHTML("beforeend", content)
  }

  removeChoice(event) {
    event.preventDefault()
    let choice = event.target.closest(".choice-fields")
    choice.remove()
  }
}

