import { Controller } from "stimulus"
import 'jquery.panzoom'
import AntigenicCartogPlot from '../vendor/antigenic-cartog'


export default class extends Controller {
  static targets = ["canvas"]

  connect(){
    const coordinate_path = $(this.canvasTarget).data('coordinate')
    const cluster_path = $(this.canvasTarget).data('cluster')

    let antigenicMap = null;
    antigenicMap = new AntigenicCartogPlot(coordinate_path, cluster_path, 4.0, 4.0);
    let $svg = $(".plotbox.display-controls svg");
    let $panzoom = $svg.panzoom();
    $panzoom.parent().on('mousewheel.focal', function (e) {
      e.preventDefault();
      let delta = e.delta || e.originalEvent.wheelDelta;
      let zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
      $panzoom.panzoom('zoom', zoomOut, {
        increment: 0.1,
        animate: false,
        focal: e
      });
    });
    $("#antigenic_map_zoom_reset").click(function () {
      $svg.panzoom('reset');
    });
    $("#antigenic_map_zoom_in").click(function () {
      $svg.panzoom('zoom', {
        increment: 0.25
      });
    });
    $("#antigenic_map_zoom_out").click(function () {
      $svg.panzoom('zoom', true, {
        increment: 0.25
      });
    });
    $("#antigenic_mapping_isolates_box").height($(".plotbox").height() - 50);
  }

}

