/* -----------------------------------------------------------------------------
//
// Language:       Javascript
// Script/Module:  antigenic-cartog.js
// Author:         Ivano Broz
// Created:        07-Aug-2013
//
// Description:    d3.js based graph renderer for plotting an antigenic map
//                 for influenza isolates
//
// Change Log:     24-Oct-2013 - Built into a function to prevent pollution
//                               of the global namespace
//
// -------------------------------------------------------------------------- */



export default function AntigenicCartogPlot(coordfileUri, clusterblocksUri, scaleX, scaleY) {

  // Initial configuration
  const parentWidth = $(".plotbox").width()
  var first_pass = true, width = parentWidth, height = parentWidth, margin = 10, glyph_size = 6;

  // Set scales
  var x = d3.scaleLinear().domain([-scaleX,scaleX]).range([margin, width-margin]);
  var y = d3.scaleLinear().domain([-scaleY,scaleY]).range([height-margin, margin]);


  // Provide internal reference to this object
  var cartogplot = this;

  // Prepare Canvas
  this.zoom = d3.zoom().scaleExtent([1,8]).on("zoom", zoomer);
  this.svghdl = setupCanvas.call(this,width, height, margin, scaleX, scaleY);

  // Prepare datasets and controls
  let point_data, legend_data;

  var show_controls = d3.select("#show-controls")
              // .on("change", function() { update(); });

  var group_filter = "none";
  var group_controls = d3.selectAll("input[name='group-highlight']")
              .on("change", function() { group_filter = this.value; setVisualFilterColor(); });

  let year_colours, clade_colours;

  const control_glyph_colour = "green";
  const anchor_glyph_colour  = "blue";
  const isolate_glyph_colour = "red";

  var mapTooltipOverlayPadding = 4;



  // Import data
  //

  d3.tsv(coordfileUri).then(function(tsv) {
    point_data = tsv;

    // Load overlay grouping data
    d3.json(clusterblocksUri).then(function(cluster_data){
      let year_group = d3.set();
      let clade_group = d3.set();

      // Merge data
      point_data.forEach(function (d) {
        let extra_data = cluster_data[d.antigen];
        if (extra_data != null) {
          for (const key in extra_data) {
            d[key] = extra_data[key];

            // Build legend list
            if (key=="year") { year_group.add(extra_data[key]); }
            if (key=="clade") { clade_group.add(extra_data[key]); }
          }
        }
      });

      // Build legend dataset
      legend_data = {
        "control" : "control",
        "anchor"  : "anchor",
        "isolate" : "isolate",
        "year"    : year_group.values().sort(),
        "clade"   : clade_group.values().sort()
      };

      // Setup colour scales
      year_colours = d3.scaleOrdinal(d3.schemeCategory10).domain(legend_data.year);
      clade_colours = d3.scaleOrdinal(d3.schemeCategory10).domain(legend_data.clade);

      // Plot points
      update();

      // Establish the static component of the Legend
      // installStaticLegend();

    });
  });

  function showTooltip(tooltipId) {
    var $overlayText = $("#map_tooltip_text_" + tooltipId);
    var label = "";
    var isolateName = $overlayText.attr("isolate-name");
    var clade = $overlayText.attr("clade");
    var isShowIsolateName = $overlayText.attr("is-show-isolate-name") == "true";
    var isShowClade = $overlayText.attr("is-show-clade") == "true";

    if (isShowIsolateName) {
      label += isolateName;
    }

    if (isShowClade) {
      if (isShowIsolateName) label += " clade ";
    	label += clade;
    }

    var x = parseInt($overlayText.attr("ori-x"));
    var y = parseInt($overlayText.attr("ori-y"));

    var r = $(".sample[isolate-name='" +  isolateName + "']").find("circle").attr("r");
    glyph_size = parseInt(r);
    if (isNaN(glyph_size)) {
      var w = $(".sample[isolate-name='" +  isolateName + "']").find("rect").attr("width");
      w = parseInt(w);
      glyph_size = w/2;
      x = x + w + 4;
      y = y + w + 6;
    } else {
      x = x + glyph_size
      y = y + glyph_size + 2
    }


  	$overlayText
      .attr("x", x )
      .attr("y", y )
      .html(label)
      .show()

    // bring to the front
    if (isShowIsolateName || isShowClade) {
      var $g = $overlayText.parent();
      $g.parent().append($g);
    }
  }

  function hideTooltip(tooltipId) {
  	var $overlayText = $("#map_tooltip_text_" + tooltipId);
    var $overlayBg = $("#map_tooltip_overlay_" + tooltipId);
    $overlayText.hide();
    $overlayBg.hide();
  }

  function updataTooltips() {
    $(".show-isolate-name-checkbox").each(function(){
      var $this = $(this);
      var tooltipId = $this.attr("tooltip-id");
      var $overlayText = $("#map_tooltip_text_" + tooltipId);
      $overlayText.attr("is-show-isolate-name", this.checked);
    });
    $(".show-clade-checkbox").each(function(){
      var $this = $(this);
      var tooltipId = $this.attr("tooltip-id");
      var $overlayText = $("#map_tooltip_text_" + tooltipId);
      $overlayText.attr("is-show-clade", this.checked);
    });
    $(".isolate-list-checkbox").each(function(){
      var $this = $(this);
      var tooltipId = $this.attr("tooltip-id");
      if (this.checked) {
        showTooltip(tooltipId);
      } else {
        hideTooltip(tooltipId);
      }
    });
  }
  this.updateTooltips = updataTooltips;



  // Filter the dataset based on the controls selection
  //
  function filteredDataset() {

    // Start by filtering out the data which is not to be shown
    var filtered_data = [];

    var visible = true;
    point_data.forEach(function(d) {
      if (visible || d.control == null) {
        filtered_data = filtered_data.concat(d);
      }
    });

    return filtered_data;
  }


  var isolates = [];
  let updateIsolateStatus = function(){
    var checkboxes = d3.selectAll(".isolate-legend-checkbox");
    let count = 0;

    for (var i=0; i<checkboxes.size(); i++) {
      if (checkboxes._groups[0][i].checked) count++;
    }
    d3.select(".isolate-status")
      .text("Showing " + count + " of " + checkboxes.size() + (checkboxes.size() > 1 ? " isolates" : " isolate"))

    d3.select(".isolate-checkbox-control").text("");
    if (count != 0) {
      d3.select(".isolate-checkbox-control")
        .append("a")
          .attr('class', 'link')
          .style("cursor", "pointer")
          .text("clear selection")
          .property("onclick", function(){
            return function(){
              d3.selectAll(".sample")
                .style("display", "none");
              d3.selectAll(".isolate-legend-checkbox")
                .property("checked", false);
                updateIsolateStatus();
            }
          })

    }

    if (count > 0 && count < checkboxes.size()) {
      d3.select(".isolate-checkbox-control")
        .append("span")
          .text(" | ")
    }

    if (count != checkboxes.size()) {
      d3.select(".isolate-checkbox-control")
        .append("a")
          .attr('class', 'link')
          .style("cursor", "pointer")
          .text("select all")
          .property("onclick", function(){
            return function(){
              d3.selectAll(".sample")
                .style("display", "");
              d3.selectAll(".isolate-legend-checkbox")
                .property("checked", true);
                updateIsolateStatus();
            }
          })
    }
  }
  this.updateIsolateStatus = updateIsolateStatus;

  var loadIsolateLegend = function(nodes) {
    var container = d3.select(".isolate-list");
    container.select("table").remove();
    var table = container.append("table");
    table.style("font-size", "12px");
    table.attr("class", "table table-hover table-condensed")

    // collect isolates
    for (var i=0; i<nodes.length; i++) {
      var exist = false;
      for (var j=0; j<isolates.length; j++) {
        if (isolates[j].name == nodes[i].name) {
          exist = true;
          break;
        }
      }
      if (!exist) isolates.push({name: nodes[i].name, id: nodes[i].antigen});
    }
    isolates = isolates.sort(function(first, second){
      var keyFirst = first.name.toUpperCase();
      var keySecond = second.name.toUpperCase();
      if(keyFirst < keySecond) return -1;
      if(keyFirst > keySecond) return 1;
      return 0;
    });

    var thead = table.append("thead");
    var thead_tr = thead.append("tr");
    thead_tr.append("th").attr("width", "1%");
    thead_tr.append("th").text("Isolate");
    thead_tr.append("th").text("Name");
    thead_tr.append("th").text("Clade");

    var container = table.append("tbody");

    var tr = container.selectAll("tr")
      .data(isolates)
      .enter()
      .append("tr")
        .attr("isolate-name", function(d) { return name })
    var checkbox = tr.append("td")
        .append("input")
          .attr("type", "checkbox")
          .attr("class", "isolate-legend-checkbox isolate-list-checkbox")
          .attr("tooltip-id", function(d) { return d.id; })
          .property("checked", true)
          .attr("isolate-name", function(d) { return d.name })
          .attr("data-iso-name", function(d) { return d.name }) // By Nano
          .attr("data-id", function(d) { return d.id }) // By Nano
          .property("onclick", function(){
            return function(){
              var isolate_name = $(this).attr("isolate-name");
              var display = this.checked ? "" : "none";
              cartogplot.svghdl
                .select(".sample[isolate-name='" + isolate_name + "']")
                .style("display", display);
              updateIsolateStatus();
            }
          })

    tr.append("td")
      .text(function(d) { return d.name })

    tr.append("td")
      .attr("align", "center")
      .append("input")
        .attr("type", "checkbox")
        .attr("tooltip-id", function(d) { return d.id; })
        .attr("data-iso-name", function(d) { return d.name })
        .attr("class", "show-isolate-name-checkbox")
        .property("checked", false)
        .on("click", function(){
          var $this = $(this);
          var tooltipId = $this.attr("tooltip-id");
          var $overlayText = $("#map_tooltip_text_" + tooltipId);
          $overlayText.attr("is-show-isolate-name", this.checked);
          updataTooltips();
        });

    tr.append("td")
      .attr("align", "center")
      .append("input")
        .attr("type", "checkbox")
        .attr("tooltip-id", function(d) { return d.id; })
        .attr("data-iso-name", function(d) { return d.name })
        .attr("class", "show-clade-checkbox")
        .property("checked", false)
        .on("click", function(){
          var $this = $(this);
          var tooltipId = $this.attr("tooltip-id");
          var $overlayText = $("#map_tooltip_text_" + tooltipId);
          $overlayText.attr("is-show-clade", this.checked);
          updataTooltips();
        });
    updateIsolateStatus();
  }


  // Draw data plot
  //
  function update() {

    // Update nodes set
    var nodes = filteredDataset();

    loadIsolateLegend(nodes);

    var node = cartogplot.svghdl.selectAll(".sample")
      .data(nodes, function(d) { return d.antigen; });

    // Process the enter selection to add any new glyphs
    // defined by the data
    var nodeEnter = node.enter()
      .append("g")
      .attr("class", "sample")
      .attr("isolate-name", function(d) { return d.name })
      .attr("id", function(d) { return d.antigen; })

    d3.selectAll(".sample")
      .style("display", "");

    // if (first_pass == false) {
    //  nodeEnter
    //    .attr("transform", function(d) {
    //      return "translate(" + x(+d.x) + "," + y(+d.y) + ")";
    //    });
    // }

    // Create glyphs
    // Controls
    nodeEnter.filter(function(d) { return d.control})
      .append("rect")
      .attr("class", "glyph")
      .attr("width", glyph_size * 2)
      .attr("height", glyph_size * 2)
      .attr("isolate-name", function(d) { return d.name })
      .attr("x", function(d) {return x(+d.x)})
      .attr("y", function(d) {return y(+d.y)})
      .attr("id", function(d) { return d.antigen; })
      .attr("type", "reference");

    // Isolates
    nodeEnter.filter(function(d) { return d.isolate})
      .append("circle")
      .attr("class", "glyph")
      .attr("isolate-name", function(d) { return d.name })
      .attr("cx", function(d) {return x(+d.x)})
      .attr("cy", function(d) {return y(+d.y)})
      .attr("r", glyph_size)
      .attr("id", function(d) { return d.antigen; })
      .attr("type", "sample");

    nodeEnter.append("text")
      .attr("class", "map-tooltip-text")
      .attr("id", function(d) { return "map_tooltip_text_" + d.antigen; })
      .attr("text-anchor", "start")
      .style("font-size", 11)
      .style("fill", "black")
      .attr("x", function(d) {return x(+d.x) + glyph_size + 2 })
      .attr("y", function(d) {return y(+d.y) + glyph_size + 2 })
      .attr("ori-x", function(d) {return x(+d.x) })
      .attr("ori-y", function(d) {return y(+d.y) })
      .attr("clade", function(d) {
        return d.clade;
      })
      .attr("isolate-name", function(d) {
        return d.name;
      })
      .attr("tooltip-id", function(d) {
        return d.antigen;
      })

    // Add Label
    // nodeEnter.append("text")
    //  .attr("text-anchor", "end")
    //  .attr("x", "-0.5em")
    //  .attr("dy", ".35em")
    //  .attr("transform", "rotate(30)")
    //  .text(function(d) { return d.name; })

    showDetailIsolate();

    // Update all existing nodes.  By processing the enter selection nodes first
    // they are included in the update selection so all nodes for this cycle can be
    // processed at the same time
    nodeEnter.selectAll(".glyph")
      .style("fill", function(d) {
        if (group_filter == 'year') {
          return year_colours(d.year);
        } else if (group_filter == 'clade') {
          return clade_colours(d.clade);
        }
        return (d.control) ? ((d.anchor) ? anchor_glyph_colour : control_glyph_colour) : isolate_glyph_colour;
      })
      .style("opacity", function(d) {
        if (group_filter == 'year') {
          return 0.9;
        } else if (group_filter == 'clade') {
          return 0.9;
        }
        return 0.7;
      });

    // Cleanup any dropped nodes
    node.exit().remove();

    // Move to final location
    // if (first_pass == true) {
    //  node.transition().duration(1000)
    //    .attr("transform", function(d) {
    //      return "translate(" + x(+d.x) + "," + y(+d.y) + ")";
    //    });
    //  first_pass = false;
    // }


    // Update legend
    var dynamic_legend_data = [];
    var label;
    if (group_filter == 'year') {
      label = "Year";
      legend_data.year.forEach(function(year) {
        dynamic_legend_data = dynamic_legend_data.concat(
          {"id"     : year,
           "colour" : year_colours(year),
           "label"  : year}
        );
      });
    }

    if (group_filter == 'clade') {
      label = "Clade";
      legend_data.clade.forEach(function(clade) {
        dynamic_legend_data = dynamic_legend_data.concat(
          {"id"     : clade,
           "colour" : clade_colours(clade),
           "label"  : clade}
        );
      });
    }

    // Remove and replace any legend
    var legend_handle = installStaticLegend();

    if (group_filter != 'none') {

      legend_handle.append("text")
        .text(label)
        .attr("class", "dynamic_legend_title")
        .attr("text-anchor", "start")
        .attr("y", "90")

      var d_entries = legend_handle.selectAll(".d_entry")
        .data(dynamic_legend_data)
        .enter()
        .append("g")
        .attr("class", "d_entry")
        .attr("transform", function(d,i) {
          return "translate(" + (glyph_size + 0.5) + "," + (110 + i*glyph_size*3) + ")";
        });

      d_entries.append("circle")
        .attr("r", glyph_size)
        .style("fill", function(d) { return d.colour; })
        .style("opacity", "0.9");


      d_entries.append("text")
        .attr("text-anchor", "start")
        .attr("x", (25 - glyph_size))
        .attr("dy", "0.35em")
        .text(function(d) {return d.label;});
    }

  }


  // Change color for filter visualitation by year or clade
  function setVisualFilterColor(){
    var nodes = filteredDataset();
    var node = cartogplot.svghdl.selectAll(".sample")
      .data(nodes, function(d) { return d.antigen; });

    // Update all existing nodes.  By processing the enter selection nodes first
    // they are included in the update selection so all nodes for this cycle can be
    // processed at the same time
    node.selectAll(".glyph")
      .style("fill", function(d) {
        if (d.anchor) {
          return anchor_glyph_colour;
        } else if (d.control) {
          return control_glyph_colour;
        } else if (group_filter == 'year') {
          return year_colours(d.year);
        } else if (group_filter == 'clade') {
          return clade_colours(d.clade);
        } else {
          return isolate_glyph_colour;
        }
        // return (d.control) ? ((d.anchor) ? anchor_glyph_colour : control_glyph_colour) : isolate_glyph_colour;
      })
      .style("opacity", function(d) {
        if (group_filter == 'year') {
          return 0.9;
        } else if (group_filter == 'clade') {
          return 0.9;
        }
        return 0.7;
      });

    // Cleanup any dropped nodes
    node.exit().remove();

    // Update legend
    var dynamic_legend_data = [];
    var label;
    if (group_filter == 'year') {
      label = "Year";
      legend_data.year.forEach(function(year) {
        dynamic_legend_data = dynamic_legend_data.concat(
          {"id"     : year,
           "colour" : year_colours(year),
           "label"  : year}
        );
      });
    }

    if (group_filter == 'clade') {
      label = "Clade";
      legend_data.clade.forEach(function(clade) {
        dynamic_legend_data = dynamic_legend_data.concat(
          {"id"     : clade,
           "colour" : clade_colours(clade),
           "label"  : clade}
        );
      });
    }

    // Remove and replace any legend
    var legend_handle = installStaticLegend();
    var legend_handle_height = 120 + dynamic_legend_data.length * glyph_size * 3;
    legend_handle.attr("height", legend_handle_height);

    if (group_filter != 'none') {
      legend_handle.append("text")
        .text(label)
        .attr("class", "dynamic_legend_title")
        .attr("text-anchor", "start")
        .attr("y", "90");

      var d_entries = legend_handle.selectAll(".d_entry")
        .data(dynamic_legend_data)
        .enter()
        .append("g")
        .attr("class", "d_entry")
        .attr("transform", function(d,i) {
          return "translate(" + (glyph_size + 0.5) + "," + (110 + i*glyph_size*3) + ")";
        });

      d_entries.append("circle")
        .attr("r", glyph_size)
        .style("fill", function(d) { return d.colour; })
        .style("opacity", "0.9");


      d_entries.append("text")
        .attr("text-anchor", "start")
        .attr("x", (25 - glyph_size))
        .attr("dy", "0.35em")
        .text(function(d) {return d.label;});
    }
  }


  function zoomer() {
    // cartogplot.svghdl.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
  }


  // Define the canvas
  //
  function setupCanvas(width, height, margin, scaleX, scaleY) {


    // Declare canvas
    var svg = d3.select(".plotbox")
      .append("svg")
      .attr("width", width)
      .attr("height", height)
    .append("g")
      // .call(this.zoom);

    svg.append("rect")
      .attr("class", "overlay")
      .attr("width", width)
      .attr("height", height);

    var xAxis = d3.axisBottom(x)

    var yAxis = d3.axisLeft(y)

    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(0," + (height/2) + ")")
      .call(xAxis);

    svg.append("g")
      .attr("class", "axis")
      .attr("transform", "translate(" + (width/2) + ",0)")
      .call(yAxis);

    // Grid
    var xgap = scaleX/4, ygap = scaleY/5;

    svg.selectAll(".h").data(d3.range(-scaleY,(scaleY + ygap),ygap)).enter()
      .append("line").classed("h",1)
      .attr("x1", margin).attr("x2", width-margin)
      .attr("y1",y).attr("y2",y);

    svg.selectAll(".v").data(d3.range(-scaleX,(scaleX + xgap),xgap)).enter()
      .append("line").classed("v",1)
      .attr("y1", margin).attr("y2", height-margin)
      .attr("x1",x).attr("x2",x);

    return svg;
  }



  // Scrap the old legend and rebuild the static component
  //
  function installStaticLegend() {

    // Remove and replace any dynamic legend
    d3.select(".legend").select("svg").remove();

    var legend_handle = d3.select(".legend")
      .append("svg")
        .attr("id", "svg_box_legend")
        .attr("height", 60)

    // Install static legend
    var ctrl = legend_handle.append("g")
      .attr("transform", function(d) {
        return "translate(0.5,0)";
      });

    ctrl.append("rect")
      .attr("width", glyph_size * 2)
      .attr("height", glyph_size * 2)
      .style("fill", control_glyph_colour)
      .style("opacity", "0.7");

    ctrl.append("text")
      .attr("text-anchor", "start")
      .attr("x", "25")
      .attr("dy", "0.8em")
      .text("Reference Antigen");

    // Anchor key
    var anchor = legend_handle.append("g")
      .attr("transform", function(d) {
        return "translate(0.5," + (glyph_size * 3.5) + ")";
      });

    anchor.append("rect")
      .attr("width", glyph_size * 2)
      .attr("height", glyph_size * 2)
      .style("fill", anchor_glyph_colour)
      .style("opacity", "0.7");

    anchor.append("text")
      .attr("text-anchor", "start")
      .attr("x", "25")
      .attr("dy", "0.8em")
      .text("Anchor");


    var isolate = legend_handle.append("g")
      .attr("transform", function(d) {
        return "translate(" + (glyph_size + 0.5) + "," + (glyph_size * 8) + ")";
      });


    if (group_filter != "year" && group_filter != "clade") {
      isolate.append("circle")
        .attr("r", glyph_size)
        .style("fill", isolate_glyph_colour)
        .style("opacity", "0.7");
      isolate.append("text")
        .attr("text-anchor", "start")
        .attr("x", (25 - glyph_size))
        .attr("dy", "0.35em")
        .text("Test Antigen");
    }

    return legend_handle;
  }
}

AntigenicCartogPlot.prototype.reset = function() {
  this.zoom.scale(1);
  this.svghdl.attr("transform","translate(0,0)")
}