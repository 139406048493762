window.loadLocationMap = () ->
  # initialize size
  w = $(".col-md-9").width()
  # alert(w)
  size =
    width: w,
    height: w/2.5

  center = [size.width/2 , size.height/2]

  # create canvas
  canvas = d3.select("#locations_map").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#7fc8ed")
    .attr("cursor", "grab")

  # Generate MAP
  d3.json "/outbreaks/indonesia_districts.json", (data) ->
    group = canvas.selectAll("g.dis")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis")

    projection = d3.geo.mercator().scale(w*1.2).translate([-size.width*1.98, (size.height)/2.7]);
    path = d3.geo.path().projection(projection);

    # write(coloring) isolates data on map
    areas = group.append("path")
      .attr("d", path)
      # .attr("class", "area")
      .attr("class", (d,i) ->
        return "area "+d.properties.KODE_KAB;
      )
      .style("stroke", "gray")
      .style("stroke-width", "0.1")
      .attr("fill", "white")

    circle = canvas.selectAll("g.dis.cir")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis cir")
      .attr("id", (d,i) ->
        return d.properties.KODE_KAB;
      )

    # write(coloring) isolate data on map
    circle.append('svg:circle')
      .attr('cx', (d) -> path.centroid(d)[0])
      .attr('cy', (d) -> path.centroid(d)[1])
      .attr('r', 0)
      .style("opacity", "0.1")
      .style("fill", "white")

    zoomed = () ->
      canvas.selectAll(".dis").attr("transform", "translate("+zoom.translate()+")scale("+zoom.scale()+")");
    # zoom behavior
    zoom = d3.behavior.zoom()
          .on("zoom", zoomed);
    canvas.call(zoom).on("dblclick.zoom", null);

    window.zoomTo = (cx, cy, dis_id) ->
      zoom.scale( zoom.scale()/2 )
      zoom_scale = 30
      canvas.selectAll(".dis").transition()
        .duration(350)
        .attr("transform", "translate(" + size.width/2 + "," + size.height/2 + ")scale(" + zoom_scale + ")translate(" + -cx + "," + -cy + ")")

      $(".#{dis_id}").fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800)

      translate = zoom.scale(zoom_scale).translate([size.width/2 - cx * zoom_scale, size.height/2 - cy * zoom_scale]);

window.location_district_focus = (dis_id) ->
  cx = $("##{dis_id} circle").attr('cx')
  cy = $("##{dis_id} circle").attr('cy')
  zoomTo(cx, cy, dis_id)

# autocomplete for subdistrict
window.locationDistrictAutocomplete = () ->
  $.ajax
    url: $(".district-code-autocomplete").data("source")
    dataType: 'json'
    data:
      by_province_id: $("#district_province_id").val()
    success: (data) ->
      $(".district-code-autocomplete").find("option").remove()
      $.each data, (i, dt) ->
        label = "#{dt.district_code}, #{dt.area_name}"
        option = "<option value='#{dt.district_code}'>#{label}</option>"
        $(".district-code-autocomplete").append(option)

      return

ready = ->

  # if $("#locations_map").length
    # loadLocationMap()

  # $("body").on "click", ".district_name", (e) ->
  #   e.preventDefault()
  #   dis_id = $(this).attr("href")
  #   location_district_focus(dis_id)

  $("body").on "click", ".district_name", (e) ->
    e.preventDefault()
    url = $(this).attr("href")
    dis_id = $(this).data("district-id")
    $.ajax
      url: url
      dataType: "SCRIPT"
      method: "GET"
      data:
        district_id: dis_id

  $("body").on "change", "#district_province_id", (e) ->
    locationDistrictAutocomplete()

  $("body").on "click", ".update-isolates-district-code-btn", (e) ->
    e.preventDefault();
    url = $(this).attr('href');
    district_id = $(this).closest('tr').find("input[name='district_id']").val();
    district_code = $(this).closest('tr').find("select[name='district_code']").val();
    $.ajax
      url: url
      dataType: "SCRIPT"
      method: "GET"
      data:
        district_id: district_id
        district_code: district_code

$(document).on 'turbolinks:load', ready