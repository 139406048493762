# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/
settingObj = {}
isoArr = []
antigenicMapWidth = 1250
antigenicMapHeight = 1250
antigenicMapLegendSpacing = 30
antigenicMapPaddingTop = 25

antigenicMapWithLegendWidth = () ->
  return antigenicMapWidth + antigenicMapLegendSpacing + $("#svg_box_legend").width();

antigenicMapWithLegendHeight = () ->
  return Math.max(antigenicMapHeight, $("#svg_box_legend").height()) + antigenicMapPaddingTop;

constructAntigenicMapSVG = () ->
  svg = $(".plotbox").html();
  $buffer = $("#svg_buffer");
  $buffer.html(svg);
  $buffer.find("svg").attr({ version: '1.1' , xmlns:"http://www.w3.org/2000/svg" });
  $buffer.find("svg").attr("style", "");
  $buffer.find("svg > g").attr("transform", "");

  legendXOffset = (antigenicMapWidth + antigenicMapLegendSpacing)
  legend = "<g transform='translate(" + legendXOffset + ", " + antigenicMapPaddingTop + ")'><text font-size='17' style='font-weight: bold'>Legend :</text></g>";
  legend += "<g transform='translate(" + legendXOffset + ", " + (antigenicMapPaddingTop + 10) + ")' style='font-size:17px'>" + $(".legend svg").html() + "</g>";

  # run_info = "<g transform='translate(820, 140)'>\
  #   <text font-size='17' style='font-weight: bold'>\
  #     <tspan>Antigenic Run Information :</tspan>\
  #   </text>\
  # </g>";
  # run_info += "<g transform='translate(820, 145)'>\
  #   <text font-size='17' class='run-info'>\
  #   </text>\
  # </g>";

  $buffer.find("svg")
    .attr("width", antigenicMapWithLegendWidth())
    .attr("height", antigenicMapWithLegendHeight())
    .append(legend)
    # .append(run_info);

  # Insert Run Information
  # $runInfo = $buffer.find(".run-info");
  # $trs = $(".antigenic-run-table .run-row");
  # infos = []
  # max_length = 0
  # $trs.each ->
  #   label = $(this).find(".run-label").html();
  #   value = $(this).find(".run-value").html();
  #   max_length = label.length if label.length > max_length
  #   infos.push({
  #     label: label,
  #     value: value
  #   });
  # for i in [0...infos.length]
  #   label = infos[i].label
  #   $runInfo.append("<tspan x='0' dy='20'>" + label + "</tspan>" + "<tspan dx='" + max_length * 8 + "' dy='0'> : " + infos[i].value + "</tspan>");

  $buffer.find(".overlay").css("fill", "none");
  $buffer.find(".h, .v").css
    "stroke" : "rgb(20, 20, 20)",
    "stroke-dasharray" : "4 4",
    "stroke-width" : 1,
    "stroke-opacity" : 0.5
  $buffer.find(".diag").css
    "stroke": "blue"
    "stroke-dasharray": "10 10"
    "stroke-width": 1;
    "stroke-opacity": 0.5
  $buffer.find(".axis-label").css
    "font-weight": "bold"
    "fill": "#333333"
  $buffer.find(".axis path, .axis line").css
    "fill": "none"
    "stroke": "black"
    "shape-rendering": "crispEdges"
  # $buffer.find("g.sample text").css
  #   "display": "none"
  #   "fill": "blue"
  #   "font-size": "14px"
  $buffer.find(".map-tooltip-text, .map-tooltip-overlay").each () ->
    $this = $(this);
    if !$this.css("display") or $this.css("display") == "none"
      $this.remove();

  $("#svg_input").html($buffer.html());

  # w = window.open("")
  # w.document.body.innerHTML = $buffer.html();

checkIsolateCount = ->
  isolatesCount = $(".isolate-legend-checkbox").length
  checkedCount = $(".isolate-legend-checkbox:checked").length
  if isolatesCount != checkedCount
    msg = "Only " + checkedCount + " of " + isolatesCount + " "
    if isolatesCount > 1
      msg += "isolates"
    else
      msg += "isolate"
    msg += " displayed on Antigenic Map, are you sure to download the Antigenic Map?"
    result = confirm(msg)
    if !result
      return false
  return true

window.showDetailIsolate = () ->
  d3.select(".overlay").on "click", (d) ->
    $(".new-slide-panel#antigenic_map_isolate_detail").hide('slide', {easing: 'easeOutQuint', direction: 'left'}, 300)

  d3.selectAll(".glyph").each (d) ->
    $this = d3.select(this)
    if $this.attr("type") is "reference"
      x = $this.attr("x")
      y = $this.attr("y")
      place = [6, 6]
      link = "/antigens/"+$this.attr("id").split("_")[1]
    else if $this.attr("type") is "sample"
      x = $this.attr("cx")
      y = $this.attr("cy")
      place = [6, 6]
      link = "/isolates/"+$this.attr("id").split("_")[1]

    content = "<div>"+$this.attr("isolate-name")+"</div>";
    $this.tooltip () ->
      return {
        type: "tooltip",
        title: "Object",
        text: content,
        trigger: "mouseover",
        placement: "mouse",
        gravity: "right",
        position: [parseFloat(x), parseFloat(y)],
        displacement: place,
        mousemove: false
      }
    $(this.parentNode).on "click", (d) ->
      $(".new-slide-panel#antigenic_map_isolate_detail").show('slide', {easing: 'easeOutQuint', direction: 'left'}, 300)
      $(".new-slide-panel#antigenic_map_isolate_detail .panel-heading .isolate-name-place").html("<b>"+$this.attr("isolate-name")+"</b>")
      $.ajax
        url: link
        dataType: "SCRIPT"
        method: "GET"

window.showAntigenicMapSetting = (data) ->
  json = JSON.parse(data)
  $('#show-controls').prop 'checked', eval(json.control)

  $('.visual-filter').each ->
    if $(this).val() == json.visual
      $(this).trigger("click")
      $(this).prop("checked", true)

  d3.selectAll(".sample").style("display", "none")
  $(".isolate-list-checkbox").prop("checked", false)
  $.each json.isolates, (i,v) ->
    d3.selectAll(".sample").each () ->
      me = d3.select(this)
      if me.attr("isolate-name") is v
        me.style("display", "")

    $(".isolate-list-checkbox").each () ->
      if $(this).attr("isolate-name") is v
        $(this).prop("checked", true)

  $(".show-isolate-name-checkbox").prop("checked", false)
  $.each json.labels, (i,v) ->
    $(".show-isolate-name-checkbox").each () ->
      if $(this).data("iso-name") is v
        $(this).prop("checked", true)

  $(".show-clade-checkbox").prop("checked", false)
  $.each json.clades, (i,v) ->
    $(".show-clade-checkbox").each () ->
      if $(this).data("iso-name") is v
        $(this).prop("checked", true)

  ref_size = 12
  sample_size = 6
  if json.ref_size
    ref_size = json.ref_size
  if json.sample_size
    sample_size = json.sample_size
  d3.selectAll("rect.glyph").attr("height", ref_size)
  d3.selectAll("rect.glyph").attr("width", ref_size)
  d3.selectAll("circle.glyph").attr("r", sample_size)

  $("#antigenic_map_setting_setting").val JSON.stringify(json)
  window.antigenicMap.updateTooltips();
  window.antigenicMap.updateIsolateStatus();

window.resetAntigenicMapSetting = () ->
  $('#show-controls').prop 'checked', true

  $('.visual-filter').each ->
    if $(this).val() == "none"
      $(this).trigger("click")
      $(this).prop("checked", true)

  d3.selectAll(".sample").style("display", "")
  $(".isolate-list-checkbox").prop("checked", true)
  $(".show-isolate-name-checkbox, .show-clade-checkbox").prop("checked", false)

  $(".show-isolate-name-checkbox").prop("checked", false)
  d3.selectAll("rect.glyph").attr("height", 12)
  d3.selectAll("rect.glyph").attr("width", 12)
  d3.selectAll("circle.glyph").attr("r", 6)
  window.antigenicMap.updateTooltips();
  window.antigenicMap.updateIsolateStatus();

showReference = (visibility,checked) ->
  d3.selectAll('.sample').each (d) ->
    $this = d3.select(this)
    if $this.attr("id").split("_")[0] is "ref"
      $this.style("display", visibility)
      $(".isolate-list-checkbox").each () ->
        if $this.attr("isolate-name") is $(this).attr("isolate-name")
          $(this).prop("checked", checked)

setControl = () ->
  target = $("#show-controls")
  if target.is(":checked") is true
    settingObj["control"] = "true"
  else
    settingObj["control"] = "false"
  # console.log settingObj
  $("#antigenic_map_setting_setting").val ""
  $("#antigenic_map_setting_setting").val JSON.stringify(settingObj)

setVisualFilter = () ->
  $(".visual-filter").each () ->
    if $(this).is(":checked") is true
      settingObj["visual"] = $(this).val()
  # console.log settingObj
  $("#antigenic_map_setting_setting").val ""
  $("#antigenic_map_setting_setting").val JSON.stringify(settingObj)

setIsolates = () ->
  isoArr = []
  $(".isolate-legend-checkbox:checked").each () ->
    isoArr.push $(this).data "iso-name"
  settingObj["isolates"] = isoArr

  labelArr = []
  $(".show-isolate-name-checkbox:checked").each () ->
    labelArr.push $(this).data "iso-name"
  settingObj["labels"] = labelArr

  cladeArr = []
  $(".show-clade-checkbox:checked").each () ->
    cladeArr.push $(this).data "iso-name"
  settingObj["clades"] = cladeArr

  # console.log settingObj
  $("#antigenic_map_setting_setting").val ""
  $("#antigenic_map_setting_setting").val JSON.stringify(settingObj)

getGlyphSize = () ->
  settingObj["ref_size"] = d3.selectAll("rect.glyph").attr("height")
  settingObj["sample_size"] = d3.selectAll("circle.glyph").attr("r")

  $("#antigenic_map_setting_setting").val ""
  $("#antigenic_map_setting_setting").val JSON.stringify(settingObj)

setGlyphSize = (value) ->
  d3.selectAll(".glyph").each () ->
    $this = d3.select(this)
    if $this.attr("type") is "reference"
      if value is "bigger"
        $this.attr("height", parseInt($this.attr("height"))+2)
        $this.attr("width", parseInt($this.attr("width"))+2)
      else if value is "smaller"
        $this.attr("height", parseInt($this.attr("height"))-2)
        $this.attr("width", parseInt($this.attr("width"))-2)
      else if value is "reset"
        $this.attr("height", 12)
        $this.attr("width", 12)
    else if $this.attr("type") is "sample"
      if value is "bigger"
        $this.attr("r", parseInt($this.attr("r"))+1)
      else if value is "smaller"
        $this.attr("r", parseInt($this.attr("r"))-1)
      else if value is "reset"
        $this.attr("r", 6)
  if (window.antigenicMap)
    window.antigenicMap.updateTooltips();

ready = ->
  $("body").on "click", ".btn-hide-new-slide-panel-isolate-detail", (e) ->
    e.preventDefault()
    $(".new-slide-panel#antigenic_map_isolate_detail").hide('slide', {easing: 'easeOutQuint', direction: 'left'}, 300)

  $("body").on "click", "#show-controls", ->
    if $(this).is(":checked") is true
      showReference("", true)
    else
      showReference("none", false)

    setControl()
    setVisualFilter()
    setIsolates()
    getGlyphSize()

  $("body").on "change", ".visual-filter", ->
    setControl()
    setIsolates()
    getGlyphSize()
    if $(this).is(":checked") is true
      settingObj["visual"] = $(this).val()

    $("#antigenic_map_setting_setting").val ""
    $("#antigenic_map_setting_setting").val JSON.stringify(settingObj)

  $("body").on "click", ".isolate-legend-checkbox", ->
    setControl()
    setVisualFilter()
    getGlyphSize()
    type = $(this).data("id").split("_")[0]
    if type is "ref"
      if $("#show-controls").is(":checked") is true
        setIsolates()
      else
        $(this).prop("checked", false)
        d3.selectAll(".sample").each () ->
          if d3.select(this).attr("id").split("_")[0] is "ref"
            d3.select(this).style("display", "none")
        alert("Error! Change visualization for show or hide control data.")
    else
      setIsolates()

  $("body").on "click", ".show-isolate-name-checkbox", ->
    setIsolates();

  $("body").on "click", ".show-clade-checkbox", ->
    setIsolates();

  $("body").on "click", "#run_antigenic_map", (e) ->
    e.preventDefault()
    href = $(this).attr("href");
    # href += "?tool=" + $("#ant_select_tool").val()
    href += "?threshold=" + $("#ant_threshold").val()
    href += "&page=antigenic_map"
    href += "&norm_method=" + $("#ant_norm_method").val()
    href += "&temporal_model=" + $("#ant_temporal_model").is(":checked") ? 2 : 1
    href += "&rebuild_files=" + $("#rebuild_data").is(":checked") ? "true" : "false"
    Turbolinks.visit(href);
    $(this).attr("disabled", "disabled").html("Analyzing..");
    return false;

  $("body").on "click", ".btn-resize-dot-antigenic-mapping", (e) ->
    e.preventDefault()
    if $(this).attr("href") is "#bigger"
      setGlyphSize("bigger")
    else if $(this).attr("href") is "#smaller"
      setGlyphSize("smaller")
    else if $(this).attr("href") is "#reset"
      setGlyphSize("reset")

    setControl()
    setVisualFilter()
    setIsolates()
    getGlyphSize()

  ###
  # OLD PROCEDURE, DEPENDS ON HIGHCHART API
  $("body").on "click", "#download_as_pdf", ->
    if checkIsolateCount() == false
      return false
    $("#download_type").val("application/pdf");
    constructAntigenicMapSVG();
    $("#svg_converter").submit();
    return false;

  $("body").on "click", "#download_as_png", ->
    if checkIsolateCount() == false
      return false
    $("#download_type").val("image/png");
    constructAntigenicMapSVG();
    $("#svg_converter").submit();
    return false;

  $("body").on "click", "#download_as_jpeg", ->
    if checkIsolateCount() == false
      return false
    $("#download_type").val("image/jpeg");
    constructAntigenicMapSVG();
    $("#svg_converter").submit();
    return false;

  $("body").on "click", "#download_as_svg", ->
    if checkIsolateCount() == false
      return false
    $("#download_type").val("image/svg+xml");
    constructAntigenicMapSVG();
    $("#svg_converter").submit();
    return false;
  ###

  $("body").on "click", "#download_as_png", (e) ->
    e.preventDefault()
    if checkIsolateCount() == false
      return false
    downloadAntigenicMap("png")

  $("body").on "click", "#download_as_jpeg", (e) ->
    e.preventDefault()
    if checkIsolateCount() == false
      return false
    downloadAntigenicMap("jpg")

  $("body").on "click", "#download_as_svg", ->
    if checkIsolateCount() == false
      return false
    downloadAntigenicMap("svg")

  $("body").on "click", "#download_as_pdf", (e) ->
    e.preventDefault()
    if checkIsolateCount() == false
      return false
    downloadAntigenicMap("pdf")

  $("body").on "click", "#btn_toggle_form_antigenic_map_setting", (e) ->
    e.preventDefault()
    $("#antigenic_map_modal_save_setting").removeClass("hide")
    $("#antigenic_map_modal_setting").addClass("hide")

  $("body").on "click", "#btn_toggle_form_antigenic_map_setting_cancel", (e) ->
    e.preventDefault()
    $("#antigenic_map_modal_save_setting").addClass("hide")
    $("#antigenic_map_modal_setting").removeClass("hide")


constructAntigenicMapCanvas = () ->
  constructAntigenicMapSVG()
  svg = $("#svg_input").val()
  c = document.getElementById('antigenic_map_canvas')
  c.width = antigenicMapWithLegendWidth();
  c.height = antigenicMapWithLegendHeight();
  ctx = c.getContext('2d')
  ctx.font = "11px Open Sans";
  ctx.fillStyle = "rgb(255,255,255)"
  ctx.fillRect(0, 0, antigenicMapWithLegendWidth(), antigenicMapWithLegendHeight())
  ctx.fillStyle = "rgb(0,0,0)"
  ctx.drawSvg(svg, 0, 5, antigenicMapWithLegendWidth(), antigenicMapWithLegendHeight())

downloadAntigenicMap = (type) ->
  mimes = {
    "jpg": "image/jpeg",
    "png": "image/png",
    "pdf": "application/pdf"
  }
  constructAntigenicMapCanvas()
  canvas = document.getElementById("antigenic_map_canvas")
  if type == "svg"
    svg = $("#svg_input").val()
    b64 = btoa(svg)
    data_uri = "data:image/svg+xml;base64,\n" + b64
  else if type == "pdf"
    jpg = canvas.toDataURL(mimes["jpg"])
    doc = new jsPDF("l", "px", [antigenicMapWithLegendWidth(), antigenicMapWithLegendHeight()])
    doc.addImage(jpg, 'JPEG', 0, 0, antigenicMapWithLegendWidth(), antigenicMapWithLegendHeight())
    data_uri = doc.output("datauristring")
  else
    data_uri = canvas.toDataURL(mimes[type])
  filename = $("#filename").val();
  filename += " - " + $("#panel_title_setting_label").html() + " Setting"
  link = document.createElement('a');
  link.download = filename + "." + type;
  link.href = data_uri;
  link.click();

$(document).on 'turbolinks:load', ready