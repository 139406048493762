class @Wiki
  @SHOW: '#wiki_show_wrapper'
  @INDEX: '#wiki_index_wrapper'

  constructor: (id) ->
    @id = id
    @item = $("#wiki_#{@id}")
    @preview = $(@constructor.SHOW)
    @list = $(@constructor.INDEX)

  show: (showItem) ->
    @constructor.resetIndex()
    @preview.html(showItem)
    @item.addClass("active")

  create: (showItem, listItem) ->
    @constructor.resetIndex()
    @preview.html(showItem)
    @list.append(listItem)

  update: (showItem) ->
    @constructor.resetIndex()
    @preview.html(showItem)
    @item.replaceWith(listItem)

  destroy: (nextId, nextItem) ->
    @constructor.resetIndex()
    @item.remove()

    # render next item (to always show an item)
    $("#wiki_#{nextId}").addClass("active")
    @preview.html(nextItem)

  @renderForm: (f) ->
    $(@SHOW).html(f)

  @updateList: (c) ->
    $(@INDEX).html(c)

  @resetIndex: ->
    $(@INDEX).find(".list-group-item.active").removeClass("active")

ready = ->

$(document).on 'turbolinks:load', ready