ready = ->
  $("body").off("click", ".add-comment-btn").on "click", ".add-comment-btn", (e) ->
    e.preventDefault()
    template = $("#comment_form_template").html()
    wrapper = $(".comment-form")
    data = JSON.parse($(this).attr("data-comment"))

    wrapper.html(Mustache.render(template, data))
    $(".comments-add-link").addClass("hide")
    $(".no-comment-message").addClass("hide")

  $("body").off("click", ".edit-comment-btn").on "click", ".edit-comment-btn", (e) ->
    e.preventDefault()
    template = $("#edit_comment_form_template").html()
    comment_wrapper = $(this).closest(".comment-card")
    wrapper = comment_wrapper.find(".comment-edit-form")
    data = JSON.parse($(this).attr("data-comment"))
    url = $(this).attr('data-url')
    data["url"] = url
    data["token"] = $('meta[name="csrf-token"]').attr('content')

    wrapper.html(Mustache.render(template, data))
    comment_wrapper.find(".comment-content").addClass("hide")

  $("body").off("click", ".comment-cancel-btn").on "click", ".comment-cancel-btn", (e) ->
    e.preventDefault()
    form_wrapper = $(this).closest("form")
    form_id = form_wrapper.attr("id")
    if form_id == "new_comment"
      $(".comments-add-link").removeClass("hide")
      $(".no-comment-message").removeClass("hide")
      $(".comment-form").html("")
    else
      comment_wrapper = $(this).closest(".comment-card")
      comment_wrapper.find(".comment-content").removeClass("hide")
      comment_wrapper.find(".comment-edit-form").html("")

$(document).on 'turbolinks:load', ready