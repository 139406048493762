import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cards", "cardContent"]

  addCard(event) {
    const el = event.currentTarget
    const url = el.dataset.cardUrl
    const type = el.dataset.cardType
    const position = parseInt(this.cardsTarget.querySelectorAll(".nav-link").length)
    const name = `Card #${type.toUpperCase()} #${position + 1}`

    this._createCard(name, url, type, position)
  }

  updateCard(event) {
    const [data] = event.detail
    event.currentTarget.innerHTML = data.content
  }

  cardDetail(event) {
    const [data] = event.detail
    this.cardContentTarget.innerHTML = data.content
  }

  // Private

  _createCard(name, url, type, position) {
    const token = document.querySelector("meta[name=csrf-token]").content
    const body = JSON.stringify({ card: { title: name, type: type, position: position } })

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": token,
      },
      body: body,
    })
      .then((res) => {
        if (!res.ok) throw res
        return res.json()
      })
      .then((data) => {
        this.cardsTarget.insertAdjacentHTML(
          "beforeend",
          this._cardListTemplate(data.title, data.url)
        )
      })
      .catch((error) => console.error(error))
  }

  _cardListTemplate(title, cardUrl) {
    return `<a href="${cardUrl}" class="nav-link" data-toggle="tab" data-action="ajax:success->trainings--card#cardDetail" data-remote="true" data-disable-with="Loading...">${title}</a>`
  }
}

