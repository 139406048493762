ready = ->

  $("body").on 'change', '.upcoming-isolates-check-all-vetlab', ->
    # alert('halo')
    $("input:checkbox").prop('checked', $(this).prop("checked"))

  # ================================= Chart configuration ====================================
  month_array = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

  min_year = 0
  start_month = 0
  end_month = 0
  start_year = 0
  end_year = 0

  # get month
  window.loadDate = () ->
    if typeof $("input[name=\"upcoming_isolates_start_month\"]").val() == 'undefined'
      start_month = 0
    else
      start_month = $("input[name=\"upcoming_isolates_start_month\"]").val()

    if typeof $("input[name=\"upcoming_isolates_end_month\"]").val() == 'undefined'
      end_month = 0
    else
      end_month = $("input[name=\"upcoming_isolates_end_month\"]").val()

    if typeof $("input[name=\"upcoming_isolates_start_year\"]").val() == 'undefined'
      start_year = 0
    else
      start_year = $("input[name=\"upcoming_isolates_start_year\"]").val()

    if typeof $("input[name=\"upcoming_isolates_end_year\"]").val() == 'undefined'
      end_year = 0
    else
      end_year = $("input[name=\"upcoming_isolates_end_year\"]").val()

  # max_year = new Date().getFullYear()

  window.getUpcomingIsolatesData = () ->
    isolates_data = []
    if typeof $("input[name=\"upcoming_isolates_chart_data\"]").val() == 'undefined'
      # nothing
    else
      isolates_data = $.parseJSON($("input[name=\"upcoming_isolates_chart_data\"]").val())
    return isolates_data

  window.getNumberOfStackedData  = (y, m) ->
    reports_data = getUpcomingIsolatesData()
    number_of_pre_screening = 0
    number_of_full_panel = 0
    number_of_sequence = 0

    console.log("reports_data ----")
    console.log(reports_data)
    $.each reports_data, (key_reports, value_reports) ->
      # if parseInt(y) == parseInt(value_reports.year)
      ym = parseInt(y) + "_" + parseInt(m)
      yearmonth = parseInt(value_reports.year)  + "_" + parseInt(value_reports.month)
      # if parseInt(m) == parseInt(value_reports.month) and parseInt(y) == parseInt(value_reports.year)
      if ym.toString() is yearmonth.toString()
        console.log("iffffff-->#{ym} -- #{yearmonth}")
        number_of_pre_screening += value_reports.number_of_pre_screening
        number_of_full_panel += value_reports.number_of_full_panel
        number_of_sequence += value_reports.number_of_sequence

    return [number_of_pre_screening, number_of_full_panel, number_of_sequence]

  window.createReportsForUpcomingStackedChart = () ->
    isolates_data = getUpcomingIsolatesData()
    loadDate()
    string_reports = "["

    year = start_year
    max_year = end_year

    month = start_month
    max_month = end_month
    total_month = 12

    while year <= max_year
      # $.each month_array, (key_month, val_month) ->
      while year <= max_year or month <= 12
        # console.log("year = "+year+" maxyear = "+max_year)
        string_reports += "{ \"id\" : \""+year+"_"+month_array[(month-1)]+"\","
        string_reports += " \"year\" : "+year+","
        string_reports += "\"month\" : \""+month_array[(month-1)]+"\","
        number_of_isolates = getNumberOfStackedData(year, month)
        string_reports += "\"number_of_pre_screening\" : "+number_of_isolates[0]+","
        string_reports += "\"number_of_full_panel\" : "+number_of_isolates[1]+","
        string_reports += "\"number_of_sequence\" : "+number_of_isolates[2]+" "
        # string_reports = string_reports.substring(0, string_reports.length - 1);
        string_reports += " },"
        if year == max_year and month == max_month
          break
        month++
        if month == 13
          break
      year++
      month = 1
    string_reports = string_reports.substring(0, string_reports.length - 1);
    string_reports += "]"
    console.log(string_reports)
    return string_reports


  window.createStackedChartForUpcomingIsolates = () ->
    string_reports = createReportsForUpcomingStackedChart()

    margin =
      top: 20
      right: 20
      bottom: 30
      left: 40

    w = $('.col-md-9').width() - 100
    width = w - margin.left - margin.right
    height = 400 - margin.top - margin.bottom

    x = d3.scale.ordinal()
      .rangeRoundBands([0, width], 0.1)

    y = d3.scale.linear()
      .range([height, 0])

    xAxis = d3.svg.axis()
      .scale(x)
      .orient("bottom")

    yAxis = d3.svg.axis()
      .scale(y)
      .orient("left")

    color = d3.scale.ordinal()
      .range(["#2980b9", "#c0392b", "#00cc00"])

    svg = d3.select("#upcoming_isolates_chart").append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

    data = []
    if typeof string_reports == 'undefined'
      data = []
    else if string_reports.length > 2
      data = jQuery.parseJSON(string_reports)

    color.domain(d3.keys(data[0]).filter((key) -> key != "id" and key != "year" and key != "month"));

    data.forEach((d) ->
      y0 = 0;
      d.vetlab = color.domain().map((name) -> {
          name: name,
          y0: y0,
          y1: y0 += +d[name]
      })
      d.counter = d.vetlab[d.vetlab.length - 1].y1
    )

    # data.sort((a, b) -> b.counter - a.counter)

    x.domain(data.map((d) -> d.id ))
    y.domain([0, d3.max(data, (d) -> d.counter )])

    svg.append("g")
      .attr("class", "x axis stacked-year")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    svg.append("g")
      .attr("class", "y axis")
      .call(yAxis)
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 6)
      .attr("dy", ".71em")
      .style("text-anchor", "end")
      .text("Number of Isolates");

    year = svg.selectAll(".year")
      .data(data)
      .enter().append("g")
      .attr("class", "g")
      .attr("transform", (d) -> "translate(" + x(d.id) + ",0)" );

    year.selectAll("rect")
      .data((d) -> d.vetlab)
      .enter().append("rect")
      .attr("width", x.rangeBand())
      .attr("y", (d) -> y(d.y1))
      .attr("height", (d) -> y(d.y0) - y(d.y1))
      .style("fill", (d) -> color(d.name))

    # create legend
    legend = svg.selectAll(".legend")
      .data(color.domain().slice().reverse())
      .enter().append("g")
      .attr("class", "legend")
      .attr("transform", (d, i) -> "translate(0," + (i+1) * 20 + ")");

    legend.append("rect")
      .attr("x", width - 18)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", color);

    legend.append("text")
      .attr("x", width - 24)
      .attr("y", 9)
      .attr("dy", ".35em")
      .style("text-anchor", "end")
      .text((d) -> d)

    #add link to x axis
    d3.selectAll(".tick.major text")
      .attr("transform", "translate(-20,10) rotate(-45)")

    # console.log(vet_lab_data)
    # dic_legend = svg.selectAll(".vl-legend")
    #   .data(vet_lab_data)
    #   .enter().append("g")
    #   .attr("class", "vl-legend")
    #   .attr("transform", (d, i) -> "translate(0,0)");

    # dic_legend.append("text")
    #   .attr("x", width)
    #   .attr("y", 9)
    #   .attr("dy", ".35em")
    #   .style("text-anchor", "end")
    #   .text((d) -> "Vetlab : "+d)

  # ================================= Map configuration ====================================
  isolates_data_by_district = [] # global variabel
  window.loadUpcomingIsolatesByDistricts = () ->
    if typeof $("input[name=\"isolates_data_by_district\"]").val() == "undefined"
      isolates_data_by_district = []
    else
      isolates_data_by_district = jQuery.parseJSON($("input[name=\"isolates_data_by_district\"]").val())
    return isolates_data_by_district

  window.checkNumberOfUpcomingIsolates = (dis_code) ->
    noi = 0; # number of isolates
    $.each isolates_data_by_district, (key_iso, val_iso) ->
      district_code = val_iso.province_code+val_iso.district_code
      if dis_code == district_code
        noi = val_iso.counter
        return noi
    return noi

  window.loadMapUpcomingIsolates = () ->
    isolates_data_by_district = loadUpcomingIsolatesByDistricts()

    # initialize color on map
    color_domain = [1, 50, 100, 500, 1000]
    ext_color_domain = [0, 1, 50, 100, 500, 1000]
    legend_labels = ["0", "1+", "50+", "100+", "500+", "> 1000"]
    color = d3.scale.threshold()
      .domain(color_domain)
      .range(["#ffffff", "#ffe49b", "#ffba00", "#ff7d73", "#ff4e40", "#ff1300"])
    isolate_color_domain = [1, 25, 50, 75, 100]
    isolate_ext_color_domain = [0, 1, 25, 50, 75, 100]
    isolate_legend_labels = ["0", "1+", "25+", "50+", "75+", "> 100"]
    isolate_color = d3.scale.threshold()
      .domain(isolate_color_domain)
      .range(["#ffffff", "#7fff60", "#1dfe00", "#9999df", "#3636c1", "#000091"])

    # initialize size
    w = $(".col-md-9").width()
    if w == 965 # screen width 1366px
      size =
        width: -1550,
        height: "430"
    else if w == 708 # screen width 1024px
      size =
        width: -1650,
        height: "430"
    else if w == 900 # screen width 1280px
      size =
        width: -1600,
        height: "430"
    else if w == 1367 # screen width 1920px
      size =
        width: -1400,
        height: "430"
    else # default
      size =
        width: -1550,
        height: "430"

    # alert(w)

    # create canvas
    canvas = d3.select("#upcoming_isolates_map").append("svg")
      .attr("width", "100%")
      .attr("height", "430px")
      .style("background-color", "lightblue")
      .attr("cursor", "grab")

    # Generate MAP
    d3.json "/outbreaks/indonesia_districts.json", (data) ->
      group = canvas.selectAll("g.dis")
        .data(data.features)
        .enter()
        .append("g")
        .attr("class", "dis")

      projection = d3.geo.mercator().scale(1000).translate([size.width, (size.height)/2.7]);
      path = d3.geo.path().projection(projection);

      # write(coloring) outbreaks data on map
      areas = group.append("path")
        .attr("d", path)
        .attr("class", "area")
        .style("stroke", "gray")
        .style("stroke-width", "0.1")
        .attr("fill", "white")
        .on('mouseover', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          string_details = "<b>"+d.properties.KAB_KOTA+"</b>. Total isolates = <b>"+total_isolates+" isolate(s)</b>";
          $("#uc_map i").html(string_details)
        )
        .on('mouseout', (d,i) ->
          $("#uc_map i").html("District Details")
        )
        .on('dblclick', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
        )

      circle = canvas.selectAll("g.dis.cir")
        .data(data.features)
        .enter()
        .append("g")
        .attr("class", "dis cir")

      circle.append('svg:circle')
        .attr('cx', (d) -> path.centroid(d)[0])
        .attr('cy', (d) -> path.centroid(d)[1])
        .attr('r', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          if total_isolates > 25
            return 2
          else
            return 1
        )
        .style("opacity", "0.5")
        .style("fill", (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          return isolate_color(total_isolates, i)
        )
        .on('mouseover', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          string_details = "<b>"+d.properties.KAB_KOTA+"</b>. Total isolates = <b>"+total_isolates+" isolate(s)</b>";
          $("#uc_map i").html(string_details)
        )
        .on('mouseout', (d,i) ->
          $("#uc_map i").html("District Details")
        )
        .on('dblclick', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          # mapDetails(d.properties.KAB_KOTA, d.properties.KODE_KAB)
        )

      circle.append('svg:circle')
        .attr('cx', (d) -> path.centroid(d)[0])
        .attr('cy', (d) -> path.centroid(d)[1])
        .attr('r', (d,i) ->
          total_isolates = checkNumberOfUpcomingIsolates(d.properties.KODE_KAB);
          if total_isolates > 25
            return 2
          else
            return 1
          # return total_isolates;
        )
        .style("opacity", "0")
        .style("fill", "white")

      # legend title
      legend_title_data = ["Number of Isolates"]
      legend_title = canvas.selectAll("g.title")
        .data(legend_title_data)
        .enter().append("g")
        .attr("class", "title")

      legend_title.append("text")
        .attr("x", -410)
        .attr("y", (d,i) -> 30 + (i*100))
        .attr("transform", "rotate(-90)")
        .text((d) -> d)

      # add isolate legend
      isolate_legend = canvas.selectAll("g.isolate_legend")
        .data(isolate_ext_color_domain)
        .enter().append("g")
        .attr("class", "isolate_legend")

      leg_w = 20
      leg_h = 20

      isolate_legend.append("rect")
        .attr("x", 35)
        .attr("y", (d,i) -> size.height - (i*leg_h) - (2*leg_h) )
        .attr("width", leg_w)
        .attr("height", leg_h)
        .style("fill", (d,i) -> isolate_color(d) )
        .style("opacity", "0.8")

      isolate_legend.append("text")
        .attr("x", 65)
        .attr("y", (d,i) -> size.height - (i*leg_h) - leg_h - 4 )
        .text((d,i) -> isolate_legend_labels[i] )

    # zoom behavior
    zoom = d3.behavior.zoom()
      .on("zoom", () ->
        canvas.selectAll(".dis").attr("transform", "translate("+d3.event.translate.join(",")+")scale("+d3.event.scale+")");
        canvas.selectAll(".dis").selectAll("path")
          .attr("d", canvas.projection(projection));
      )

    canvas.call(zoom).on("dblclick.zoom", null);

  if $("#upcoming_isolates_map").length
    loadMapUpcomingIsolates()
    createStackedChartForUpcomingIsolates()

$(document).on 'turbolinks:load', ready