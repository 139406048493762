#update edit button attribute
window.updateKitDetailBtn = (submission_kit, kit_id) ->
  link = "/en/kits/#{kit_id}"
  kit_wrapper = $(".kit-detail")
  edit_link_wrapper = kit_wrapper.find('.edit-kit')
  delete_link_wrapper = kit_wrapper.find('.delete-kit-btn')

  edit_link_wrapper.attr('id', 'edit_kit_' + kit_id)
  edit_link_wrapper.attr('data-id', kit_id)
  edit_link_wrapper.attr('href', link + '/edit')

  delete_link_wrapper.attr('id', 'remove_kit_' + kit_id)
  delete_link_wrapper.attr('data-id', kit_id)
  delete_link_wrapper.attr('href', link)

  if submission_kit == true
    $(".kit-detail").find('.delete-kit-btn').addClass('already-used-kit')
  else
    $(".kit-detail").find('.delete-kit-btn').removeClass('already-used-kit')

#load antigen dan antisera detail di kit detail sesuai dengan data kit yang ter-select
window.loadAntigenAntiseraDetail = (data) ->
  for i of data[0].antisera_ids
    batch_counter = 0
    antisera_id = data[0].antisera_ids[i]
    antisera_name = data[0].antiseras[i].antisera_name
    append_content = '<div class="list-antisera" id="kit_antisera_'+antisera_id+'">'+antisera_name+'<br></div>'

    for j of data[0].antiseras[i].batches
      ref_antiseras_id = data[0].antiseras[i].batches[j].ref_antiseras_id
      batch_no = data[0].antiseras[i].batches[j].batch_no
      expected_titre = data[0].antiseras[i].batches[j].expected_titre
      if batch_no.length == 0
        batch_no = '<i>without batch</i>'
      if expected_titre == null
        expected_titre = '<i>not set</i>'
      batch_wrapper = "<span>#{batch_no} </span><span id='ref_antisera_batch_#{ref_antiseras_id}'><a role='button' data-content='Test' data-html='true' data-toggle='popover' data-title='<strong>Update Expected Titre</strong>' class='popover-titre-form badge badge-success titre-on-form' data-remote='true' href='/en/ref_antiseras/#{ref_antiseras_id}/edit?location=kit_detail' data-original-title='' title=''>#{expected_titre}</a></span>"
      wrapper = "<div id='ref_antisera_batch_wrapper_#{ref_antiseras_id}' class='text-muted'>#{batch_wrapper}</div>"
      append_content = append_content + wrapper
      batch_counter++

    $('.antisera-list').append('<div class="form-group">'+append_content+'</div>')
  for i of data[0].antigen_ids
    batch_counter = 0
    antigen_id = data[0].antigen_ids[i]
    antigen_name = data[0].antigens[i].antigen_name
    append_content = '<div class="list-antigen" id="kit_antigen_'+antigen_id+'">'+antigen_name+'<br></div>'

    for j of data[0].antigens[i].batches
      ref_antigens_id = data[0].antigens[i].batches[j].ref_antigens_id
      batch_no = data[0].antigens[i].batches[j].batch_no
      expected_titre = data[0].antigens[i].batches[j].expected_titre
      if batch_no.length == 0
        batch_no = '<i>without batch</i>'
      if expected_titre == null
        expected_titre = '<i>not set</i>'
      batch_wrapper = "<span>#{batch_no} </span><span id='ref_antigen_batch_#{ref_antigens_id}'><a role='button' data-content='Test' data-html='true' data-toggle='popover' data-title='<strong>Update Expected Titre</strong>' class='popover-titre-form badge badge-success titre-on-form' data-remote='true' href='/en/ref_antigens/#{ref_antigens_id}/edit?location=kit_detail' data-original-title='' title=''>#{expected_titre}</a></span>"
      wrapper = "<div id='ref_antigen_batch_wrapper_#{ref_antigens_id}' class='text-muted'>#{batch_wrapper}</div>"
      append_content = append_content + wrapper

      batch_counter++

    $('.antigen-list').append('<div class="form-group">'+append_content+'</div>')

#reset isi kit detail
window.resetKitDetail = (id) ->
  $.ajax
    url: '/kits/'+id
    dataType: 'json'
    method: 'GET'
    success: (kitsData) ->
      data = getDataKitDetail(kitsData)
      kit_id = data[0].id
      kit_name = data[0].kit_name
      $(".kit-detail").find(".kit-name").html("#{kit_name}")
      $(".antigen-list").empty()
      $(".antisera-list").empty()

      loadAntigenAntiseraDetail(data)

      #mengecek apakah kit sudah terpakai di run
      submission_kit = kitsData.kit_submission_any
      updateKitDetailBtn(submission_kit, kit_id)

  # Activate kit detail tab
  $('a[href="#kit_list_tab"]').tab('show');

#refresh kit detail template after update the kit
window.updateKitDetail = (id) ->
  name = $("#kit_kit_name").val()
  wrapper = $(".kit-detail")
  $("#kit_"+id).find('a').replaceWith('<a href="#">'+name+'</a>')
  $("#list_antigen").find('.remove-batch-list').remove()
  $("#list_antisera").find('.remove-batch-list').remove()
  $(".selected-antigen").addClass('list-antigen').removeClass('selected-antigen')
  $(".selected-antisera").addClass('list-antisera').removeClass('selected-antisera')
  list_antigen = $("#list_antigen").html()
  list_antisera = $("#list_antisera").html()
  $('.kit-detail').find(".kit-name").html('<h4>'+name+'</h4>')
  wrapper.find(".antigen-list").html(list_antigen)
  wrapper.find(".antisera-list").html(list_antisera)

#show error notification when kit's (name/antigen/antisera) field blank
window.checkKitBlankFields = (part) ->
  length = $("#kit_kit_name").val().length
  antigen_count = $(".selected-antigen").length
  antisera_count = $(".selected-antisera").length
  kit_name_field = $("#kit_kit_name").closest('.form-group')
  if length > 0
    kit_name_field.find('.form-error-message').addClass('hide')
    kit_name_field.removeClass('has-error')
  else
    kit_name_field.find('.form-error-message').removeClass('hide')
    kit_name_field.addClass('has-error')
  if $(".selected-#{part}").length == 0
    $("##{part}_list").find(".form-error-message").addClass('hide')
  else
    $("##{part}_list").find(".form-error-message").removeClass('hide')

#cek apakah batch number yang baru sudah ada sebelumnya dlm scope (antigen/antisera)
window.checkExistingBatchNumber = (part,batch_field) ->
  new_batch_no = 0
  $("#batch_list_on_#{part}_modal").find(".#{part}-list-modal").each ->
    batch_no = $(this).data('batch')
    if (batch_no == batch_field)
      new_batch_no += 1
  return new_batch_no

#cek apakah ref antigen/ ref antisera sudah ter-select sebelumnya
window.checkExistingAntigenAntisera = (part, ref_id, ref_batch_id) ->
  errors = 0
  $('#list_'+part).find('.selected-'+part).each ->
    selected_ref_id = $(this).find('.remove-list').data('value')
    $(this).find("input:hidden[name='kit[ref_"+part+"_ids][]']").each ->
      selected_batch_id = $(this).val()
      if ("#{ref_id}" == "#{selected_ref_id}") && ("#{ref_batch_id}" == "#{selected_batch_id}")
        errors += 1
        return errors
  return errors

window.duplicateNewBatchValidation = (part) ->
  errors = 0
  new_batch_no = $("#ref_#{part}_batch_no").val()
  wrapper = $("##{part}_batch_number_modal")
  batches_wrapper = wrapper.find(".batch-number-wrapper")
  batches_wrapper.find(".#{part}-list-modal").each ->
    batch_no = $(this).data('batch')
    if "#{batch_no}" is "#{new_batch_no}"
      errors += 1
  return errors

#show error notification when there's no batch number selected
#show error notification when batch number selected has already exist on the list
window.batchNumberErrorNotification = (part, no_selected_batch, is_exist, batch_field, new_batch_no) ->
  $(".error-message").remove()
  batch_number_is_exist = "<div class='error-message alert alert-danger alert-small'>Batch Number has already been taken.</div>"
  no_batch_number = "<div class='error-message alert alert-danger alert-small'>There's no batch number yet.</div>"
  new_batch_field = $("#ref_#{part}_batch_no").val()
  errors = false
  if new_batch_field.length is 0
    if (no_selected_batch is true)
      $(".ref_"+part+"_batch_no").closest('.row').after(no_batch_number)
      return true
    else
      if (is_exist > 0) or (new_batch_no > 0)
        $(".ref_"+part+"_batch_no").closest('.row').after(batch_number_is_exist)
      return true
  else
    if (is_exist > 0) or (new_batch_no > 0)
      $(".ref_"+part+"_batch_no").closest('.row').after(batch_number_is_exist)
      return true

#menambahkan antisera/antigen beserta batch number ke dalam antigen/antisera list di form edit kit.
window.appendSelectedRefAntigenAntisera = (ref_id, part, is_exist, tab_id, new_batch_no, ref_batch_id ) ->
  $(".no-#{part}").hide()
  ref_name = $(".#{part}-list-modal:checked").data('name')
  ref_batch = $(".#{part}-list-modal:checked").data('batch')
  ref_expected_titre = $(".#{part}-list-modal:checked").data('exptitre')
  if ref_expected_titre == null
    ref_expected_titre = '<i>not set</i>'
  if ref_batch.length == 0
    ref_batch = '<i>without batch</i>'

  if (is_exist < 1)
    if $("##{part}_list").find("##{part}_#{ref_id}").length > 0
      $("##{part}_list").find("##{part}_#{ref_id}").append('<div class="kit-ref-'+part+'-selected"><dd id="form_ref_'+part+'_batch_wrapper_'+ref_batch_id+'"><span>'+ref_batch+' </span><span class="badge">'+ref_expected_titre+'</span><i class="remove-batch-list btn btn-link btn-xs" data-part="ref_'+part+'" data-value="'+ref_batch_id+'" id="remove_ref_'+part+'_'+ref_batch_id+'">remove</i><br><input name="kit[ref_'+part+'_ids][]" type="hidden" value="'+ref_batch_id+'"></dd></div>')
    else
      $("##{part}_list .list").append('<div class="form-group"><div class="selected-'+part+'" id="'+part+'_'+ref_id+'"><b>'+ref_name+'</b><i class="remove-list btn btn-link btn-xs hide" data-part="'+part+'" data-value="'+ref_id+'" id="remove_'+part+'_'+ref_id+'">remove</i><br><div class="kit-ref-'+part+'-selected"><dd id="form_ref_'+part+'_batch_wrapper_'+ref_batch_id+'"><span>'+ref_batch+' </span><span class="badge">'+ref_expected_titre+'</span><i class="remove-batch-list btn btn-link btn-xs" data-part="ref_'+part+'" data-value="'+ref_batch_id+'" id="remove_ref_'+part+'_'+ref_batch_id+'">remove</i><br><input name="kit[ref_'+part+'_ids][]" type="hidden" value="'+ref_batch_id+'"></dd></div><input name="kit['+part+'_ids][]" type="hidden" value="'+ref_id+'"></div></div>')
    # $("##{part}_batch_number_modal").modal("hide")
    resetAntigenAntiseraForm(part)
    $("##{part}_slide_panel").modal("hide")
    $("##{part}_list").find(".form-error-message").remove()

#modifikasi json yang didapat dari ajax show kit
window.getDataKitDetail = (kitsData) ->
  antiseras = []
  check_name_antisera = []
  antigens = []
  check_name_antigen = []
  antisera_ids = kitsData.antisera_ids.filter((x, i, a) => a.indexOf(x) == i)
  antigen_ids = kitsData.antigen_ids.filter((x, i, a) => a.indexOf(x) == i)
  #data kit
  data = [{
    id: kitsData.id,
    kit_name: kitsData.kit_name,
    is_valid: kitsData.is_valid,
    antigen_ids: antigen_ids,
    antisera_ids: antisera_ids,
    antiseras: antiseras,
    antigens: antigens
  }]

  # mencari data antiseras
  for i of kitsData.antiseras
    batches = []
    name = kitsData.antiseras[i].antisera_name
    batch_no = kitsData.antiseras[i].batch_no
    ref_antiseras_id = kitsData.antiseras[i].ref_antiseras_id
    expected_titre = kitsData.antiseras[i].expected_titre
    batches.push({batch_no : batch_no, ref_antiseras_id : ref_antiseras_id, expected_titre : expected_titre})
    if($.inArray(name, check_name_antisera) == -1)
      check_name_antisera.push(name)
      antiseras.push({antisera_name : name , batches : batches, expected_titre : expected_titre})
    else
      index = $.map antiseras, (obj, index) ->
        if obj.antisera_name == name
          return index
      antiseras[index].batches.push({batch_no : batch_no, ref_antiseras_id : ref_antiseras_id, expected_titre : expected_titre})

  # mencari data antigens
  for i of kitsData.antigens
    batches = []
    name = kitsData.antigens[i].antigen_name
    anchor = kitsData.antigens[i].anchor
    batch_no = kitsData.antigens[i].batch_no
    ref_antigens_id = kitsData.antigens[i].ref_antigens_id
    expected_titre = kitsData.antigens[i].expected_titre
    batches.push({batch_no : batch_no, ref_antigens_id : ref_antigens_id, expected_titre : expected_titre})
    if($.inArray(name, check_name_antigen) == -1)
      check_name_antigen.push(name)
      antigens.push({antigen_name : name , anchor : anchor, batches : batches, expected_titre : expected_titre})
    else
      #mencari index of array dari antigen dengan nama yang sama
      index = $.map antigens, (obj, index) ->
        if obj.antigen_name == name
          return index
      antigens[index].batches.push({batch_no : batch_no, anchor : anchor, ref_antigens_id : ref_antigens_id, expected_titre : expected_titre})

  return data

#menampilkan list antigen di slide panel ketika klik tombol 'browse'
window.loadAntigenOnSidePanel = () ->
  antigen_list_wrapper = $("#antigen_slide_panel").find(".modal-body").find("#antigen_list_slide_panel")
  $(antigen_list_wrapper).html("")
  antigen_list_on_slide = ""
  antigens_table = '<table class="table table-sm table-borderless table-hover" id="antigen_slide_panel_row"><tbody>'
  $.ajax
    url: '/antigens/show_antigens_to_slide_panel'
    dataType: 'json'
    method: 'GET'
    success: (data) ->
      $.each data, (index, antigen) ->
        index = index
        id = antigen.id
        name = antigen.antigen_name
        antigen_list_on_slide += '<tr><td><a class="ref-in-slide" data-part="antigen" data-index="'+index+'" data-antigen-name="'+name+'" data-antigen-id="'+id+'" href="#antigen_'+index+'">'+name+'</a></td></tr>'
      $(antigen_list_wrapper).append(antigens_table)
      $("#antigen_slide_panel_row tbody").append(antigen_list_on_slide)

#menampilkan list antisera di slide panel ketika klik tombol 'browse'
window.loadAntiseraOnSidePanel = () ->
  antisera_list_wrapper = $("#antisera_slide_panel").find(".modal-body").find("#antisera_list_slide_panel")
  $(antisera_list_wrapper).html("")
  antisera_list_on_slide = ""
  antiseras_table = '<table class="table table-sm table-borderless table-hover" id="antisera_slide_panel_row"><tbody>'
  $.ajax
    url: 'antiseras/show_antiseras_to_slide_panel'
    dataType: 'json'
    method: 'GET'
    success: (data) ->
      $.each data, (index, antisera) ->
        index = index
        id = antisera.id
        name = antisera.antisera_name
        antisera_list_on_slide += '<tr><td><a class="ref-in-slide" data-part="antisera" data-index="'+index+'" data-antisera-name="'+name+'" data-antisera-id="'+id+'" href="#antisera_'+index+'">'+name+'</a></td></tr>'
      $(antisera_list_wrapper).append(antiseras_table)
      $("#antisera_slide_panel_row tbody").append(antisera_list_on_slide)

#clear form antigen/form antisera
window.resetAntigenAntiseraForm = (part) ->
  $(".form-#{part}").find(".error-message").remove()
  $(':input','.form-'+part).not(':button, :submit, :hidden').val('').attr('checked', false)
  $("#title_#{part}s , ##{part}_list_slide_panel").removeClass('hide')
  $("#add_#{part}_slide_panel , ##{part}_batch_number_modal , #title_#{part}_form , #title_#{part}_batch").addClass('hide')
  $(".add-on-slide").removeClass('hide')

#load dataTable for removed kit
window.removedKitDataTablesLoad = () ->
  url = $("#removed_kits").data('url')
  $("#removed_kits").DataTable
    'destroy': true
    'searching': true
    'ajax':
      'url': url
    'processing': true
    'serverSide': true
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-removed-kit-name'
        'col-removed-kit-antigen'
        'col-removed-kit-antisera'
        'col-removed-kit-restore-link'
      ]
    } ]
    # "order": [[ 4, "desc" ]]
    start: 0

window.removedKitDataTablesReset = () ->
  table = removedKitDataTablesLoad()
  table.ajax.reload()

window.loadKitDetail = () ->
  id = $(".kit-detail").find(".edit-kit").prop("id")
  kit_id = id.split("edit_kit_").pop()
  $("#kit_list").find("#kit_"+kit_id).click()


ready = ->

  $("body").on "keyup", "#kit_kit_name", (e) ->
    e.preventDefault()
    checkKitBlankFields()

  $("body").on "click", ".cancel-btn-kit-form", (e) ->
    e.preventDefault()
    $(".form-kit").remove()
    $(".kit-detail").removeClass('hide')

  $("body").on "click", ".kit-list", (e) ->
    e.preventDefault()
    $(".form-kit").remove()
    $(".kit-detail").removeClass("hide")
    id = $(this).data('id')
    resetKitDetail(id)

  $("body").on "click", ".browse-btn", (e) ->
    e.preventDefault();
    part = $(this).data("part")
    resetAntigenAntiseraForm(part)
    if part == 'antigen'
      loadAntigenOnSidePanel()
    else if part == 'antisera'
      loadAntiseraOnSidePanel()

  # show modal antigen
  $("body").on "click", ".ref-in-slide", (e) ->
    $(".error-message").remove()
    e.preventDefault();
    batch_number_wrapper = '<div class="mb-3 batch-number-wrapper"></div>'
    part = $(this).data('part')

    $("##{part}_batch_number_modal , #title_#{part}_batch").removeClass('hide')
    $("##{part}_list_slide_panel , #add_#{part}_slide_panel , #title_#{part}s , .add-on-slide").addClass('hide')

    if part == 'antigen'
      id = $(this).data('antigen-id')
      color = 'success'
    else if part == 'antisera'
      id = $(this).data('antisera-id')
      color = 'info'
    # target_id = "batch_#{part}_#{$(this).data('index')}"
    ref_name = $(this).data("#{part}-name")
    $.ajax
      url: '/'+part+'s/'+id
      dataType: 'json'
      method: 'GET'
      success: (data) ->
        batch_number_arr = ""
        if part == 'antisera'
          for i of data.antiseras
            batch_number = data.antiseras[i].batch_no
            ref_antisera_id = data.antiseras[i].ref_antiseras_id
            expected_titre = data.antiseras[i].expected_titre

            if batch_number.length == 0
              batch_number = '<i>without batch</i>'

            if expected_titre == null
              expected_titre = '<i>not set</i>'

            batch_number_arr += '<div class="form-check"><label><input type="radio" name="ref_antisera" id="antisera_'+ref_antisera_id+'" value="'+ref_antisera_id+'" class="boolean optional form-check-input antisera-list-modal" data-name="'+ref_name+'" data-batch="'+batch_number+'" data-exptitre="'+expected_titre+'"><label for="antisera_'+ref_antisera_id+'" class="form-check-label">'+batch_number+' </label><span class="ml-1 badge badge-success">'+expected_titre+'</span></label></div>'
        else if part == 'antigen'
          for i of data.antigens
            batch_number = data.antigens[i].batch_no
            ref_antigen_id = data.antigens[i].ref_antigens_id
            expected_titre = data.antigens[i].expected_titre

            if batch_number.length == 0
              batch_number = '<i>without batch</i>'

            if expected_titre == null
              expected_titre = '<i>not set</i>'

            batch_number_arr += '<div class="form-check"><label><input type="radio" name="ref_antigen" id="antigen_'+ref_antigen_id+'" value="'+ref_antigen_id+'" class="boolean optional form-check-input antigen-list-modal" data-name="'+ref_name+'" data-batch="'+batch_number+'" data-exptitre="'+expected_titre+'"><label for="antigen_'+ref_antigen_id+'" class="form-check-label">'+batch_number+' </label><span class="ml-1 badge badge-success">'+expected_titre+'</span></label></div>'

        $("#batch_list_on_#{part}_modal").html("") # mengosongkan modal body
        $("#batch_list_on_#{part}_modal").append("<div class='alert alert-#{color}'><b>#{ref_name}</b></div><div><b>Select batch<b></div>") # menambahkan nama antigen ke modal body
        $("#batch_list_on_#{part}_modal").append("<input type='hidden' id='ref_#{part}_#{part}_id' name='ref_#{part}[#{part}_id]' value='#{id}' />") # menambahkan input antigen_id ke modal body
        $("#batch_list_on_#{part}_modal").append("<input type='hidden' id='ref_#{part}_#{part}_name' value='#{ref_name}' />") # variabel bantu untuk kirim nama antigen/antisera
        $("#ref_#{part}_batch_no").attr("data-name", "#{ref_name}") # menambahkan atribute name di input batch number
        $(batch_number_wrapper).appendTo("#batch_list_on_#{part}_modal")
        $(batch_number_arr).appendTo(".batch-number-wrapper")
        # memindahkan list batch ke modal body
        # $("#batch_list_on_#{part}_modal ##{target_id}").removeClass("hide") # menghilangkan class hide di modal body
        $("##{part}_batch_number_modal").find("#ref_antigen_batch_no, #ref_antigen_expected_titre").val('')


  $("body").on "focus", ".new-batch-in-modal", (e) ->
    part = $(this).data('part')
    $(".#{part}-list-modal").prop('checked', false)

  $("body").on "change", ".antigen-list-modal", (e) ->
    $('.new-batch-in-modal').val('')

  $("body").on "change", ".antisera-list-modal", (e) ->
    $('.new-batch-in-modal').val('')

  $("body").on "click", ".submit-to-new-kit", (e) ->
    e.preventDefault();
    part = $(this).data("part")
    batch_field = $("#ref_#{part}_batch_no").val()
    selected_batch = $(".#{part}-list-modal:checked")
    new_batch_no = checkExistingBatchNumber(part,batch_field)
    tab_id = $("##{part}_batch_number_modal").data('tab')
    ref_id = $("input:hidden[name='ref_"+part+"["+part+"_id]']").val()
    ref_batch_id = $("."+part+"-list-modal:checked").val()
    is_exist = checkExistingAntigenAntisera(part, ref_id, ref_batch_id)
    false_titre = false

    if selected_batch.length > 0
      no_selected_batch = false
      appendSelectedRefAntigenAntisera(ref_id, part, is_exist, tab_id, new_batch_no, ref_batch_id)
    else
      # new ref_antigen or new ref_antisera
      no_selected_batch = false
      if batch_field.length > 0 && new_batch_no < 1
        ref_name = $("#ref_#{part}_#{part}_name").val()
        ref_batch = $("#ref_#{part}_batch_no").val()
        ref_expected_titre = $("#ref_#{part}_expected_titre").val()

        url = $(this).data('url')
        ref_id = $("#ref_#{part}_#{part}_id").val()

        if ref_batch.length == 0
          ref_batch = "<i>without batch</i>"
        if ref_expected_titre.length == 0
          ref_expected_titre = "<i>not set</i>"

        # add new batch number
        if part == 'antigen'
          data = ref_antigen: { antigen_id: ref_id, batch_no: ref_batch, expected_titre: ref_expected_titre, is_valid: true }
        else if part == 'antisera'
          data = ref_antisera: { antisera_id: ref_id, batch_no: ref_batch, expected_titre: ref_expected_titre, is_valid: true }

        # VALIDATE EXPECTED-TITRE
        expected_titre_val = parseInt(ref_expected_titre)

        if isTitration(expected_titre_val) or ref_expected_titre.length == 0
          if ref_expected_titre.length == 0
            ref_expected_titre_label = '<i>not set</i>'
          else
            ref_expected_titre_label = ref_expected_titre

          new_batch_no = duplicateNewBatchValidation(part)
          any_errors = batchNumberErrorNotification(part, no_selected_batch, is_exist, batch_field, new_batch_no)
          unless any_errors
            $.ajax
              url: url
              dataType: "SCRIPT"
              method: "POST"
              data: data
              success: () ->
                ref_batch_id = $("."+part+"-list-modal:checked").val()
                $(".no-#{part}").hide()
                if $("##{part}_list").find("##{part}_#{ref_id}").length > 0
                  $("##{part}_list").find("##{part}_#{ref_id}").append('<div class="kit-ref-'+part+'-selected"><dd id="form_ref_'+part+'_batch_wrapper_'+ref_batch_id+'"><span>'+ref_batch+' </span><span class="badge">'+ref_expected_titre_label+'</span><i class="remove-batch-list btn btn-link btn-xs" data-part="ref_'+part+'" data-value="'+ref_batch_id+'" id="remove_ref_'+part+'_'+ref_batch_id+'">remove</i><br><input name="kit[ref_'+part+'_ids][]" type="hidden" value="'+ref_batch_id+'"></dd></div>')
                else
                  $("##{part}_list .list").append('<div class="form-group"><div class="selected-'+part+'" id="'+part+'_'+ref_id+'"><b>'+ref_name+'</b><i class="remove-list btn btn-link btn-xs hide" data-part="'+part+'" data-value="'+ref_id+'" id="remove_'+part+'_'+ref_id+'">remove</i><br><div class="kit-ref-'+part+'-selected"><dd id="form_ref_'+part+'_batch_wrapper_'+ref_batch_id+'"><span>'+ref_batch+' </span><span class="badge">'+ref_expected_titre_label+'</span><i class="remove-batch-list btn btn-link btn-xs" data-part="ref_'+part+'" data-value="'+ref_batch_id+'" id="remove_ref_'+part+'_'+ref_batch_id+'">remove</i><br><input name="kit[ref_'+part+'_ids][]" type="hidden" value="'+ref_batch_id+'"></dd></div><input name="kit['+part+'_ids][]" type="hidden" value="'+ref_id+'"></div></div>')
        else
          false_titre = true
          alert('False titre value!')
    if selected_batch.length < 1 && batch_field.length < 1
      no_selected_batch = true
    any_errors = batchNumberErrorNotification(part, no_selected_batch, is_exist, batch_field, new_batch_no)
    unless any_errors or false_titre
      resetAntigenAntiseraForm(part)
      $("##{part}_slide_panel").modal('hide')

  $("body").on "click", ".finish-btn", (e) ->
    e.preventDefault();
    part = $(this).data("part")
    $("##{part}_slide_panel").toggle('slide', {easing: 'easeOutQuint', direction: 'right'}, 500);
    $("##{part}_list .list").html ''
    $("##{part}_slide_panel .#{part}-list-modal:checked").each ->
      id =  $(this).attr("value")
      name = $(this).data "name"
      $(".no-" + part + "").addClass 'hide'
      $("##{part}_list .list").append '
        <div id="' + part + '_'+ id + '" class="selected-' + part + '">' + name + '
        <input type="hidden" value="' + id + '" name="kit[' + part + '_ids][]" />
        <i id="remove_' + part + '_' + id + '" class="remove-list btn btn-link btn-xs" data-value="' + id + '" data-part="'+ part + '"><span class="glyphicon glyphicon-remove"></span></i>
        </div>'
    selected_number = $("##{part}_list").find(".selected-#{part}").length
    if selected_number == 0
      $("##{part}_list .list").append '
          <input type="hidden" value="" name="kit[' + part + '_ids][]" />'
      $(".no-" + part + "").removeClass 'hide'

  $("body").on "click", ".cancel-btn", (e) ->
    selected_array = []
    e.preventDefault();
    part = $(this).data("part")
    $("##{part}_slide_panel").toggle('slide', {easing: 'easeOutQuint', direction: 'right'}, 500);
    if $("##{part}_list").find(".selected-#{part}").length == 0
      $("##{part}_slide_panel").find(".#{part}-list-modal").prop('checked', false);
    else
      $("##{part}_slide_panel").find(".#{part}-list-modal").each ->
        id = $(this).attr("value")
        $("##{part}_list").find(".selected-#{part} input").each ->
          selected_id = $(this).attr("value")
          if id == selected_id
            selected_array.push(selected_id)
          else
            $("input[id='" + part + "_" + id + "']").prop('checked', false);
      $.each selected_array, (key, value) ->
        $("input[id='" + part + "_" + value + "']").prop('checked', true);

  # remove group batch of antigen/antisera
  $("body").on "click", ".remove-batch-list", (e) ->
    ref_part = $(this).data("part")
    part = ref_part.split("ref_").pop()
    id = $(this).data 'value'
    batch_length = $(this).closest(".selected-"+part).find(".kit-ref-#{part}-selected").length

    if $(this).hasClass('already-used-kit-ref')
      alert("Can't delete this reference #{part}, because it is already used in other process.")
      e.preventDefault();
      return false
    else
      c = confirm("Are you sure want to delete this ref #{part}?")
      if c == true
        # // The user pressed OK
        # // Do nothing, the link will continue to be opened normally
      else
        # // The user pressed Cancel, so prevent the link from opening
        e.preventDefault();
        return false


      if batch_length > 1
        $(this).closest(".kit-ref-#{part}-selected").remove()
      else
        $(this).closest(".selected-#{part}").remove()

  # remove group antigen/antisera
  $("body").on "click", ".remove-list", (e) ->
    e.preventDefault();
    part = $(this).data("part")
    id = $(this).data 'value'
    # $("##{part}_#{id}").remove()
    $(this).closest(".selected-#{part}").remove()
    $("##{part}_slide_panel").find(".#{part}-list-modal").each ->
      if $(this).attr('id') == "#{part}_#{id}"
        $(this).attr("checked", false)
    selected_number = $("##{part}_list").find(".selected-#{part}").length
    if selected_number == 0
      $("##{part}_list .list").append '
          <input type="hidden" value="" name="kit[' + part + '_ids][]" />'
      $(".no-" + part + "").removeClass 'hide'
    checkKitBlankFields(part)

  $("body").on "click", ".close-tab-group", (e) ->
    e.preventDefault()
    groupId = $(this).data('id')
    part = $(this).data('part')
    $("#tab_#{groupId}").remove()
    $(".tab-content ##{groupId}").remove()
    $("##{part}_list_tab").addClass 'in active'
    $('#manage_kit_tab a[href=\'#' + part + '_list_tab\']').tab 'show'

  $("body").on "click", ".add-on-slide", (e) ->
    e.preventDefault()
    part = $(this).data('part')
    $("#title_#{part}s , ##{part}_list_slide_panel").addClass('hide')
    $("#add_#{part}_slide_panel , #title_#{part}_form").removeClass('hide')
    $(this).addClass('hide')

  #event klik tombol 'cancel' pada form antigen/antisera
  $("body").on "click", ".cancel-button-form", (e) ->
    e.preventDefault()
    part = $(this).data('part')
    position = $(this).data('position-on-slide')
    if position == 'form'
      $(".#{part}-list-row").removeClass('hide')
      $(".#{part}-list-row").closest("##{part}").find(".tab-pane").remove()
    else
      resetAntigenAntiseraForm(part)

  # modal add batch number on antigen
  $("body").on "click", ".batch-button-antigen", (e) ->
    e.preventDefault();
    collapse_id = $(this).closest(".collapse").attr('id')
    antigen_slide_panel_row = $(this).closest('#antigen_slide_panel_row')
    toggle = antigen_slide_panel_row.find("a[href='##{collapse_id}']")
    checkbox_id = toggle.closest('label').find("input").attr('value')
    # alert(checkbox_id)
    $("#ref_antigen_antigen_id").val(checkbox_id)

  # modal add batch number on antisera
  $("body").on "click", ".batch-button-antisera", (e) ->
    e.preventDefault();
    collapse_id = $(this).closest(".collapse").attr('id')
    antisera_slide_panel_row = $(this).closest('#antisera_slide_panel_row')
    toggle = antisera_slide_panel_row.find("a[href='##{collapse_id}']")
    checkbox_id = toggle.closest('label').find("input").attr('value')
    # alert(checkbox_id)
    $("#ref_antisera_antisera_id").val(checkbox_id)

  #cancel new kit form
  $("body").on "click", ".remove-new-kit-form", (e) ->
    e.preventDefault()
    $(".kit-details").find(".kit-detail:first").removeClass("hide")

  # Validate kit before delete
  $("body").on "click", ".remove-kit", (e) ->
    if $(this).hasClass('already-used-kit')
      alert("Can't delete this kit, because it is already used in other process.")
      e.preventDefault();
      return false
    else
      c = confirm('Are you sure want to delete this kit?')
      if c == true
        # // The user pressed OK
        # // Do nothing, the link will continue to be opened normally
      else
        # // The user pressed Cancel, so prevent the link from opening
        e.preventDefault();
        return false

  # Validate antigen before delete
  $("body").on "click", ".ref-antigen-delete", (e) ->
    if $(this).hasClass('already-used-ref-antigen')
      alert("Can't delete this reference antigen, because it is already used in other process.")
      e.preventDefault();
      return false
    else
      c = confirm('Are you sure want to delete this ref antigen?')
      if c == true
        # // The user pressed OK
        # // Do nothing, the link will continue to be opened normally
      else
        # // The user pressed Cancel, so prevent the link from opening
        e.preventDefault();
        return false

  # Validate antisera before delete
  $("body").on "click", ".ref-antisera-delete", (e) ->
    if $(this).hasClass('already-used-ref-antisera')
      alert("Can't delete this reference antisera, because it is already used in other process.")
      e.preventDefault();
      return false
    else
      c = confirm('Are you sure want to delete this ref antisera?')
      if c == true
        # // The user pressed OK
        # // Do nothing, the link will continue to be opened normally
      else
        # // The user pressed Cancel, so prevent the link from opening
        e.preventDefault();
        return false

ready_on_change = ->
  $("body").on "change keyup", ".input-expected-titre", ->
    $this = $(this)
    raw_val = $this.val()
    val = parseInt(raw_val)
    submit_btn = $this.closest("form").find(".btn-submit-expected-titre")
    if isTitration(val)
      $this.closest(".form-group").removeClass("has-error")
      submit_btn.removeAttr('disabled')
    else
      $this.closest(".form-group").addClass("has-error")
      submit_btn.attr('disabled', true)

  $("body").on "click", ".cancel-update-expected-titre", ->
    $(".popover-titre-form").popover('hide');

$(document).on 'turbolinks:load', ready
$(document).on 'turbolinks:render', ready_on_change