window.antiseraDataTablesLoad = () ->
  url = $("#antiseras").data('url')
  $("#antiseras").DataTable
    'destroy': true
    'searching': true
    'ajax':
      'url': url
    'processing': true
    'serverSide': true
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-antisera-batch-no'
        'col-antisera-validity'
        'col-antisera-edit'
        'col-antisera-type'
      ]
    } ]
    "order": [[ 5, "desc" ]]
    start: 0

window.antiseraDataTablesReset = () ->
  table = antiseraDataTablesLoad()
  table.ajax.reload()

ready = ->
  $("body").on "click", ".cancel-update-expected-titre", (e) ->
    e.preventDefault()
    batch_wrapper = $(this).closest(".batch-wrapper")
    popover_titre_form = batch_wrapper.find(".popover-titre-form")
    popover_titre_form.popover('hide')

$(document).on 'turbolinks:load', ready