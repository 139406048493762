window.checkNumberOfData = (data, kode_kab) ->
  counter = 0
  epinum_of_positive_data = []
  # console.log(val)
  $.each data, (key, val) ->
    if val.area_code == kode_kab and val.hasil_uji == "positif"
      epinum_of_positive_data.push(val.epinum)
      counter += 1

  uniq_data = []
  if epinum_of_positive_data.length > 0
    $.each epinum_of_positive_data, (key, val) ->
      if $.inArray(val, uniq_data) == -1
        uniq_data.push(val)

  return uniq_data.length

window.loadIsikhnasLabReportsMap = (lab_test_data, highest_data_by_district) ->
  $("#isikhnas_lab_report_map").html("");

  # initialize size
  w = $("#lab_report_list").width() * 2.9/4
  size =
    width: w,
    height: w/2.5
  center = [size.width/2 , size.height/2]

  highest_data = 12
  if lab_test_data.length > 2
    array_data = jQuery.parseJSON(lab_test_data)
    array_highest_data = jQuery.parseJSON(highest_data_by_district)
    highest_data = array_highest_data[0].total_test

  # initialize legend's data
  pengali_data = parseInt(highest_data/4)

  if highest_data % 4 != 0
    pengali_data += 1

  if highest_data < 12
    data_legend_max = 12
  else
    data_legend_max = 4 * pengali_data

  beda = Math.round((parseInt(data_legend_max) + 1) / 4)

  batas_bawah = 1
  data_color_domain = [0]
  data_ext_color_domain = [0]
  data_legend_labels = ["0"]
  while batas_bawah <= data_legend_max
    data_color_domain.push(batas_bawah)
    data_ext_color_domain.push(batas_bawah)
    data_legend_labels.push("#{batas_bawah} - #{batas_bawah+beda-1}")
    batas_bawah = batas_bawah + beda

  # initialize color
  color = d3.scaleThreshold()
    .domain(data_color_domain)
    .range(["#ffffff", "#f9f9f9", "#7fff60", "#94adc6", "#455eae", "#193387"])

  # create canvas
  canvas = d3.select("#isikhnas_lab_report_map").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#{mapColors['sea']}")
    .attr("cursor", "grab")

  # create tooltip
  tooltip = d3.select("body").append("div")
    .attr("class", "tooltip-isolate")
    .style("opacity", 0);

  # ----------------------------------------------- Generate MAP --------------------------------------------------------
  group = canvas.append('g')

  projection = d3.geoMercator().scale(w*1.2).translate([-size.width*1.98, (size.height)/2.8]);
  path = d3.geoPath().projection(projection);

  d3.json("/outbreaks/indonesia_districts_2018.json").then (data) ->

    # write(coloring) data on map
    areas = group.selectAll("path")
      .data(data.features)
      .enter()
        .append('path')
        .attr("d", path)
        .attr("class", (d,i) ->
          return "area "+d.properties.CC_2;
        )
        .style("stroke", "#{mapColors['landBorder']}")
        .style("stroke-width", "0.1")
        .attr("fill", "#f9f9f9")
        # .attr("fill", (d,i) ->
        #   total_data = checkNumberOfData(array_data, d.properties.CC_2)
        #   return color(total_data);
        # )
        .on('mouseover', (d,i) ->
          string_details = "<b>"+d.properties.NAME_2+"</b>";
          $("#iskhnas_map_detail i").html(string_details)
        )
        .on('mouseout', (d,i) ->
          $("#iskhnas_map_detail i").html("District Details")
        )

    circle = canvas.selectAll("g.dis.marker")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis marker")
      .attr("id", (d,i) ->
        d.realx = path.centroid(d)[0]
        d.realy = path.centroid(d)[1]
        return d.properties.CC_2;
      )


    circle.append('svg:circle')
      .attr('cx', (d) -> path.centroid(d)[0])
      .attr('cy', (d) -> path.centroid(d)[1])
      .attr('r', 0.0001)
      .style("opacity", 0)


    marker_path = $("#marker_path").val()
    circle.append('image')
      .attr('xlink:href',"#{marker_path}")
      .attr('class', 'pico')
      .attr('height', 20)
      .attr('width', 20)
      .attr('x', (d) ->
        return path.centroid(d)[0]-10
      )
      .attr('y', (d) ->
        return path.centroid(d)[1]-20
      )
      .style('display', (d,i) ->
        total_data = checkNumberOfData(array_data, d.properties.CC_2)
        if total_data > 0
          return 'visible'
        else
          return 'none'
      )
      .style('cursor', 'pointer')
      .on('mouseover', (d,i) ->
        total_data = checkNumberOfData(array_data, d.properties.CC_2)
        string_details = "<b>"+d.properties.NAME_2+"</b> <br /> <b>"+total_data+" test</b>";
        # $("##{map_id}_detail i").html(string_details)
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        tooltip.html(string_details)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      )
      .on('mouseout', (d,i) ->
        # $("##{map_id}_detail i").html("District Details")
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      )
    # circle.append('svg:circle')
    #   .attr('cx', (d) -> path.centroid(d)[0])
    #   .attr('cy', (d) -> path.centroid(d)[1])
    #   .attr('r', 0.1)
    #   .style("opacity", "0.1")
    #   .style("fill", (d,i) ->
    #     total_data = checkNumberOfData(array_data, d.properties.CC_2)
    #     return color(total_data);
    #   )
    #   .on('mouseover', (d,i) ->
    #     total_data = checkNumberOfData(array_data, d.properties.CC_2)
    #     string_details = "<b>"+d.properties.NAME_2+"</b>. Total data = <b>"+total_data+" test</b>";
    #     $("#iskhnas_map_detail i").html(string_details)
    #   )
    #   .on('mouseout', (d,i) ->
    #     $("#iskhnas_map_detail i").html("District Details")
    #   )
  # ------------- Zooming Map (by scroll) --------------------------------
  zoomed = () ->
    canvas.selectAll("g").attr("transform", d3.event.transform);
    zoom_scale = parseFloat(1)/d3.event.transform.k
    canvas.selectAll(".pico")
      .attr("width", 20*zoom_scale)
      .attr("height", 20*zoom_scale)
      .attr('x', (d) ->
        newx = parseFloat(d.realx) - parseFloat(((20*zoom_scale)/parseFloat(2)))
        return newx;
      )
      .attr('y', (d) ->
        newy = parseFloat(d.realy) - parseFloat(20*zoom_scale)
        return newy;
      )

  zoom = d3.zoom().on("zoom", zoomed);

  canvas.call(zoom);
  canvas.call(zoom).on("dblclick.zoom", null);
  # ------------- end of Zooming Map (by scroll) -------------------------

  # ------------- Zooming Map (by control button) -------------------------
  d3.selectAll('.zoom-control').on('click', () ->
    if (this.id == 'zoom_in')
      zoom.scaleBy(canvas.transition().duration(350), 1.3);
    else if (this.id == 'zoom_out')
      zoom.scaleBy(canvas.transition().duration(350), 0.7);
    else
      canvas.call(zoom.transform,d3.zoomIdentity)
  )
  # ------------- end of Zooming Map (by control button) ------------------

  # ------------- Zooming Map (by selected DIC) -------------------------
  window.zoomDic = (cx, cy, zoom_scale) ->
    canvas.transition().duration(750).call(
      zoom.transform,
      d3.zoomIdentity.translate(size.width / 2, size.height / 2).scale(zoom_scale).translate(-cx, -cy)
    );
  # ------------- end of Zooming Map (by selected DIC) ------------------


# window.modifyArrayToJson = (array_data) ->
#   array_of_obj = []
#   $.each array_data, (key, val) ->
#     obj = {}
#     obj.area_code = val[1]
#     array_of_obj.push(obj)

#   console.log(array_of_obj)


# 'complete': (result) ->
  # modifyArrayToJson(result.responseJSON.data)
window.isikhnasLabReportDataTablesLoad = () ->
  # console.log "isikhnasLabReportDataTablesLoad"
  url = $("#isikhnas_lab_report_table").data('url')
  $("#isikhnas_lab_report_table").DataTable
    "destroy": true
    'searching': true
    'ajax':
      'url': url
      'data': uploadedFilterData()
    "processing": true
    "serverSide": true
    "fnDrawCallback": ->
      showHideTableColumn()
    start: 0

window.uploadedFilterData = () ->
  $("#form_filter_lab_report_data").find("input,select").serializeJSON()

window.retrieveData = (url, start_date, end_date) ->
  $.ajax
    url: url
    dataType: "script"
    data:
      start_date: start_date
      end_date: end_date
      url: url


ready = ->

  if $("#lab_report_list").length > 0
    district_autocomplete('.district-typeahead', 'isikhnas');

  # Default date picker to all input with .datepicker class
  $('body').on 'focus', '.isikhnas-datepicker', ->
    $(this).datepicker
      format: 'yyyy-mm-dd'
      todayHighlight: true
      todayBtn: 'linked'
      weekStart: 1
      autoclose: true

  # districtSearchInIsikhnasMapAutocomplete();
  if $("#lab_report_list").length > 0
    loadIsikhnasLabReportsMap("", 0);

  $("body").on "click", "#show_isikhnas_lab_report_map", (e) ->
    e.preventDefault();
    $("#isikhnas_map_area").slideToggle("slow")

  $("body").on "click", "#isikhnas_map_go_to_district_button", ->
    dis_id = $("#isikhnas_map_district_id").val()
    district_focus("#{dis_id}")

  $("body").on "click", "#retrieve_isikhnas_data_button", (e) ->
    e.preventDefault();
    url = $(this).attr("href")
    start_date = $(this).data("startdate")
    end_date = $(this).data("enddate")
    $("#retrieve_isikhnas_data_button").html("Fetching Data...");
    $("#retrieve_isikhnas_data_button").attr("disabled", "disabled");
    retrieveData(url, start_date, end_date)

$(document).on 'turbolinks:load', ready