// Rails
require('@rails/ujs').start()
require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')
require('trix')
require('@rails/actiontext')

// Styles
import '../styles/application.scss'

// Vendors
import '../vendor/jquery'
import '../vendor/raphael'
import '../vendor/jsphylosvg-min'
import '../vendor/d3'
import '../vendor/d3-bs-tooltips'
import '../vendor/sequenceViewer'
import '../vendor/bootstrap'
import '../vendor/feather-icons'
import '../vendor/bootstrap-datepicker'
import '../vendor/jquery-ui'
import '../vendor/jquery-serializejson'
import '../vendor/mustache'
// import '../vendor/antigenic-cartog'
import '../vendor/comparison-map'
import '../vendor/datatables'
import '../vendor/jsPdf'
import '../vendor/canvg'
import '../vendor/chosen-js'
import '../vendor/inputmask'
// import '../vendor/panzoom'
// import '../vendor/phylotree'
// import '../vendor/trix'
import '../vendor/easymde'
import '../vendor/slick'
import '../vendor/typeahead'

// Sprockets
import './sprockets/index'
import './home'

// images
require.context('../images', true)

// Stimullus
import "controllers"