/*
  sequenceViewer.js
  DNA sequence viewer jquery plugin
  Based on IVM (Influenza Virus Monitoring) Online
  author : Mayu Krisnawan
*/

;(function($, window, document, undefined){

/*
 * ArgumentParser Class
 * Used to simplify the arguments parsing
 */
function ArgumentParser(){
  var rules = [];
  var selectedIndex = -1;

  /*
   * Add argument parsing rule (ArgumentRule instance)
   * @args rule ArgumentRule
   */
  this.add = function(rule){
    if (rule.constructor != ArgumentRule) return false;
    rules.push(rule);
  };

  /*
   * Add argument parsing rule (ArgumentRule instance)
   * @args rule ArgumentRule
   */
  this.index = function(){
    return selectedIndex;
  };

  /*
   * Start argument parsing with specified arguments
   * @args args array of argument
   * @return Object
   */
  this.parse = function(args) {
    if (args.length == 0) return false;
    for (var i=0; i<rules.length; i++) {
      var rule = rules[i];
      if (rule.matchWith(args)) {
        selectedIndex = i;
        return rule.createArguments(args);
      }
    }
  }
}

/*
 * ArgumentRule Class
 * Used to simplify the arguments parsing
 * and belongs to ArgumentParser Object
 */
function ArgumentRule(_args){
  var signedArguments = _args;

  /*
   * Check the signed rules match with the given arguments
   * @return Boolean
   */
  this.matchWith = function(args) {
    var match = true;

    for (var i=0; i<signedArguments.length; i++) {
      signedArgument = signedArguments[i];
      if (signedArgument.default !== undefined) continue;
      argument = args[i];
      if (argument.constructor != signedArgument.type) {
        match = false;
        break;
      }
    }
    return match;
  }

  this.createArguments = function(args) {
    var results = {};
    for (var i=0; i<signedArguments.length; i++) {
      signedArgument = signedArguments[i];
      results[signedArgument.name] = args[i] ? args[i] : signedArgument.default;
    }
    return results;
  }
}

/*
 * PatternEngine Class
 * used for pattern related tasks
 */
function PatternEngine(){}

/*
 * Translate String to
 * Correct Regular Expression String
 * for example AT/GG/C/ATT => A[TG][GCA]TT
 * @args input String
 * @args reduceStart Number
 * @args reduceFinish Number
 * @return String
 */
PatternEngine.strRegex = function(input, reduceStart, reduceFinish, replacement) {
  if (reduceStart === undefined) reduceStart = 0;
  if (reduceFinish === undefined) reduceFinish = 0;
  var output = "";
  var slashIndexes = [];
  var groups = [];
  var vertexes = [];
  var matcher = new RegExp("/", "g");

  // Remove all spaces
  input = input.replace(/[ ]/g, "");

  // Remove slash at begin and end of input
  input = input.replace(/^[\//]+/, "");
  input = input.replace(/[\/]+$/, "");

  // Remove double slashes
  input = input.replace(/[\/]{2,}/g, "/");

  // Find all the slash indexes
  do {
    var result = matcher.exec(input);
    if (result != null) slashIndexes.push(result.index);
  } while (result != null);

  // Remove all slashes
  // input = input.replace(/\//g, "");

  // Construct slash groups
  var lastPosition = -1;
  for (var i=0; i<slashIndexes.length; i++) {
    var position = slashIndexes[i];
    if (lastPosition == -1 || (position-lastPosition > 2)) {
      // Create new group
      groups.push([position]);
    } else {
      // Append Existing Group
      groups[groups.length-1].push(position);
    }
    lastPosition = position;
  }

  // Create group vertexes based on groups
  for (var i=0; i<groups.length; i++) {
    var group = groups[i];
    var minIndex = group[0] - 1;
    var maxIndex = group[group.length-1] + 1;
    vertexes.push({
      min:minIndex,
      max:maxIndex,
      builded:false,
      build:function(input){
        var start = this.min;
        var count = this.max - this.min + 1;
        this.builded = true;
        return input.substr(start, count);
      }
    });
  }


  var existOnVertexes = function(index, vertexes) {
    for (var i=0; i<vertexes.length; i++) {
      var vertex = vertexes[i];
      if (vertex.min <= index && index <= vertex.max) {
        return i;
      }
    }
    return false;
  }

  var outputs = [];

  // Create output based on group vertexes
  for (var i=0; i<input.length; i++) {
    var vertexIndex = existOnVertexes(i, vertexes)
    if (vertexIndex !== false) {
      var vertex = vertexes[vertexIndex];
      if (vertex.builded) continue;
      var group = vertex.build(input);
      group = group.replace(/\//g, "");
      var output = "[" + group + "]";
    } else {
      var output = input[i];
    }
    outputs.push(output);
  }

  var result = "";
  for (var i=reduceStart; i<outputs.length-reduceFinish; i++) {
    result += outputs[i];
  }

  if (replacement !== undefined) {
    for (var i=0; i<reduceStart; i++) result = replacement + result;
    for (var i=outputs.length-reduceFinish; i<outputs.length; i++) result = result + replacement;
  }

  return result;
}

/*
 * Create regular expression instance from specified pattern
 * @args pattern String
 * @return RegExp
 */
PatternEngine.buildRegex = function(pattern) {
  pattern = PatternEngine.strRegex(pattern);
  try {
    return new RegExp(pattern, "g");
  } catch(e) {
    return false;
  }
}

/*
 * Create item string from specified pattern
 * "AAA/GGG/CCC" => ["AAA", "GGG", "CCC"]
 * @args input String
 * @return Array of String
 */
PatternEngine.generateItems = function(input) {
  // Remove slash at begin and end of input
  input = input.replace(/^[\//]+/, "");
  input = input.replace(/[\/]+$/, "");

  // Remove double slashes
  input = input.replace(/[\/]{2,}/g, "/");

  // split string by slash
  var output = input.split("/");

  return output;
}

/*
 * Generate Range Object from expression, for example :
 * Range expression :
 * {
 *   start:"11/20",
 *   finish:"50/80"
 * }
 *
 * Result :
 * [Range(11,50), Range(20,80)]
 *
 * @args rangeExpression String
 * @return array of Range
 */
PatternEngine.generateRanges = function(rangeExpression) {
  var ranges = [];
  var startItems = PatternEngine.generateItems(rangeExpression.start.trim());
      finishItems = PatternEngine.generateItems(rangeExpression.finish.trim());
  var length = Math.max(startItems.length, finishItems.length);
  for (var i=0; i<length; i++) {
    var startItem = startItems[i],
        finishItem = finishItems[i];
    if (startItem === undefined) {
      for (var j=i-1; j>=0; j--) {
        if (startItems[j]) startItem = startItems[j];
      }
    }
    if (finishItem === undefined) {
      for (var j=i-1; j>=0; j--) {
        if (finishItems[j]) finishItem = finishItems[j];
      }
    }
    if (startItem === undefined || finishItem === undefined) continue;
    if (startItem.length == 0 || finishItem.length == 0) continue;
    var start = parseInt(startItem),
        finish = parseInt(finishItem);
    var range = new Range(start, finish);
    ranges.push(range);
  }
  return ranges;
}

/*
 * Utility Class
 */
 function Util(){}

 /*
  * convert string to camel case
  * @args input String
  * @return String
  */
Util.camelize = function(input){
  var words = input.split("_");
  var output = "";
  for (var i=0; i<words.length; i++) {
    var word = words[i];
    if (i == 0) {
      word = word.substr(0, 1).toLowerCase() + word.substr(1, word.length);
    } else {
      word = word.substr(0, 1).toUpperCase() + word.substr(1, word.length);
    }
    output += word;
  }
  return output;
}

/*
 * convert string to underscored case
 * @args input String
 * @return String
 */
Util.uncamelize = function(input){
  var words = [];
  var word = "";
  for (var i=0; i<input.length; i++) {
    var isCapital = input[i].toUpperCase() == input[i];
    if (isCapital && i != 0) {
      words.push(word);
      word = input[i].toLowerCase();
    } else {
      word += input[i].toLowerCase();
    }
  }
  // push last word
  if (word.length != 0) words.push(word);
  return words.join("_");
}

Util.iterate = function(items, action, options){
  if (!options) options = {};
  for (var i = 0; i < items.length; i++) {
    var item = items[i];
    var result = item;
    // pass item attributes
    if (options.attributes) {
      var attributes = [];
      for (var attr in Object(item)) {
        attributes.push(item[attr]);
      }
      //console.log(attributes);
      result = action.apply(item, attributes);
    } else {
      result = action.call(item, item, i);
    }

    // change the content of items
    if (options.save) {
      if (result.constructor == Util.IteratorFinishFlag) {
        items[i] = result.returnValue;
      } else {
        items[i] = result;
      }
    }

    if (result !== undefined) {
      if (result.constructor == Util.IteratorFinishFlag) break;
    }
  }
}


Util.IteratorFinishFlag = function(returnValue){
  this.returnValue = null;
  if (returnValue !== undefined) {
    this.returnValue = returnValue;
  }
}

Util.stopIterate = function(returnValue){
  return new Util.IteratorFinishFlag(returnValue);
}

Util.number = function(stringInput){
  try {
    stringInput = stringInput.replace(/[^0-9]/g, "")
    return (new Number(stringInput)).valueOf();
  } catch (e) {
    return stringInput;
  }
}

Util.merge = function(){
  var results = [];
  for (var i=0; i < arguments.length; i++) {
    var data = arguments[i];
    for (var j=0; j<data.length; j++) {
      results.push(data[j]);
    }
  }
  return results;
}

/*
 * Fasta File Parser
 */
function FastaFileParser(){}

/*
 * Read from fasta file content
 * @return Sequence, array of Sequence
 * @args content String, multiple Boolean
 */
FastaFileParser.read = function(content, multiple){
  if (multiple === undefined) multiple = false;
  content = content.trim("\n");
  var lines = content.split("\n");

  var sequence = new Sequence();
  var sequences = new SequenceGroup();
  var sequenceCount = 0;

  for (var i = 0; i<lines.length; i++) {
    var line = lines[i].trim("\n");
    if (line[0] == ">") {
      line = line.substr(1, line.length)
      // stop parsing when single parsing condition found
      if (!multiple && sequenceCount > 0) {
        break;
      }

      // attach sequence header
      if (multiple) {
        var tmp = new Sequence();
        sequences.attach(tmp);
        sequences.find(sequenceCount).header(line);
      } else {
        sequence.header(line);
      }
      sequenceCount++;
    } else {

      // append sequence body
      if (multiple) {
        sequences.find(sequenceCount-1).appendBody(line, function(sequence){
          sequences.calculateMaxLengthBy(sequence);
        });
      } else {
        sequence.appendBody(line);
      }
    }
  }

  // return the parsed file
  if (multiple) {
    return sequences;
  } else {
    return sequence;
  }
}

/*
 * Feature Class
 * Holding Information about some parts of sequence
 */
function Feature(properties){
  //
  // Initialize default properties
  //
  var name = "",
      type = "",
      rangeExpression = {start:"", finish:""},
      ranges = [],
      motifs = {start:"", finish:""},
      lengthExpression = "",
      lengths = [],
      isForValidation = false;

  var matched = {
    start:{
      index:-1,
      from:-1,
      to:-1,
      motif:""
    },
    finish:{
      index:-1,
      from:-1,
      to:-1,
      motif:""
    },
    range:new Range(-1,-1),
    length:-1
  };

  var disabled = false;

  //
  // Initialize property by passed configurations
  //
  if (properties) {
    if (properties.name) name = properties.name;
    if (properties.type) type = properties.type;

    if (properties.range_expression) rangeExpression = properties.range_expression;
    if (properties.rangeExpression) rangeExpression = properties.rangeExpression;
    if (properties.ranges) ranges = properties.ranges;

    if (properties.length_expression) lengthExpression = properties.length_expression;
    if (properties.lengthExpression) lengthExpression = properties.lengthExpression;

    if (properties.motifs) motifs = properties.motifs;
    if (properties.isForValidation) isForValidation = properties.isForValidation;
  }

  //
  // Generate ranges from range expression
  //
  var generateRanges = function() {
    if (rangeExpression.start.length != 0 && rangeExpression.finish.length != 0) {
      ranges = PatternEngine.generateRanges({
        start : rangeExpression.start,
        finish : rangeExpression.finish
      });
    }
  }
  //
  // Generate ranges immediately
  //
  generateRanges();

  //
  // Generate lengths from length expression
  //
  var lengths_array = PatternEngine.generateItems(lengthExpression);
  for (var i=0; i<lengths_array.length; i++) {
    var length = Util.number(lengths_array[i]);
    lengths.push(length);
  }

  // Public Members
  /*
   * setter and getter for name attibute
   */
  this.name = function(newName) {
    if (newName === undefined) return name;
    name = newName;
  }

  /*
   * setter and getter for type attibute
   */
  this.type = function(newType) {
    if (newType === undefined) return type;
    type = newType;
  }

  /*
   * setter and getter for motifs attibute
   */
  this.motifs = function(newMotifs) {
    if (newMotifs === undefined) return motifs;
    motifs = newMotifs;
  }

  this.isForValidation = function() {
    return isForValidation;
  }

  /*
   * setter and getter for ranges attibute
   */
  this.ranges = function(newRanges) {
    if (newRanges === undefined) return ranges;
    ranges = newRanges;
  }

  this.lengths = function(){
    return lengths;
  }

  /*
   * return expression of the feature ranges
   * @return Hash
   */
  this.rangeExpression = function(){
    return rangeExpression;
  }

  /*
   * return expression of the feature lengths
   * @return Hash
   */
  this.lengthExpression = function(){
    return lengthExpression;
  }

  /*
   * Set range expression and recompile ranges
   * @args rangeExpression String
   */
  this.compileRanges = function(newRangeExpression) {
    rangeExpression = newRangeExpression;
    generateRanges();
  }

  /*
   * build hash from feature properties
   * @return Hash
   */
  this.hashify = function(){
    return {
      name: name,
      type: type,
      rangeExpression: rangeExpression,
      range_expression: rangeExpression,
      ranges: ranges,
      motifs: motifs
    }
  }

  /*
   * Adjust feature offset by specified index
   * @args adjustmentIndex Number
   */
  this.adjustBy = function(adjustmentIndex) {
    Util.iterate(ranges, function(range){
      return range.adjustBy(adjustmentIndex);
    }, {save:true});
    return ranges;
  }

 /*
   * Match with feature ranges
   * @args start Number
   * @args finish Number
   * @return Boolean
   */
  var existOnRanges = function(start, finish, startResult, finishResult, proteinMode, matchStart, matchFinish, reduceStart, reduceFinish) {
    if (matchStart === undefined) matchStart = true;
    if (matchFinish === undefined) matchFinish = true;

    if (start > finish && matchStart && matchFinish) return false;
    var exist = false;
    Util.iterate(ranges, function(range){
      var rangeHash = range.hashify();
      var assignResult = function(){
        exist = true;
        // Assign result
        matched = {
          start:{
            index:start,
            from:start,
            to:start + startResult[0].length - 1,
            motif:startResult[0]
          },
          finish:{
            index:finish,
            from:finish - finishResult[0].length + 1,
            to:finish,
            motif:finishResult[0]
          },
          range:range,
          length:finish-start+1
        };

        if (proteinMode) {
          matched.start.index = Math.floor(matched.start.index/3);
          matched.start.from = Math.floor(matched.start.from/3);
          matched.start.to = Math.floor(matched.start.to/3);
          matched.finish.index = Math.floor(matched.finish.index/3);
          matched.finish.from = Math.floor(matched.finish.from/3);
          matched.finish.to = Math.floor(matched.finish.to/3);
        }
      }

      if (matchStart && matchFinish) {
        if (rangeHash.start + reduceStart == start && rangeHash.finish - reduceFinish == finish) {
          assignResult();
          return Util.stopIterate();
        }
      } else if (matchStart && rangeHash.start + reduceStart  == start) {
        assignResult();
        return Util.stopIterate();
      } else if (matchFinish && rangeHash.finish - reduceFinish == finish) {
        assignResult();
        return Util.stopIterate();
      }
    });
    return exist;
  }


  /*
   * Match feature with sequence
   * @args sequence Sequence
   * @return Boolean
   */
  var matchWith = function(sequence, matchStart, matchFinish, reduceStart, reduceFinish) {
    if (matchStart === undefined) matchStart = true;
    if (matchFinish === undefined) matchFinish = true;
    if (reduceStart === undefined) reduceStart = 0;
    if (reduceFinish === undefined) reduceFinish = 0;

    var startExpression = PatternEngine.strRegex(motifs.start, reduceStart, reduceFinish),
        finishExpression = PatternEngine.strRegex(motifs.finish, reduceStart, reduceFinish),
        // matcher = new RegExp("(" + startExpression + ").*(" + finishExpression + ")", "g");
        startMatcher = new RegExp(startExpression, "g"),
        finishMatcher = new RegExp(finishExpression, "g");
    var body = sequence.nucleotide();
    var startResults = [],
        finishResults = [];

    var startResult = startMatcher.exec(body),
        finishResult = finishMatcher.exec(body);

    while (startResult != null) {
      startResults.push(startResult);
      startResult = startMatcher.exec(body);
      if (startResult != null) {
        startMatcher.lastIndex = startResult.index+1;
      }
    }

    while (finishResult != null) {
      finishResults.push(finishResult);
      finishResult = finishMatcher.exec(body);
      if (finishResult != null) {
        finishMatcher.lastIndex = finishResult.index+1;
      }
    }

    if (matchStart && matchFinish) {
      if (startResults.length == 0 || finishResults.length == 0) return false;
    } else if (matchStart) {
      if (startResults.length == 0) return false;
    } else if (matchFinish) {
      if (finishResults.length == 0) return false;
    }

    // console.log(name)
    for (var i=0; i<startResults.length; i++) {
      for (var j=0; j<finishResults.length; j++) {
        var start = startResults[i].index,
            finish = finishResults[j].index + finishResults[j][0].length - 1;
        // console.log(start + " " + finish);
        if (existOnRanges(start, finish, startResults[i], finishResults[j], sequence.inProteinMode(), matchStart, matchFinish, reduceStart, reduceFinish)) return true;
      }
    }
    return false;
  }

  /*
   * Match feature with sequence or sequence group
   * @args input Sequence
   * @args reduceStart Number
   * @args reduceFinish Number
   * @return Boolean
   */
  this.match = function(input, reduceStart, reduceFinish) {
    if (input.constructor == Sequence) {
      return matchWith(input, true, true, reduceStart, reduceFinish);
    }
  }

  /*
   * return matched information
   * @return Hash
   */
  this.matched = function() {
    return matched;
  }


  this.startMatched = function(input, reduceStart, reduceFinish) {
    if (input.constructor == Sequence) {
      matchWith(input, true, false, reduceStart, reduceFinish);
      return matched;
    }
  }

  this.finishMatched = function(input, reduceStart, reduceFinish) {
    if (input.constructor == Sequence) {
      matchWith(input, false, true, reduceStart, reduceFinish);
      return matched;
    }
  }

  /*
   * Check the feature is disabled or not
   * @return Boolean
   */
  this.disabled = function() {
    return disabled;
  }

  /*
   * Enable feature
   */
  this.enable = function() {
    disabled = false;
  }

  /*
   * Disable feature
   */
  this.disable = function() {
    disabled = true;
  }
}

function FeatureManager(){
  var features = [],
      matched = [];

  /*
   * Find speciied feature by specified index
   * @args index Number
   * @return Feature
   */
  this.find = function(index){
    return features[index];
  }

  /*
   * Find all features
   * @return array of Feature
   */
  this.all = function(){
    return features;
  }

  /*
   * Add new feature
   * @args feature Feature
   */
  this.attach = function(feature){
    features.push(feature);
    layers = null;
  }

  /*
   * Match feature with array of sequence
   * @args sequences array of Sequence
   * @return array of Hash
   */
  var matchWith = function(sequences) {
    matched = [];
    Util.iterate(sequences, function(sequence, index){
      matched[index] = [];
      Util.iterate(features, function(feature, featureIndex){
        matched[index].push({
          feature:feature,
          result:false
        })
        if (feature.match(sequence)) {
          matched[index][featureIndex].result = feature.matched();
        }
        // console.log(index + "," + featureIndex);
        // console.log(sequence.body());
        // console.log(feature.motifs());
        // console.log(feature.ranges()[1].hashify());
        // console.log("");
      })
    })
    return matched;
  }

  /*
   * Match feature with sequence or sequence group
   * @args input Sequence|SequenceGroup
   * @return array of Hash
   */
  this.match = function(input){
    if (input.constructor == Sequence) {
      return matchWith([input]);
    } else if (input.constructor == SequenceGroup) {
      return matchWith(input.all());
    }
  }

  /*
   * Match feature with sequence or sequence group
   * @args input Sequence|SequenceGroup
   * @return array of Hash
   */
  this.layers = function(sequence){
    var results = this.match(sequence);
    var inputs = results[0];

    var layers = [];
    Util.iterate(inputs, function(input, index){
      if (input.result == false) return;
      if (input.feature.disabled()) return;
      var feature = {
        index: index,
        name: input.feature.name(),
        type: input.feature.type(),
        range: new Range(input.result.start.index, input.result.finish.index),
        from: input.result.start.index,
        to: input.result.finish.index
      };

      var targetLayerIndex = 0;
      for (var layerIndex=0; layerIndex<layers.length; layerIndex++) {
        var currentLayer = layers[layerIndex];
        for (var featureIndex = 0; featureIndex < currentLayer.length; featureIndex++) {
          var currentFeature = currentLayer[featureIndex];
          if (currentFeature.range.intersect(feature.range)) targetLayerIndex++;
        }
      }

      // push feature to the feature layers
      if (layers[targetLayerIndex] === undefined) layers[targetLayerIndex] = [];
      layers[targetLayerIndex].push(feature);
    });
    return layers;
  }

  /*
   * return matched information
   * @return array of Hash
   */
  this.matched = function() {
    return matched;
  }

  /*
   * return matched information by specified index
   * @args index Number
   * @return Hash
   */
  this.findMatched = function(index){
    return matched[index];
  }

  /* OLD
   * Validate sequence with specified features
   * @args sequence Sequence
   * @return Boolean
  this.validate = function(sequence){
    var results = [];
    Util.iterate(features, function(feature, featureIndex){
      if (!feature.isForValidation()) return;
      if (!feature.match(sequence)) {
        results.push({
          index : featureIndex,
          name : feature.name()
        });
      }
    });
    return results;
  }
  */

  this.validate = function(sequence){
    var results = [];
    var sequenceBody = sequence.body();
    Util.iterate(features, function(feature){
      if (!feature.isForValidation()) return;
      var result = [];
      if (SequenceValidation.exactlyMatch(sequenceBody, feature)) return;

      var is_too_short = SequenceValidation.tooShort(sequenceBody, feature);
      var is_too_long = SequenceValidation.tooLong(sequenceBody, feature);

      if (is_too_short) {
        result.push({
          message : feature.name() + " has been found in the sequence, but the length is too short"
        })
      } else if (is_too_long) {
        result.push({
          message : feature.name() + " has been found in the sequence, but the length is too long"
        })
      }

      var is_novel = SequenceValidation.novel(sequenceBody, feature);
      if (is_novel && !is_too_long && !is_too_short) {
        var message = feature.name();
        if (SequenceValidation.correctStartMotifPosition(sequenceBody, feature)) {
          message += " has correct start motif (" + feature.motifs().start + ") position, but finish motif is missing";
        } else if (SequenceValidation.correctFinishMotifPosition(sequenceBody, feature)) {
          message += " has correct finish motif (" + feature.motifs().finish + ") position, but start motif is missing";
        }
        message += ". so, it has a novel position";
        result.push({
          message : message
        });
      }

      var is_partially_missing = SequenceValidation.partiallyMissing(sequenceBody, feature);
      if (is_partially_missing && !is_novel) result.push({
        message : feature.name() + " is partially missing"
      });

      if (!is_partially_missing && !is_too_long && !is_too_short && !is_novel) result.push({
        message : feature.name() + " is missing"
      });


      results = Util.merge(results, result);
    });
    return results;
  }

  this.disabledCount = function(){
    var count = 0;
    Util.iterate(features, function(feature){
      if (feature.disabled()) count++;
    });
    return count;
  }
}

function Range(_start, _finish) {
  // Initialize default properties
  var start = 0,
      finish = 0;
  if (_start) start = _start;
  if (_finish) finish = _finish;

  // Revert start and finish
  // when position not appropriate
  if (start > finish) {
    var tmp = start;
    start = finish;
    finish = tmp;
  }

  /*
   * return Range position
   * @return Hash
   */
  this.hashify = function(){
    return {
      start: start,
      finish: finish
    }
  }

  this.start = function() { return start; }
  this.finish = function() { return finish; }

  /*
   * Adjust range offset by index
   * @args adjustmentIndex Number
   */
  this.adjustBy = function(adjustmentIndex) {
    start += adjustmentIndex;
    finish += adjustmentIndex;
    return this;
  }

  /*
   * Check that range intersect with other Range object
   * @args range Range
   * @return Boolean
   */
  this.intersect = function(range){
    var other = range.hashify();
    if (other.start > finish) return false;
    if (start > other.finish) return false;
    return true;
  }
}

/*
 * DNA Sequence object
 * @args properties Object
 */
function Sequence(properties){
  // Initialize default properties
  if (properties) {
    var header = properties.header ? properties.header :  "";
    var body = properties.body ? properties.body :  "";
  } else {
    var header = "";
    var body = "";
  }

  // Private members
  var lastPosition = {
    index:-1,
    start:-1,
    finish:-1,
    length:-1
  };
  var proteinMode = false;
  var proteinBody = "";
  var proteinTable = {
    "A":["GCT", "GCC", "GCA", "GCG",
       "GCU"],
    "C":["TGC", "TGT",
       "UGC", "UGU"],
    "D":["GAC", "GAT",
        "GAU"],
    "E":["GAA", "GAG"],
    "F":["TTT", "TTC",
       "UUU", "UUC"],
    "G":["GGA", "GGC", "GGG", "GGT"],
    "H":["CAT", "CAC",
       "CAU"],
    "I":["ATT", "ATC", "ATA",
       "AUU", "AUC", "AUA"],
    "K":["AAA", "AAG"],
    "L":["CTT", "CTC", "CTA", "CTG", "TTA", "TTG",
       "CUU", "CUC", "CUA", "CUG", "UUA", "UUG"],
    "M":["ATG",
       "AUG"],
    "N":["AAC", "AAT",
       "AAU"],
    "P":["CCT", "CCC", "CCA", "CCG",
       "CCU"],
    "Q":["CAA", "CAG"],
    "R":["AGA", "AGG", "CGA", "CGC", "CGG", "CGT",
       "CGU"],
    "S":["AGC", "AGT", "TCA", "TCC", "TCG", "TCT",
       "AGU", "UCA", "UCC", "UCG", "UCU"],
    "T":["ACT", "ACC", "ACA", "ACG",
       "ACU"],
    "V":["GTT", "GTC", "GTA", "GTG",
       "GUU", "GUC", "GUA", "GUG"],
    "W":["TGG",
       "UGG"],
    "Y":["TAT", "TAC",
       "UAU", "UAC"],
    "Z":["CAA", "CAG", "GAA", "GAG"],
    "*":["TAA", "TAG", "TGA",
       "UAA", "UAG", "UGA"],
  }

  // Public members
  this.rawFasta = function(){
    return ">" + header + "\n" + body;
  }

  /*
   * set or get sequence header
   */
  this.header = function(newHeader, callback) {
    if (!newHeader) return header;
    header = newHeader;
    if (callback != undefined) callback(this);
  }

  /*
   * set or get sequence body
   */
  this.body = function(newBody, callback) {
    if (!newBody) return proteinMode ? proteinBody : body;
    body = newBody.trim("\n");
    if (proteinMode) proteinBody = this.protein();
    if (callback != undefined) callback(this);
  }

  this.edit = function(index, newCode) {
    body = body.substr(0, index) + newCode + body.substr(index+1);
    if (proteinMode) proteinBody = this.protein();
  }

  /*
   * append sequence body
   */
  this.appendBody = function(adder, callback) {
    body += adder.trim("\n");
    if (callback != undefined) callback(this);
  }

  /*
   * Search with specified pattern
   * @args pattern String
   * @return Object
   */
  this.search = function(pattern, startingIndex){
    if (startingIndex !== undefined) lastPosition.index = startingIndex;
    var regex = PatternEngine.buildRegex(pattern);
    if (lastPosition.index == -1) {
      regex.lastIndex = null;
    } else {
      regex.lastIndex = lastPosition.index + 1;
    }

    var searchSequence = function(){
      try {
        var result = proteinMode ? regex.exec(proteinBody) : regex.exec(body);
        lastIndex = regex.lastIndex;
      } catch(e) {
        lastIndex = 0;
        return false;
      }

      var searchResult = result == null ? {
        index:-1,
        length:-1,
        start:-1,
        finish:-1
      } : {
        index:result.index,
        length:result[0].length,
        start:result.index,
        finish:result.index + result[0].length-1
      }
      lastPosition = searchResult;
      return searchResult;
    }

    var result = searchSequence();

    // if not found, try to search from beginning
    if (result.index == -1) {
      lastIndex = 0;
      regex.lastIndex = null;
      result = searchSequence();
      result.last = true;
    } else {
      // Indicating the search result not at near the end of sequence
      result.last = false;
    }
    return result;
  }

  /*
   * Reset search to first position
   */
  this.resetSearch = function() {
    lastPosition = {
      index:-1,
      start:-1,
      finish:-1,
      length:-1
    };
  }

  /*
   * return last position
   * @return Number
   */
  this.position = function(newPosition){
    if (newPosition !== undefined) lastPosition = newPosition;
    var result = new Object(lastPosition);
    if (result.index == -1) result.index = 0;
    if (result.length == -1) result.length = 0;
    if (result.start == -1) result.start = 0;
    if (result.finish == -1) result.finish = 0;
    return lastPosition;
  }

  /*
   * return protein form of sequence body
   * @return String
   */
  this.protein = function(){
    return Sequence.proteinize(body, proteinTable);
  }

  /*
   * return nucleotide form of sequence body
   * @return String
   */
  this.nucleotide = function(){
    return body;
  }

  /*
   * turn sequence to protein mode
   * @return String
   */
  this.proteinize = function(){
    proteinMode = true;
    proteinBody = this.protein();
    return proteinBody;
  }

  /*
   * turn sequence to nucleotide mode
   * @return String
   */
  this.unproteinize = function(){
    proteinMode = false;
    return body;
  }

  /*
   * return the indicator of protein mode
   * @return Boolean
   */
  this.inProteinMode = function(){
    return proteinMode;
  }

  this.remove = function(selectedSites, callback) {
    for (var i = 0; i<selectedSites.length; i++) {
      var selectedSite = selectedSites[i];
      var index = selectedSite.sequenceIndex - i;
      if (proteinMode) {
        index = index * 3;
        body = body.substr(0, index) + body.substr(index+3);
      } else {
        body = body.substr(0, index) + body.substr(index+1);
      }
    }
    if (proteinMode) this.proteinize();
    if (callback) callback();
  }

  this.computeProteinPreview = function(){
    var proteins = [];

    var s1 = null,
        s2 = null,
        s3 = null;

    if (body.length == 0) return proteins;
    if (body.length >= 3) s1 = body.substring(0, body.length);
    if (body.length >= 4) s2 = body.substring(1, body.length);
    if (body.length >= 5) s3 = body.substring(2, body.length);

    if (s1 != null) proteins.push(Sequence.proteinize(s1, proteinTable));
    if (s2 != null) proteins.push(Sequence.proteinize(s2, proteinTable));
    if (s3 != null) proteins.push(Sequence.proteinize(s3, proteinTable));

    return proteins;
  }
}

Sequence.proteinize = function(source, proteinTable){
  var result = "";
  var translate = function(codon){
    if (codon == "---") return "-";
    for (var proteinForm in proteinTable) {
      var nucleotideForms = proteinTable[proteinForm];
      for (var i=0; i<nucleotideForms.length; i++) {
        var nucleotide = nucleotideForms[i];
        if (nucleotide == codon) return proteinForm;
      }
    }
    return "?";
  }
  for (var i=0; i<source.length; i=i+3) {
    var codon = source.substring(i, i+3);
    if (codon.length != 3) continue;
    result += translate(codon);
  }
  return result;
}

/*
 * SequenceGroup
 * @desc container for multiple sequence
 */
function SequenceGroup(){
  var sequences = [];
  var maxLength = 0,
      longestSequence = null;
  var currentRow = 0;
  var proteinMode = false;
  var consensus = null;

  /*
   * find sequence by index
   * @args index Number
   * @return Sequence
   */
  this.find = function(index){
    return sequences[index];
  }

  /*
   * all sequences
   * @return array of Sequence
   */
  this.all = function(){
    return sequences;
  }

  /*
   * return sequences count
   * @return Number
   */
  this.count = function(){
    return sequences.length;
  }

  /*
   * max length of the sequences
   * @return Number
   */
  this.max = function(){
    return {
      length: maxLength,
      sequence: longestSequence
    }
  }

  /*
   * attach Sequence at the end
   * @args sequence Sequence
   */
  this.attach = function(sequence){
    if (sequence.constructor == Sequence) {
      if (longestSequence === null) longestSequence = sequence;
      sequences.push(sequence);
      consensus = null;
    }
  }

  /*
   * each iterator
   * @args action Function
   */
  this.each = function(action){
    for (var i=0; i<sequences.length; i++) {
      action(sequences[i], i);
    }
  }

  /*
   * Calculate longest sequence by including new sequence
   */
  this.calculateMaxLengthBy = function(sequence) {
    var newLength = sequence.body().length;
    if (newLength > maxLength) {
      maxLength = newLength;
      longestSequence = sequence;
    }
  }

  /*
   * Search with specified pattern
   * @args pattern String
   * @args startingRow Number
   * @return Object
   */
  this.search = function(pattern, startingRow) {
    var sequence, result = {
      index:-1,
      start:-1,
      finish:-1,
      length:-1
    };
    if (startingRow !== undefined) currentRow = startingRow;

    if (currentRow >= sequences.length) currentRow = 0;

    var searchFromTo = function(from, to){
      for (var i=from; i<=to; i++) {
        sequence = sequences[i];
        do {
          result = sequence.search(pattern);
        } while (result.index == -1 && !result.last);
        if (result.index != -1) {
          if (result.last) {
            if (i == sequences.length-1) {
              result.index = -1;
              break;
            }
          } else {
            currentRow = i;
            break;
          }
        }
      }
    }
    searchFromTo(currentRow, sequences.length-1);

    if (result.index == -1) {
      // almost at the end of the row, so return to the first row
      if (currentRow == sequences.length-1 && sequences.length != 1) {
        currentRow = 0;
        searchFromTo(currentRow, sequences.length-2);
      }
    }
    result.row = currentRow;
    return result;
  }

  /*
   * get current position of active sequence
   * @return Object
   */
  this.position = function(){
    //console.log(currentRow);
    var sequence = sequences[currentRow];
    var _position = sequence.position();
    var position = {};
    for (var property in _position) {
      if (property != "last") position[property] = _position[property];
    }
    position.row = currentRow;
    return position;
  }

  /*
   * Sort sequences by header
   */
  this.sort = function(){
    for (var i=0; i<sequences.length-1; i++) {
      for (var j=i+1; j<sequences.length; j++) {
        var first = sequences[i];
        var second = sequences[j];
        if (first.header() > second.header()) {
          sequences[i] = second;
          sequences[j] = first;
        }
      }
    }
  }

  /*
   * turn sequence to protein mode
   * @return String
   */
  this.proteinize = function(){
    proteinMode = true;
    consensus = null;
    for (var i=0; i<sequences.length; i++) {
      sequences[i].proteinize();
    }
    return this.all().map(function(sequence){
      return sequence.body();
    });
  }

  /*
   * turn sequence to nucleotide mode
   * @return String
   */
  this.unproteinize = function(){
    proteinMode = false;
    consensus = null;
    for (var i=0; i<sequences.length; i++) {
      sequences[i].unproteinize();
    }
    return this.all().map(function(sequence){
      return sequence.body();
    });
  }

  /*
   * return the indicator of protein mode
   * @return Boolean
   */
  this.inProteinMode = function(){
    return proteinMode;
  }

  this.remove = function(selectedSites, callback) {
    consensus = null;
    var sites = [];
    for (var i = 0; i < selectedSites.length; i++) {
      var selectedSite = selectedSites[i];
      if (sites[selectedSite.rowIndex] === undefined) sites[selectedSite.rowIndex] = [];
      sites[selectedSite.rowIndex].push(selectedSite);
    }
    this.each(function(sequence, index){
      sequence.remove(sites[index]);
    });
    if (callback) callback();
  }

  this.consensus = function(){
    if (sequences.length == 1) return sequences[0].body();
    if (consensus !== null) return consensus;
    count_list = [];

    // collect count_list of genetic code from all sequences
    for (var i=0; i<sequences.length; i++) {
      var sequence = sequences[i];
      for (var j=0; j<maxLength; j++) {
        if (j >= sequence.body().length) break;
        var code = sequence.body()[j];
        if (count_list[j] === undefined) count_list[j] = {};
        if (count_list[j][code] === undefined) {
          count_list[j][code] = 1;
        } else {
          count_list[j][code] += 1;
        }
      }
    }

    consensus = "";

    for (var i=0; i<count_list.length; i++) {
      var count = count_list[i];
      var result = "-";
      var max = null;
      var same;
      for (var code in count) {
        if (max === null || count[code] > max) {
          max = { code: code, count: count[code] }
          same = false;
          result = code;
        } else if (count[code] == max.count) {
          same = true;
        }
      }
      if (same === true) result = "-";
      consensus += result;
    }

    return consensus;
  }
}

/*
 * DNA Sequence Parser
 */
function SequenceParser(){}

/*
 * Read from sequence file
 * @return Sequence
 * @args options Object
 */
SequenceParser.read = function(options){
  var content = options.content ? options.content : "";
  var type = options.type ? options.type : "fasta";
  var multiple = options.multiple ? options.multiple : false;

  if (type == "fasta") {
    return FastaFileParser.read(content, multiple);
  }
}

function SequenceValidation(){}

SequenceValidation.hasFinishMotif = function(sequenceBody, feature) {
  var motif = feature.motifs().finish,
      matcher = new RegExp(PatternEngine.strRegex(motif), "g");
  return matcher.exec(sequenceBody) != null;
}

SequenceValidation.hasStartMotif = function(sequenceBody, feature) {
  var motif = feature.motifs().start,
      matcher = new RegExp(PatternEngine.strRegex(motif), "g");
  return matcher.exec(sequenceBody) != null;
}

SequenceValidation.correctStartMotifPosition = function(sequenceBody, feature){
  var motif = feature.motifs().start,
      matcher = new RegExp(PatternEngine.strRegex(motif), "g")
      ranges = feature.ranges();

  var matchWithRanges = function(index){
    for (var i=0; i<ranges.length; i++) {
      var range = ranges[i];
      // console.log(feature.name() + " " + range.start() + " " + index);
      if (range.start() == index) return true;
    }
    return false;
  }

  do {
    var result = matcher.exec(sequenceBody);
    if (result != null) {
      if (matchWithRanges(result.index)) return true;
      matcher.lastIndex = result.index + 1;
    }
  } while (result != null);
  return false;
}

SequenceValidation.correctFinishMotifPosition = function(sequenceBody, feature){
  var motif = feature.motifs().finish,
      matcher = new RegExp(PatternEngine.strRegex(motif), "g")
      ranges = feature.ranges();

  var matchWithRanges = function(finishIndex){
    for (var i=0; i<ranges.length; i++) {
      var range = ranges[i];
      if (range.finish() == finishIndex) return true;
    }
    return false;
  }

  do {
    var result = matcher.exec(sequenceBody);
    if (result != null) {
      if (matchWithRanges(result.index + result[0].length-1)) return true;
      matcher.lastIndex = result.index + 1;
    }
  } while (result != null);
  return false;
}

SequenceValidation.exactlyMatch = function(sequenceBody, feature){
  //console.log(feature.name() + " " + SequenceValidation.correctStartMotifPosition(sequenceBody, feature) + " " + SequenceValidation.correctFinishMotifPosition(sequenceBody, feature))
  return SequenceValidation.correctStartMotifPosition(sequenceBody, feature) && SequenceValidation.correctFinishMotifPosition(sequenceBody, feature);
}

SequenceValidation.tooLong = function(sequenceBody, feature){
  var correctStartMotifPosition = SequenceValidation.correctStartMotifPosition(sequenceBody, feature);
  var correctFinishMotifPosition = SequenceValidation.correctFinishMotifPosition(sequenceBody, feature);

  var hasFinishMotif = SequenceValidation.hasFinishMotif(sequenceBody, feature);
  var hasStartMotif = SequenceValidation.hasStartMotif(sequenceBody, feature);
  if (!hasFinishMotif || !hasStartMotif) return false;

  var sequence = new Sequence({body:sequenceBody});

  var startMatched = feature.startMatched(sequence);
  var startRange = startMatched.range;
  var finishMotifAfterCorrectPosition = startMatched.finish.index > startRange.finish();

  var finishMatched = feature.finishMatched(sequence);
  var finishRange = finishMatched.range;
  var startMotifBeforeCorrectPosition = finishMatched.start.index < finishRange.start();

  var firstCondition = correctStartMotifPosition && finishMotifAfterCorrectPosition;
  var secondCondition = correctFinishMotifPosition && startMotifBeforeCorrectPosition;

  // console.log(firstCondition + " " + secondCondition)
  if (!firstCondition && !secondCondition) {
    var startMatcher = PatternEngine.buildRegex(feature.motifs().start),
    finishMatcher = PatternEngine.buildRegex(feature.motifs().finish);
    do {
      var startResult = startMatcher.exec(sequenceBody);
      finishMatcher.lastIndex = null;
      do {
        var finishResult = finishMatcher.exec(sequenceBody);
        for (var i=0; i<feature.ranges().length; i++) {
          var range = ranges[i];
          // console.log(startResult.index + " " + finishResult.index);
          // console.log(range.hashify());
          try {
            if (startResult.index < range.start() && finishResult.index > range.finish()) return true;
          } catch(e) {
            continue;
          }
        }
        if (finishResult != null) finishResult.lastIndex = finishResult.index + 1;
      } while (finishResult != null)
      if (startResult != null) startResult.lastIndex = startResult.index + 1;
    } while(startResult != null);
  }

  return firstCondition || secondCondition;
}

SequenceValidation.tooShort = function(sequenceBody, feature){
  var correctStartMotifPosition = SequenceValidation.correctStartMotifPosition(sequenceBody, feature);
  var correctFinishMotifPosition = SequenceValidation.correctFinishMotifPosition(sequenceBody, feature);

  var hasFinishMotif = SequenceValidation.hasFinishMotif(sequenceBody, feature);
  var hasStartMotif = SequenceValidation.hasStartMotif(sequenceBody, feature);
  if (!hasFinishMotif || !hasStartMotif) return false;

  var sequence = new Sequence({body:sequenceBody});
  feature.match(sequence);

  var finishMotifBeforeCorrectPosition = false;
  var reduceFinish = 0;
  do {
    var startMatched = feature.startMatched(sequence, 0, reduceFinish);
    var startRange = startMatched.range;
    finishMotifBeforeCorrectPosition = startMatched.finish.index < startRange.finish();
    if (startMatched.finish.index == startRange.finish()) break;
    reduceFinish++;
  } while (startMatched.finish.index == -1 || !finishMotifBeforeCorrectPosition);

  var finishMatched = feature.finishMatched(sequence);
  var finishRange = finishMatched.range;
  var startMotifAfterCorrectPosition = finishMatched.start.index > finishRange.start();

  var firstCondition = correctStartMotifPosition && finishMotifBeforeCorrectPosition;
  var secondCondition = correctFinishMotifPosition && startMotifAfterCorrectPosition;

  if (!firstCondition && !secondCondition) {
    var startMatcher = PatternEngine.buildRegex(feature.motifs().start),
    finishMatcher = PatternEngine.buildRegex(feature.motifs().finish);
    do {
      var startResult = startMatcher.exec(sequenceBody);
      finishMatcher.lastIndex = null;
      do {
        var finishResult = finishMatcher.exec(sequenceBody);
        for (var i=0; i<feature.ranges().length; i++) {
          var range = ranges[i];
          try {
            if (startResult.index > range.start() && finishResult.index < range.finish()) return true;
          } catch(e) {
            continue;
          }
        }
        if (finishResult != null) finishResult.lastIndex = finishResult.index + 1;
      } while (finishResult != null)
      if (startResult != null) startResult.lastIndex = startResult.index + 1;
    } while(startResult != null);
  }

  return firstCondition || secondCondition;
}

SequenceValidation.novel = function(sequenceBody, feature) {
  var firstCondition = SequenceValidation.correctStartMotifPosition(sequenceBody, feature) && !SequenceValidation.correctFinishMotifPosition(sequenceBody, feature);
  var secondCondition = !SequenceValidation.correctStartMotifPosition(sequenceBody, feature) && SequenceValidation.correctFinishMotifPosition(sequenceBody, feature);
  return firstCondition || secondCondition;
}

SequenceValidation.partiallyMissing = function(sequenceBody, feature) {

}

/*
 * UI namespace
 */
var UI = {}

UI.Element = function(signedName, selectorExpression, $signedBase){
  var name = signedName,
      $base = $signedBase === undefined ? $(document.body) : $signedBase,
      $el = $base.find(selectorExpression);

  /*
   * Return name of current element
   * @return String
   */
  this.name = function(){
    return name;
  }

  /*
   * Return element as jQuerySelector Object
   * @return jQuerySelector
   */
  this.$el = function(){
    return $el;
  }

  /*
   * Return element as Document Object Model Node
   * @return DOMElement
   */
  this.dom = function(){
    if ($el == null) return null;
    return $el.get(0);
  }

  /*
   * Bind event handler to the element
   * @args type String
   * @args action Function
   */
  this.event = function(type, action, data){
    $el.unbind(type).on(type, data, action);
  }

  /*
   * Bind late event handler to the element
   * Late Event is an event handled by element parent as a element base
   * @args type String
   * @args action Function
   */
  this.lateEvent = function(type, action, data){
    $base.on(type, selectorExpression, data, action);
  }
}

UI.ElementManager = function(baseElement, assignedEventDataBAse){
  var elements = [],
      $base = $(baseElement),
      eventDataBase = assignedEventDataBAse;

  function createEventDataBy(data) {
    var result = {};
    for (var key in eventDataBase) result[key] = eventDataBase[key];
    for (var key in data) result[key] = data[key];
    return result;
  }

  /*
   * Return base element of element manager
   * @return jQuerySelector
   */
  this.$base = function(){
    return $base;
  }

  /*
   * Return user interface element by name
   * @args name String
   * @return UI.Element
   */
  this.get = function(name){
    var result = false;
    Util.iterate(elements, function(element){
      if (element.name() == name) {
        result = element;
        return Util.stopIterate();
      }
    });
    return result;
  }

  /*
   * Return all elements of Element Manager
   * @return array of UI.Element
   */
  this.all = function(){
    return elements;
  }

  /*
   * Add new element to the User Interface Manager
   * @args name String
   * @args selectorExpression String
   */
  this.attach = function(name, selectorExpression){
    var newElement = new UI.Element(name, selectorExpression, $base);
    // console.log(newElement.name())
    // console.log(newElement.dom())
    if (elements.length != 0) {
      for (var i=0; i<elements.length; i++) {
        var element = elements[i];
        if (element.name() == name) {
          elements.splice(i, 1, newElement);
          return;
        }
      }
    }
    elements.push(newElement);
  }

  /*
   * Bind an event handler to the specified element
   * @args name String
   * @args type String
   * @args action Function
   */
  this.event = function(name, type, action, data){
    var element = this.get(name);
    var passedData = createEventDataBy(data);
    element.event(type, action, passedData);
  }

  /*
   * Bind late event handler to the element
   * Late Event is an event handled by element parent as a element base
   * @args name String
   * @args type String
   * @args action Function
   */
  this.lateEvent = function(name, type, action, data){
    var element = this.get(name);
    var passedData = createEventDataBy(data);
    element.lateEvent(type, action, passedData);
  }
}

/*
 * TemplateManager Class
 * Class for managing template
 */
UI.TemplateManager = function(){
  var templates = [];

  /*
   * flush template contents
   */
  this.flush = function(){
    templates = [];
  }

  /*
   * prepend template contents
   * @args contents String
   */
  this.prepend = function(contents){
    templates.unshift(contents);
  }

  /*
   * append template contents
   * @args contents String
   */
  this.append = function(contents){
    templates.push(contents);
  }

  /*
   * return contents of the template
   * @return String
   */
  this.contents = function(){
    return templates.join("\n");
  }
}

var templates = [],
    events = [];

var sequenceColors = {
  "A":"#FFFFCF",
  "B":"#BCD56E",
  "C":"#CBE2FF",
  "D":"#A0EE6E",
  "E":"#E9C3AB",
  "F":"#BDE4BF",
  "G":"#E4C3F3",
  "H":"#C0D2E0",
  "I":"#CCD56E",
  "K":"#F1C1FA",
  "L":"#DAFCFF",
  "M":"#E9F1CA",
  "N":"#F7CBD3",
  "P":"#FAF8E7",
  "Q":"#FFDCC5",
  "R":"#D5F1A2",
  "S":"#C1E2E2",
  "T":"#FFB3C2",
  "U":"#D2F6FF",
  "V":"#F8C4CC",
  "W":"#B2B2F7",
  "Y":"#F89AC4",
  "Z":"#88D5EE",
  "*":"#555555",
  "?":"#555555"
}

var featureColors = [
  "#BCD56E",
  "#CBE2FF",
  "#A0EE6E",
  "#E9C3AB",
  "#BDE4BF",
  "#E4C3F3",
  "#C0D2E0",
  "#CCD56E",
  "#F1C1FA",
  "#DAFCFF",
  "#E9F1CA",
  "#F7CBD3",
  "#FAF8E7",
  "#ECEBDA",
  "#D5F1A2",
  "#C1E2E2",
  "#FFB3C2",
  "#D2F6FF",
  "#F8C4CC",
  "#B2B2F7",
  "#F89AC4",
  "#88D5EE",
  "#FFFFCF"
];

var nucleotideName = {
  "A" : "Adenin",
  "G" : "Guanin",
  "T" : "Thymine",
  "C" : "Cytosine",
  "U" : "Uracil",
  "-" : "gap"
};

var proteinName = {
  "A" : "(Ala/A) Alanine",
  "C" : "(Cys/C) Cysteine",
  "D" : "(Asp/D) Aspartic acid",
  "E" : "(Glu/E) Glutamic acid",
  "F" : "(Phe/F) Phenylalanine",
  "G" : "(Gly/G) Glycine",
  "H" : "(His/H) Histidine",
  "I" : "(Ile/I) Isoleucine",
  "K" : "(Lys/K) Lysine",
  "L" : "(Leu/L) Leucine",
  "M" : "(Met/M) Methionine",
  "N" : "(Asn/N) Asparagine",
  "P" : "(Pro/P) Proline",
  "Q" : "(Gln/Q) Glutamine",
  "R" : "(Arg/R) Arginine",
  "S" : "(Ser/S) Serine",
  "T" : "(Thr/T) Threonine",
  "V" : "(Val/V) Valine",
  "W" : "(Trp/W) Tryptophan",
  "Y" : "(Tyr/Y) Tyrosine",
  "TAA" : "Stop (Ochre)",
  "TAG" : "Stop (Amber)",
  "TGA" : "Stop (Opal)",
  "-" : "gap"
}

var multi = function(viewer) {
  return viewer.constructor === MultipleSequenceViewer;
}

var single = function(viewer) {
  return viewer.constructor === SingleSequenceViewer;
}

var deepCopy = function(source, destination) {
  for (var property in source) {
      if (typeof source[property] === "object" && source[property] !== null && destination[property]) {
          deepCopy(destination[property], source[property]);
      } else {
          destination[property] = source[property];
      }
  }
};



templates["ambiguity_editor"] = "\
<div class='svae_container sv_toolbar_table'> \
  <div class='svae_header sv_toolbar_header'> \
    Ambiguity \
  </div> \
  <div class='svfm_body'> \
    <div> \
      <table class='table table-bordered svae_table'> \
        <thead class='sv_toolbar_table_header'> \
          <tr style='font-size : 10px;'> \
            <th width='2%'>#</th> \
            <th>Code</th> \
            <th>Position</th> \
            <th width='2%'>Replacement</th> \
          </tr> \
        </thead> \
        <tbody class='svae_list sv_toolbar_table_inner'> \
          <tr> \
            <td colspan='4'><center><i>There is no ambiguity in this sequence</i></center></td> \
          </tr> \
        </tbody> \
      </table> \
    </div> \
  </div> \
</div> \
";

templates["feature_manager"] = "\
<div class='svfm_container sv_toolbar_table'> \
  <div class='svfm_header sv_toolbar_header'> \
    Feature Manager \
  </div> \
  <div class='svfm_body'> \
    <form class='form-inline' style='display:none'> \
      <input type='checkbox' class='svfm_toggle_features'/> \
      Show Feature \
    </form> \
    <div> \
      <table class='table table-bordered svfm_feature_table'> \
        <thead class='sv_toolbar_table_header'> \
          <tr style='font-size : 10px;'> \
            <th width='2%' style='margin:0; padding:0px 8px 0px 8px'><input type='checkbox' class='svfm_feature_checkbox_all' disabled checked/></th> \
            <th width='2%'>#</th> \
            <th>Feature Name</th> \
            <th>Length</th> \
            <th>Start Position</th> \
            <th>Finish Position</th> \
            <th>Start Motif</th> \
            <th>Finish Motif</th> \
          </tr> \
        </thead> \
        <tbody class='svfm_feature_list sv_toolbar_table_inner '> \
          <tr> \
            <td colspan='8'><center><i>No features added</i></center></td> \
          </tr> \
        </tbody> \
      </table> \
    </div> \
  </div> \
</div> \
";

templates["footer"] = "\
<div class='sv_region sv_footer' style='height:30px; '> \
  <span class='pull-left sv_left_status'></span> \
  <span class='pull-right sv_right_status'></span> \
</div> \
";

templates["midbar"] = "\
<div class='sv_midbar'> \
  <table style='width:100%'> \
    <tr class='msv_consensus_bar'> \
      <td class='msv_consensus_label' width='26.7%' style='padding-top:2px; background-color : #eee; text-align: right; padding-right: 5px; border-right: solid 1px #eee'> \
        <b>CONSENSUS</b> \
      </td> \
      <td style='padding:0px; vertical-align:top'> \
        <div class='msv_consensus'> \
          <span style='padding: 2px 5px 0px 5px'></span> \
        </div> \
      </td> \
    </tr> \
\
    <tr class='sv_protein_preview_bar'> \
      <td class='sv_protein_preview_label' width='26.7%' style='padding-top:2px; background-color : #eee; text-align: right; padding-right: 5px; border-right: solid 1px #eee'> \
        <b>PROTEIN FORM</b> \
      </td> \
      <td style='padding:0px; vertical-align:top'> \
        <div class='sv_protein_preview'> \
          <span style='padding: 2px 5px 0px 5px'></span> \
        </div> \
      </td> \
    </tr> \
\
    <tr> \
      <td class='sv_sequence_header_outer' width='25%'> \
        <div class='msv_sequence_header_topbar'> \
          <div class='input-append pull-right' style='margin:0px'> \
            <input type='text' class='msv_search_header_input' placeholder='search sequence by header..' style=''/> \
            <span class='add-on'><i class='icon-search'></i></span> \
          </div> \
        </div> \
        <div class='sv_sequence_header'> \
          <center><i>Empty sequence header</i></center> \
        </div> \
      </td> \
      <td style='padding:0px; vertical-align:top'> \
        <div class='sv_sequence_body'> \
          <center style='height:40px; padding:20px 0px 0px 0px;'><i>Empty sequence body</i></center> \
        </div> \
      </td> \
    </tr> \
  </table> \
</div> \
";

templates["sequence_quality"] = "\
<div class='svsc_container sv_toolbar_table'> \
  <div class='svsc_header sv_toolbar_header'> \
    Sequence Quality \
  </div> \
  <div class='svfm_body'> \
    <div> \
      <table class='table table-bordered svsc_table'> \
        <tbody class='svsc_list sv_toolbar_table_inner'> \
          <tr> \
            <td colspan='2'><center><i>No quality problems found</i></center></td> \
          </tr> \
        </tbody> \
      </table> \
    </div> \
  </div> \
</div> \
";

templates["smallbar"] = {};
templates["smallbar"].open = "\
<div class='sv_smallbar sv_region'> \
  <div class='pull-right sv_sites_selection_display' style='font-size: 10px; padding: 5px; 0px; 0px; 0px;'> \
  </div> \
";

/*
    <a class='btn btn-success btn-mini msv_tree'> \
      Phylogenetic Tree \
    </a> \
*/

templates["smallbar"].close = "</div>";

templates["toolbar"] = {};
templates["toolbar"].open = "\
<div class='sv_toolbar sv_region'> \
  <form class='form-inline' style='margin:0px'> \
    <select class='sv_sequence_form' style='width:150px;'> \
      <option value='nucleotide'>Nucleotide Form</option> \
      <option value='protein'>Protein Form</option> \
    </select> \
    <select class='sv_sequence_selection_mode' style='width:150px;'> \
      <option value='multiple'>Multiple Site Selection</option> \
      <option value='single'>Single Site Selection </option> \
    </select> \
    <a class='svfm_btn_show_fm btn btn-info btn-mini'><i class='icon-chevron-down icon-white'></i> Show Feature Manager</a> \
    <a class='svfm_btn_hide_fm btn btn-info btn-mini' style='display:none'><i class='icon-chevron-up icon-white'></i> Hide Feature Manager</a> \
    \
    <a class='btn btn-danger btn-mini svsc_btn_show_sc_table'> \
      <i class=' icon-chevron-down icon-white'></i> \
      Show Sequence Quality \
      <span class='svsc_errors_count'></span> \
    </a> \
    \
    \
    <a class='btn btn-danger btn-mini svsc_btn_hide_sc_table' style='display:none'> \
      <i class='icon-chevron-up icon-white'></i> \
      Hide Sequence Quality \
      <span class='svsc_errors_count'></span> \
    </a> \
    \
    \
    <a class='btn btn-warning btn-mini msv_alignment'> \
      Align sequences \
    </a> \
    \
    \
    \
    \
    <a class='btn btn-success btn-mini svae_btn_show'> \
      <i class=' icon-chevron-down icon-white'></i> \
      Show Ambiguity Editor \
      <span class='svae_count'></span> \
    </a> \
    \
    \
    <a class='btn btn-success btn-mini svae_btn_hide' style='display:none'> \
      <i class=' icon-chevron-up icon-white'></i> \
      Hide Ambiguity Editor \
      <span class='svae_count'></span> \
    </a> \
    \
    <div class='pull-right' style='display:none'> \
      <span>Zoom</span> \
      - \
        <span class='sv_zoom_line'> \
          <span class='sv_zoom_slider'></span> \
        </span> \
      + \
    </div> \
  </form> \
";

/*
    <a class='btn btn-success btn-mini msv_tree'> \
      Phylogenetic Tree \
    </a> \
*/

templates["toolbar"].close = "</div>";

templates["topbar"] = "\
<div class='sv_region sv_topbar'> \
  <div class='control-group'> \
    <div class='controls'> \
      <div class='input-append pull-right' style='margin:0px'> \
        <input type='text' class='sv_search_input' placeholder='type here, for example : AT/GG/C/ATT'/> \
        <span class='add-on'><i class='icon-search'></i></span> \
      </div> \
      <div class='input-prepend pull-left' style='margin: 0px 5px 0px 0px; display:none'> \
        <span class='add-on'>Current Row</span> \
        <input type='number' class='sv_row_input' value='1' min='1'/> \
      </div> \
      <div class='input-prepend pull-left' style='margin: 0px'> \
        <span class='add-on'>Go to</span> \
        <input type='number' class='sv_site_input' value='1' min='1'/> \
      </div> \
    </div> \
  </div> \
</div> \
";

templates["viewer"] = {};
templates["viewer"].open = "\
<div class='sv_container'> \
";
templates["viewer"].close = "\
</div> \
";

events["feature_manager"] = {};

events["feature_manager"].show = function(e){
  if ($(this).attr("disabled") == "disabled") return false;
  var viewer = e.data.viewer,
      $this = $(this),
      $fm = viewer.$el("feature_manager"),
      $btnHide = viewer.$el("btn_hide_feature_manager");
  $fm.slideDown(400, function(){
    $this.hide();
    $btnHide.show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["feature_manager"].hide = function(e){
  var viewer = e.data.viewer,
      $this = $(this),
      $fm = viewer.$el("feature_manager"),
      $btnShow = viewer.$el("btn_show_feature_manager");
  $fm.slideUp(400, function(){
    $this.hide();
    $btnShow.show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["feature_manager"].toggle_feature = function(e){
  var viewer = e.data.viewer;
  if (this.checked) {
    viewer.showFeatures();
  } else {
    viewer.hideFeatures();
  }
}

events["search_input"] = {};
events["search_input"].search_sequence = function(e){
  if (e.keyCode == 13) {
    var pattern = $(this).val();
    pattern = pattern.toUpperCase();
    e.data.viewer.search(pattern);
  }
}

events["search_input"].flush_search_input = function(e){
  if ($(this).val() == "") {
    SingleSVModules.unhighlightSequence(e.data.viewer);
    e.data.viewer.notifyLeft("");
    return;
  }
}

events["sequence_ambiguity"] = {};

events["sequence_ambiguity"].show = function(e){
  var viewer = e.data.viewer,
      $this = viewer.$el("btn_show_ambiguity_editor"),
      $sc = viewer.$el("ambiguity_editor"),
      $btnHide = viewer.$el("btn_hide_ambiguity_editor");
  $this.attr("disabled", "disabled");
  $sc.slideDown(400, function(){
    $this.hide();
    $btnHide.removeAttr("disabled").show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["sequence_ambiguity"].hide = function(e){
  var viewer = e.data.viewer,
      $this = viewer.$el("btn_hide_ambiguity_editor"),
      $sc = viewer.$el("ambiguity_editor"),
      $btnShow = viewer.$el("btn_show_ambiguity_editor");
  $this.attr("disabled", "disabled");
  $sc.slideUp(400, function(){
    $this.hide();
    $btnShow.removeAttr("disabled").show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["sequence_body"] = {};

events["sequence_body"].hoverSequence = function(viewer, $box) {
  if (multi(viewer) && viewer.alignmentMode()) {
    return false;
  }
  if ($box.attr("disabled-sequence")) return false;

  if ($box.attr("data-selected") !== "true") {
    var color = viewer.configs("hover_color");
    $box.css("fill", color)
    $box.attr("data-hovered", "true")
  }

  if ($box.attr("consensus-annotation") !== undefined) {
    var msg = $box.attr("consensus-annotation");
    viewer.notifyRight(msg);
    return;
  }

  var index = $box.attr("data-sequence-index");
  index = new Number(index).valueOf() + 1;
  var msg = "Site #" + index;
  if (multi(viewer)) {
    var row = $box.attr("data-row-index");
    msg += " " + MultipleSequenceBodyModule.sequenceDescription(viewer, row, index-1);
  } else {
    msg += " " + SequenceBodyModule.sequenceDescription(viewer, index-1);
  }
  viewer.notifyRight(msg);
}

events["sequence_body"].unhoverSequence = function(viewer, $box) {
  if (multi(viewer) && viewer.alignmentMode()) {
    return false;
  }

  if ($box.attr("disabled-sequence") || $box.attr("data-selected") === "true") return false;
  var color = $box.attr("ori-color"),
      highlighted = $box.attr("data-highlighted");
  if (highlighted == "true") {
    color = viewer.configs("highlight_color");
  }
  $box.css("fill", color);
  viewer.notifyRight("");
}

events["sequence_body"].box_hover = function(e){
  var viewer = e.data.viewer;
  var $box = $(this);
  events["sequence_body"].hoverSequence(viewer, $box);
}

events["sequence_body"].box_out = function(e){
  var viewer = e.data.viewer;
  var $box = $(this);
  events["sequence_body"].unhoverSequence(viewer, $box);
}

events["sequence_body"].label_hover = function(e){
  var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-sequence-index");
  if (multi(viewer)) {
    var row = $label.attr("data-row-index");
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index + "[data-row-index='" + row + "']");
  } else {
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index);
  }
  $box.$label = $label;
  events["sequence_body"].hoverSequence(viewer, $box);
}

events["sequence_body"].label_out = function(e){
  var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-sequence-index");
  if (multi(viewer)) {
    var row = $label.attr("data-row-index");
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index + "[data-row-index='" + row + "']");
  } else {
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index);
  }
  $box.$label = $label;
  events["sequence_body"].unhoverSequence(viewer, $box);
}

events["sequence_body"].consensus_label_hover = function(e){
  var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-sequence-index");
  if (!multi(viewer)) return;
  var $box = viewer.elementManager().$base().find(".sv_consensus_box[data-sequence-index='" + index + "']");
  $box.$label = $label;
  events["sequence_body"].hoverSequence(viewer, $box);
}

events["sequence_body"].consensus_label_out = function(e){
var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-sequence-index");
  if (!multi(viewer)) return;
  var $box = viewer.elementManager().$base().find(".sv_consensus_box[data-sequence-index='" + index + "']");
  $box.$label = $label;
  events["sequence_body"].unhoverSequence(viewer, $box);
}

events["sequence_feature"] = {};

events["sequence_feature"].hoverFeature = function(viewer, $box) {
  if (multi(viewer) && viewer.alignmentMode()) {
    return false;
  }
  $box.css("cursor", "pointer");
  if ($box.$label) $box.$label.css("cursor", "pointer");

  var color = viewer.configs("hover_color");
  $box.css("fill", color);

  var feature = {
    name : $box.attr("data-feature-name"),
    type : $box.attr("data-feature-type"),
    from : Util.number($box.attr("data-feature-from")),
    to : Util.number($box.attr("data-feature-to"))
  };

  var msg = "#Site " + (feature.from+1) + "-" + (feature.to+1);
  msg += ", " + feature.name + " (" + feature.type + ")";
  viewer.notifyRight(msg);
}

events["sequence_feature"].unhoverFeature = function(viewer, $box) {
  if (multi(viewer) && viewer.alignmentMode()) {
    return false;
  }
  $box.css("cursor", "default");
  if ($box.$label) $box.$label.css("cursor", "default");

  var color = $box.attr("ori-color");
  $box.css("fill", color);
  viewer.notifyRight("");
}

events["sequence_feature"].box_hover = function(e){
  var viewer = e.data.viewer;
  var $box = $(this);
  events["sequence_feature"].hoverFeature(viewer, $box);
}

events["sequence_feature"].box_out = function(e){
  var viewer = e.data.viewer;
  var $box = $(this);
  events["sequence_feature"].unhoverFeature(viewer, $box);
}

events["sequence_feature"].label_hover = function(e){
  var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-layer-index") + "_" + $label.attr("data-feature-index");
  if (multi(viewer)) {
    var row = $label.attr("data-row-index");
    var $box = viewer.$el("sequence_body").find(".svf_box_" + index + "[data-row-index='" + row + "']");
  } else {
    var $box = viewer.$el("sequence_body").find(".svf_box_" + index);
  }
  events["sequence_feature"].hoverFeature(viewer, $box);
}

events["sequence_feature"].label_out = function(e){
  var viewer = e.data.viewer;
  var $label = $(this);
  var index = $label.attr("data-layer-index") + "_" + $label.attr("data-feature-index");
  if (multi(viewer)) {
    var row = $label.attr("data-row-index");
    var $box = viewer.$el("sequence_body").find(".svf_box_" + index + "[data-row-index='" + row + "']");
  } else {
    var $box = viewer.$el("sequence_body").find(".svf_box_" + index);
  }
  events["sequence_feature"].unhoverFeature(viewer, $box);
}

events["toolbar.sequence_form"] = {};
events["toolbar.sequence_form"].change = function(e){
  var value = $(this).val();
  if (value == "protein") {
    e.data.viewer.setProteinMode(true);
  } else {
    e.data.viewer.setProteinMode(false);
  }
}

events["sequence_quality"] = {};

events["sequence_quality"].show = function(e){
  var viewer = e.data.viewer,
      $this = viewer.$el("btn_show_sequence_quality"),
      $sc = viewer.$el("sequence_quality"),
      $btnHide = viewer.$el("btn_hide_sequence_quality");
  $this.attr("disabled", "disabled");
  $sc.slideDown(400, function(){
    $this.hide();
    $btnHide.removeAttr("disabled").show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["sequence_quality"].hide = function(e){
  var viewer = e.data.viewer,
      $this = viewer.$el("btn_hide_sequence_quality"),
      $sc = viewer.$el("sequence_quality"),
      $btnShow = viewer.$el("btn_show_sequence_quality");
  $this.attr("disabled", "disabled");
  $sc.slideUp(400, function(){
    $this.hide();
    $btnShow.removeAttr("disabled").show();
    SingleSVModules.setContainerDimension(viewer);
  });
}

events["toolbar.sequence_selection_mode"] = {};
events["toolbar.sequence_selection_mode"].change = function(e){
  var value = $(this).val();
  if (value == "single") {
    e.data.viewer.singleSelectionMode();
  } else {
    e.data.viewer.multipleSelectionMode();
  }
}

events["sequence_selection"] = {};

events["sequence_selection"].toggleSiteSelection = function(viewer, $box) {
  if ($box.attr("data-selected") === "true") {
  	viewer.unselectSite($box);
  } else {
  	viewer.selectSite($box);
  }

  var output = [];
  var selectedSites = viewer.selectedSites();
  for (var i=0; i<selectedSites.length; i++) {
  	output.push(selectedSites[i].sequenceIndex);
  }
}

events["sequence_selection"].box_click = function(e){
  var viewer = e.data.viewer;
  if (multi(viewer) && viewer.alignmentMode()) return false;
  var $box = $(this);
  if ($box.attr("disabled-sequence")) return false;
  events["sequence_selection"].toggleSiteSelection(viewer, $box);
}

events["sequence_selection"].label_click = function(e){
  var viewer = e.data.viewer;
  if (multi(viewer) && viewer.alignmentMode()) return false;
  var $label = $(this);
  var index = $label.attr("data-sequence-index");
  if (multi(viewer)) {
    var row = $label.attr("data-row-index");
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index + "[data-row-index='" + row + "']");
  } else {
    var $box = viewer.$el("sequence_body").find(".sv_sequence_box_" + index);
  }
  if ($box.attr("disabled-sequence")) return false;
  events["sequence_selection"].toggleSiteSelection(viewer, $box);
}

events["site_input"] = {};
events["site_input"].update_site_input = function(e){
  var viewer = e.data.viewer;

  if ($(this).val() == "") return;
  var index = Util.number($(this).val());
  if (index == NaN || index == 0) index = 1;
  index = index - 1;

  SingleSVModules.unhighlightSequence(viewer);

  var position ={
    index:index-2,
    start:index-2,
    finish:index-2,
    length:1
  };

  if (multi(viewer)) {
    viewer.sequences().each(function(sequence){
      sequence.position(position);
    });
  } else {
    viewer.sequence().position(position);
  }
  viewer.slideTo(index);
}

events["zoom_slider"] = {
  moveBy:function(viewer, offset) {
    offset = Math.round(offset/4);
    var $slider = viewer.$el("toolbar.zoom_slider");
    if (!$slider.hasClass("clicked")) return false;

    var oldLeft = $slider.css("left").valueOf();
    oldLeft = oldLeft.replace("px", "");
    oldLeft = new Number(oldLeft).valueOf();

    var newLeft = (oldLeft + offset) % 101;
    if (newLeft < 0) newLeft = 0;
    $slider.css("left", newLeft);
  }
};
events["zoom_slider"].click = function(e){
  $(this).addClass("clicked");
}
events["zoom_slider"].mousemove = function(e){
  events["zoom_slider"].moveBy(e.data.viewer, e.offsetX);
}

events["zoom_line"] = {};
events["zoom_line"].mousemove = function(e){
  events["zoom_slider"].moveBy(e.data.viewer, e.offsetX);
}


events["row_input"] = {};
events["row_input"].update_row_input = function(e){
  var viewer = e.data.viewer,
      em = viewer.elementManager();
  var index = Util.number($(this).val()) - 1;

  if (index > viewer.sequences().count() - 1 || index == NaN || index < 0) index = 0;

  var $sequenceHeaders = em.$base().find(".msv_sequence_header");

  $sequenceHeaders.each(function(){
    var $this = $(this);
    var sequenceIndex = $this.attr("data-sequence-index");
    if (index == sequenceIndex) {
      $this.addClass("active");
    } else {
      $this.removeClass("active");
    }
  });
}

events["sequence_header.search_input"] = function(e){
  var viewer = e.data.viewer;
  var $input = viewer.$el("sequence_header.search_input"),
      query = $input.val();
  if (query == "") {
    viewer.notifyLeft("");
    return;
  }

  var sequences = viewer.sequences();
  var result = -1;
  for (var i =0; i < sequences.count(); i++) {
    var sequence = sequences.find(i);
    result = sequence.header().indexOf(query);
    if (result != -1) {
      result = i;
      break;
    }
  }
  if (result == -1) {
    viewer.notifyLeft("<i>Search not found for '" + query + "'</i>");
  } else {
    var $base = viewer.elementManager().$base();
    $base.find(".msv_sequence_header").removeClass("active");

    var $sequenceHeader = $base.find(".msv_sequence_header[data-sequence-index='" + result + "']");
    $sequenceHeader.addClass("active");

    var header = sequences.find(result).header();
    header = header.substr(0, 10) + "...";
    viewer.notifyLeft("<i>Search match with " + header + "</i>");

    viewer.notifyRow(result);
  }
}

Ambiguity = {};
Ambiguity.registerElements = function(viewer){
  var em = viewer.elementManager();

  em.attach("ambiguity_editor", ".svae_container");
  em.attach("ambiguity_list", ".svae_list");
  em.attach("btn_show_ambiguity_editor", ".svae_btn_show");
  em.attach("btn_hide_ambiguity_editor", ".svae_btn_hide");
  // em.attach("btn_save_ambiguity", ".svae_btn_save");
  em.attach("ambiguity_count", ".svae_count");
}

Ambiguity.registerEvents = function(viewer){
  var em = viewer.elementManager();
  em.event("btn_show_ambiguity_editor", "click", events["sequence_ambiguity"].show);
  em.event("btn_hide_ambiguity_editor", "click", events["sequence_ambiguity"].hide);
  // em.event("btn_save_ambiguity", "click", function(){
  //   viewer.saveAmbiguity();
  // })
}

// source : http://www.boekhoff.info/?pid=data&dat=fasta-codes
Ambiguity.table = {
  "K":["G", "T"],
  "M":["A", "C"],
  "R":["A", "G"],
  "Y":["C", "T"],
  "S":["C", "G"],
  "W":["A", "T"],
  "B":["C", "G", "T"],
  "V":["A", "C", "G"],
  "H":["A", "C", "T"],
  "D":["A", "G", "T"],
  "X":["A", "C", "G", "T"],
  "N":["A", "C", "G", "T"],
  ".":[],
};

Ambiguity.fillAmbiguityEditor = function(viewer, ambiguities) {
  var $list = viewer.$el("ambiguity_list"),
      $count = viewer.$el("ambiguity_count");
      // $btn = viewer.$el("btn_save_ambiguity");

  if (ambiguities.length == 0) {
    $list.html("\
    <tr> \
      <td colspan='4'><center><i>There is no ambiguity in this sequence</i></center></td> \
    </tr> \
    ");
    $count.html("");
    // $btn.hide();
    return;
  }

  $list.html("");
  $count.html(" (" + ambiguities.length + ")");
  // $btn.show();

  Util.iterate(ambiguities, function(ambiguity, index){
    var replacement = "<select class='svae_replacement_select' data-position-index='" +  ambiguity.index + "'>";
    replacement += "<option value='" + ambiguity.code + "'>?</option>";
    var replacementCodes = Ambiguity.table[ambiguity.code];
    for (var i=0; i<replacementCodes.length; i++) {
      var replacementCode = replacementCodes[i];
      replacement += "<option value='" + replacementCode + "'>" + replacementCode + "</option>";
    }
    replacement += "</select>";

    $list.append("\
    <tr> \
      <td>" + (index+1) + "</td> \
      <td>" + ambiguity.code + "</td> \
      <td><a class='svae_position' href='" + ambiguity.index + "'>" + (ambiguity.index+1) + "</a></td> \
      <td>" + replacement + "</td> \
    </tr> \
    ");
  });

  // Slide to specified position
  viewer.elementManager().$base().find(".svae_position").click(function(){
    var index = $(this).attr("href");
    viewer.slideTo(Util.number(index));
    return false;
  });

  // Replacement code selected
  viewer.elementManager().$base().find(".svae_replacement_select").change(function(){
    var index = $(this).attr("data-position-index");
    viewer.slideTo(Util.number(index));
    var code = $(this).val();
    viewer.ambiguityCorrection({
      index: Util.number(index),
      code: code
    });
  });
}

Ambiguity.check = function(viewer){
  if (viewer.inProteinMode()) return false;
  var sequence = viewer.sequence(),
      body = sequence.body();
  var matcher = /[KMRYSWBVHDXN]/g;
  var ambiguities = [];

  var result;
  do {
    result = matcher.exec(body);
    if (result !== null) {
      ambiguities.push({
        code: result[0],
        index: result.index
      });
    }
  } while (result !== null);

  Ambiguity.fillAmbiguityEditor(viewer, ambiguities);
}

FeatureManagerModule = {};

FeatureManagerModule.updateFeatureDisplay = function(viewer, callback){
  var em = viewer.elementManager(),
      fm = viewer.featureManager();
  em.$base().find(".svfm_feature_checkbox").each(function(){
    var index = $(this).attr("data-feature-index");

    var selectorExp = ".svf_box[data-feature-index='" + index + "']";
    selectorExp += ", .svf_label[data-feature-index='" + index + "']";
    var $featureBox = em.$base().find(selectorExp);

    if (this.checked) {
      fm.find(index).enable();
    } else {
      fm.find(index).disable();
    }
  });

  // if (fm.disabledCount() == 0) {
  //   viewer.showRuler();
  // } else {
  //   viewer.hideRuler();
  // }

  if (multi(viewer)) {
    MultipleSequenceFeatureModule.renderFeatureTo(viewer, callback);
  } else {
    SequenceFeatureModule.renderFeatureTo(viewer, callback);
  }
}

FeatureManagerModule.loadFeatureList = function(viewer){
  var $container = viewer.$el("feature_list");
  var $table = viewer.$el("feature_table");
  var features = viewer.featureManager().all();
  if (features.length == 0) {
    $container.html("<td colspan='6'><center><i>No features added</i></center></td>");
    return;
  }
  $table.find(".svfm_feature_checkbox_all").removeAttr("disabled");
  $container.html("");

  Util.iterate(features, function(feature, index){
    $container.append("\
      <tr> \
        <td style='margin:0; padding:0px 0px 0px 8px'> \
          <input type='checkbox' class='svfm_feature_checkbox' data-feature-index='" + index + "' checked/> \
        </td> \
        <td> " + (index+1) + "</td> \
        <td> " + feature.name() + "</td> \
        <td> " + feature.lengthExpression() + "</td> \
        <td> " + feature.rangeExpression().start + "</td> \
        <td> " + feature.rangeExpression().finish + "</td> \
        <td> " + feature.motifs().start + "</td> \
        <td> " + feature.motifs().finish + "</td> \
      </tr> \
    ");
  });
  FeatureManagerModule.registerEvents(viewer);
}

FeatureManagerModule.registerEvents = function(viewer){
  var em = viewer.elementManager();
  em.attach("feature_check_box_all", ".svfm_feature_checkbox_all");
  em.attach("feature_check_boxes", ".svfm_feature_checkbox");

  em.lateEvent("feature_check_box_all", "click", function(){
    var $checkboxes = em.get("feature_check_boxes").$el();
    if (this.checked) {
      $checkboxes.each(function(){
        this.checked = true;
      });
    } else {
      $checkboxes.removeAttr("checked");
    }
    FeatureManagerModule.updateFeatureDisplay(viewer);
  });

  em.lateEvent("feature_check_boxes", "click", function(){
    var $checkboxes = em.$base().find(".svfm_feature_checkbox:checked");
    if ($checkboxes.length == 0) {
      em.get("feature_check_box_all").dom().checked = false;
    }
    var fm = viewer.featureManager();
    if (fm.all().length == $checkboxes.length) {
      em.get("feature_check_box_all").dom().checked = true;
    }
    $(this).attr("disabled", "disabled");
    viewer.notifyRight("Updating features..");

    var $this = $(this);
    FeatureManagerModule.updateFeatureDisplay(viewer, function(){
      $this.removeAttr("disabled");
      viewer.notifyRight("");
    });
  });
}

FeatureManagerModule.renderSequenceBoxes = function(viewer){
  viewer.clearSiteSelection();
  var fm = viewer.featureManager();
  var $container = viewer.$el("sequence_body");
  var $svg = $container.find(".sv_sequence_body_svg");

  Util.iterate(fm.all(), function(feature, index){
    var $sequenceBoxes = $svg.find(".sv_sequence_box[data-feature-index='" + index + "']");
    var $sequenceLabels = $svg.find(".sv_sequence_label[data-feature-index='" + index + "']");
    // console.log($sequenceBoxes);

    if (feature.disabled()) {
      $sequenceBoxes.each(function(){
        if ($(this).attr("ori-fill") === undefined) {
          $(this).attr("ori-fill", $(this).css("fill"));
        }

        $(this).attr("disabled-sequence", "disabled-sequence")
               .css("fill", "#eee")
               .css("cursor", "auto");
      });
      $sequenceLabels.each(function(){
        if ($(this).attr("old-code") === undefined) {
          $(this).attr("old-code", $(this).html());
        }
        $(this).html("X").css("cursor", "auto");
      });

    } else {
      $sequenceBoxes.each(function(){
        $(this).removeAttr("disabled-sequence")
               .css("fill", $(this).attr("ori-fill"))
               .removeAttr("ori-fill")
               .css("cursor", "default");
      });
      $sequenceLabels.each(function(){
        $(this).html($(this).attr("old-code"))
               .removeAttr("old-code")
               .css("cursor", "default");
      });
    }
  });
}

FeatureManagerModule.disable = function(viewer){
  if (viewer.$el("btn_show_feature_manager").attr("disabled") == "disabled") return;
  viewer.$el("btn_hide_feature_manager").click();
  viewer.$el("btn_show_feature_manager").show().attr("disabled", "disabled");
  viewer.$el("btn_hide_feature_manager").attr("disabled", "disabled");
}

FeatureManagerModule.enable = function(viewer){
  if (viewer.$el("btn_show_feature_manager").attr("disabled") == undefined) return;
  viewer.$el("btn_hide_feature_manager").removeAttr("disabled")
  viewer.$el("btn_show_feature_manager").removeAttr("disabled").click();
}

var SequenceBodyModule = {};

SequenceBodyModule.sequenceDescription = function(viewer, index) {
  var sequence = viewer.sequence();
  var code = sequence.body()[index];
  if (code == "?") return "";
  if (code == "*") {
    var nucleotide = sequence.nucleotide();
    code = nucleotide.substr(index*3, 3);
  }
  if (sequence.inProteinMode()) {
    if (proteinName[code] !== undefined) return proteinName[code];
  } else {
    var ambiguityRegex = /[KMRYSWBVHDXN]/g;
    if (ambiguityRegex.exec(code) != null) return "Ambiguity";
    if (nucleotideName[code] !== undefined) return nucleotideName[code];
  }
  return "";
}

SequenceBodyModule.sequenceColor = function(code) {
  if (sequenceColors[code]) return sequenceColors[code];
  return "#eee";
}

SequenceBodyModule.bindSequenceBodyEventsTo = function(viewer) {
  var em = viewer.elementManager();
  // Register sequence box events
  em.attach("sequence_box", ".sv_sequence_box");
  em.attach("sequence_label", ".sv_sequence_label");
  em.event("sequence_box", "mouseover", events["sequence_body"].box_hover);
  em.event("sequence_box", "mouseout", events["sequence_body"].box_out);
  em.event("sequence_box", "click", events["sequence_selection"].box_click);

  // Register sequence label events
  em.event("sequence_label", "mouseover", events["sequence_body"].label_hover);
  em.event("sequence_label", "mouseout", events["sequence_body"].label_out);
  em.event("sequence_label", "click", events["sequence_selection"].label_click);
}

SequenceBodyModule.loadProteinPreview = function(viewer, $container, sequence, boxSize) {
  FeatureManagerModule.disable(viewer);
  var width = $container.width();
  $container.css("max-width", width).css("overflow-x", "scroll");

  var container = d3.select($container.get(0)).text("")
  var proteins = sequence.computeProteinPreview();

  var proteinAnnotation = function(code){
    if (code == "-" || code == "?" || code == "*") return "";
    if (proteinName[code] !== undefined) return proteinName[code];
    return "";
  }

  var findSequenceBoxes = function(proteinIndex, layerIndex){
    var $container = viewer.$el("sequence_body");
    var startIndex = proteinIndex*3 + layerIndex;
    var expr = ".sv_sequence_box[data-sequence-index='" + (startIndex) + "']";
        expr += ", .sv_sequence_box[data-sequence-index='" + (startIndex + 1) + "']";
        expr += ", .sv_sequence_box[data-sequence-index='" + (startIndex + 2) + "']";
    var $boxes = $container.find(expr);
    return $boxes;
  }

  var hoverSequences = function(proteinIndex, layerIndex){
    var $boxes = findSequenceBoxes(proteinIndex, layerIndex);
    $boxes.mouseover();
  }

  var unhoverSequences = function(proteinIndex, layerIndex){
    var $boxes = findSequenceBoxes(proteinIndex, layerIndex);
    $boxes.mouseout();
  }

  var boxMouseHover = function(code, index){
    return function(){
      var msg = proteinAnnotation(code);
      this.style.fill = "rgb(255, 238, 48)";
      var layerIndex = $(this).attr("protein-layer-index");
      layerIndex = parseInt(layerIndex);
      hoverSequences(index, layerIndex);
      viewer.notifyRight(msg);
    }
  }

  var boxMouseOut = function(code, index){
    return function(){
      this.style.fill = "rgb(238, 238, 238)";
      var layerIndex = $(this).attr("protein-layer-index");
      layerIndex = parseInt(layerIndex);
      unhoverSequences(index, layerIndex);
      viewer.notifyRight("");
    }
  }

  var labelMouseHover = function(code, index){
    return function(){
      var layerIndex = $(this).attr("protein-layer-index");
      layerIndex = parseInt(layerIndex);
      var $box = viewer.elementManager().$base()
                  .find(".sv_protein_preview_svg > rect[protein-index='" + index + "'][protein-layer-index='" + layerIndex + "']");
      $box.mouseover();
    }
  }

  var labelMouseOut = function(code, index){
    return function(){
      var layerIndex = $(this).attr("protein-layer-index");
      layerIndex = parseInt(layerIndex);
      var $box = viewer.elementManager().$base()
                  .find(".sv_protein_preview_svg > rect[protein-index='" + index + "'][protein-layer-index='" + layerIndex + "']");
      $box.mouseout();
    }
  }

  for (var i=0; i<proteins.length; i++) {
    var protein = proteins[i];
    var bodyContainer = container.append("svg")
      .attr("class", "sv_protein_preview_svg")
      .attr("width", (boxSize * 3 * protein.length + i * boxSize)+"px")
      .attr("height", boxSize);

    // sequence box
    bodyContainer.selectAll("rect")
      .data(protein)
      .enter()
      .append("rect")
        .property("onmouseover", boxMouseHover)
        .property("onmouseout", boxMouseOut)
        .attr("protein-layer-index", i)
        .attr("protein-index", function(d,j){ return j; })
        .style("cursor", "pointer")
        .attr("x", function(d,j){ return (j * boxSize  * 3 + i * boxSize); })
        .attr("y", 0)
        .attr("width", boxSize * 3)
        .attr("height", boxSize)
        .style("fill", "#eee")
        .attr("stroke", "#000");

    // sequence label
    bodyContainer.selectAll(".sv_protein_preview_label")
      .data(protein)
      .enter()
        .append("text")
          .property("onmouseover", labelMouseHover)
          .property("onmouseout", labelMouseOut)
          .attr("protein-layer-index", i)
          .attr("protein-index", function(d,j){ return j; })
          .style("cursor", "pointer")
          .attr("class", function(d,j){ return "sv_protein_preview_label sv_protein_preview_label_"+j})
          .attr("transform", function(d,j) {
            var x = j * boxSize * 3 + (boxSize-10)/2;
            var y = 10 + ((boxSize-10)/2);
            x += i * boxSize;
            return "translate ("+ x + "," + y + ")";
          })
          .each(function(d,i){
            d3.select(this).text(d);
          });
  }

  var $target = viewer.elementManager().$base();

  $container.scroll(function(e){
    var offset = $container.scrollLeft();
    $target.find(".sv_sequence_body").scrollLeft(offset);
  });

  $target.find(".sv_sequence_body").scroll(function(e){
    var offset = $target.find(".sv_sequence_body").scrollLeft();
    $container.scrollLeft(offset);
  });
}

SequenceBodyModule.loadSequenceBodySVG = function(viewer, $container, sequence, boxSize) {
  FeatureManagerModule.disable(viewer);
  var width = $container.width();
  $container.css("max-width", width);

  var bodyContainer = d3.select($container.get(0)).text("")
    .append("svg")
    .attr("class", "sv_sequence_body_svg")
    .attr("width", (boxSize * sequence.body().length)+"px")
    .attr("height", boxSize);

  // sequence box
  bodyContainer.selectAll("rect")
    .data(sequence.body())
    .enter()
    .append("rect")
      .attr("class", function(d,j){ return "sv_sequence_box sv_sequence_box_" + j; })
      .attr("data-sequence-index", function(d,j){ return j; })
      .attr("data-highlighted", "false")
      .attr("x", function(d,j){ return j * boxSize; })
      .attr("y", 0)
      .attr("width", boxSize)
      .attr("height", boxSize)
      .style("fill", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
      .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
      .attr("stroke", "#000");

  // sequence label
  bodyContainer.selectAll(".sv_sequence_label")
    .data(sequence.body())
    .enter()
      .append("text")
        .attr("class", function(d,j){ return "sv_sequence_label sv_sequence_label_"+j})
        .attr("data-sequence-index", function(d,j){ return j; })
        .attr("data-highlighted", "false")
        .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
        .attr("transform", function(d,j) {
          var x = j * boxSize + (boxSize-10)/2;
          var y = 10 + ((boxSize-10)/2);
          return "translate ("+ x + "," + y + ")";
        })
        .each(function(d,i){
          d3.select(this).text(d);
        });

  // bind sequence body events to viewer
  SequenceBodyModule.bindSequenceBodyEventsTo(viewer);
}

SequenceBodyModule.loadRuler = function(viewer, $container, sequence) {
  var boxSize = viewer.configs("sequence_box_size"),
      length = sequence.body().length;
  var sequenceScale = d3.scaleLinear()
                        .domain([1, length+1])
                        .range([0, boxSize * length])

  var axis = d3.axisBottom(sequenceScale).tickFormat(Math.round(length/5))

  var ruler = d3.select($container.get(0))
    .insert("svg", ".sv_sequence_body_svg")
    .attr("class", "sv_ruler")
    .attr("width", (boxSize * (length-1) + 20)+"px")
    .attr("height", "22px")
      .append("g")
        .attr("transform", "translate(0, -5)")
        .transition().duration(600)
        .call(axis);

  ruler.selectAll("text")
  .attr("font-size", "6px")
        .style("text-anchor", "start")
        .style("fill", "#555");

  ruler.selectAll("path")
        .style("fill", "#aaa")

  d3.select($container.get(0))
    .insert("svg", ".sv_sequence_body_svg")
    .attr("width", (boxSize * (length-1) + 20)+"px")
      .attr("height", "8px")
      .append("g")
        .attr("transform", "translate(0, 0)")
        .append("line")
          .attr("x2", boxSize * length)
          .attr("stroke", "#aaa")
          .attr("stroke-dasharray", "5")
}

SequenceFeatureModule = {};

SequenceFeatureModule.createColor = function(index){
  index = index % featureColors.length;
  return featureColors[index];
}

SequenceFeatureModule.bindSequenceFeaturesEventsTo = function(viewer, featureLayers) {
  var em = viewer.elementManager();

  em.attach("feature_layers", ".svf_layer");
  em.attach("feature_box", ".svf_box");
  em.attach("feature_label", ".svf_label");

  em.event("feature_box", "mouseover", events["sequence_feature"].box_hover, { featureLayers : featureLayers });
  em.event("feature_box", "mouseout", events["sequence_feature"].box_out);
  em.event("feature_label", "mouseover", events["sequence_feature"].label_hover, { featureLayers : featureLayers });
  em.event("feature_label", "mouseout", events["sequence_feature"].label_out);
}

SequenceFeatureModule.createFeatureLayers = function(viewer, svg, layers, boxSize, featureBoxSize, callback, row) {
  var max = null;
  var index = 0;
  for (var i = 0; i < layers.length; i++) {
    var layer = layers[i];
    for (var j = 0; j < layer.length; j++) {
      var feature = layer[j];
      if (feature.to > max || max === null) max = feature.to;
      feature.color = SequenceFeatureModule.createColor(index);
      index++;
    }
  }
  if (max === null) max = 0;

  $(svg[0]).html("")
  if (layers.length == 0) {
    svg.style("display", "none");
  } else {
    svg.attr("height", layers.length * featureBoxSize)
       .attr("width", (max + 1) * boxSize)
       .style("display", "");
  }

  // Draw feature to the screen
  Util.iterate(layers, function(layer, index){
    var layerContainer = svg.append("g")
      .attr("class", "svf_layer svf_layer_" + index)
      .attr("transform", "translate(0, " + (index * featureBoxSize)  + ")");

    // console.log(row);
    // console.log(layer);

    // feature box
    layerContainer.selectAll(".svf_box_" + index)
      .data(layer)
      .enter()
      .append("rect")
        .attr("data-feature-index", function(feature){ return feature.index })
        .attr("data-layer-index", index)
        .attr("data-row-index", row === undefined ? 0 : row)
        .attr("class", function(feature){ return "svf_box svf_box_" + index + "_" + feature.index })
        .attr("x", function(feature){ return feature.from * boxSize })
        .attr("width", function(feature){ return (feature.to - feature.from + 1) * boxSize })
        .attr("height", featureBoxSize)
        .attr("stroke", "#eee")
        .style("fill", function(feature){ return feature.color })
        .attr("ori-color", function(feature){ return feature.color })
        .attr("data-feature-name", function(feature){ return feature.name; })
        .attr("data-feature-type", function(feature){ return feature.type; })
        .attr("data-feature-from", function(feature){ return feature.from; })
        .attr("data-feature-to", function(feature){ return feature.to; });

    // feature label
    layerContainer.selectAll(".svf_label_" + index)
      .data(layer)
      .enter()
      .append("text")
        .text(function(feature){
          return feature.name;
        })
        .attr("data-feature-index", function(feature){ return feature.index })
        .attr("data-layer-index", index)
        .attr("data-row-index", row === undefined ? 0 : row)
        .attr("class", function(feature){ return "svf_label svf_label_" + index + "_" + feature.index })
        .attr("font-size", "8px")
        .attr("x", function(feature) {
          var width = d3.select(this).style("width");
          width = Util.number(width);
          var x = feature.from * boxSize;
          var offset = (feature.to - feature.from + 1) * boxSize;
          offset = Math.round(offset/2);
          x += offset;
          return x;
        })
        .attr("transform", function(feature) {
          return "translate (0, 10)";
        })
        .attr("ori-color", function(feature){ return feature.color })
        .attr("data-feature-name", function(feature){ return feature.name; })
        .attr("data-feature-type", function(feature){ return feature.type; })
        .attr("data-feature-from", function(feature){ return feature.from; })
        .attr("data-feature-to", function(feature){ return feature.to; });
  });

  if (typeof callback == "function") callback();
}

SequenceFeatureModule.bindFeatureIndex = function(viewer, layers, layersIndex, layersCount, callback){
  var expressions = [];
  Util.iterate(layers, function(layer, index){
    for (var i=0; i<layer.length; i++) {
      var feature = layer[i];
      for (var j=feature.from; j<=feature.to; j++) {
        if (multi(viewer)) {
          var expression = ".sv_sequence_box[data-sequence-index='" + j + "']";
          expression += "[data-row-index='" + layersIndex + "']";
          expression += ", .sv_sequence_label[data-sequence-index='" + j + "']";
          expression += "[data-row-index='" + layersIndex + "']";
        } else {
          var expression = ".sv_sequence_box[data-sequence-index='" + j + "']";
          expression += ", .sv_sequence_label[data-sequence-index='" + j + "']";
        }
        expressions.push({
          expression:expression,
          featureIndex:feature.index
        });
      }
    }
  });

  do {
    var number = Math.round(Math.random() * 1000);
    var processName = "bind_feature_index" + number;
  } while(viewer.processRunning(processName));

  // async!!
  var expressionIndex = 0;
  var $loadingContainer = viewer.$el("sequence_body");
  var bindFeatureIndex = function(){
    if (expressions[expressionIndex] === undefined || !viewer.processRunning(processName)) {
      viewer.notifyLeft("");
      viewer.terminateProcess(processName);
      if (typeof callback == "function") callback();
      return;
    }
    FeatureManagerModule.disable(viewer);
    viewer.$el("sequence_body").find(expressions[expressionIndex].expression)
          .attr("data-feature-index", expressions[expressionIndex].featureIndex);
    var percent = (expressionIndex+1)/expressions.length * 100;
    if (multi(viewer)) {
      percent = (expressionIndex+1)/expressions.length * 100 / layersCount;
      percent += 100 / layersCount * layersIndex;
    }
    percent = Math.round(percent);
    viewer.notifyLeft("Loading feature manager " + percent + "%");
    setTimeout(function(){
      expressionIndex++;
      bindFeatureIndex();
    }, 1);
  }

  if (expressions.length == 0) {
    viewer.notifyLeft("");
    if (typeof callback == "function") callback();
    return;
  }

  $testElement = viewer.$el("sequence_body").find(expressions[expressionIndex].expression);
  if ($testElement && $testElement.attr("data-feature-index") === undefined) {
    viewer.attachProcess(processName, bindFeatureIndex);
    bindFeatureIndex();
  } else {
    viewer.notifyLeft("");
    if (typeof callback == "function") callback();
  }
}

SequenceFeatureModule.renderFeatureTo = function(viewer, callback){
  var featureManager = viewer.featureManager(),
      sequence = viewer.sequence();
  var container = viewer.dom("sequence_body");
  var $svg = viewer.$el("sequence_body").find(".svf_svg");

  // Transform input data
  var layers = featureManager.layers(sequence);

  var boxSize = viewer.configs("sequence_box_size"),
      featureBoxSize = viewer.configs("feature_box_size");

  if ($svg.length  != 0) $svg.remove();
  var svg = d3.select(container)
              .append("svg")
              .attr("class", "svf_svg");

  FeatureManagerModule.disable(viewer);
  SequenceFeatureModule.createFeatureLayers(viewer, svg, layers, boxSize, featureBoxSize, function(){
    SequenceFeatureModule.bindSequenceFeaturesEventsTo(viewer);
    SequenceFeatureModule.bindFeatureIndex(viewer, layers, 0, 1, function(){
      FeatureManagerModule.renderSequenceBoxes(viewer);
      FeatureManagerModule.enable(viewer);
    });
    if (typeof callback == "function") callback();
  });
}

SequenceQualityModule = {};
SequenceQualityModule.registerElements = function(viewer){
  var em = viewer.elementManager();

  em.attach("sequence_quality", ".svsc_container");
  em.attach("sequence_quality_list", ".svsc_list");
  em.attach("btn_show_sequence_quality", ".svsc_btn_show_sc_table");
  em.attach("btn_hide_sequence_quality", ".svsc_btn_hide_sc_table");
  em.attach("sequence_quality_errors_count", ".svsc_errors_count");
}

SequenceQualityModule.registerEvents = function(viewer){
  var em = viewer.elementManager();
  em.event("btn_show_sequence_quality", "click", events["sequence_quality"].show);
  em.event("btn_hide_sequence_quality", "click", events["sequence_quality"].hide);
}

SequenceQualityModule.validate = function(viewer, sequence) {
  var featureManager = viewer.featureManager();
  var em = viewer.elementManager();
  var results = featureManager.validate(sequence);
  var $scList = viewer.$el("sequence_quality_list");

  if (results.length != 0) {
    viewer.notifyLeft("<font color='red'><i>This sequence has bad quality</i></font>, <a href='' class='svsc_show_details'>check sequence quality details..</a>");
    em.attach("sequence_quality_details", ".svsc_show_details");
    em.event("sequence_quality_details", "click", function(e){
      events["sequence_quality"].show(e);
      return false;
    });

    viewer.$el("sequence_quality_errors_count").html("(" + results.length + ")");
  } else {
    $scList.html("\
      <tr> \
        <td colspan='2'><center><i>No quality problems found</i></center></td> \
      </tr>\
    ");
    viewer.$el("sequence_quality_errors_count").html("");
    return;
  }

  $scList.html("\
    <tr> \
      <td colspan='2'><b>This sequence has bad quality because of the following reason :</b></td> \
    </tr>\
  ");


  for (var i=0; i<results.length; i++) {
    var result = results[i];
    $scList.append("\
    <tr> \
      <td width='2%'> " + (i+1) + "</td> \
      <td> " + result.message + "</td> \
    </tr> \
    ");
  }
}

var SequenceSelectionModule = {};

SequenceSelectionModule.existOnSeletedSites = function(viewer, sequenceIndex, rowIndex){
	var selectedSites = viewer.selectedSites();
  for (var i=0; i<selectedSites.length; i++) {
    var selectedSite = selectedSites[i];
    if (multi(viewer)) {
      if (selectedSite.sequenceIndex == sequenceIndex && selectedSite.rowIndex == rowIndex) return true;
    } else {
      if (selectedSite.sequenceIndex == sequenceIndex) return true;
    }
  }
  return false;
}

SequenceSelectionModule.findNearestSelectedSites = function(viewer, sequenceIndex, rowIndex){
	var selectedSites = viewer.selectedSites();
  selectedSites.sort(function(a,b){
    return a.sequenceIndex-b.sequenceIndex;
  });
  // console.log(selectedSites);
  var result = {
    start: false,
    finish: false
  }

  for (var i=0; i<selectedSites.length; i++) {
    var selectedSite = selectedSites[i];
    if (multi(viewer) && selectedSite.rowIndex != rowIndex) continue;
    if (selectedSite.sequenceIndex < sequenceIndex) {
      result.start = selectedSite.sequenceIndex;
      // console.log("start : " + result.start);
    }
    if (selectedSite.sequenceIndex > sequenceIndex) {
      if (result.finish !== false) continue;
      result.finish = selectedSite.sequenceIndex;
      // console.log("finish : " + result.finish)
    }
  }
  return result;
}

SequenceSelectionModule.selectSite = function(viewer, $box){
  var sequenceIndex = parseInt($box.attr("data-sequence-index"));

  if (multi(viewer)) {
    var rowIndex = parseInt($box.attr("data-row-index"));
    if (SequenceSelectionModule.existOnSeletedSites(viewer, sequenceIndex, rowIndex)) return false;
    var nearestSelectSites = SequenceSelectionModule.findNearestSelectedSites(viewer, sequenceIndex, rowIndex);
  } else {
    if (SequenceSelectionModule.existOnSeletedSites(viewer, sequenceIndex)) return false;
    var nearestSelectSites = SequenceSelectionModule.findNearestSelectedSites(viewer, sequenceIndex);
  }


  if (viewer.selectionMode() == "single" || (nearestSelectSites.start === false && nearestSelectSites.finish === false) ) {
    if (multi(viewer)) {
      viewer.selectedSites().push({
        sequenceIndex: parseInt(sequenceIndex),
        rowIndex: parseInt(rowIndex)
      });
    } else {
      viewer.selectedSites().push({
        sequenceIndex: parseInt(sequenceIndex)
      });
    }
    $box.attr("data-selected", "true")
        .css("fill", "#f00")
    return;
  }

  if (nearestSelectSites.start !== false) {
    var start = nearestSelectSites.start;
    var finish = sequenceIndex;
  } else if (nearestSelectSites.finish !== false) {
    var start = sequenceIndex;
    var finish = nearestSelectSites.finish;
  }

  var expression = "";
  // console.log(start);
  // console.log(finish);
  for (var i=start; i<=finish; i++) {
    if (multi(viewer)) {
      if (SequenceSelectionModule.existOnSeletedSites(viewer, i, rowIndex)) continue;
    } else {
      if (SequenceSelectionModule.existOnSeletedSites(viewer, i)) continue;
    }
    if (multi(viewer)) {
      viewer.selectedSites().push({
        sequenceIndex: i,
        rowIndex: parseInt(rowIndex)
      });
    } else {
      viewer.selectedSites().push({
        sequenceIndex: i
      });
    }
    if (expression != "") expression += ",";
    expression += ".sv_sequence_box[data-sequence-index='" + i + "']";

    if (multi(viewer)) {
      expression += "[data-row-index='" + rowIndex + "']";
    }
  }

  var $boxes = viewer.$el("sequence_body").find(expression);
  $boxes.attr("data-selected", "true")
        .css("fill", "#f00")
}

SequenceSelectionModule.unselectSite = function(viewer, $box){
  var sequenceIndex = parseInt($box.attr("data-sequence-index"));
  if (multi(viewer)) {
    var rowIndex = parseInt($box.attr("data-row-index"));
  }
  for (var i=0; i<viewer.selectedSites().length; i++) {
    var selectedSite = viewer.selectedSites()[i];
    if (multi(viewer)) {
      if (selectedSite.sequenceIndex == sequenceIndex && selectedSite.rowIndex == rowIndex) viewer.selectedSites().splice(i, 1);
    } else {
      if (selectedSite.sequenceIndex == sequenceIndex) viewer.selectedSites().splice(i, 1);
    }
  }

  $box.attr("data-selected", "false")
      .css("fill", $box.attr("ori-color"));
}

SequenceSelectionModule.notifySelectedSites = function(viewer) {
  var msg = "";
  var count = viewer.selectedSites().length;
  if (count == 1) {
    msg = "one site selected";
  } else if (count != 0) {
    msg = count + " sites selected";
  }

  if (count != 0) {
    msg += ", \
      <a class='sv_clear_selection' style='cursor: pointer'>clear selection</a> | \
      <a class='sv_delete_selected_sites' style='cursor: pointer'>delete selected " + (count == 1 ? "site" : "sites") + "</a> \
    ";
  }
  viewer.$el("toolbar.sites_selection_display").html(msg);
}

SequenceSelectionModule.deleteSelectedSites = function(viewer){
  var selectedSites = viewer.selectedSites();
  selectedSites.sort(function(a,b){
    return a.sequenceIndex - b.sequenceIndex;
  });

  var target;
  if (multi(viewer)) {
    target = viewer.sequences()
  } else {
    target = viewer.sequence();
  }

  target.remove(selectedSites, function(){
    if (!viewer.inProteinMode()) {
      Ambiguity.check(viewer);
      if (single(viewer)) SequenceQualityModule.validate(viewer, target);
    }
    viewer.softReload();
    alert(selectedSites.length + " " + (selectedSites.length == 1 ? "site" : "sites") + " successfully deleted from " + (multi(viewer) ? "sequences." : "sequence.") );
  })
}

var SingleSVModules = {};

/*
 * Render container to viewer element
 * @args viewer SingleSequeneViewer
 * @args $target jQuerySelector
 */
SingleSVModules.createContainer = function(viewer, $target){
  var tm = viewer.templateManager();
  tm.flush();
  tm.append(templates["topbar"]);
  tm.append(templates["toolbar"].open);
  tm.append(templates["feature_manager"]);
  tm.append(templates["sequence_quality"]);
  tm.append(templates["ambiguity_editor"]);
  tm.append(templates["toolbar"].close);
  tm.append(templates["smallbar"].open);
  tm.append(templates["smallbar"].close);
  // tm.append(templates["consensus_bar"]);
  tm.append(templates["midbar"]);
  tm.append(templates["footer"]);

  // Viewer wrapper
  tm.prepend(templates["viewer"].open);
  tm.append(templates["viewer"].close);

  $target.html(tm.contents());

  if (single(viewer)) {
    viewer.elementManager().$base().find(".msv_alignment, .msv_tree, .msv_consensus_bar").remove();
  }
}

/*
 * Register user interface element handler
 * @args viewer SingleSequeneViewer
 */
SingleSVModules.registerElements = function(viewer){
  var em = viewer.elementManager();
  //
  // TOPBAR
  //
  em.attach("site_input", ".sv_site_input");
  em.attach("search_input", ".sv_search_input");

  //
  // TOOLBAR
  //
  em.attach("toolbar.sequence_form", ".sv_sequence_form");
  em.attach("toolbar.sequence_selection_mode", ".sv_sequence_selection_mode");
  em.attach("toolbar.sites_selection_display", ".sv_sites_selection_display");
  em.attach("toolbar.zoom_slider", ".sv_zoom_slider");
  em.attach("toolbar.zoom_line", ".sv_zoom_line");

  // FEATURE MANAGER
  em.attach("feature_manager", ".svfm_container");
  em.attach("feature_table", ".svfm_feature_table");
  em.attach("feature_list", ".svfm_feature_list");
  em.attach("btn_show_feature_manager", ".svfm_btn_show_fm");
  em.attach("btn_hide_feature_manager", ".svfm_btn_hide_fm");
  em.attach("btn_toggle_features", ".svfm_toggle_features");

  //
  // MIDBAR
  //
  em.attach("sequence_header", ".sv_sequence_header");
  em.attach("sequence_body", ".sv_sequence_body");
  em.attach("protein_preview", ".sv_protein_preview");

  //
  // FOOTER
  //
  em.attach("left_status", ".sv_left_status");
  em.attach("right_status", ".sv_right_status");
}

/*
 * Register viewer event handlers
 * @args viewer SingleSequeneViewer
 */
SingleSVModules.registerEvents = function(viewer){
  var em = viewer.elementManager();
  //
  // ZOOM EVENTS
  //
  // em.event("toolbar.zoom_slider", "click", events["zoom_slider"].click);
  // em.event("toolbar.zoom_slider", "mousemove", events["zoom_slider"].mousemove);
  // em.event("toolbar.zoom_line", "mousemove", events["zoom_line"].mousemove);

  // FEATURE MANAGER EVENTS
  em.event("btn_show_feature_manager", "click", events["feature_manager"].show);
  em.event("btn_hide_feature_manager", "click", events["feature_manager"].hide);
  em.lateEvent("btn_toggle_features", "click", events["feature_manager"].toggle_feature);

  //
  // TOP BAR EVENTS
  //
  em.event("site_input", "keyup", events["site_input"].update_site_input);
  em.event("site_input", "change", events["site_input"].update_site_input);
  em.event("search_input", "keydown", events["search_input"].search_sequence);
  em.event("search_input", "keyup", events["search_input"].flush_search_input);

  // TOOLBAR EVENTS
  em.event("toolbar.sequence_form", "change", events["toolbar.sequence_form"].change);
  em.event("toolbar.sequence_selection_mode", "change", events["toolbar.sequence_selection_mode"].change);
  em.$base().on("click", ".sv_clear_selection", function(){
    viewer.clearSiteSelection();
    return false;
  });
  em.$base().on("click", ".sv_delete_selected_sites", function(){
    viewer.deleteSelectedSites();
    return false;
  });
}

/*
 * Unhighlight sequence
 * @args viewer SingleSequeneViewer
 */
SingleSVModules.unhighlightSequence = function(viewer){
  var $container = viewer.$el("sequence_body");
  var $sequenceBoxes = $container.find(".sv_sequence_box[data-highlighted='true']");
  $sequenceBoxes.each(function(){
    var $this = $(this),
        color = $this.attr("ori-color");
    $this.css("fill", color);
    $this.attr("data-highlighted", "false");
  });
}

/*
 * Highlight sequence
 * @args viewer SingleSequeneViewer
 * @args start Number
 * @args finish Number
 * @args row Number
 */
SingleSVModules.highlightSequence = function(viewer, start, finish, row){
  SingleSVModules.unhighlightSequence(viewer);
  var $container = viewer.$el("sequence_body");
  var selector = "",
      color = viewer.configs("highlight_color");
  for (var i=start; i<=finish; i++) {
    selector += ".sv_sequence_box_" + i;
      if (row != undefined) selector += "[data-row-index='" + row + "']";
    if (i != finish) selector += ", ";
  }
  // console.log(selector);
  var $sequenceBoxes = $container.find(selector);

  $sequenceBoxes.each(function(){
    var $this = $(this);
    $this.css("fill", color);
    $this.attr("data-highlighted", "true");
  });
  viewer.slideTo(start);
}

SingleSVModules.setContainerDimension = function(viewer){
  var $target = viewer.elementManager().$base();
  var $container = $target.find(".sv_container");

  if (multi(viewer)) {
    var headerHeight = $target.find(".msv_sequence_header_topbar").height();
    headerHeight =+ $target.find(".sv_sequence_header").height();
    $target.find(".sv_sequence_header_outer").parent().height(headerHeight);
  }

  var height = 0;
  height += $container.find(".sv_midbar").height();
  height += $container.find(".sv_topbar").height();
  height += $container.find(".sv_footer").height();
  height += $container.find(".sv_toolbar").height();
  height += 60;
  $container.height(height);
}

var MultipleSequenceBodyModule = {};

MultipleSequenceBodyModule.sequenceDescription = function(viewer, row, index) {
  var sequences = viewer.sequences();
  var sequence = sequences.find(row);
  var code = sequence.body()[index];
  if (code == "?") return "";
  if (code == "*") {
    var nucleotide = sequence.nucleotide();
    code = nucleotide.substr(index*3, 3);
  }
  if (sequence.inProteinMode()) {
    return proteinName[code];
  } else {
    return nucleotideName[code];
  }
}

MultipleSequenceBodyModule.bindSequenceBodyEventsTo = function(viewer) {
  var em = viewer.elementManager();

  em.attach("sequence_box", ".sv_sequence_box");
  em.attach("sequence_label", ".sv_sequence_label");
  em.attach("consensus_box", ".sv_consensus_box");
  em.attach("consensus_label", ".sv_consensus_label");

  // Register sequence box events
  em.event("sequence_box", "mouseover", events["sequence_body"].box_hover);
  em.event("sequence_box", "mouseout", events["sequence_body"].box_out);
  // em.event("sequence_box", "click", events["sequence_selection"].box_click);

  // Register sequence label events
  em.event("sequence_label", "mouseover", events["sequence_body"].label_hover);
  em.event("sequence_label", "mouseout", events["sequence_body"].label_out);
  // em.event("sequence_label", "click", events["sequence_selection"].label_click);

  // Register sequence box events
  em.event("consensus_box", "mouseover", events["sequence_body"].box_hover);
  em.event("consensus_box", "mouseout", events["sequence_body"].box_out);

  // Register sequence label events
  em.event("consensus_label", "mouseover", events["sequence_body"].consensus_label_hover);
  em.event("consensus_label", "mouseout", events["sequence_body"].consensus_label_out);
}

MultipleSequenceBodyModule.loadingState = function(viewer, $container) {
  var $parent = $container.parent();
  $parent.append("\
  <div class='msv_load_state'>\
    Loading..\
  </div>\
  ");

  var em = viewer.elementManager();
  em.attach("load_state", ".msv_load_state");

  viewer.elementManager().$base().find(".msv_consensus")
        .css("overflow-x", "")
        .html("");

  var height = $parent.parent().parent().height();
  $parent.attr("ori-height", $parent.height()).height(height);

  var $loadState = viewer.$el("load_state");
  $loadState.css("padding", (height/2-5) + "px 0px 0px 0px");

  $container.css("display", "none")
  $parent.css("overflow", "hidden");
}

MultipleSequenceBodyModule.notifyLoading = function(viewer, $container, percent) {
  var $loadState = viewer.$el("load_state");
  if (percent === undefined) {
    $loadState.html("Loading..");
  } else {
    $loadState.html("Loading " + percent + "%");
  }
}

MultipleSequenceBodyModule.normalState = function(viewer, $container) {
  var $loadState = viewer.$el("load_state");
  $loadState.remove();
  $container.parent()
    .height($container.parent().attr("ori-height"))
    .removeAttr("ori-height");
  $container.fadeIn(500);
}

MultipleSequenceBodyModule.loadSequenceBodySVG = function(viewer, $container, sequences, boxSize, callback) {
  var width = $container.width();
  $container.css("max-width", width);
  var $loadingContainer = $container;

  var bodyContainer = d3.select($container.get(0)).text("");

  row = 0;
  function loadSequence(){
    if (row == sequences.count()) {
      MultipleSequenceBodyModule.loadConsensus(viewer, boxSize);
      MultipleSequenceBodyModule.bindSequenceBodyEventsTo(viewer);
      MultipleSequenceBodyModule.normalState(viewer, $loadingContainer);
      if (callback !== undefined) callback();
      return;
    }
    MultipleSequenceBodyModule.notifyLoading(viewer, $loadingContainer, Math.round((row+1)/sequences.count() * 100));

    var sequenceSVG = bodyContainer.append("svg")
                      .attr("class", "sv_sequence_body_svg")
                      .attr("data-sequence-index", row)
                      .attr("width", (boxSize * sequences.find(row).body().length)+"px")
                      .attr("height", boxSize);

    var featureSVG  = bodyContainer.append("svg")
                                   .attr("class", "msv_feature_svg")
                                   .attr("data-sequence-index", row)
                                   .attr("height", 0);

    viewer.elementManager().attach("feature_svg_" + row, ".msv_feature_svg[data-sequence-index='" + row + "']");

    var sequence = sequences.find(row);

    // sequence box
    sequenceSVG.selectAll("rect.msv_sequence_box_" + row)
      .data(sequence.body())
      .enter()
      .append("rect")
        .attr("class", function(d,j){
          var classes = "msv_sequence_box msv_sequence_box_ " + row;
          classes += " sv_sequence_box sv_sequence_box_" + j;
          return classes;
        })
        .attr("data-row-index", row)
        .attr("data-sequence-index", function(d,j){ return j; })
        .attr("data-highlighted", "false")
        .attr("x", function(d,j){ return j * boxSize; })
        //.attr("y", row * boxSize)
        .attr("width", boxSize)
        .attr("height", boxSize)
        .style("fill", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
        .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
        .attr("stroke", "#000");

    // sequence label
    sequenceSVG.selectAll(".msv_sequence_label_" + row)
      .data(sequence.body())
      .enter()
        .append("text")
          .attr("class", function(d,j){
            var classes = "msv_sequence_label msv_sequence_label_ " + row;
            classes += " sv_sequence_label sv_sequence_label_" + j;
            return classes;
          })
          .attr("data-row-index", row)
          .attr("data-sequence-index", function(d,j){ return j; })
          .attr("data-highlighted", "false")
          .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
          .attr("transform", function(d,j) {
            var x = j * boxSize + (boxSize-10)/2;
            //var y = boxSize * row;
            var y = 0;
            y = y + 10 + ((boxSize-10)/2);
            return "translate ("+ x + "," + y + ")";
          })
          .each(function(d,i){
            d3.select(this).text(d);
          });
    if (row == sequences.count()-1) MultipleSequenceBodyModule.notifyLoading(viewer, $loadingContainer, 100);
    row++;
    setTimeout(loadSequence, 200);
  }

  MultipleSequenceBodyModule.loadingState(viewer, $loadingContainer);
  FeatureManagerModule.disable(viewer);
  loadSequence();
}

MultipleSequenceBodyModule.loadConsensus = function(viewer, boxSize){
  var consensus = viewer.sequences().consensus();
  var $target = viewer.elementManager().$base();
  var $container = $target.find(".msv_consensus");

  var width = $container.width();
  $container.css("max-width", width).css("overflow-x", "scroll");

  $container.scroll(function(e){
    $target.find(".sv_sequence_body").scrollLeft($container.scrollLeft());
  });

  $target.find(".sv_sequence_body").scroll(function(e){
    $container.scrollLeft($target.find(".sv_sequence_body").scrollLeft());
  });

  var bodyContainer = d3.select($container.get(0)).text("")
    .append("svg")
    .attr("class", "sv_consensus_svg")
    .attr("width", (boxSize * consensus.length)+"px")
    .attr("height", boxSize);

  var consensusAnnotation = function(code, index){
    var msg = "Site #" + (index+1);
    if (code == "-" || code == "?" || code == "*") return msg;
    if (viewer.inProteinMode()) {
      msg += " " + proteinName[code];
    } else {
      msg += " " + nucleotideName[code];
    }
    return msg;
  }

  // sequence box
  bodyContainer.selectAll("rect")
    .data(consensus)
    .enter()
    .append("rect")
    .attr("class", "sv_consensus_box")
      .attr("data-sequence-index", function(d,j){ return j; })
      .attr("consensus-annotation", consensusAnnotation)
      .attr("data-highlighted", "false")
      .attr("x", function(d,j){ return j * boxSize; })
      .attr("y", 0)
      .attr("width", boxSize)
      .attr("height", boxSize)
      .style("fill", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
      .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
      .attr("stroke", "#000");

  // sequence label
  bodyContainer.selectAll(".sv_consensus_label")
    .data(consensus)
    .enter()
      .append("text")
        .attr("class", "sv_consensus_label")
        .attr("data-sequence-index", function(d,j){ return j; })
        .attr("consensus-annotation", consensusAnnotation)
        .attr("data-highlighted", "false")
        .attr("ori-color", function(d,i){ return SequenceBodyModule.sequenceColor(d); })
        .attr("transform", function(d,j) {
          var x = j * boxSize + (boxSize-10)/2;
          var y = 10 + ((boxSize-10)/2);
          return "translate ("+ x + "," + y + ")";
        })
        .each(function(d,i){
          d3.select(this).text(d);
        });
}

MultipleSequenceFeatureModule = {};

MultipleSequenceFeatureModule.renderFeatureTo = function(viewer, callback){
  var featureManager = viewer.featureManager(),
      sequences = viewer.sequences(),
      featureBoxSize = viewer.configs("feature_box_size");

  var sequenceIndex = 0;
  var boxSize = viewer.configs("sequence_box_size"),
      featureBoxSize = viewer.configs("feature_box_size");

  var layersList = [];

  var renderFeatures = function(){
    var sequence = sequences.find(sequenceIndex);
    var layers = featureManager.layers(sequence);
    layersList.push(layers);
    var $svg = viewer.$el("feature_svg_" + sequenceIndex);
    var svg = d3.select($svg.get(0));

    SequenceFeatureModule.createFeatureLayers(viewer, svg, layers, boxSize, featureBoxSize, function(){
      var $spacer = viewer.elementManager().$base().find(".msv_feature_spacer[data-sequence-index='" + sequenceIndex + "']");
      if (layers.length == 0) {
        $spacer.css("display", "none");
      } else {
        $spacer.css("display", "").height(featureBoxSize * layers.length);
      }
      sequenceIndex++;
      var percent = sequenceIndex/sequences.count() * 100;
      percent = Math.round(percent);
      viewer.notifyLeft("Loading features " + percent + "%");
      if (sequenceIndex == sequences.count() || !viewer.processRunning("render_features")) {
        SequenceFeatureModule.bindSequenceFeaturesEventsTo(viewer);
        SingleSVModules.setContainerDimension(viewer);
        viewer.terminateProcess("render_features");
        viewer.attachProcess("global_bind_feature_index", renderFeatures);
        bindFeatureIndex();
      } else {
        renderFeatures();
      }
    }, sequenceIndex);
  }

  var layerIndex = 0;
  var bindFeatureIndex = function(){
    SequenceFeatureModule.bindFeatureIndex(viewer, layersList[layerIndex], layerIndex, layersList.length, function(){
      layerIndex++;
      if (layerIndex == layersList.length || !viewer.processRunning("global_bind_feature_index")) {
        FeatureManagerModule.renderSequenceBoxes(viewer, layersList[layerIndex]);
        FeatureManagerModule.enable(viewer);
        viewer.terminateProcess("global_bind_feature_index");
        if (typeof callback == "function") callback();
      } else {
        bindFeatureIndex();
      }
    });
  }

  FeatureManagerModule.disable(viewer);
  viewer.attachProcess("render_features", renderFeatures);
  renderFeatures();
}

var MultipleSVModules = {};

/*
 * Render container to viewer element
 * @args viewer MultipleSequeneViewer
 * @args $target jQuerySelector
 */
MultipleSVModules.createContainer = function(viewer, $target){
  SingleSVModules.createContainer(viewer, $target);
  viewer.elementManager().$base()
    .find(".svsc_btn_show_sc_table, .svae_btn_show, .sv_protein_preview_bar, .sv_sequence_selection_mode, .sv_smallbar").remove();
}

/*
 * Register user interface element handler
 * @args viewer SingleSequeneViewer
 */
MultipleSVModules.registerElements = function(viewer){
  SingleSVModules.registerElements(viewer);
  var em = viewer.elementManager();
  em.attach("row_input", ".sv_row_input");
  em.attach("sequence_header.topbar", ".msv_sequence_header_topbar");
  em.attach("sequence_header.search_input", ".msv_search_header_input");
  em.attach("toolbar.alignment", ".msv_alignment");
  em.attach("toolbar.tree", ".msv_tree");
  viewer.$el("row_input").parent().show();
}

/*
 * Register viewer event handlers
 * @args viewer MultipleSequeneViewer
 */
MultipleSVModules.registerEvents = function(viewer){
  SingleSVModules.registerEvents(viewer);
  var em = viewer.elementManager();
  em.event("row_input", "keyup", events["row_input"].update_row_input);
  em.event("row_input", "change", events["row_input"].update_row_input);
  em.event("sequence_header.search_input", "keyup", events["sequence_header.search_input"]);
  em.event("toolbar.alignment", "click", function(){
    viewer.alignSequences();
  });
  em.event("toolbar.tree", "click", function(){
    viewer.showTree();
  });
}



/*
 * Single Sequence Viewer Class
 * act as initializer for jQuery wrapper
 */
function SingleSequenceViewer(signedTarget, signedOptions){
  var options = {}
  var $target = $(signedTarget);
  var viewer = this;
  var process = {};

  var constructOptions = function(){
    options = {
      fasta:{},
      // cut:{},
      ambiguity:{},
      features:[],
      featureAdjustment:0
    }

    if (!signedOptions["fasta"]) throw("Fasta file not available");
    if (signedOptions["fasta"].constructor == String) {
      options.fasta.url = signedOptions["fasta"];
    } else {
      deepCopy(signedOptions["fasta"], options.fasta);
    }

    // if (signedOptions["cut"] !== undefined) if (signedOptions["cut"].constructor == String) {
    //   options.cut.url = signedOptions["cut"];
    // } else {
    //   deepCopy(signedOptions["cut"], options.cut);
    // }

    if (signedOptions["features"] !== undefined) if (signedOptions["features"].constructor == String) {
      options.features = { url: signedOptions.features };
    } else {
      deepCopy(signedOptions.features, options.features);
    }

    if (signedOptions["ambiguity"] !== undefined) if (signedOptions["ambiguity"].constructor == String) {
      options.ambiguity = { url: signedOptions.ambiguity };
    } else {
      deepCopy(signedOptions.ambiguity, options.ambiguity);
    }

    if (signedOptions.featureAdjustment) options.featureAdjustment = signedOptions.featureAdjustment;
  }
  constructOptions();



  var elements = new UI.ElementManager($target, {viewer:viewer});
  var sequence = null;
  var lastSearchIndex = 0;
  var featureManager = new FeatureManager();
  var templateManager = new UI.TemplateManager();

  var configs = {
    sequence_box_size : 20,
    feature_box_size : 14,
    highlight_color : "#2DABFF",
    hover_color : "#FFEE30"
  }

  var ambiguityCorrections = {};

  /*
    Site Selection Functionality
  */
  var selectionMode = "multiple";
  var selectedSites = [];

  this.singleSelectionMode = function(){
    selectionMode = "single";
  }

  this.multipleSelectionMode = function(){
    selectionMode = "multiple";
  }

  this.selectionMode = function(){
    return selectionMode;
  }

  this.selectedSites = function(){
    return selectedSites;
  }

  this.clearSiteSelection = function(){
    selectedSites = [];
    this.$el("sequence_body").find(".sv_sequence_box[data-selected='true']").each(function(){
      $(this).click();
    });
    // console.log(selectedSites);
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.selectSite = function($box){
    SequenceSelectionModule.selectSite(viewer, $box);
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.unselectSite = function($box){
    SequenceSelectionModule.unselectSite(viewer, $box);
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.deleteSelectedSites = function(){
    var result = confirm("Are you sure to delete selected sites from sequence?");
    if (result === true) {
      SequenceSelectionModule.deleteSelectedSites(viewer);
      this.notifySequenceEdit();
    }
  }
  /*
    /Site Selection Functionality
  */

  /*
   * Return Element Manager of the viewer
   * @return UI.ElementManager
   */
  this.elementManager = function(){
    return elements;
  }

  /*
   * Return Template Manager of the viewer
   * @return UI.TemplateManager
   */
  this.templateManager = function(){
    return templateManager;
  }

  /*
   * Return Feature Manager of the viewer
   * @return FeatureManager
   */
  this.featureManager = function(){
    return featureManager;
  }

  /*
   * Return sequence object of the viewer
   * @return Sequence
   */
  this.sequence = function(){
    return sequence;
  }

  /*
   * Get sequene viewer configuration
   * @args name String
   * @return Hash|Object
   */
  this.configs = function(name){
    if (name === undefined) return configs;
    return configs[name];
  }

  /*
   * Element selector helper
   * @return jQuerySelector
   */
  this.$el = function(name){
    var el = elements.get(name);
    if (el == false) throw "Element " + name + " not found";
    return el.$el();
  }

  /*
   * Element selector helper
   * @return jQuerySelector
   */
  this.dom = function(name){
    return elements.get(name).dom();
  }

  /*
   * Load sequence
   * @args options Hash
   */
  this.loadSequenceFrom = function(sequenceOptions, callback){
    var passedCallback = sequenceOptions.success;
    sequenceOptions.success = function(response){
      sequence = FastaFileParser.read(response);
      callback();
      if (passedCallback) passedCallback();
    }
    $.ajax(sequenceOptions);
  }

  /*
   * Load features
   * @args options Hash
   * @args callback Function
   */
  this.loadFeaturesFrom = function(featuresOptions, callback){
    // return immediately when feature options defined as an array
    if (featuresOptions.constructor == Array) {
      callback();
      return false;
    }

    var passedCallback = featuresOptions.success;
    featuresOptions.dataType = "json";
    featuresOptions.success = function(response){
      options.features = response;
      callback();
      if (passedCallback) passedCallback();
    }
    featuresOptions.complete = function(){
      // viewer.$el("btn_show_feature_manager").removeAttr("disabled");
      // viewer.$el("btn_hide_feature_manager").removeAttr("disabled");
      //viewer.notifyLeft("");
    }

    viewer.$el("btn_show_feature_manager").attr("disabled", "disabled");
    viewer.$el("btn_hide_feature_manager").attr("disabled", "disabled");
    viewer.notifyLeft("Loading sequence features..");
    $.ajax(featuresOptions);
  }

  /*
   * Load the ruler of the viewer
   */
  this.loadRuler = function(){
    var $container = this.$el("sequence_body");
    SequenceBodyModule.loadRuler(viewer, $container, sequence);
  }


  this.hideRuler = function(){
    this.$el("sequence_body").find(".sv_ruler").hide();
  }

  this.showRuler = function(){
    this.$el("sequence_body").find(".sv_ruler").show();
  }

  /*
   * Load sequence body
   */
  this.loadSequenceBody = function(){
    var $container = this.$el("sequence_body");
    var $proteinPreviewContainer = this.$el("protein_preview");
    if (!viewer.inProteinMode()) {
      $proteinPreviewContainer.show();
      SequenceBodyModule.loadProteinPreview(viewer, $proteinPreviewContainer, sequence, configs.sequence_box_size);
    } else {
      $proteinPreviewContainer.hide();
    }
    SequenceBodyModule.loadSequenceBodySVG(viewer, $container, sequence, configs.sequence_box_size);
    this.slideTo(0);
  }

  /*
   * Load sequence header
   */
  this.loadSequenceHeader = function(){
    this.$el("sequence_header").html(sequence.header());
  }

  /*
   * Load features to the viewer
   */
  this.loadFeatures = function(){
    SequenceFeatureModule.renderFeatureTo(this);
  }

  this.showFeatures = function(){
    this.$el("feature_layers").show(200);
  }

  this.hideFeatures = function(){
    this.$el("feature_layers").hide(200);
  }

  /*
   * Notify something in the right status bar
   * @args content String
   */
  this.notifyRight = function(content){
    this.$el("right_status").html(content);
  }

  /*
   * Notify something in the left status bar
   * @args content String
   */
  this.notifyLeft = function(content){
    this.$el("left_status").html(content);
  }

  /*
   * Flush notification of the viewer
   */
  this.flushNotification = function(){
    this.notifyLeft("");
    this.notifyRight("");
  }

  /*
   * Slide sequence body to specified index
   * @args sequenceIndex Number
   */
  this.slideTo = function(sequenceIndex){
    this.$el("site_input").html(sequenceIndex+1);
    this.$el("sequence_body").scrollLeft(sequenceIndex * configs.sequence_box_size);
    viewer.$el("site_input").val(sequenceIndex+1);
  }

  /*
   * Set viewer to protein mode or nucleotide mode
   * @args protein Boolean
   */
  this.setProteinMode = function(protein) {
    this.flushProcess();
    if (protein) {
      sequence.proteinize();
      viewer.$el("ambiguity_editor").hide(0);
      viewer.$el("btn_hide_ambiguity_editor").hide(0);
      viewer.$el("btn_show_ambiguity_editor").hide(0);
    } else {
      sequence.unproteinize();
      viewer.$el("ambiguity_editor").hide(0);
      viewer.$el("btn_hide_ambiguity_editor").hide(0);
      viewer.$el("btn_show_ambiguity_editor").show(0);
    }
    Ambiguity.check(viewer);
    this.loadSequenceBody();
    this.loadRuler();
    this.flushNotification();
    this.loadFeatures();
    this.clearSiteSelection();
  }

  this.inProteinMode = function() {
    return sequence.inProteinMode();
  }

  /*
   * Search with specified pattern
   * @args pattern String
   */
  this.search = function(pattern){
    var result = sequence.search(pattern);
    var message = "";
    if (result.index != -1) {
      SingleSVModules.highlightSequence(this, result.start, result.finish);
      message = "Found at " + (result.start + 1);
      if (result.start != result.finish) message += " to " + (result.finish + 1);
    } else {
      SingleSVModules.unhighlightSequence(this);
      this.slideTo(0);
      message = "<font color='red'>Search with query '" + pattern + "' has no result</font>";
    }
    this.notifyLeft("<i>" + message + "</i>");
  }

  /*
   * Add ambiguity correction to the sequence
   * @args correction Hash
   */
  this.ambiguityCorrection = function(correction) {
    ambiguityCorrections[correction.index] = correction.code;
    sequence.edit(correction.index, correction.code);
    SequenceQualityModule.validate(viewer, sequence);
    viewer.softReload();
    viewer.slideTo(correction.index);
    this.notifySequenceEdit();
  }

  this.notifySequenceEdit = function(){
    if (signedOptions.sequence_edited === undefined) return false;
    signedOptions.sequence_edited(viewer);
  }

  /*
   * Return ambiguity corrections
   * @return Hash
   */
  this.ambiguityCorrections = function(){
    return ambiguityCorrections;
  }

  this.attachProcess = function(name, fn){
    if (process[name] !== undefined) return false;
    process[name] = fn;
    // console.log(name + " attached");
  }

  this.processRunning = function(name){
    return process[name] === undefined ? false : true;
  }

  this.terminateProcess = function(name){
    // console.log("terminating " + name);
    if (process[name] == undefined) return false;
    process[name] = undefined;
    // console.log(name + " terminated");
  }

  this.flushProcess = function(){
    process = {};
    // console.log("processes flushed");
    // console.log(viewer.processRunning("bind_feature_index"));
  }

  /*
   * Initialize the sequence viewer
   */
  this.init = function(){
    this.loadSequenceFrom(options.fasta, function(){
      SingleSVModules.createContainer(viewer, $target);
      SingleSVModules.registerElements(viewer);
      SingleSVModules.registerEvents(viewer);

      Ambiguity.registerElements(viewer);
      Ambiguity.registerEvents(viewer);
      Ambiguity.check(viewer);

      viewer.loadSequenceBody();
      viewer.loadSequenceHeader();
      viewer.loadRuler();
      if (options.features !== undefined) viewer.loadFeaturesFrom(options.features, function(){
        Util.iterate(options.features, function(feature){
          var f = new Feature(feature);
          // console.log(feature)
          // console.log(f.isForValidation())
          f.adjustBy(options.featureAdjustment);
          //
          // @note Feature Matching with UTR Feature
          // @desc Ignore UTR PART, REMEMBER!!
          // f.ignoreBy();
          //
          featureManager.attach(f);
        });
        viewer.loadFeatures();
        FeatureManagerModule.loadFeatureList(viewer);

        SequenceQualityModule.registerElements(viewer);
        SequenceQualityModule.registerEvents(viewer);
        viewer.notifyLeft("");
        SequenceQualityModule.validate(viewer, sequence);
      });
    });
  }

  this.notifyReload = function(){
    viewer.clearSiteSelection();
    viewer.notifyLeft("");
    viewer.notifyRight("Reloading viewer..");
    viewer.$el("sequence_body").html("");
  }

  this.reload = function(reload) {
    viewer.notifyReload();
    elements = new UI.ElementManager($target, {viewer:viewer});
    sequence = null;
    lastSearchIndex = 0;
    featureManager = new FeatureManager();
    templateManager = new UI.TemplateManager();
    ambiguityCorrections = {};
    process = {};

    selectionMode = "multiple";

    constructOptions();
    $target.unbind("click");

    viewer.init();
  }

  this.softReload = function(){
    viewer.flushProcess();
    viewer.notifyReload();
    this.loadSequenceBody();
    this.loadRuler();
    this.flushNotification();
    this.loadFeatures();
    this.clearSiteSelection();
  }

  // initialize viewer immediatelly
  this.init();
}

/*
 * Multiple Sequence Viewer Class
 * act as initializer for jQuery wrapper
 */
function MultipleSequenceViewer(signedTarget, signedOptions){
  var options = {};
  var $target = $(signedTarget);
  var viewer = this;
  var alignmentMode = false;
  var process = {};

  var constructOptions = function(){
    options = {
      fasta:{},
      // cut:{},
      alignment:{},
      //tree:{},
      tree:"",
      features:[],
      featureAdjustment:0
    }
    // Fasta Options
    if (!signedOptions["fasta"]) throw("Fasta file not available");
    if (signedOptions["fasta"].constructor == String) {
      options.fasta.url = signedOptions["fasta"];
    } else {
      deepCopy(signedOptions["fasta"], options.fasta);
    }

    // Cut Options
    // if (signedOptions["cut"] !== undefined) if (signedOptions["cut"].constructor == String) {
    //   options.cut.url = signedOptions["cut"];
    // } else {
    //   deepCopy(signedOptions["cut"], options.cut);
    // }

    // Feature Options
    if (signedOptions["features"] !== undefined) if (signedOptions["features"].constructor == String) {
      options.features = { url: signedOptions.features };
    } else {
      deepCopy(signedOptions.features, options.features);
    }

    // Alignment Options
    if (signedOptions["alignment"] !== undefined) if (signedOptions["alignment"].constructor == String) {
      options.alignment = { url: signedOptions.alignment };
    } else {
      deepCopy(signedOptions.alignment, options.alignment);
    }

    // Tree Options
    options.tree = signedOptions.tree;
    /*
    if (signedOptions["tree"] !== undefined) if (signedOptions["tree"].constructor == String) {
      options.tree = { url: signedOptions.tree };
    } else {
      deepCopy(signedOptions.tree, options.tree);
    }*/

    if (signedOptions.featureAdjustment) options.featureAdjustment = signedOptions.featureAdjustment;
  }
  constructOptions();

  var elements = new UI.ElementManager($target, {viewer:viewer});
  var sequences = null;
  var lastSearchIndex = {
    row:0,
    column:0
  };
  var featureManager = new FeatureManager();
  var templateManager = new UI.TemplateManager();

  var configs = {
    sequence_box_size : 20,
    feature_box_size : 14,
    highlight_color : "#2DABFF",
    hover_color : "#FFEE30"
  }

  /*
    Site Selection Functionality
  */
  var selectionMode = "multiple";
  var selectedSites = [];

  this.singleSelectionMode = function(){
    selectionMode = "single";
  }

  this.multipleSelectionMode = function(){
    selectionMode = "multiple";
  }

  this.selectionMode = function(){
    return selectionMode;
  }

  this.selectedSites = function(){
    return selectedSites;
  }

  this.clearSiteSelection = function(){
    selectedSites = [];
    this.$el("sequence_body").find(".sv_sequence_box[data-selected='true']").each(function(){
      $(this).click();
    });
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.selectSite = function($box){
    SequenceSelectionModule.selectSite(viewer, $box);
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.unselectSite = function($box){
    SequenceSelectionModule.unselectSite(viewer, $box);
    SequenceSelectionModule.notifySelectedSites(viewer);
  }

  this.deleteSelectedSites = function(){
    var result = confirm("Are you sure to delete selected sites from sequences?");
    if (result === true) {
      SequenceSelectionModule.deleteSelectedSites(viewer, options);
    }
  }
  /*
    /Site Selection Functionality
  */


  /*
   * Return Element Manager of the viewer
   * @return UI.ElementManager
   */
  this.elementManager = function(){
    return elements;
  }

  /*
   * Return Template Manager of the viewer
   * @return UI.TemplateManager
   */
  this.templateManager = function(){
    return templateManager;
  }

  /*
   * Return Feature Manager of the viewer
   * @return FeatureManager
   */
  this.featureManager = function(){
    return featureManager;
  }

  /*
   * Return sequence group object of the viewer
   * @return SequenceGroup
   */
  this.sequences = function(){
    return sequences;
  }

  /*
   * Get sequene viewer configuration
   * @args name String
   * @return Hash|Object
   */
  this.configs = function(name){
    if (name === undefined) return configs;
    return configs[name];
  }

  /*
   * Element selector helper
   * @return jQuerySelector
   */
  this.$el = function(name){
    var el = elements.get(name);
    if (el == false) throw "Element " + name + " not found";
    return el.$el();
  }

  /*
   * Element selector helper
   * @return jQuerySelector
   */
  this.dom = function(name){
    return elements.get(name).dom();
  }

  /*
   * Load sequences
   * @args options Hash
   */
  this.loadSequencesFrom = function(sequencesOptions, callback){
    var passedCallback = sequencesOptions.success;
    sequencesOptions.success = function(response){
      sequences = FastaFileParser.read(response, true);
      callback();
      if (passedCallback) passedCallback();
    }
    $.ajax(sequencesOptions);
  }

  /*
   * Load features
   * @args options Hash
   * @args callback Function
   */
  this.loadFeaturesFrom = function(featuresOptions, callback){
    // return immediately when feature options defined as an array
    if (featuresOptions.constructor == Array) {
      callback();
      return false;
    }

    var passedCallback = featuresOptions.success;
    featuresOptions.dataType = "json";
    featuresOptions.success = function(response){
      options.features = response;
      callback();
      if (passedCallback) passedCallback();
    }
    featuresOptions.complete = function(){
      // viewer.$el("btn_show_feature_manager").removeAttr("disabled");
      // viewer.$el("btn_hide_feature_manager").removeAttr("disabled");
      // viewer.notifyLeft("");
    }

    viewer.$el("btn_show_feature_manager").attr("disabled", "disabled");
    viewer.$el("btn_hide_feature_manager").attr("disabled", "disabled");
    viewer.notifyLeft("Loading sequence features..");
    $.ajax(featuresOptions);
  }

  /*
   * Load the ruler of the viewer
   */
  this.loadRuler = function(){
    var $container = this.$el("sequence_body");
    SequenceBodyModule.loadRuler(viewer, $container, sequences.max().sequence);
  }

  /*
   * Load sequence body
   */
  this.loadSequenceBody = function(callback){
    var $container = this.$el("sequence_body");
    MultipleSequenceBodyModule.loadSequenceBodySVG(viewer, $container, sequences, configs.sequence_box_size, callback);
    this.slideTo(0);
  }

  /*
   * Load sequence header
   */
  this.loadSequenceHeader = function(){
    this.$el("sequence_header.topbar").show();
    var $header = this.$el("sequence_header").html("");
    var oriWidth = $header.width();

    $header.parent().css("padding", 0);
    $header.parent().css("vertical-align", "top");
    $header.css("overflow-x", "scroll");

    sequences.each(function(sequence, index){
      var header = index == 0 ? "<div class='msv_sequence_header active' data-sequence-index='" + index + "'>" : "<div class='msv_sequence_header' data-sequence-index='" + index + "'>";
      header += (index+1) + ". "  + sequence.header() + "</div>";
      $header.append(header);

      var spacer = "<div class='msv_feature_spacer' data-sequence-index='" + index + "'></div>";
      $header.append(spacer);
    });
    var $headers = elements.$base().find(".msv_sequence_header");
    $headers.width($header.width());
    $header.css("max-width", oriWidth);

    $headers.click(function(){
      $headers.removeClass("active");
      var $this = $(this);
      $this.addClass("active");
      var row = $this.attr("data-sequence-index");
      viewer.notifyRow(row);
    });
  }

  /*
   * Load features to the viewer
   */
  this.loadFeatures = function(){
    MultipleSequenceFeatureModule.renderFeatureTo(this);
  }

  /*
   * Notify something in the right status bar
   * @args content String
   */
  this.notifyRight = function(content){
    this.$el("right_status").html(content);
  }

  /*
   * Notify something in the left status bar
   * @args content String
   */
  this.notifyLeft = function(content){
    this.$el("left_status").html(content);
  }

  /*
   * Flush notification of the viewer
   */
  this.flushNotification = function(){
    this.notifyLeft("");
    this.notifyRight("");
  }

  /*
   * Slide sequence body to specified index
   * @args sequenceIndex Number
   */
  this.slideTo = function(sequenceIndex){
    this.$el("site_input").html(sequenceIndex+1);
    this.$el("sequence_body").scrollLeft(sequenceIndex * configs.sequence_box_size);
    viewer.$el("site_input").val(sequenceIndex+1);
  }

  /*
   * Notify row index
   * @args index Number
   */
  this.notifyRow = function(index){
    $target.find(".msv_sequence_header").removeClass("active");
    var $sequenceHeader = $target.find(".msv_sequence_header[data-sequence-index='" + index + "']");
    $sequenceHeader.addClass("active");
    index = Util.number(index) + 1;
    viewer.$el("row_input").val(index);
  }

  /*
   * Set viewer to protein mode or nucleotide mode
   * @args protein Boolean
   */
  this.setProteinMode = function(protein) {
    this.flushProcess();
    if (protein) {
      sequences.proteinize();
    } else {
      sequences.unproteinize();
    }
    this.loadSequenceBody(function(){
      viewer.loadRuler();
      viewer.flushNotification();
      viewer.loadFeatures();
      SingleSVModules.setContainerDimension(viewer);
    });
  }

  this.inProteinMode = function() {
    return sequences.inProteinMode();
  }

  /*
   * Search with specified pattern
   * @args pattern String
   */
  this.search = function(pattern){
    var startingRow = Util.number(viewer.$el("row_input").val());
    startingRow -= 1;
    var result = sequences.search(pattern, startingRow);
    var message = "";

    if (result.index != -1) {
      SingleSVModules.highlightSequence(this, result.start, result.finish, result.row);
      this.notifyRow(result.row);
      message = "Found at " + (result.start + 1);
      if (result.start != result.finish) message += " to " + (result.finish + 1);
    } else {
      SingleSVModules.unhighlightSequence(this);
      this.slideTo(0);
      this.notifyRow(0);
      message = "<font color='red'>Search with query '" + pattern + "' has no result</font>";
    }
    this.notifyLeft("<i>" + message + "</i>");
  }


  /*
   * Checke the viewer is in alignment mode or not!
   */
  this.alignmentMode = function() {
    return alignmentMode;
  }

  /*
   * Align sequences
   */
  this.alignSequences = function(callback){
    this.flushProcess();
    var passedCallback = options.alignment.success;
    var $sb = viewer.$el("sequence_body");
    var $btn = viewer.$el("toolbar.alignment");
    this.flushNotification();

    options.alignment.success = function(response){
      sequences = FastaFileParser.read(response, true);
      MultipleSequenceBodyModule.normalState(viewer, $sb);
      viewer.loadSequenceHeader();
      viewer.loadSequenceBody(function(){
        viewer.loadRuler();
        viewer.loadFeatures();
        SingleSVModules.setContainerDimension(viewer);
        viewer.notifyRight("");
        if (callback !== undefined) callback();
        if (passedCallback) passedCallback();
        $btn.remove();

        // show feature manager
        viewer.$el("btn_show_feature_manager").click();
        // set mode to alignment mode
        alignmentMode = true;

        // reset sequence form input
        viewer.elementManager().$base().find(".sv_sequence_form").val("nucleotide");
      });
    }

    options.alignment.failed = function(){
      $btn.removeAttr("disabled");
    }

    // MultipleSequenceBodyModule.loadingState(viewer, $sb);
    viewer.notifyRight("Fetching from alignment source..");
    $btn.attr("disabled", "disabled");
    $.ajax(options.alignment);
  }

  this.attachProcess = function(name, fn){
    if (process[name] !== undefined) return false;
    process[name] = fn;
    // console.log(name + " attached");
  }

  this.processRunning = function(name){
    return process[name] === undefined ? false : true;
  }

  this.terminateProcess = function(name){
    if (process[name] == undefined) return false;
    process[name] = undefined;
    // console.log(name + " terminated");
  }

  this.flushProcess = function(){
    process = {};
    // console.log("processes flushed");
  }

  /*
   * Show Phylogenetic Tree on new tab
   *
  this.showTree = function(){
    var $btn = viewer.$el("toolbar.tree");

    options.tree.success = function(response){
      console.log(response);
    }

    options.tree.failed = function(){
      $btn.removeAttr("disabled");
    }

    options.tree.complete = function(){
      $btn.removeAttr("disabled");
    }

    this.notifyLeft("Constructing phylogenetic tree..")
    $btn.attr("disabled", "disabled");
    $.ajax(options.tree);
  } */

  // this.showTree = function(){
  //   if (!options.tree == "") window.open(options.tree);
  // }

  /*
   * Initialize the sequence viewer
   */
  this.init = function(){
    MultipleSVModules.createContainer(viewer, $target);
    if (signedOptions["alignment"] === undefined) {
      $target.find(".msv_alignment").hide();
    }
    MultipleSVModules.registerElements(viewer);
    MultipleSVModules.registerEvents(viewer);
    viewer.loadSequenceHeader();
    SingleSVModules.setContainerDimension(viewer);
    viewer.loadSequenceBody(function(){
      viewer.loadRuler();
      if (options.features !== undefined) viewer.loadFeaturesFrom(options.features, function(){
        Util.iterate(options.features, function(feature){
          var f = new Feature(feature);
          f.adjustBy(options.featureAdjustment);
          featureManager.attach(f);
        });
        viewer.loadFeatures();
        FeatureManagerModule.loadFeatureList(viewer);
        SingleSVModules.setContainerDimension(viewer);
      });
    });
  }

  this.reload = function() {
    viewer.clearSiteSelection();
    viewer.notifyLeft("");
    viewer.notifyRight("Reloading viewer..");
    viewer.$el("sequence_body").html("");

    elements = new UI.ElementManager($target, {viewer:viewer});
    sequences = null;
    lastSearchIndex = {
      row:0,
      column:0
    };
    featureManager = new FeatureManager();
    templateManager = new UI.TemplateManager();
    alignmentMode = false;
    process = {};

    selectionMode = "multiple";

    constructOptions();
    // console.log(JSON.stringify(options.cut));
    $target.unbind("click");

    this.loadSequencesFrom(options.fasta, function(){
      viewer.init();
    });
  }

  // initialize viewer immediatelly
  this.loadSequencesFrom(options.fasta, function(){
    viewer.init();
  });
}

$.fn["singleSequenceViewer"] = function(options) {
  return new SingleSequenceViewer(this, options);
}

$.fn["multipleSequenceViewer"] = function(options) {
  return new MultipleSequenceViewer(this, options);
}

})(jQuery, window, document)
