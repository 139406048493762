import { Controller } from "stimulus"
import feather from 'feather-icons'
import Turbolinks from 'turbolinks'

// Gabungan parameter yg ditambahkan sebelum waktu timeout
// akan dikirim bersamaan saat ajax request di kirim
let waitingParams = {}

// Waktu timeout ajax
// Waktu akan bertambah selama 2 detik setiap melakukan filter
let waitingTimes = 0

// Untuk menyimpan sementara fungsi setTimeout ajax
// Fungsi akan di clearTimeout ketika masih ada tambaha filter
let timeOutFunction = null

export default class extends Controller {
  static targets = ["list", "labels", "formData", "btnReset", "searchInputs", "currentScopes"]

  connect() {
    this._filteredLabel()
  }

  onFiltered(event) {
    let [data] = event.detail

    if (typeof data == "string") data = JSON.parse(data)

    this._setQueryParams(data.params)
    this._renderTable(data.content, data.button)
    this._filteredLabel()
  }

  reset(event) {
    $(event.currentTarget).closest("form")[0].reset()
  }

  searchOptions(event) {
    const word = event.currentTarget.value
    let dropdownMenu = event.currentTarget.dataset.dropdownMenu
    let options = document.querySelectorAll(`[data-dropdown-group="${dropdownMenu}"]`)

    options.forEach(option => {
      option.classList.add("d-none")

      if (option.dataset.label.includes(word)) {
        option.classList.remove("d-none")
      }
    })
  }

  onSubmitFilter(event) {
    waitingTimes += 2000
    const inputName = $(event.currentTarget).attr('name')
    const url = $(event.currentTarget).data('url')
    let values

    if ($(event.currentTarget).attr('type') == 'checkbox') {
      const arrValues = []
      $(`.input-filter-${inputName}:checked`).each(function () {
        arrValues.push($(this).val())
      })
      values = arrValues.join(',')
    } else {
      values = $(event.currentTarget).val()
    }

    this._request(url, values, inputName)
  }

  onResetFilter(event) {
    this._resetGlobalVariable()
    const url = $(event.currentTarget).attr('href')
    Turbolinks.visit(url)
  }

  // private

  _requestData(url, target, visit, isSearchByName) {
    let arrowKeys = [37, 38, 39, 40, 13]

    if (!arrowKeys.includes(event.keyCode)) {
      target.typeahead({
        source: (query, process) => {
          return $.get(
            url + query,
            (data) => {
              process(data)
            },
            "json"
          )
        },
        autoSelect: false,
        minLength: 3,
        afterSelect: (item) => {
          if (isSearchByName) {
            Turbolinks.visit(`${visit}/${item.id}`)
            target.val("")
          }
        },
      })
    }
  }

  _setQueryParams(responseParams) {
    if (responseParams == undefined) {
      window.history.replaceState({}, "", location.pathname)
    } else {
      const queryParams = new URLSearchParams()

      Object.keys(responseParams).forEach((param) => {
        queryParams.set(param, responseParams[param])
      })

      window.history.replaceState({}, "", `${location.pathname}?${queryParams.toString()}`)
    }
  }

  _filteredLabel() {
    const scopes = $(this.currentScopesTarget).data('scopes')
    const modelName = $(this.currentScopesTarget).data('model-name')
    this.labelsTarget.innerHTML = ""

    if (jQuery.isEmptyObject(scopes)) {
      this.labelsTarget.innerHTML = this._allListFilteredLabel(modelName)
    } else {
      this.labelsTarget.insertAdjacentHTML(
        'beforeend',
        this._listFilteredLabel('', scopes)
      )

      // for (const values of Object.values(scopes)) {
      //   values.split(',').forEach(value => {
      //     this.labelsTarget.insertAdjacentHTML(
      //       'beforeend',
      //       this._listFilteredLabel('', value)
      //     )
      //   })
      // }
    }
  }

  _renderTable(content, button) {
    this.listTarget.innerHTML = content

    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()
    feather.replace()

    if (this.hasBtnResetTarget) {
      if (button === "filter") {
        this.btnResetTarget.classList.remove("d-none")
      } else {
        this.btnResetTarget.classList.add("d-none")
      }
    }
  }

  _allListFilteredLabel(modelName) {
    return `
      <div class="btn btn-sm btn-light border text-primary">
        All ${modelName}
      </div>
    `
  }

  _listFilteredLabel(url, scopes) {
    let params
    if (Object.keys(scopes).includes('by_isolate_name')) {
      params = {...scopes, keyword: scopes['by_isolate_name']['keyword']}
      delete params.by_isolate_name
    } else {
      params = scopes
    }

    const content = Object.keys(params).map(k => {
      return `
        <div class='mt-2'>
          <div class='mb-1 text-capitalize'>${k.split('_').join(' ')}</div>
          ${this._listFilteredLabelItem(params[k].split(','))}
        </div>
      `
    })

    return content.join('')
  }

  _listFilteredLabelItem(items) {
    const content = items.map(item => {
      return `<div class="btn btn-sm btn-light border text-primary mr-1 text-capitalize">${item.split('_').join(' ')}</div>`
    })

    return content.join('')
  }

  _resetGlobalVariable(e) {
    waitingParams = {}
    waitingTimes = 0
    timeOutFunction = null
  }

  _request(url, values, inputName) {
    let params = {}
    const thisController = this

    delete $(thisController.currentScopesTarget).data('scopes')[inputName]
    const currentScopes = $(thisController.currentScopesTarget).data('scopes')

    params[inputName] = values
    params = {...params, ...currentScopes}
    waitingParams = {...waitingParams, ...params}
    clearTimeout(timeOutFunction)

    timeOutFunction = setTimeout(() => {
      $.ajax({
        url: `${url}.js`,
        method: 'GET',
        data: waitingParams,
        dataType: 'JSON',
        success: function(res) {
          if (typeof res == "string") res = JSON.parse(res)
          thisController._setQueryParams(res.params)
          thisController._renderTable(res.content, res.button)
          thisController._filteredLabel()
          thisController._resetGlobalVariable()
        }
      })
    }, waitingTimes)
  }
}
