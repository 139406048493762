myArr = []

installCircleLegend = (canvas,color) ->
  uniqueArr = []
  y = 0
  for i in myArr
    if $.inArray(i, uniqueArr) is -1
      uniqueArr.push(i)

  for i in uniqueArr
    y += 20
    g_legend = canvas.append("g")
    circle = g_legend.append("circle")
      .attr("cx", 5)
      .attr("cy", y)
      .attr("r", 5)
      .style("fill", color(i))
      .attr("label", i)
    text = g_legend.append("text")
      .attr("x", 15)
      .attr("y", y+5)
      .attr("fill", color(i))
      .style("font-weight", "bold")
      .text(i)

setNodeLabelType = (element) ->
  collectionId = element.attr("collection-id")
  $tspan = $("#phylogenetic_tree_canvas_" + collectionId + " tspan")
  $tspan.each () ->
    name = $(this).data("isolate-name")
    organism = $(this).data("species-label")
    clade = $(this).data("clade")
    cladeSpan = " <tspan data-target='' class='clade-label hide' data-clade='"+clade+"' data-species-label='"+organism+"'>("+clade+")</tspan>"
    if (element.val() is "compact")
      if name
        splitName = name.split("/")
        species = splitName[1]
        year = splitName[splitName.length-1]
        if organism is "human"
          location = splitName[1]
          $(this).html("#{location}/#{year}"+cladeSpan)
        else
          location = splitName[2]
          $(this).html("#{species}/#{location}/#{year}"+cladeSpan)
    else if (element.val() is "default")
      if name
        $(this).html("#{name}"+cladeSpan)

cladeLabelVisibility = (element) ->
  collectionId = element.attr("collection-id")
  if element.is(":checked")
    $("#phylogenetic_tree_canvas_" + collectionId + " .clade-label").removeClass("hide")
  else
    $("#phylogenetic_tree_canvas_" + collectionId + " .clade-label").addClass("hide")

window.showIsolateDetailOnPhyloTree = (collectionId) ->
  $("#phylogenetic_tree_canvas_" + collectionId + " tspan:not(.bootstrap-label)").each () ->
      $(this).css("cursor", "hand")

  $("#phylogenetic_tree_canvas_" + collectionId).on "click", "tspan", () ->
    return false if $(this).hasClass('bootstrap-label')
    id = $(this).data "id"
    link = "/isolates/"+ id + "?collection-id=" + collectionId
    $("#phylogenetic_tree_isolate_detail_" + collectionId + ".modal").modal("show");
    $("#phylogenetic_tree_isolate_detail_" + collectionId + ".modal .modal-header h4").html("<b>"+$(this).html()+"</b>")
    $.ajax
      url: link
      dataType: "SCRIPT"
      method: "GET"

  $("#phylogenetic_tree_canvas_" + collectionId).on "click", ".btn-close-new-slide-panel", (e) ->
    e.preventDefault()
    target = $(this).attr("href")
    $(target).hide()

  $("body").on "click", ".tree-filter", () ->
    myArr = []
    c10 = d3.scale.category10()
    c20 = d3.scale.category20()
    $this = $(this)
    collectionId = $this.attr("collection-id");
    $tspan = $("#phylogenetic_tree_canvas_" + collectionId + " tspan");
    $tspan.attr("fill", "#000000")
    $("#phylo_tree_legend_" + collectionId).html("")
    svg = d3.select("#phylo_tree_legend_" + collectionId).append("svg")
      .attr("height", "250")
      .attr("width", "100%")
    if ($this.val() is "clade")
      $tspan.each () ->
        return true if $(this).hasClass('bootstrap-label')
        $(this).attr("fill", c20($(this).data("clade")))
        if ($(this).data("clade"))
          myArr.push($(this).data("clade"))
      installCircleLegend(svg,c20)

    else if ($this.val() is "species")
      $tspan.each () ->
        return true if $(this).hasClass('bootstrap-label')
        $(this).attr("fill", c10($(this).data("species-label")))
        if ($(this).data("species"))
          myArr.push($(this).data("species-label"))
      installCircleLegend(svg,c10)

    else
      $tspan.attr("fill", "#000000")
      $("#phylo_tree_legend_" + collectionId).html("<small><i class='text-mute'>No legend</i></small>")

  $("body").on "click", ".clade_label_toggle", () ->
    cladeLabelVisibility($(this))

  $("body").on "click", ".tree-filter-name", () ->
    setNodeLabelType($(this))
    collectionId = $(this).attr("collection-id")
    $("#clade_label_toggle_" + collectionId).prop("checked", false)