window.checkNumberOfLabTestData = (data, kode_kab) ->
  counter = 0
  # console.log(kode_kab)
  $.each data, (key, val) ->
    if val.area_code == kode_kab
      counter += 1

  return counter

window.checkNumberOfIsolateData = (data, kode_kab) ->
  counter = 0
  # console.log(data)
  # console.log(kode_kab)
  $.each data, (key, val) ->
    dis_code = "#{val.province_code}#{val.district_code}"
    if dis_code is kode_kab
      counter += 1

  return counter

window.loadHomePageLabTestAndIsolateMap = (isolate_data, lab_test_data, map_id) ->

  # initialize size
  w = $("#home_page .col-8").width()
  size =
    width: w,
    height: w*0.4
  center = [size.width/2 , size.height/2]

  array_isolate_data = isolate_data
  array_data = lab_test_data
  $("##{map_id}").html('')
  # create canvas
  canvas = d3.select("##{map_id}").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#{mapColors['sea']}")
    .style("border-radius", "inherit")
    .attr("cursor", "grab")

  # create tooltip
  tooltip = d3.select("body").append("div")
    .attr("class", "tooltip-isolate")
    .style("opacity", 0);

  group = canvas.append('g')

  projection = d3.geoMercator().scale(w*1.2).translate([-size.width*1.97, (size.height)/2.9]);
  path = d3.geoPath().projection(projection);
  # ----------------------------------------------- Generate MAP --------------------------------------------------------
  d3.json("/outbreaks/indonesia_districts_2018.json").then (data) ->

    areas = group.selectAll("path")
      .data(data.features)
      .enter()
        .append("path")
        .attr("d", path)
        .attr("class", (d,i) ->
          return "area "+d.properties.CC_2;
        )
        .style("stroke", "#{mapColors['landBorder']}")
        .style("stroke-width", "0.1")
        .attr("fill", "#f9f9f9")
        .on('mouseover', (d,i) ->
          string_details = "<b>"+d.properties.NAME_2+"</b>";
          $("##{map_id}_detail i").html(string_details)
        )
        .on('mouseout', (d,i) ->
          $("##{map_id}_detail i").html("Map Details")
        )

    circle = canvas.selectAll("g.dis.marker")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis marker")
      .attr("id", (d,i) ->
        d.realx = path.centroid(d)[0]
        d.realy = path.centroid(d)[1]
        return d.properties.CC_2;
      )
    circle.append('svg:circle')
      .attr('cx', (d) -> path.centroid(d)[0])
      .attr('cy', (d) -> path.centroid(d)[1])
      .attr('r', 0.0001)
      .style('opacity', 0)

    marker_path = $("#green_marker_path").val()
    if map_id == 'dashboard_occurrences_map'
      marker_path = $("#marker_path").val()
    circle.append('image')
      .attr('xlink:href',"#{marker_path}")
      .attr('class', 'pico')
      .attr('height', 20)
      .attr('width', 20)
      .attr('x', (d) ->
        return path.centroid(d)[0]-10
      )
      .attr('y', (d) ->
        return path.centroid(d)[1]-20
      )
      .style('display', (d,i) ->
        total_data = 0
        if map_id == 'dashboard_occurrences_map'
          total_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
        else
          total_data = checkNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
        if total_data > 0
          return 'visible'
        else
          return 'none'
      )
      .style('cursor', 'pointer')
      .on('mouseover', (d,i) ->
        total_data = 0
        data_name = 'Isolates'
        if map_id == 'dashboard_occurrences_map'
          total_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
          data_name = 'Occurrences'
        else
          total_data = checkNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
        string_details = "<b>"+d.properties.NAME_2+"</b> <br /> <b>"+total_data+" "+data_name+"</b>";
        # $("##{map_id}_detail i").html(string_details)
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        tooltip.html(string_details)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      )
      .on('mouseout', (d,i) ->
        # $("##{map_id}_detail i").html("District Details")
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      )
  # ----------------------------------------------- END OF MAP --------------------------------------------------------

  # ------------- Zooming Map (by scroll) --------------------------------
  zoomed = () ->
    canvas.selectAll("g").attr("transform", d3.event.transform);
    zoom_scale = parseFloat(1)/d3.event.transform.k
    canvas.selectAll(".pico")
      .attr("width", 20*zoom_scale)
      .attr("height", 20*zoom_scale)
      .attr('x', (d) ->
        newx = parseFloat(d.realx) - parseFloat(((20*zoom_scale)/parseFloat(2)))
        return newx;
      )
      .attr('y', (d) ->
        newy = parseFloat(d.realy) - parseFloat(20*zoom_scale)
        return newy;
      )

  zoom = d3.zoom().on("zoom", zoomed);

  canvas.call(zoom);
  canvas.call(zoom).on("dblclick.zoom", null);
  # ------------- end of Zooming Map (by scroll) -------------------------

  # ------------- Zooming Map (by control button) -------------------------
  d3.selectAll('.zoom-control').on('click', () ->
    if (this.id == 'zoom_in')
      zoom.scaleBy(canvas.transition().duration(350), 1.3);
    else if (this.id == 'zoom_out')
      zoom.scaleBy(canvas.transition().duration(350), 0.7);
    else
      canvas.call(zoom.transform,d3.zoomIdentity)
  )
  # ------------- end of Zooming Map (by control button) ------------------



window.calculateAndLoadMap = () ->
  isolate_data = $("#home_page_isolate_data").data("isolate")
  lab_test_data = $("#home_page_lab_test_data").data("lab")

  # loadHomePageLabTestAndIsolateMap(isolate_data, lab_test_data, "dashboard_occurrences_map")
  loadHomePageLabTestAndIsolateMap(isolate_data, lab_test_data, "dashboard_isolate_map")

  # $("body").on "click", "#home_page_lab_test_and_isolate_map_go_to_district_button", ->
  #   dis_id = $("#home_page_lab_test_and_isolate_map_district_id").val()
  #   district_focus("#{dis_id}")


ready = ->
  $("body").on "click", ".wiki-help-popover", ->
    $this = $(this)
    $(".wiki-help-popover").each ->
      $(this).removeClass("wiki-help-popover-show")
    $this.addClass("wiki-help-popover-show")

  # close slide when cancel button pressed
  $("body").on "click", ".wiki-cancel-btn", (e) ->
    e.preventDefault();
    $(".wiki-help-slide").toggle('slide', {easing: 'easeOutQuint', direction: 'right'}, 300);

  $("body").on "click", ".wiki-edit-btn", (e) ->
    e.preventDefault();
    title = $(this).attr("title");
    section = $(this).attr("section");
    window.open("http://id.wiki.ivmonline.org/index.php?title=" + title + "&veaction=edit&vesection=" + section);

  $("body").on "change", ".dashboard-period", (e) ->
    e.preventDefault();
    selected_val = $(this).val()
    loadingNotification("show","Loading...")

    $.ajax
      url: $(this).data("url")
      dataType: 'script'
      method: 'GET'
      data:
        period: selected_val
      success: (data) ->
        $(".dashboard-period").val(selected_val)
        loadingNotification("hide")

  # $("body").on "click", ".matching-isolate-btn", (e) ->
  #   e.preventDefault();
  #   console.log "masuk"
  #   period = $(this).data('period')
  #   if period == null
  #     period = "all"
  #   $.ajax
  #     url: $(this).data("url")
  #     dataType: 'script'
  #     method: 'GET'
  #     data:
  #       period: period
  #       matching_type: true

  if $("#home_page").length > 0
    calculateAndLoadMap()
    $("body").on "click", ".dashboard-map-toggle", (e) ->
      e.preventDefault();
      data_map = $(this).data("map")
      $(".dasboard-map-nav").find("li").removeClass("active")
      if data_map == "isolate"
        $(".isolate-nav-item").addClass("active")
        $("#dashboard_isolate_map_tab_content").removeClass("hide")
        $("#dashboard_occurrences_map_tab_content").addClass("hide")
        $(".isolate-filter").removeClass("hide")
        $(".occurrences-filter").addClass("hide")
      else if data_map == "lab_test_data"
        $(".labtestdata-nav-item").addClass("active")
        $("#dashboard_isolate_map_tab_content").addClass("hide")
        $("#dashboard_occurrences_map_tab_content").removeClass("hide")
        $(".isolate-filter").addClass("hide")
        $(".occurrences-filter").removeClass("hide")

  $("body").on "click", "#close_ai_situation", (e) ->
    e.preventDefault();
    $(".ai-situation-wrapper").remove();


  if $(".news-page").length > 0
    $("#main_navbar").hide();

$(document).on 'turbolinks:load', ready