window.antigenDataTablesLoad = () ->
  url = $("#antigens").data('url')
  $("#antigens").DataTable
    'destroy': true
    'searching': true
    'ajax':
      'url': url
    'processing': true
    'serverSide': true
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-antigen-batch-no'
        # 'col-antigen-validity'
        'col-antigen-edit'
      ]
    } ]
    "order": [[ 3, "desc" ]]
    start: 0

window.antigenDataTablesReset = () ->
  table = antigenDataTablesLoad()
  table.ajax.reload()