ready = ->
  $("body").on "click", ".cancel-form", (e) ->
    e.preventDefault()
    form = $(this).data('form')
    $("#form_#{form}").empty()

  $("body").on "click", ".add-production-system-btn", (e) ->
    $('#register_production_system').removeClass 'hide'
    $('#edit_production_system, #edit_sector, #register_sector').addClass 'hide'

  $("body").on "click", ".edit-production-system-btn", (e) ->
    $('#edit_production_system').removeClass 'hide'
    $('#register_production_system, #edit_sector, #register_sector').addClass 'hide'

  $("body").on "click", "#production_system_tab , #vaccine_tab", (e) ->
    $(".cancel-form").click()

  $("body").on "click", "#sector_tab", (e) ->
    $('#edit_production_system, #register_production_system').addClass 'hide'

$(document).on 'turbolinks:load', ready