import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modalContent", "card", "cardPill"]

  newEvent(event) {
    const [data] = event.detail
    this.modalContentTarget.innerHTML = data.content
    $(this.modalContentTarget.querySelector(".modal")).modal("show")
  }

  showCard(event) {
    const [data] = event.detail
    this._renderCard(data)
  }

  prevCard(event) {
    const [data] = event.detail
    this._renderCard(data)
    this._activedCard("prev")
  }

  nextCard(event) {
    let [data] = event.detail

    if (typeof data === "object") {
      this._renderCard(data)
      this._activedCard("next")
    }
  }

  navErrors(event) {
    const [data] = event.detail
    const el = event.currentTarget

    el.insertAdjacentHTML(
      "beforebegin",
      `<div id="quiz-alert" class="alert alert-danger border-0" role="alert">${data.message}</div>`
    )

    setTimeout(() => {
      document.getElementById("quiz-alert").remove()
    }, 4000)
  }

  // Private

  _renderCard(data) {
    this.cardTarget.innerHTML = data.content
  }

  _activedCard(nav) {
    let actived
    let navLinks = this.cardPillTarget.querySelectorAll(".nav-link")

    for (let i = 0; i < navLinks.length; i++) {
      if (!navLinks[i].classList.contains("active")) continue

      let current = i
      navLinks[i].classList.remove("active")

      if (nav === "prev") {
        current -= 1
        if (current < 1) current = 0
      } else {
        current += 1
        if (current > navLinks.length) current = i
      }

      actived = navLinks[current]
    }

    actived.classList.remove("disabled")
    actived.classList.add("active")
  }
}
