ready = ->
  if $('#news_feeds').length > 0
    tables = $('.news-feeds-datatable').DataTable(
      "ordering": false,
      "dom": '<"top">rt<"bottom"i>p<"clear">'
    )

    $('body').on 'keyup', '#news_feed_search', () ->
      tables.tables().search($(this).val()).draw()

  $("body").on "click", ".remove-picture-btn", (e) ->
    e.preventDefault()

    wrapper = $(this).closest(".news-feed-picture")
    template = $("#remove_news_feed_picture_template").html()

    if wrapper.hasClass "new-news-feed-picure"
      wrapper.remove()
    else
      id = wrapper.attr("data-id")
      data =
        id: id

      wrapper.replaceWith(Mustache.render(template, data))

    $("#news_feed_picture_content").find(".news-feed-picture").each (v) ->
      $(this).find(".news-feed-picture-order").html(v+1)

  $("body").off("click", ".upload-news-feed-picture-btn").on "click", ".upload-news-feed-picture-btn", (e) ->
    e.preventDefault()

    id = (new Date).getTime()
    template = $("#news_feed_picture_template").html()

    pictures_wrapper = $("#news_feed_picture_content")
    picture_count = pictures_wrapper.find(".news-feed-picture").length

    data =
      id: id
      order: picture_count + 1

    pictures_wrapper.append(Mustache.render(template, data))

  $("body").off("change", ".upload-pict-news-feed").on "change", ".upload-pict-news-feed", (e) ->
    wrapper = $(this).closest(".news-feed-picture")
    img_src = wrapper.find(".preview-pict-news-feed")
    # preview image
    input = $(e.currentTarget)
    file = input[0].files[0]
    reader = new FileReader()
    reader.onload = (ev) ->
      image_base64 = ev.target.result
      img_src.attr("src", image_base64)
    reader.readAsDataURL(file)

$(document).on 'turbolinks:load', ready