# Find all selected sequences by <selector> in a <selected_container>
window.selectedSequences = (selected_container, selector) ->
  selected_container.find selector

# Find all selected sequences by <selector> in a <selected_container> and map the value
window.selectedSequencesIds = (selected_container, selector) ->
  selected_seq_ids = selectedSequences(selected_container, selector).map(->
    $(this).val()
  ).get()

ready = ->
  $("body").on "click", "#download_unsave_collection", (e) ->
    e.preventDefault()
    $this = $(this)
    sequence_ids = selectedSequencesIds($("#selected_sequences"), ".selected-item:checked")

    window.open($(this).data("url") + "?ids=" + JSON.stringify(sequence_ids))
    return false

  htmlCanvasHeight = 800
  htmlCanvasWidth= 800
  constructPhylogeneticTreeSVG = (collection_id) ->
    $collection_tab = $("#phylogenetic_tree_canvas_" + collection_id)
    $svg = $collection_tab.find(".collection_view_container").find("svg#show_phylo_tree");
    htmlCanvasWidth = parseInt($svg.attr("width"));
    htmlCanvasHeight = parseInt($svg.attr("height"));
    svg = $svg.clone();
    $buffer = $collection_tab.find(".svg_buffer")
    $buffer.html(svg);
    $buffer.find(".clade-label.hide").remove();
    $buffer.find("svg")
      # .attr("style", "")
      # .attr("transform", "scale(2,2)")
      # .attr("width", htmlCanvasWidth)
      # .attr("height", htmlCanvasHeight)

  constructPhylogeneticTreeCanvas = (collection_id) ->
    $collection_tab = $("#phylogenetic_tree_canvas_" + collection_id)
    constructPhylogeneticTreeSVG(collection_id)
    svg = $collection_tab.find(".svg_buffer").html()
    c =  $collection_tab.find(".phylogenetic_tree_canvas")[0]
    c.width = htmlCanvasWidth * 2;
    c.height = htmlCanvasHeight * 2;
    ctx = c.getContext('2d')
    ctx.fillStyle = "rgb(255,255,255)"
    ctx.fillRect(0,0,htmlCanvasWidth * 2, htmlCanvasHeight * 2)
    ctx.fillStyle = "rgb(0,0,0)"
    ctx.drawSvg(svg, 0, 0, htmlCanvasWidth * 2, htmlCanvasHeight * 2)

  addInlineStyling = (elements) ->
    if elements and elements.length
      elements.forEach (d) ->
        d3.selectAll(d.el).each ->
          element = this
          if d.properties and d.properties.length
            d.properties.forEach (prop) ->
              computedStyle = getComputedStyle(element, null)
              value = computedStyle.getPropertyValue(prop)
              element.style[prop] = value

  downloadPhylogeneticTree = (type, collection_id) ->
    addInlineStyling([
      {el: '.branch', properties: ['fill', 'stroke', 'stroke-width']},
      {el: '.node', properties: ['font']},
      {el: '.branch-tracer', properties: ['stroke', 'stroke-dasharray', 'stroke-width']}
    ]);

    $collection_tab = $("#phylogenetic_tree_canvas_" + collection_id)
    mimes = {
      "jpg": "image/jpeg",
      "png": "image/png",
      "pdf": "application/pdf"
    }
    constructPhylogeneticTreeCanvas(collection_id)
    canvas = $collection_tab.find(".phylogenetic_tree_canvas")[0]
    if type == "svg"
      svg =  $collection_tab.find(".svg_buffer").html()
      b64 = btoa(svg)
      data_uri = "data:image/svg+xml;base64,\n" + b64
    else if type == "pdf"
      jpg = canvas.toDataURL(mimes["jpg"])
      orientation = "l"
      if (htmlCanvasHeight > htmlCanvasWidth)
        orientation = "p"
      # console.log(htmlCanvasHeight > htmlCanvasWidth, htmlCanvasHeight, htmlCanvasWidth, orientation)
      doc = new jpdf(orientation, "px", [htmlCanvasWidth * 2, htmlCanvasHeight * 2])
      doc.addImage(jpg, 'JPEG', 0, 0, htmlCanvasWidth * 2, htmlCanvasHeight * 2)
      data_uri = doc.output("datauristring")
    else
      data_uri = canvas.toDataURL(mimes[type])
    filename =  $collection_tab.find(".filename").val();
    link = document.createElement('a');
    link.download = filename + "." + type;
    link.href = data_uri;
    # console.log(link.href);
    link.click();

  $("body").on "click", "#toggle_phylogenetic_tree_options", (e) ->
    e.preventDefault()
    block = $(this).closest(".collection-show-wrapper")
    target = block.find("#phylogenetic_tree_options")
    target.toggle('slide', {easing: 'easeOutQuint', direction: 'up'}, 500);

  $("body").on "click", ".download_tree_as_png", (e) ->
    e.preventDefault()
    collection_id = $(this).attr("collection-id")
    downloadPhylogeneticTree("png", collection_id)

  $("body").on "click", ".download_tree_as_jpeg", (e) ->
    e.preventDefault()
    collection_id = $(this).attr("collection-id")
    downloadPhylogeneticTree("jpg", collection_id)

  $("body").on "click", ".download_tree_as_pdf", (e) ->
    e.preventDefault()
    collection_id = $(this).attr("collection-id")
    downloadPhylogeneticTree("pdf", collection_id)

  $("body").on "click", ".btn_generate_tree", (e) ->
    wrapper= $(".collection-show-wrapper")
    wrapper.find(".submit-tree-option-form").click()

$(document).on 'turbolinks:load', ready