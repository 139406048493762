function getMaxIsolates(data){
  array_values = Object.values(data.districts);
  array_sum = array_values.map(function(val){
    return parseInt(val.h5n1_size)+parseInt(val.other_size);
  });
  return d3.max(array_sum)
}

function initializeMapContent (districtJson, canvas, height, width, loadingMessage, tooltips) {
  loadingMessage.remove()
  max_isolates = getMaxIsolates(districtJson);

  const projection = d3.geoMercator().scale(width*1.23).translate([-width*2.03, (height)/3])
  const path = d3.geoPath().projection(projection)

  const group = canvas.append('g')

  d3.json("/outbreaks/indonesia_districts_2018.json").then(data => {
    group.selectAll("path")
    .data(data.features)
    .enter()
    .append("path")
      .attr("cursor", "pointer")
      .attr("d", path)
      .style("stroke", "#ffffff")
      .style("stroke-width", .2)
      .attr('fill', '#e2e8f0')
      .on('mouseover', (d, i) => {
        const matchedDistrict = districtJson.districts[d.properties.CC_2]
        if (matchedDistrict !== undefined) {
          let content = ''
          content += `<div class='font-weight-bold mb-2'>${d.properties.NAME_2}</div>`
          content += `<div>H5N1 = <b>${matchedDistrict.h5n1_size}</b> Isolate</div>`
          content += `<div>Others = <b>${matchedDistrict.other_size}</b> Isolate</div>`
          tooltips.transition()
            .duration(200)
            .style("opacity", .95);
          tooltips.html(content)
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px");
        }
      })
      .on('mouseout', () => {
        tooltips.transition()
          .duration(500)
          .style("opacity", 0)
      })


    circle = canvas.selectAll("g.dis")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis")
      .attr("id", function (d){
        return d.properties.CC_2;
        }
      )
    circle.append('svg:circle')
      .attr('class', 'map-circle')
      .attr('cx', function(d){ return path.centroid(d)[0] })
      .attr('cy', function(d){ return path.centroid(d)[1] })
      .attr('r', function(d){
        const matchedDistrict = districtJson.districts[d.properties.CC_2];
        radius_size = 0;
        if (matchedDistrict !== undefined) {
          radius_size = 3.5;
        }
        return radius_size;
      })
      .style('opacity', 1)
      .attr('fill', '#21c87a')

    circle.append('svg:circle')
      .attr('class', 'map-circle-radius')
      .attr('cx', function(d){ return path.centroid(d)[0] })
      .attr('cy', function(d){ return path.centroid(d)[1] })
      .attr('r', function(d){
        const matchedDistrict = districtJson.districts[d.properties.CC_2];
        if (matchedDistrict !== undefined) {
          total_isolates = matchedDistrict.other_size + matchedDistrict.h5n1_size;
        } else {
          total_isolates = 0;
        }
        const stroke_size = (2 / max_isolates) * total_isolates;
        return stroke_size;
      })
      .style('opacity', .3)
      .attr('fill', '#21c87a')
      .on('mouseover', (d, i) => {
        const matchedDistrict = districtJson.districts[d.properties.CC_2]
        if (matchedDistrict !== undefined) {
          let content = ''
          content += `<div class='font-weight-bold mb-2'>${d.properties.NAME_2}</div>`
          content += `<div>H5N1 = <b>${matchedDistrict.h5n1_size}</b> Isolate</div>`
          content += `<div>Others = <b>${matchedDistrict.other_size}</b> Isolate</div>`
          tooltips.transition()
            .duration(200)
            .style("opacity", .95);
          tooltips.html(content)
            .style("left", (d3.event.pageX) + "px")
            .style("top", (d3.event.pageY - 28) + "px");
        }
      })
      .on('mouseout', () => {
        tooltips.transition()
          .duration(500)
          .style("opacity", 0)
      })
  })

  function handleZoom (eventTransform) {
    // canvas.selectAll("g").transition().duration(100).attr("transform", eventTransform)
    canvas.selectAll("g").attr("transform", d3.event.transform);
    zoom_scale = parseFloat(1) / d3.event.transform.k
    canvas.selectAll(".map-circle")
      .attr('r', function (d) {
        const matchedDistrict = districtJson.districts[d.properties.CC_2];
        radius_size = 0;
        if (matchedDistrict !== undefined) {
          radius_size = 3.5;
          total_isolates = matchedDistrict.other_size + matchedDistrict.h5n1_size;
        }
        return radius_size*zoom_scale;
      })
  }

  const zoom = d3.zoom()
    .on('zoom', () => { handleZoom(d3.event.transform) })

  canvas.call(zoom)
  canvas.call(zoom).on("dblclick.zoom", null)

  d3.selectAll('.zoom-control').on('click', function () {
    if (this.id == 'zoom_in') {
      zoom.scaleBy(canvas, 0.7)
    } else if (this.id == 'zoom_out') {
      zoom.scaleBy(canvas, 0.7)
    } else {
      canvas.call(zoom.transform, d3.zoomIdentity)
    }
  })
}

function loadMap () {
  $('#isolate_map_home').html('')
  const width = $("#isolate_map_home").width()
  const height = width/2.5
  const canvas = d3.select("#isolate_map_home").append("svg")
    .attr("width", "100%")
    .attr("height", `${height}px`)
    .style("background-color", "#f6f8fa")
    .style("border-radius", "inherit")

  const loadingMessage = canvas.append('text')
    .attr('x', '50%')
    .attr('y', '50%')
    .attr('dominant-baseline', 'middle')
    .attr('text-anchor', 'middle')
    .text('LOADING MAP...')

  const tooltips = d3.select('body').append('div')
    .attr('class', 'tooltip-isolate')
    .style('opacity', 0)

  d3.json("/home.json").then(data => {
    initializeMapContent(data, canvas, height, width, loadingMessage, tooltips)
  })
}

document.addEventListener('turbolinks:load', () => {
  if ($('#isolate_map_home').length > 0){
    loadMap()
  }
})