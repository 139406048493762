/* -----------------------------------------------------------------------------
//
// Language:       Javascript
// Script/Module:  comparison-map.js
// Author:         Ivano Broz
// Created:        07-Nov-2013
//
// Description:    d3.js based graph renderer for plotting a comparison between
//                 antigenic and genetic distances for influenza isolates
//
// Change Log:
//
// -------------------------------------------------------------------------- */




function ComparisonPlot(coordfileUri, scaleX, scaleY) {

	// Initial configuration
	var first_pass = true, width = 850, height = 700, margin = 10, glyph_size = 6;
	var left_margin = 5*margin, bottom_margin = 4*margin;
	
	// Set scales
	var x = d3.scale.linear().domain([0,scaleX]).range([left_margin, width-margin]);
	var y = d3.scale.linear().domain([0,scaleY]).range([height-bottom_margin, margin]);

	
	// Provide internal reference to this object
	var compareplot = this;
	
	// Prepare Canvas
	this.zoom = null;
	this.svghdl = null;
	//	this.svghdl = setupCanvas.call(this,width, height, xmargin, ymargin, scaleX, scaleY);
	
	// Prepare datasets and controls
	var point_data, legend_data;
	
	var control_glyph_colour = "green";
	var anchor_glyph_colour  = "blue";
	var isolate_glyph_colour = "red";
	

	// Import data
	d3.tsv(coordfileUri, function(tsv) {
		point_data = tsv;
		compareplot.zoom = d3.behavior.zoom().scaleExtent([1,8]).on("zoom", zoomer);
		compareplot.svghdl = setupCanvas.call(compareplot);

		point_data = point_data.sort(function(a,b){
			if (a.isolate_name.toLowerCase() < b.isolate_name.toLowerCase()) return -1;
			if (a.isolate_name.toLowerCase() > b.isolate_name.toLowerCase()) return 1;
			return 0;
		});

		// fill isotales filter
		var $isolates = $("#isolates");
		$isolates.html("");
		for (var i=0; i<point_data.length; i++) {
			var isolate = point_data[i];
			$isolates.append("\
			<tr>\
	  		<td>\
	  			<input type='checkbox' value='" + isolate.id + "' name='isolate-checkbox' isolate-name='" + isolate.isolate_name + "' checked>\
	  		</td>\
	  		<td>" + isolate.isolate_name + "</td>\
	  	</tr>\
			");
		}
		
		// Plot points
		update();
			
// Establish the static component of the Legend
//	installStaticLegend();
			
	});
	
	// Filter the dataset based on the controls selection
	//
	function filteredDataset() {
		
		// Start by filtering out the data which is not to be shown
//		var filtered_data = [];
//	
//		var visible = show_controls[0][0].checked;
//		point_data.forEach(function(d) {
//			if (visible || d.control == null) {
//				filtered_data = filtered_data.concat(d);
//			}
//		});
//		
//		return filtered_data;
		
		return point_data;
	}

	
	// Draw data plot
	//
	function update() {
		
		// Update nodes set
		var nodes = filteredDataset();

		// console.log(nodes)
		
		var node = compareplot.svghdl.selectAll(".sample")
			.data(nodes, function(d) { return d.antigen; });
		
		// Process the enter selection to add any new glyphs
		// defined by the data
		var nodeEnter = node.enter()
			.append("g")
			.attr("class", "sample")
			.attr("isolate-name", function(d) { return d.isolate_name; })
			.attr("id", function(d) { return d.antigen; });
		
		if (first_pass) {
			nodeEnter.attr("transform", "translate("+ (left_margin) +"," + (height-bottom_margin) + ")");
		} else {
			nodeEnter
				.attr("transform", function(d) { 
					return "translate(" + x(+d.x) + "," + y(+d.y) + ")";
				});
		}
		
		// Create glyphs
		
		
		// Isolates
		nodeEnter
			.append("circle")
			.attr("class", "glyph")
			.attr("r", glyph_size);


		nodeEnter.append("text")
			.attr("text-anchor", "start")
			.attr("x", "10")
			.attr("dy", "0.4em")
			.text(function(d) { return d.isolate_name; });
		
		
		// Update all existing nodes.  By processing the enter selection nodes first
		// they are included in the update selection so all nodes for this cycle can be
		// processed at the same time
		node.selectAll(".glyph")
			.style("fill", function(d) { 
				return isolate_glyph_colour;
			})
			.style("opacity", function(d) {
				return 0.7;
			});
		
		
		
		// Cleanup any dropped nodes
		node.exit().remove();
	
		// Move to final location
		if (first_pass == true) {
			node.transition().duration(1000)
				.attr("transform", function(d) { 
					return "translate(" + x(+d.x) + "," + y(+d.y) + ")";
				});
			first_pass = false;
		}
		
/*		
		// Update legend
		var dynamic_legend_data = [];
		var label;
		if (group_filter == 'year') {
			label = "Year";
			legend_data.year.forEach(function(year) {
				dynamic_legend_data = dynamic_legend_data.concat(
					{"id"     : year,
					 "colour" : year_colours(year),
					 "label"  : year}
				);
			});
		}
	
		if (group_filter == 'clade') {
			label = "Clade";
			legend_data.clade.forEach(function(clade) {
				dynamic_legend_data = dynamic_legend_data.concat(
					{"id"     : clade,
					 "colour" : clade_colours(clade),
					 "label"  : clade}
				);
			});
		}
		
		// Remove and replace any legend
		var legend_handle = installStaticLegend();
		
		if (group_filter != 'none') {
			legend_handle.append("text")
				.text(label)
				.attr("class", "dynamic_legend_title")
				.attr("text-anchor", "start")
				.attr("y", "90");
			
			var d_entries = legend_handle.selectAll(".d_entry")
				.data(dynamic_legend_data)
				.enter()
				.append("g")
				.attr("class", "d_entry")
				.attr("transform", function(d,i) {
					return "translate(" + (glyph_size + 0.5) + "," + (110 + i*glyph_size*3) + ")";
				});
			
			d_entries.append("circle")
				.attr("r", glyph_size)
				.style("fill", function(d) { return d.colour; })
				.style("opacity", "0.9");
				
			
			d_entries.append("text")
				.attr("text-anchor", "start")
				.attr("x", (25 - glyph_size))
				.attr("dy", "0.35em")
				.text(function(d) {return d.label;});
		}
*/
	}
	
	
	
	function zoomer() {
		compareplot.svghdl.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
	}
	

	// Define the canvas
	//
	function setupCanvas() {
		
		// Declare canvas
		var svg = d3.select(".plotbox")
			.text("")
			.style("display", "block")
			.append("svg")
			.attr("width", width)
			.attr("height", height)
		.append("g")
			.call(this.zoom);
		
		svg.append("rect")
			.attr("class", "overlay")
			.attr("width", width)
			.attr("height", height);
	
		var xAxis = d3.svg.axis()
			.scale(x)
			.orient("bottom");
		
		var yAxis = d3.svg.axis()
			.scale(y)
			.orient("left");
		
		svg.append("g")
			.attr("class", "axis")
			.attr("transform", "translate(0," + (height - bottom_margin) + ")")
			.call(xAxis);
		
		svg.append("g")
			.attr("class", "axis")
			.attr("transform", "translate(" + (left_margin) + ",0)")
			.call(yAxis);
		
		// Grid
		var xgap = scaleX/8, ygap = scaleY/8;
		
		svg.selectAll(".h").data(d3.range(0,(scaleY + ygap),ygap)).enter()
			.append("line").classed("h",1)
			.attr("x1", left_margin).attr("x2", width-margin)
			.attr("y1",y).attr("y2",y);
			
		svg.selectAll(".v").data(d3.range(0,(scaleX + xgap),xgap)).enter()
			.append("line").classed("v",1)
			.attr("y1", margin).attr("y2", height-bottom_margin)
			.attr("x1",x).attr("x2",x);
		
		
		// Add diagonal
		svg.append("line").classed("diag",1)
			.attr("y1", height-bottom_margin).attr("y2", margin)
			.attr("x1", left_margin).attr("x2", width-margin);
		
		// Label Axes
		svg.append("text")
			.attr("class", "axis-label")
			.attr("text-anchor", "middle")
			.attr("transform", "translate(" + (margin) + "," + ((height/2) - bottom_margin - margin) + ") rotate(-90)")
			.text("Antigenic Distance");
		
		svg.append("text")
			.attr("class", "axis-label")
			.attr("text-anchor", "middle")
			.attr("transform", "translate(" + ((width/2) + left_margin - margin) + "," + (height) + ")")
			.text("Genetic Distance");
		
		return svg;
	}
	
	
}
