ready = ->
  $("body").on "click", ".add-vaccine-btn", (e) ->
    $('#register_vaccine').removeClass 'hide'
    $('#edit_vaccine, #edit_manufacture, #register_manufacture').addClass 'hide'

  $("body").on "click", ".edit-vaccine-btn", (e) ->
    $('#edit_vaccine').removeClass 'hide'
    $('#register_vaccine, #edit_manufacture, #register_manufacture').addClass 'hide'

  $("body").on "click", "#vaccine_tab", (e) ->
    $('#edit_manufacture, #register_manufacture').addClass 'hide'

  $("body").on "click", "#manufacture_tab", (e) ->
    $('#edit_vaccine, #register_vaccine').addClass 'hide'

$(document).on 'turbolinks:load', ready