# initialzie
month_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

# ======================== ANNUALLY ================================

window.checkNumberOfStaffProgressPerMonth = (data, m) ->
  noi = 0; # number of isolates
  $.each data, (key, val) ->
    if (m == val.month)
      noi += val.total_isolates
      return noi
  return noi

window.createUserAnnuallProgressJSON = (data, y) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  year = new Date().getFullYear()
  month = new Date().getMonth()

  # get filter's date
  start_month = 1
  end_month = 12

  i = start_month
  json_for_staff_progress = "["
  while i <= end_month
    new_i = ""
    if i < 10
      new_i = "0" + i
    else
      new_i = i
    json_for_staff_progress += "{ \"month\": \"#{y}-"+new_i+"\", "
    staff_progress_per_month = 0
    json_for_staff_progress += " \"counter\": "
    staff_progress_per_month = checkNumberOfStaffProgressPerMonth(trueJson, (y+"-"+new_i))
    json_for_staff_progress += "#{staff_progress_per_month}"

    json_for_staff_progress += "},"
    i++ # to the next month!

  json_for_staff_progress = json_for_staff_progress.substring(0, json_for_staff_progress.length - 1)
  json_for_staff_progress += "]"

  console.log json_for_staff_progress
  return json_for_staff_progress

window.userProgressIsolatesChart = (data, data_year) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  year = new Date().getFullYear()
  month = new Date().getMonth()

  # get filter's date
  start_month = 1
  end_month = 12

  # create month data for x axis
  array_month = []
  i = start_month
  while i <= end_month
    # create month format (ex. march -> 03)
    array_month.push("#{month_arr[(i-1)]} #{data_year}")
    i++

  margin = {top: 20, right: 30, bottom: 50, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  max_data = 50
  max_data = d3.max(trueJson, (d) -> (d.counter))

  x = d3.scale.ordinal()
    .domain(array_month)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#staff_contribution_chart_#{data_year}").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")


  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/3))
    .text("Number of Isolates")


  color_additional = ['#6633cc', '#009900', '#f9e55b', '#f1219d', '#000177', '#ff0000', '#ff8c00', '#3ebcf7', '#fecce5', '#c5ec1f', '#fe0000']
  # staff_progress = d3.svg.line()
  #   .x((d) -> x(d.month)+(x.rangeBand()/2))
  #   .y((d) -> y(d.counter))

  # staff_progress_line = svg.append("g")
  #   .attr("class", "line-staff-progress")
  # staff_progress_line.append("path")
  #   .attr("fill", "none")
  #   .attr("d", staff_progress(trueJson))
  #   .attr("stroke", "#800000")
  #   .attr("stroke-width", 3)

  barStaffProgress = svg.append("g")
    .attr("class", "g-staff-progress")
  barStaffProgress.selectAll(".bar-staff-progress")
    .data(trueJson)
    .enter().append("rect")
      .attr("class", "bar-staff-progress")
      .attr("x", (d) -> return x(d.month) )
      .attr("width", x.rangeBand())
      .attr("y", (d) -> return y(d.counter))
      .attr("height", (d) -> return height-y(d.counter))
      .style("fill", "#6633cc")
      # .tooltip (d) ->
      #   return {
      #     type: "tooltip",
      #     title: "Object",
      #     text: "<div>#{counter} isolate(s)</div>",
      #     trigger: "mouseover",
      #     detection: "shape",
      #     placement: "fixed",
      #     gravity: "right",
      #     position: [parseFloat(x(d.month)), parseFloat(y(d.counter))],
      #     displacement: [50, 0],
      #     mousemove: false
      #   }

  # console.log trueJson
  # legend = svg.append("g")
  #   .attr("class", "legend")

  # $.each vet_labs_name, (key_vet, val_vet) ->
  #   legendIsolatesPerDIC = legend.append("g")
  #     .attr("class", "g-legend-#{key_vet}")
  #   legendIsolatesPerDIC.append("rect")
  #     .attr("class", "bar-legend-#{key_vet}")
  #     .attr("height", "10px")
  #     .attr("width", "30px")
  #     .attr("x", margin.left)
  #     .attr("y", height+55+(key_vet*25))
  #     .style("fill", color_test[key_vet])
  #   legendIsolatesPerDIC.append("text")
  #     .attr("class", "txt-legend-#{key_vet}")
  #     .text("#{val_vet}")
  #     .attr("dx", margin.left+40)
  #     .attr("dy", height+65+(key_vet*25))


# ======================== ALL YEAR ================================
window.checkNumberOfStaffProgressPerYear = (data, y) ->
  noi = 0; # number of isolates
  $.each data, (key, val) ->
    year = parseInt(val.month.substring(0, 4))
    if (y == year)
      noi += val.total_isolates
      return noi
  return noi

window.createUserAllProgressJSON = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  year = new Date().getFullYear()

  # get filter's date
  start_year = 2011
  end_year = year

  i = start_year
  json_for_staff_progress = "["
  while i <= end_year
    json_for_staff_progress += "{ \"year\": \"#{i}\", "
    staff_progress_per_year = 0
    json_for_staff_progress += " \"counter\": "
    staff_progress_per_year = checkNumberOfStaffProgressPerYear(trueJson, i)
    json_for_staff_progress += "#{staff_progress_per_year}"
    json_for_staff_progress += "},"
    i++ # to the next year!

  json_for_staff_progress = json_for_staff_progress.substring(0, json_for_staff_progress.length - 1)
  json_for_staff_progress += "]"

  # console.log json_for_staff_progress
  return json_for_staff_progress

window.userAllProgressIsolatesChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  year = new Date().getFullYear()

  # get filter's date
  start_year = 2011
  end_year = year

  # create month data for x axis
  array_year = []
  i = start_year
  while i <= end_year
    array_year.push("#{i}")
    i++

  margin = {top: 20, right: 30, bottom: 50, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  max_data = 50
  max_data = d3.max(trueJson, (d) -> (d.counter))

  x = d3.scale.ordinal()
    .domain(array_year)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#staff_contribution_chart_per_year").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/3))
    .text("Number of Isolates")


  color_additional = ['#6633cc', '#009900', '#f9e55b', '#f1219d', '#000177', '#ff0000', '#ff8c00', '#3ebcf7', '#fecce5', '#c5ec1f', '#fe0000']
  # staff_progress = d3.svg.line()
  #   .x((d) -> x(d.month)+(x.rangeBand()/2))
  #   .y((d) -> y(d.counter))

  # staff_progress_line = svg.append("g")
  #   .attr("class", "line-staff-progress")
  # staff_progress_line.append("path")
  #   .attr("fill", "none")
  #   .attr("d", staff_progress(trueJson))
  #   .attr("stroke", "#800000")
  #   .attr("stroke-width", 3)

  barStaffProgress = svg.append("g")
    .attr("class", "g-staff-progress")
  barStaffProgress.selectAll(".bar-staff-progress")
    .data(trueJson)
    .enter().append("rect")
      .attr("class", "bar-staff-progress")
      .attr("x", (d) -> return x(d.year) )
      .attr("width", x.rangeBand())
      .attr("y", (d) -> return y(d.counter))
      .attr("height", (d) -> return height-y(d.counter))
      .style("fill", "#6633cc")
      # .tooltip (d) ->
      #   return {
      #     type: "tooltip",
      #     title: "Object",
      #     text: "<div>#{counter} isolate(s)</div>",
      #     trigger: "mouseover",
      #     detection: "shape",
      #     placement: "fixed",
      #     gravity: "right",
      #     position: [parseFloat(x(d.month)), parseFloat(y(d.counter))],
      #     displacement: [50, 0],
      #     mousemove: false
      #   }

  # console.log trueJson
  # legend = svg.append("g")
  #   .attr("class", "legend")

  # $.each vet_labs_name, (key_vet, val_vet) ->
  #   legendIsolatesPerDIC = legend.append("g")
  #     .attr("class", "g-legend-#{key_vet}")
  #   legendIsolatesPerDIC.append("rect")
  #     .attr("class", "bar-legend-#{key_vet}")
  #     .attr("height", "10px")
  #     .attr("width", "30px")
  #     .attr("x", margin.left)
  #     .attr("y", height+55+(key_vet*25))
  #     .style("fill", color_test[key_vet])
  #   legendIsolatesPerDIC.append("text")
  #     .attr("class", "txt-legend-#{key_vet}")
  #     .text("#{val_vet}")
  #     .attr("dx", margin.left+40)
  #     .attr("dy", height+65+(key_vet*25))


window.showStaffContributionChart = (user_id, path) ->
  $.ajax
    url: path
    dataType: "script"
    method: "GET"
    data:
      user_id: user_id

window.vetLabProgressIsolatesChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  year = new Date().getFullYear()
  month = new Date().getMonth()

  # get filter's date
  end_year = year
  end_month = month
  start_year = year - 2
  start_month = month + 1

  # create month data for x axis
  array_month = []
  i = start_year
  j = start_month
  while i <= end_year
    # create month format (ex. march -> 03)
    array_month.push("#{month_arr[(j-1)]} #{i}")
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  margin = {top: 20, right: 30, bottom: 50, left: 40}
  width = $(".col-md-12").width() - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  max_data = 50
  max_data = d3.max(trueJson, (d) -> (d.counter))

  x = d3.scale.ordinal()
    .domain(array_month)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#vet_lab_contribution_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/3))
    .text("Number of Isolates")

  vet_lab_progress = d3.svg.line()
    .x((d) -> x(d.month)+(x.rangeBand()/2))
    .y((d) -> y(d.counter))

  vet_lab_progress_line = svg.append("g")
    .attr("class", "line-vet-lab-progress")
  vet_lab_progress_line.append("path")
    .attr("fill", "none")
    .attr("d", vet_lab_progress(trueJson))
    .attr("stroke", "#009900")
    .attr("stroke-width", 3)

window.staffIsolatesPieChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  total_all_isolates = d3.sum(trueJson, (d) -> d.total_isolates)

  width = 300
  height = 300
  innerRadius = 0
  outerRadius = Math.min(width, height) / 2

  color = d3.scale.category10()

  svg = d3.select("#staff_isolates_pie_chart")
    .append("svg")
    .attr("width", width)
    .attr("height", height)

  arc = d3.svg.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)

  pie = d3.layout.pie()
    .value((d) -> d.total_isolates)
    .sort(null)

  arcs = svg.selectAll("g.arc")
    .data(pie(trueJson))
    .enter()
    .append("g")
    .attr("class", "arc")
    .attr("transform", (d) -> return "translate("+outerRadius+","+outerRadius+")")

  arcs.append("path")
    .attr("fill", (d,i) -> color(d.data.user_id))
    .attr("d", arc)
    .on("mouseover", (d) ->
      if d.data.user_id is null
        staff_name = "Unknown"
      else
        staff_name = $("#staff_#{d.data.user_id}").text()

      percentage_isolates = (d.value*100/total_all_isolates).toFixed(2)
      d3.select("#pie_tooltip")
        .style("left", d3.event.pageX + "px")
        .style("top", (d3.event.pageY-60) + "px")
        .style("opacity", 1)
        .select("#pie_detail")
        .text("#{staff_name} (#{percentage_isolates}%)")
    )
    .on("mouseout", () ->
      d3.select("#pie_tooltip")
        .style("opacity", 0)
    )

  arcs.append("text")
    .attr("transform", (d) -> return "translate("+arc.centroid(d)+")")
    .attr("text-anchor", "middle")
    .attr("fill", "white")
    .text((d) -> return d.value )

window.staffRunPieChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  total_all_run = d3.sum(trueJson, (d) -> d.total_run)

  width = 300
  height = 300
  innerRadius = 0
  outerRadius = Math.min(width, height) / 2

  color = d3.scale.category10()

  svg = d3.select("#staff_run_pie_chart")
    .append("svg")
    .attr("width", width)
    .attr("height", height)

  arc = d3.svg.arc()
    .innerRadius(innerRadius)
    .outerRadius(outerRadius)

  pie = d3.layout.pie()
    .value((d) -> d.total_run)
    .sort(null)

  arcs = svg.selectAll("g.arc")
    .data(pie(trueJson))
    .enter()
    .append("g")
    .attr("class", "arc")
    .attr("transform", (d) -> return "translate("+outerRadius+","+outerRadius+")")

  arcs.append("path")
    .attr("fill", (d,i) -> color(d.data.user_id))
    .attr("d", arc)
    .on("mouseover", (d) ->
      if d.data.user_id is null
        staff_name = "NULL"
      else
        staff_name = $("#staff_#{d.data.user_id}").text()
      percentage_run = (d.value*100/total_all_run).toFixed(2)
      d3.select("#pie_tooltip")
        .style("left", d3.event.pageX + "px")
        .style("top", (d3.event.pageY-60) + "px")
        .style("opacity", 1)
        .select("#pie_detail")
        .text("#{staff_name} (#{percentage_run}%)")
    )
    .on("mouseout", () ->
      d3.select("#pie_tooltip")
        .style("opacity", 0)
    )
  arcs.append("text")
    .attr("transform", (d) -> return "translate("+arc.centroid(d)+")")
    .attr("text-anchor", "middle")
    .attr("fill", "white")
    .text((d) -> return d.value )

window.renderVetlabList = () ->
  $('#register_vetlab').html('')
  $('#vet_lab_list_wrapper').removeClass('hide')
  $('#vet_lab_heading_wrapper').removeClass('hide')

ready = ->

  if $('#vet_labs').length > 0
    tables = $('.vetlabs-datatable').DataTable(
      "ordering": false,
      "dom": '<"top">rt<"bottom"i>p<"clear">'
    )

    $('body').on 'keyup', '#vetlab_search', () ->
      tables.tables().search($(this).val()).draw()

  $("body").on "click", ".show_staff_contribution", (e) ->
    e.preventDefault()
    user_id = $(this).data("userid")
    path = $(this).data("path")
    showStaffContributionChart(user_id, path)

  if $("#single_vet_lab_statistic_tab").length
    vet_lab_progress_json = $("#vet_lab_progress").val()
    # vet_lab_progress_json_new = createUserAnnuallProgressJSON(vet_lab_progress_json)
    all_user_isolate_progress_json = $("#all_user_isolate_progress").val()
    all_user_run_progress_json = $("#all_user_run_progress").val()
    # vetLabProgressIsolatesChart(vet_lab_progress_json_new)
    staffIsolatesPieChart(all_user_isolate_progress_json)
    staffRunPieChart(all_user_run_progress_json)

  $('body').on 'change', 'input[name="staff-contribution-type"]', ->
    if $(this).val() == "annualy"
      $('select[name="staff-contribution-year"]').removeClass("hide")
      year = $('select[name="staff-contribution-year"] option:selected').val()
      $("#staff_contribution_chart_#{year}").removeClass("hide")
      $('#staff_contribution_chart_per_year').addClass("hide")
    else
      $('select[name="staff-contribution-year"]').addClass("hide")
      $(".staff-contribution-chart").addClass("hide")
      $('#staff_contribution_chart_per_year').removeClass("hide")

  $('body').on 'change', 'select[name="staff-contribution-year"]', ->
    selected_year = $('select[name="staff-contribution-year"] option:selected').val()
    $(".staff-contribution-chart").addClass("hide")
    $("#staff_contribution_chart_#{selected_year}").removeClass("hide")

  $('body').on 'click', 'a.vetlab-area-province', (e) ->
    e.preventDefault()
    province_id = $(this).data("province")
    $('.districts-table').addClass('hide')
    $("#vet_lab_area_province_#{province_id}").removeClass("hide");

  $('body').on 'click', '.cancel-btn-vetlab', (e) ->
    e.preventDefault()
    renderVetlabList()

  # Validate antigen before delete
  $("body").on "click", ".vet-lab-delete", (e) ->
    if $(this).hasClass('already-used-vet-lab')
      alert("Can't delete this vet lab (DIC), because it is already used in other process.")
      e.preventDefault();
      return false
    else
      c = confirm('Are you sure want to delete this vet lab (DIC)?')
      if c == true
        # // The user pressed OK
        # // Do nothing, the link will continue to be opened normally
      else
        # // The user pressed Cancel, so prevent the link from opening
        e.preventDefault();
        return false

$(document).on 'turbolinks:load', ready
