# # Adds reader and writer methods for manipulating a value object:
window.checkedIsolates = []
window.checkedIsolatesOnForm = []
window.counterCheck = () ->
  if (checkedIsolates.length > 0)
    $("#isolate_group_selection").removeClass "hide"
  else
    # $("#isolate_group_selection").addClass "hide"
    $("#fetch_genbank_modal").modal('hide')
    $("#isolate_group_modal_").modal('hide')
  $(".selected-size").html("#{checkedIsolates.length} selected")
  return

window.renderCheckedIsolates = () ->
  $isolateTable = $(".isolate-table")
  $fetchFromGenbankIsolateList = $("#fetch_genbank_modal .isolate-list")
  $isolateGroupList = $(".isolate-group-list")
  $isolateGroupList.empty();
  $fetchFromGenbankIsolateList.empty();

  # ISOLATE GROUP LIST
  # for checkedIsolate in checkedIsolatesOnForm
  for checkedIsolate in checkedIsolatesOnForm
    isolateId = checkedIsolate.id
    isolateName = checkedIsolate.name

    isolateGroupCheckbox = "<div class='custom-control custom-checkbox'>"
    isolateGroupCheckbox += "<input type='checkbox' name='isolate_group[isolate_ids][]' value='#{isolateId}' isolate-name='#{isolateName}' isolate-id='#{isolateId}' id='isolate_group_form_id_#{isolateId}' class='isolate-on-form custom-control-input' checked>"
    isolateGroupCheckbox += "<label class='custom-control-label' for='isolate_group_form_id_#{isolateId}'>#{isolateName}</label>"
    isolateGroupCheckbox += "</div>"
    $isolateGroupListElement = $(isolateGroupCheckbox)
    $isolateGroupListElement.find("input:checkbox").click ->
      isolateId = $(this).attr("isolate-id")
      if !$(this).prop('checked')
        # $isolateTable.find("input:checkbox[name='isolate_group[isolate_ids][" + isolateId + "]'][value='" + isolateId + "']").prop("checked", false)
        $(this).closest('.checkbox').remove()
        # removeCheckedIsolateOnForm(isolateId)
        # counterCheck()
    $isolateGroupList.append($isolateGroupListElement)

  # FETCH FROM GENBANK
  for checkedIsolate in checkedIsolates
    isolateId = checkedIsolate.id
    isolateName = checkedIsolate.name

    checkboxInput = "<div class='custom-control custom-checkbox'>"
    checkboxInput += "<input type='checkbox' isolate-id='#{isolateId}' name='fetch_genbank[fetch_genbank_isolates_attributes][#{isolateId}][isolate_id]' value='#{isolateId}' isolate-name='#{isolateName}' checked class='isolate-on-fetch-genbank custom-control-input' id='fetch_genbank_isolate_list_#{isolateId}'>"
    checkboxInput += "<label class='custom-control-label' for='fetch_genbank_isolate_list_#{isolateId}'></label>"
    checkboxInput += "</div>"
    accessionNumberInput = "<input type='text' class='form-control form-control-sm' name='fetch_genbank[fetch_genbank_isolates_attributes][" + isolateId + "][accession_number]'/>"

    $fetchGenbankElement = $("<tr>\
      <td class='align-middle'>" + checkboxInput + "</td>\
      <td class='align-middle'>" + isolateName + "</td>\
      <td class='align-middle'>" + accessionNumberInput + "</td>\
    </tr>")
    $fetchGenbankElement.find("input:checkbox").click ->
      isolateId = $(this).attr("isolate-id")
      if !$(this).prop('checked')
        # $isolateTable.find("input:checkbox[name='isolate_group[isolate_ids][]'][value='" + isolateId + "']").prop("checked", false)
        $(this).closest('tr').remove()
        # removeCheckedIsolatesOnFetchGenBank(isolateId)
        # counterCheck()
    $fetchFromGenbankIsolateList.append($fetchGenbankElement)
  # counterCheck()

window.removeCheckedIsolate = (isolateId) ->
  # $("body").on "change", "input[name='isolate_group[isolate_ids][]']", ->
  for checkIsolate, index in checkedIsolates
    if "#{checkIsolate.id}" == "#{isolateId}"
      window.checkedIsolates.splice(index, 1)
      window.checkedIsolatesOnForm.splice(index, 1)
      break
  $("#isolate_group_isolate_ids_#{isolateId}").prop('checked', false)

window.checkIt = () ->
  wrapper = $(".isolate-table").find('tbody').find('tr')
  for checkIsolate, index in checkedIsolates
    wrapper.find("#isolate_group_isolate_ids_#{checkIsolate.id}").prop("checked", true)

window.setIsolateName = (data) ->
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  if is_isolated is "true"
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}})"
    val_old = "{{original_name}}"
  else
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}}) [NON-ISOLATE]"
    val_old = "{{original_name}} [NON-ISOLATE]"

  radio = "<div class='custom-control custom-radio ml-2'>"
  radio += "<input class='radio_buttons radio-on-change custom-control-input' id='isolate_name_ivm_standard_1' type='radio' name='isolate[ivm_standard_name]' value='#{val}'/>"
  radio += "<label class='custom-control-label' for='isolate_name_ivm_standard_1'>#{val}</label>"
  radio += "</div>"

  radio_old = "<div class='origin-name-radio disable-generated-name'>"
  radio_old += "<div>Will still searchable by original (pasted) name</div>"
  radio_old += "<div class='custom-control custom-radio ml-2'>"
  radio_old += "<input class='radio_buttons radio-on-change custom-control-input' id='isolate_name_ivm_standard_2' disabled='disabled' type='radio' name='isolate[ivm_standard_name]' value='#{val_old}'/>"
  radio_old += "<label class='custom-control-label' for='isolate_name_ivm_standard_2'>#{val_old}</label>"
  radio_old += "</div>"
  radio_old += "</div>"

  template = "<div class='generate-name-label'>Generated IVM standard naming</div>"
  template += "<div class='radio_buttons optional isolate_isolate_name generated-name'>"
  template += "<div class='py-1'>#{radio}</div>"
  template += "<div class='py-1'>#{radio_old}</div>"
  template += "</div>"

  Mustache.render(template, data)

window.setIsolateNameIndonesian = (data) ->
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  if is_isolated is "true"
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}})"
    val_alt = "{{flu_type}}/{{species}}/{{province}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}})"
    val_old = "{{original_name}}"
  else
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}}) [NON-ISOLATE]"
    val_alt = "{{flu_type}}/{{species}}/{{province}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}}) [NON-ISOLATE]"
    val_old = "{{original_name}} [NON-ISOLATE]"

  radio = "<div class='generate-name-label'>IVM generated name (option 1)</div>"
  radio += "<div class='custom-control custom-radio generated-name ml-2'>"
  radio += "<input class='radio_buttons radio-on-change custom-control-input' id='ivm_standard_name_option_1' type='radio' name='isolate[ivm_standard_name]' value='#{val}'/>"
  radio += "<label class='custom-control-label' for='ivm_standard_name_option_1'>#{val}</label>"
  radio += "</div>"

  radio_alt = "<div class='generate-name-label'>IVM generated name (option 2)</div>"
  radio_alt += "<div class='custom-control custom-radio generated-name ml-2'>"
  radio_alt += "<input class='radio_buttons radio-on-change custom-control-input' id='ivm_standard_name_option_2' type='radio' name='isolate[ivm_standard_name]' value='#{val_alt}'/>"
  radio_alt += "<label class='custom-control-label' for='ivm_standard_name_option_2'>#{val_alt}</label>"
  radio_alt += "</div>"

  radio_old = "<div class='origin-name-radio disable-generated-name'>"
  radio_old += "<div>Will still searchable by original (pasted) name</div>"
  radio_old += "<div class='custom-control custom-radio ml-2' disabled='disabled'>"
  radio_old += "<input class='radio_buttons radio-on-change custom-control-input' id='ivm_standard_name_option_3' disabled='disabled' type='radio' name='isolate[ivm_standard_name]' value='#{val_old}'/>"
  radio_old += "<label class='custom-control-label' for='ivm_standard_name_option_3'>#{val_old}</label>"
  radio_old += "</div>"
  radio_old += "</div>"

  template = "<div class='radio_buttons optional isolate_isolate_name'>"
  template += "<div class='py-1'>#{radio}</div>"
  template += "<div class='py-1'>#{radio_alt}</div>"
  template += "<div class='py-1'>#{radio_old}</div>"
  template += "</div>"

  Mustache.render(template, data)

window.setIsolateNameAbroad = (data) ->
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  if is_isolated is "true"
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}})"
    val_old = "{{original_name}}"
  else
    val = "{{flu_type}}/{{species}}/{{location}}/{{reference}}/{{year}}({{#h_type}}H{{h_type}}{{/h_type}}{{#n_type}}N{{n_type}}{{/n_type}}) [NON ISOLATE]"
    val_old = "{{original_name}} [NON ISOLATE]"

  radio = "<div class='generate-name-label'>IVM generated name</div>"
  radio += "<div class='custom-control custom-radio generated-name'>"
  radio += "<input class='radio_buttons radio-on-change custom-control-input' id='ivm_standard_name_option_1' type='radio' name='isolate[ivm_standard_name]' value='#{val}'/>"
  radio += "<label class='custom-control-label' for='ivm_standard_name_option_1'>#{val}</label>"
  radio += "</div>"

  radio_old = "<div class='generate-name-label origin-name-radio'>"
  radio_old += "<div>Use original (pasted) name</div>"
  radio_old += "<div class='custom-control custom-radio generated-name'>"
  radio_old += "<input class='radio_buttons radio-on-change custom-control-input' id='ivm_standard_name_option_2' type='radio' name='isolate[ivm_standard_name]' value='#{val_old}'/>"
  radio_old += "<label class='custom-control-label' for='ivm_standard_name_option_2'>#{val_old}</label>"
  radio_old += "</div>"
  radio_old += "</div>"

  template = "<div class='radio_buttons optional isolate_isolate_name'>"
  template += "<div class='py-1'>#{radio}</div>"
  template += "<div class='py-1'>#{radio_old}</div>"
  template += "</div>"

  Mustache.render(template, data)

window.updateExportFilter = () ->
  # get filter data
  serialize_params = $("#form_filter_isolate_data").find("input, select").serializeJSON()
  params_filter = $.param(serialize_params)
  new_params_filter = params_filter.split("utf8=%E2%9C%93&").pop();
  $("#export_isolates_xls, #export_isolates_xlsx").each ->
    old_url = $(this).attr('href')
    main_url = old_url.substring(0, old_url.indexOf('?'))
    new_url = main_url + "?" + new_params_filter
    $(this).attr("href", new_url)

window.filterDataOption = () ->
  my_data = $("#my_data").val()
  my_lab_data = $("#my_lab_data").val()
  all_data = $("#all_data").val()

  if my_data != "" && my_lab_data == "" && all_data == ""
    $(".my-data-option").prop('checked', true)

  if my_data == "" && my_lab_data != "" && all_data == ""
    $(".my-lab-data-option").prop('checked', true)

  if my_data == "" && my_lab_data == "" && all_data != ""
    $(".all-data-option").prop('checked', true)

window.isolateDataTablesLoad = () ->
  url = $(".isolate-table").data('url')
  $(".isolate-table").DataTable
    'destroy': true
    'searching': false
    'ajax':
      'url': url
      'data': $("#form_filter_isolate_data").find("input, select").serializeJSON()
    'processing': true
    'serverSide': true
    'fnDrawCallback': ->
      # show data table to the top of page when switch pagination
      $('html, body').animate { scrollTop: $('body').offset().top }, 'slow'
      filterDataOption()
      updateExportFilter()
      showHideTableColumn()
      isolateFilterLabel()
      $('.new-slide-panel').css 'display', 'none'
      checkIt()
      tooltipAndPopoverShow()
      # counterCheck()
      $(".isolate-modal").modal('hide')
      return
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-checkbox-column'
        'col-is-in-genbank'
        'col-options-column'
        'col-ag-data'
        'col-gen-data'
      ]
    } ]
    start: 0

window.isolateDataTablesReset = () ->
  table = isolateDataTablesLoad()
  table.ajax.reload()

window.clearFormFilterIsolate = () ->
  form = $("#form_filter_isolate_data")
  form.find("#by_start_created_start").val("")
  form.find("#by_end_created_end").val("")
  form.find("#by_year").val("")
  $(".chosen-select").val('').trigger("chosen:updated");
  form.find(".default-checked").prop("checked", true)
  form.find("#by_isolate_name_keyword").val("")
  form.find("#my_data").val("")
  lab_data_id = $(".my-lab-data-option").data('options')
  form.find("#my_lab_data").val(lab_data_id)
  form.find("#all_data").val("")
  $(".my-lab-data-option").prop('checked',true)
  $("#keyword_search").val("")
  $(".hxnx-select").val('')
  not_active_columns = ['6', '12', '13']
  $(".control-table-column").each ->
    value = $(this).val()
    if not_active_columns.includes(value)
      $(this).prop("checked", false)
    else
      $(this).prop("checked", true)

window.clearIsolateGroupFilter = () ->
  $("#group_option").closest(".radio").remove()
  $("#isolate_group_ids").html("")
  $("#title").html("Isolate List")
  $(".side-isolate-data-options").prop("disabled", false)

window.filterDataOptionOnClick = ($this) ->
  clearIsolateGroupFilter()
  if $this.val() is "my_data"
    options = $this.data("options")
    $("#my_data").val(options)
    $("#all_data").val("")
  else if $this.val() is "my_lab_data"
    options = $this.data("options")
    $("#my_lab_data").val(options)
    $("#all_data").val("")
  else
    $("#my_data").val("")
    $("#my_lab_data").val("")
    $("#all_data").val("true")

window.resetFormPCR = () ->
  $(".pcr-detail-form").find('.dot, .operator, .pcr-test-type, .pcr-machine').val("")

# autocomplete for district
window.isolateDistrictAutocomplete = () ->
  isolate_district = $(".district-autocomplete")
  isolate_district.typeahead {
    minLength: 3,
    highlight: true
  },
  {
    name: 'districts',
    limit: 10,
    display: 'district_name',
    templates: {
      notFound: "<div class='tt-suggestion'>District not found</div>"
    },
    source: (keyword_search, sync_process, async_process) ->
      $.ajax(
        url: '/en/districts',
        dataType: 'json',
        data: {
                by_vet_lab_id: {
                  keyword: keyword_search,
                  vet_lab_id: $("#isolate_vet_lab_id").val()
                }
              }
        success: (data) ->
          async_process(data)
      )
  }

  isolate_district.bind 'typeahead:select', (ev, suggestion) ->
    $("#isolate_district_id").val(suggestion.id)
    data = $('#isolate_form').data 'isolate-name'
    data.location = suggestion.district_name
    data.province = suggestion.province_name
    generateIsolateName(data)
  # isolate_district = $(".district-autocomplete").autocomplete
  #   minLength: 1
  #   source: (request, response) ->
  #     $.ajax
  #       url: $(".district-autocomplete").data("source")
  #       dataType: 'json'
  #       data:
  #         by_vet_lab_id:
  #           keyword: request.term
  #           vet_lab_id: $("#isolate_vet_lab_id").val()
  #       success: (data) ->
  #         response data
  #         return
  #     return
  #   select: (event, ui) ->
  #     $(".district-autocomplete").val ui.item.district_name
  #     # $("#isolate_district_id").val ui.item.id
  #     data = $('#isolate_form').data 'isolate-name'
  #     data.location = $(".district-autocomplete").val()
  #     data.province = "#{ui.item.province_name}"
  #     generateIsolateName(data)
  #     false
  #   response: (event, ui) ->
  #     $("#district_not_found").remove()
  #     if ui.content.length is 0
  #       $(".district-autocomplete").after "<div id='district_not_found' class='help-block'><span class='text-danger'>District not found</span></div>"
  #       # $("#isolate_district_id").val ""
  #     else
  #       $("#district_not_found").remove()
  #     return

  # # district autocomplete menu formater
  # unless isolate_district.data("ui-autocomplete") is undefined
  #   isolate_district.data("ui-autocomplete")._renderItem = (ul, item) ->
  #     content = "#{item.district_name}, #{item.province_name}"
      # $("<li>").append("<a>" + content + "</a>").appendTo ul

# autocomplete for subdistrict
window.isolateSubDistrictAutocomplete = () ->
  isolate_subdistrict = $(".sub-district-autocomplete")
  isolate_subdistrict.typeahead {
    minLength: 3,
    highlight: true
  },
  {
    name: 'sub_districts',
    limit: 10,
    display: 'area_name',
    templates: {
      notFound: "<div class='tt-suggestion'>Subdistrict not found</div>"
    },
    source: (keyword_search, sync_process, async_process) ->
      $.ajax(
        url: '/en/areas',
        dataType: 'json',
        data: {
                by_area_name: {
                  keyword: keyword_search
                  level: 3
                  district_id: $("#isolate_district_id").val()
                }
              }
        success: (data) ->
          async_process(data)
      )
  }

  isolate_subdistrict.bind 'typeahead:select', (ev, suggestion) ->
    $("#isolate_sub_district_id").val(suggestion.id)

  # isolate_subdistrict = $(".sub-district-autocomplete").autocomplete
  #   minLength: 1
  #   source: (request, response) ->
  #     $.ajax
  #       url: $(".sub-district-autocomplete").data("source")
  #       dataType: 'json'
  #       data:
  #         by_area_name:
  #           keyword: request.term
  #           level: 3
  #           district_id: $("#isolate_district_id").val()
  #       success: (data) ->
  #         response data
  #         return
  #     return
  #   select: (event, ui) ->
  #     $(".sub-district-autocomplete").val ui.item.area_name
  #     $("#isolate_sub_district_id").val ui.item.id
  #     false
  #   response: (event, ui) ->
  #     $("#sub_district_not_found").remove()
  #     if ui.content.length is 0
  #       $(".sub-district-autocomplete").after "<div id='sub_district_not_found' class='help-block'><span class='text-danger'>subdistrict not found</span></div>"
  #       $("#isolate_sub_district_id").val ""
  #     else
  #       $("#sub_district_not_found").remove()
  #     return

  # # subdistrict autocomplete menu formater
  # unless isolate_subdistrict.data("ui-autocomplete") is undefined
  #   isolate_subdistrict.data("ui-autocomplete")._renderItem = (ul, item) ->
  #     content = "#{item.sub_district_code}, #{item.area_name}"
  #     $("<li>").append("<a>" + content + "</a>").appendTo ul

window.newSequenceIsolate = (id, name) ->
  target = $("#isolate_search_result")
  new_isolate = '<li><label><input type="radio" name="sequence[isolate_id]" id="sequence_isolate_id_'+id+'" value="'+id+'" isolate-name="'+name+'" checked> '+name+'</label></li>'
  if target.find("ul").length < 1
    new_isolate = '<div class="ul list-unstyled">' + new_isolate + '</div>'
  else
    target = target.find('ul')
  target.append(new_isolate)

  # $("#sequence_isolate_id_#{id}").prop("checked", true)

window.isolateFilterLabel = () ->
  label = "Showing: "
  label = label + "all "

  # Species
  species = []
  species_wrapper = $("select[name='by_species[]']").closest(".form-group")
  species_wrapper.find(".search-choice").each ->
    species.push($(this).find("span").html())
  unless species.length == 0
    label = label + filterLabelTemplate(species.join(", "))

  # isolated
  isolated_val = $("input[name='isolated']:checked").val()
  data = ""
  if isolated_val == "false"
    data = "non"

  # data option
  my_data = $("#my_data").val()
  my_lab_data = $("#my_lab_data").val()
  all_data = $("#all_data").val()
  if my_data != "" && my_lab_data == "" && all_data == ""
    data = data + " isolates you've uploaded"
  else if my_data == "" && my_lab_data != "" && all_data == ""
    data = data + " isolates from your lab"
  else if my_data == "" && my_lab_data == "" && all_data != ""
    data = data + " isolates from all lab"
  else
    group_name = $("input[name='group_name']").val()
    data = data + ' isolates from isolate group "' + group_name + '"'
  label = label + filterLabelTemplate(data)

  # virus type
  htype = $("select[name='by_htype']").val()
  ntype = $("select[name='by_ntype']").val()

  virus_type_label = ""
  unless htype == ""
    virus_type_label = virus_type_label + "H#{htype}"
  unless ntype == ""
    virus_type_label = virus_type_label + "N#{ntype}"

  if (htype.trim().length > 0) or (ntype.trim().length > 0)
    label = label + filterLabelTemplate("#{virus_type_label}")

  # uploaded at
  # start date
  uploaded_start_date = $("input[name='by_start_created[start]']").val()
  # end date
  uploaded_end_date = $("input[name='by_end_created[end]']").val()
  if uploaded_start_date.trim().length > 0 and uploaded_end_date.trim().length == 0
    label = label + filterLabelTemplate("uploaded from #{formatDate(uploaded_start_date)}")
  else if uploaded_start_date.trim().length == 0 and uploaded_end_date.trim().length > 0
    label = label + filterLabelTemplate("uploaded until #{formatDate(uploaded_end_date)}")
  else if uploaded_start_date.trim().length > 0 and uploaded_end_date.trim().length > 0
    label = label + filterLabelTemplate("uploaded between #{formatDate(uploaded_start_date)} until #{formatDate(uploaded_end_date)}")

  # collection year
  collection_year = $("input[name='by_year']").val()
  if collection_year.trim().length > 0
    label = label + filterLabelTemplate("collected in year #{collection_year}")

  # country, province, district
  place = []
  # country
  country = []
  country_wrapper = $("select[name='by_country[]']").closest(".form-group")
  country_wrapper.find(".search-choice").each ->
    country.push($(this).find("span").html())
  # province
  province = []
  province_wrapper = $("select[name='by_province[]']").closest(".form-group")
  province_wrapper.find(".search-choice").each ->
    province.push($(this).find("span").html())
  # district
  district = []
  district_wrapper = $("select[name='by_district[]']").closest(".form-group")
  district_wrapper.find(".search-choice").each ->
    district.push($(this).find("span").html())
  if country.length > 0 or province.length > 0 or district.length > 0
    # label = label + "<br /> - In"
    if country.length > 0
      place.push(" Country: (#{country.join(', ')})")
    if province.length > 0
      place.push(" Province: (#{province.join(', ')})")
    if district.length > 0
      place.push(" District: (#{district.join(', ')})")
    label = label + filterLabelTemplate("in" + place.join(", "))

  # vet lab
  vet_lab = []
  vet_lab_wrapper = $("select[name='by_isolate_lab[]']").closest(".form-group")
  vet_lab_wrapper.find(".search-choice").each ->
    vet_lab.push($(this).find("span").html())
  unless vet_lab.length == 0
    label = label + filterLabelTemplate("by Vetlab: (#{vet_lab.join(', ')})")

  # keyword
  keyword = $("input[name='by_isolate_name[keyword]']").val()
  if keyword.trim().length > 0
    label = label + filterLabelTemplate("by keyword: #{keyword}")

  # ag_data
  ag_data_val = $("input[name='antigenic_data']:checked").val()
  if ag_data_val == "true"
    label = label + filterLabelTemplate("used in at least one antigenic run")
  else if ag_data_val == "warning"
    label = label + filterLabelTemplate("has Pre Screening warning")
  # gen_data
  gen_data_val = $("input[name='genetic_data']:checked").val()
  if gen_data_val == "true"
    label = label + filterLabelTemplate("has at least a sequence")

  # missing filter data
  missing_data = []
  coll_data_wrapper = $("select[name='missing_data_filter_on_collection_data[]']").closest(".form-group")
  coll_data_wrapper.find(".search-choice").each ->
    missing_data.push($(this).find("span").html())
  epi_data = $("select[name='missing_data_filter_on_epidem_data[]']").closest(".form-group")
  epi_data.find(".search-choice").each ->
    missing_data.push($(this).find("span").html())
  unless missing_data.length == 0
    label = label + filterLabelTemplate("has missing data in these fields: #{missing_data.join(', ')}")

  $("#data_option_detail").html(label)

window.resetCheckedIsolates = () ->
  length = checkedIsolates.length
  checkedIsolates.splice(0, length)
  resetPropCheckedIsolates()

window.resetCheckedIsolatesOnForm = () ->
  length = checkedIsolatesOnForm.length
  window.checkedIsolatesOnForm.splice(0, length)

window.resetPropCheckedIsolates = () ->
  $(".isolate-table").find("input[name='isolate_group[isolate_ids][]']").prop('checked', false)

window.fieldIsolateValidatedValue = (field_validate) ->
  field = "#{field_validate}"
  fields = ["input[name='isolate[#{field}]']", "select[name='isolate[#{field}]']", "input[name='isolate[#{field}][]']"]
  field_tag = fields.join(',')
  field_value = $("#{field_tag}").val()
  return field_value

# Validate the presence of the field
window.presenceIsolateFieldsValidation = (field_validate, errors) ->
  field_value = fieldIsolateValidatedValue(field_validate)
  # check blank
  if field_validate == 'vaccine_ids'
    msg = "Vaccine should be selected at least one"
  else if (field_validate == 'ivm_standard_name')
    if $("input:radio[name='isolate[ivm_standard_name]']:checked").length == 0
      field_value = ""
    msg = "Should be selected at least one"
  else if (field_validate == 'is_stored_in_dic') or (field_validate == 'has_been_shared')
    field_value = ""
    msg = 'is not included in the list'
  else
    msg = "can't be blank"
  if field_value is undefined or field_value is ''
    if errors["#{field_validate}"] is undefined  # no errors field before
      errors["#{field_validate}"] = []
    errors["#{field_validate}"] = $.merge( $.merge( [], errors["#{field_validate}"] ), [msg] )
  return errors

# Validate the numaricality of the field
window.numericalityIsolateFieldsValidation = (field_validate, terms, errors) ->
  field_value = fieldIsolateValidatedValue(field_validate)
  messages = [] # all messages in here
  if isNaN(field_value) && (field_validate != "date_collected") # string
    messages.push('must be a number')
  else
    $.each terms, (term, value) ->
      #greater_than 1000
      if "#{term}" is "greater_than"
        if field_value <= value
          messages.push("must be greater than #{value}")
      #less_than_or_equal_to current year
      if "#{term}" is "less_than_or_equal_to"
        if field_value > value
          messages.push("must be less than or equal to #{value}")
      #minimum 4 characters
      if "#{term}" is "minimum"
        if field_value.length < 4
          messages.push("is too short (minimum #{value} characters)")
      #epi_lab_number_length & epi_year_length
      if "#{term}" is "length"
        if field_value.length != 2
          messages.push("should be 2 characters")
      #epi_greater_than
      if "#{term}" is "epi_greater_than"
        if field_value <= 0
          messages.push("must be greater than 0")
      #error_date_collected
      if "#{term}" is "error_date_collected"
        messages.push(value)
      if "#{term}" is "epi_lab_ref_num"
        if field_value.length > 5 || field_value.length == 0
          messages.push("5 characters is the maximum allowed")


  if messages.length > 0 #if message length > 0 will assume that there's errors
    if errors["#{field_validate}"] is undefined # no errors field before
      errors["#{field_validate}"] = []
    errors["#{field_validate}"] = $.merge( $.merge( [], errors["#{field_validate}"] ), messages )
  return errors

#mengecek apakah format date collected yang diinputkan sudah sesuai
window.checkDateCollectedValidity = (no_date, approximate_date,numericality_fields, isolate_year) ->
  current_year = (new Date).getFullYear()
  value = $("#isolate_date_collected").val()
  if value == ""
    msg = "at least should have year and/or month"
  else
    if value.match(/[a-z]/i) != null && approximate_date == false
      msg = "Please mark as 'approximate date' if you can only provide with year and month"
    else
      date_collected_year = value.split('-')[0]
      unless (date_collected_year <= isolate_year) && (date_collected_year <= current_year)
        msg = "can't be larger than isolate's year (#{isolate_year})"

  if no_date is false
    date_collected_fields =
      date_collected:
        error_date_collected: msg
    unless msg == undefined
      numericality_fields.push(date_collected_fields)
  return numericality_fields

#vaccine validation(epidem data)
window.vaccineValidation = (presence_fields, field) ->
  value = $("#isolate_vaccination_id").val()
  if value == ""
    presence_fields.push("#{field}")
  else
    if value == "1"
      vaccine_selected = $(".vaccine-list").find(".selected-vaccine").length
      if vaccine_selected == 0
        presence_fields.push("vaccine_ids")
  return presence_fields

#get type of isolate
#isolated - indonesian_isolate - use_ivm_standard (type 1)
#isolated - indonesian_isolate - NOT_use_ivm_standard (type 2)
#isolated - NOT_indonesian_isolate - NOT_use_ivm_standard (type 3)
#NOT_isolated - indonesian_isolate - use_ivm_standard (type 4)
#NOT_isolated - indonesian_isolate - NOT_use_ivm_standard (type 5)
#NOT_isolated - NOT_indonesian_isolate - NOT_use_ivm_standard (type 6)
window.getIsolateType = () ->
  type = 0
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  indonesian_isolate = $("input:radio[name='isolate[is_indonesian_isolate]']:checked").val()
  use_ivm_standard = $("input:radio[name='isolate[use_ivm_standard]']:checked").val()
  if (is_isolated is "true") && (indonesian_isolate is "true") && (use_ivm_standard is "true")
    type += 1
  else if (is_isolated is "true") && (indonesian_isolate is "true") && (use_ivm_standard is "false")
    type += 2
  else if (is_isolated is "true") && (indonesian_isolate is "false") && (use_ivm_standard is "false")
    type += 3
  else if (is_isolated is "false") && (indonesian_isolate is "true") && (use_ivm_standard is "true")
    type += 4
  else if (is_isolated is "false") && (indonesian_isolate is "true") && (use_ivm_standard is "false")
    type += 5
  else if (is_isolated is "false") && (indonesian_isolate is "false") && (use_ivm_standard is "false")
    type += 6
  else
    type = 0
  return type

#validasi isolate naming tab
window.isolateNamingValidation = (errors) ->
  errors = {}
  current_year = (new Date).getFullYear()
  type = getIsolateType()
  is_manual_input = $('input:checkbox[name="isolate[is_manual_input]"]').prop('checked')
  check_generated_name = $("input:radio[name='isolate[ivm_standard_name]']:checked").length
  if (type == 4) or (type == 5) or (type == 6)
    presence_fields = ['flu_type_id','species_id', 'year', 'ivm_standard_name']
  else if (type == 1) or (type == 2) or (type == 3)
    if is_manual_input == true
      presence_fields = ['flu_type_id','species_id', 'year', 'ivm_standard_name']
    else
      presence_fields = ['flu_type_id','species_id', 'year', 'original_name', 'ivm_standard_name']

  numericality_fields = [
    year:
      greater_than: 1000
      less_than_or_equal_to: current_year
      only_integer: true
      minimum: 4
  ]
  if (type == 1) or (type == 4)
    presence_fields.push('district_id', 'vet_lab_id', 'epi_year', 'epi_lab_ref_number', 'virology_lab_identifier')
    epi_year_fields =
      epi_year:
        length: 2
        numericality: true
        epi_greater_than: 0
    epi_lab_ref_number_fields =
      epi_lab_ref_number:
        numericality: true
        epi_greater_than: 0
        epi_lab_ref_num: 4
    numericality_fields.push(epi_year_fields)
    numericality_fields.push(epi_lab_ref_number_fields)
  else if (type == 2) or (type == 5)
    presence_fields.push('lab_name', 'district_id', 'vet_lab_id')
  else if (type == 3) or (type == 6)
    presence_fields.push('lab_name', 'country_id')
  validation =
    presence_fields: presence_fields
    numericality_fields: numericality_fields
  $.each validation, (validation_type, fields) ->
    if "#{validation_type}" is 'presence_fields'
      $.each fields, (key, field) ->
        presence_errors = presenceIsolateFieldsValidation("#{field}", errors)
        Object.assign(errors, presence_errors)
    if "#{validation_type}" is 'numericality_fields'
      $.each fields, (idx, field) ->
        $.each field, (field_name, terms) ->
          numericality_errors = numericalityIsolateFieldsValidation(field_name, terms, errors)
          Object.assign(errors, numericality_errors)
  return errors

#validasi isolate collection tab
window.isolateCollectionValidation = () ->
  type = getIsolateType()
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  errors = {}
  isolate_year = $("#isolate_year").val()
  presence_fields = []
  numericality_fields = []
  idk_checkboxes = ['collector_id','date_received','specimen_type_id','place_collected', 'date_isolated']
  unless (type is 6) or (type is 3)
    idk_checkboxes.push('sub_district_id')
  $.each idk_checkboxes, (idx,field) ->
    idk_value = $("#isolate_#{field}").closest('.row').find(".idk-button-collection").prop("checked")
    if idk_value is false
      if field == 'date_isolated'
        if is_isolated == "true"
          presence_fields.push("#{field}")
      else
        presence_fields.push("#{field}")
  no_date = $("#isolate_date_collected_checkbox").prop("checked")
  approximate_date = $("#isolate_is_approximate_date").prop("checked")
  year_collected = $("#isolate_date_collected").val().split('-')[0]
  checkDateCollectedValidity(no_date, approximate_date,numericality_fields, isolate_year)

  validation =
    presence_fields: presence_fields
    numericality_fields: numericality_fields
  $.each validation, (validation_type, fields) ->
    if "#{validation_type}" is 'presence_fields'
      $.each fields, (key, field) ->
        presence_errors = presenceIsolateFieldsValidation("#{field}", errors)
        Object.assign(errors, presence_errors)
    if "#{validation_type}" is 'numericality_fields'
      $.each fields[0], (field, terms) ->
        numericality_errors = numericalityIsolateFieldsValidation(field, terms, errors)
        Object.assign(errors, numericality_errors)
  return errors

#validasi isolate epidem tab
window.isolateEpidemValidation = () ->
  errors = {}
  presence_fields = []
  idk_mv_checkboxes = ['production_system_id','vaccination_id','age_unit_id', 'sector_id']
  $.each idk_mv_checkboxes, (idx, field) ->
    idk_value = $("#isolate_#{field}").closest('.row').find(".idk-button-collection").prop("checked")
    if (field == "age_unit_id") or (field == "age")
      na_value = $("#isolate_#{field}").closest('.row').find(".na-checkbox-collection").prop("checked")
      if (idk_value is false and na_value is false)
        presence_fields.push("age","age_unit_id")
    else
      if idk_value is false
        if field == "vaccination_id"
          vaccineValidation(presence_fields, field)
        if field == "sector_id"
          if ($("#na_isolate_sector_id").prop('checked') == false) && ($("#idk_isolate_sector_id").prop('checked') == false)
            presence_fields.push("sector_id")
        else
          presence_fields.push("production_system_id")
  validation =
    presence_fields: presence_fields
  $.each validation, (validation_type, fields) ->
    $.each fields, (key, field) ->
      presence_errors = presenceIsolateFieldsValidation("#{field}", errors)
      Object.assign(errors, presence_errors)
  console.log errors
  return errors

#validasi isolate lab data tab
window.isolateLabDataValidation = () ->
  errors = {}
  field = "passage"
  if $("#isolate_na_passage").prop('checked') is false
    presence_errors = presenceIsolateFieldsValidation(field, errors)
    Object.assign(errors, presence_errors)
    return errors

#validasi isolate storage sharing tab
window.isolateStorageSharingValidation = () ->
  errors = {}
  presence_fields = []
  # storage_sharing_fields = ["is_stored_in_dic", "has_been_shared"]
  radio_fields = ["is_stored_in_dic", "has_been_shared"]
  $.each radio_fields, (idx,field) ->
    is_checked = $("#isolate_#{field}").find('input').is(":checked")
    if is_checked is false
      presence_fields.push("#{field}")
    else
      is_checked_val = $('input[name=\'isolate['+field+']\']:checked').val()
      if field == "is_stored_in_dic"
        if is_checked_val == "true"
          storage_method = $("#isolate_storage_method_id").val()
          if storage_method == ""
            presence_fields.push("storage_method_id")
      else if field == "has_been_shared"
        if is_checked_val == "true"
          shared_to = $("#isolate_shared_to").val()
          if shared_to == ""
            presence_fields.push("shared_to")
  $.each presence_fields, (i, field) ->
    presence_errors = presenceIsolateFieldsValidation("#{field}", errors)
    Object.assign(errors, presence_errors)
  return errors

window.isolateFormValidation = () ->
  saved = $(".save-isolate-btn").attr("data-saved")
  if saved is "true"
    errors = {}
    active_tab = ['naming']
    selected_tab = $("#more_info").find(".card-body").find('.edit-tabs:checked')
    selected_tab.each ->
      tabs = $(this).attr("tab_name")
      active_tab.push(tabs)
    $.each active_tab, (i, tab) ->
      if tab == 'naming'
        active_tab_errors =
          naming:
            isolateNamingValidation(errors)
        Object.assign(errors,active_tab_errors)
      else if tab == 'ha_titre_isolate'
        active_tab_errors =
          ha_titre_isolate:
            isolateCollectionValidation(errors)
        Object.assign(errors,active_tab_errors)
      else if tab == 'collection_data'
        active_tab_errors =
          collection_data:
            isolateCollectionValidation(errors)
        Object.assign(errors,active_tab_errors)
      else if tab == 'epidem_data'
        active_tab_errors =
          epidem_data:
            isolateEpidemValidation(errors)
        Object.assign(errors,active_tab_errors)
      else if tab == 'lab_data'
        active_tab_errors =
          lab_data:
            isolateLabDataValidation(errors)
        Object.assign(errors,active_tab_errors)
      else if tab == 'storage_sharing'
        active_tab_errors =
          storage_sharing:
            isolateStorageSharingValidation(errors)
        Object.assign(errors,active_tab_errors)

      tab_wrapper = $("#isolate_tab").find("##{tab}")
      # remove all error
      tab_wrapper.find("a[role='tab']").removeClass("tab-error")
      tab_wrapper.find(".tab-error-count").remove()
      $("#isolate_form").find(".form-group").removeClass("has-error")
      $("#isolate_form").find(".has-error").removeClass("has-error")
      $("#isolate_form").find(".error-message").remove()
      $.each errors, (tab,errors_tab) ->
        isolateErrorsFormWarning(tab, errors_tab)

# add errors warning to current tab's fields
window.isolateErrorsFormWarning = (tab, errors_tab) ->
  errors_num = 0
  age_field_error = []
  $.each errors_tab, (field,msg) ->
    if (field == "age") or (field == "age_unit_id")
      if age_field_error.length == 0
        age_field_error.push(field)
        errors_num += 1
    else
        errors_num += 1
    error_msg = msg[0]
    isolateFieldsNotification(field,error_msg)
  isolateTabNotification(tab,errors_num)

#update next/previous/commit button attributes when click more info or tab
window.resetIsolateFormButton = (active_tab) ->
  is_isolated = $("input:radio[name='isolate[is_isolated]']:checked").val()
  date_isolated_field = $("input:text[name='isolate[date_isolated]']")
  date_collected_field = $("input:text[name='isolate[date_collected]']")
  isolate_year = $("#isolate_year").val()
  tabs = $("#isolate_tab li").not(".hide")
  all_tab_selected = []
  last_tab_selected = $("#isolate_tab li").not(".hide").last().attr("id")
  last_tab = last_tab_selected.split("_tab")[0]
  if is_isolated == 'false'
    $(date_isolated_field).attr('disabled', 'disabled')
    $("#isolate_date_isolated_checkbox").attr('disabled', 'disabled')
  else
    $(date_isolated_field).removeAttr('disabled')
    $("#isolate_date_isolated_checkbox").removeAttr('disabled')
  $.each tabs, (i,tab) ->
    tab_name = $(tab).attr("id").split("_tab")[0]
    all_tab_selected.push(tab_name)

  idx_active = all_tab_selected.indexOf(active_tab)
  next_tab = all_tab_selected[idx_active + 1]
  previous_tab = all_tab_selected[idx_active - 1]

  if active_tab != "naming"
    $(".isolate-previous-btn").removeClass("hide")
    $(".isolate-previous-btn").attr("data-prev", previous_tab)
    if active_tab == last_tab
      $(".isolate-next-btn").addClass("hide")
      $(".save-isolate-btn").removeClass("hide")
    else
      $(".save-isolate-btn").addClass("hide")
      $(".isolate-next-btn").removeClass("hide")
      $(".isolate-next-btn").attr("data-next", next_tab)
  else
    if active_tab != last_tab
      $(".isolate-previous-btn").addClass("hide")
      $(".isolate-next-btn").removeClass("hide")
      $(".save-isolate-btn").addClass("hide")
      $(".isolate-next-btn").attr("data-next", next_tab)
    else
      $(".isolate-next-btn").addClass("hide")
      $(".save-isolate-btn").removeClass("hide")

#number of error in isolate tab
window.isolateTabErrorValidation = (errors) ->
  error_tabs = []
  tab_error_count = {}
  age_fields = []
  $.each errors, (field, msg) ->
    tab = $("#isolate_"+field).closest(".tab-pane").attr("id")
    if field != "province_id"
      if (field == "age") or (field == "age_unit_id")
        if age_fields.length == 0
          age_fields.push(field)
          if error_tabs.indexOf(tab) == -1
            error_tabs.push(tab)
            error_tab =
              "#{tab}": [field]
            Object.assign(tab_error_count, error_tab)
          else
            tab_error_count["#{tab}"].push(field)
      else
        if error_tabs.indexOf(tab) == -1
          error_tabs.push(tab)
          error_tab =
            "#{tab}": [field]
          Object.assign(tab_error_count, error_tab)
        else
          tab_error_count["#{tab}"].push(field)
  $.each tab_error_count, (tab, errors) ->
    tabErrorContent = '<span class="tab-error-count"> <span class="badge badge-pill badge-danger">' + errors.length + '</span></span>'
    $("#isolate_tab").find('a[href="#' + tab + '"]').append(tabErrorContent)

#show isolate naming form ivm standard
window.ivmStandardIsolateForm = () ->
  $(".iso-field-primary , .use-ivm-standard , .isolate-form-btn , .naming").removeClass("hide")

#show isolate naming form world standard
window.worldStandardIsolateForm = () ->
  $(".iso-field-primary , .not-indonesian-isolate , .not-use-ivm-standard , .isolate-form-btn , .naming").removeClass('hide')

window.indonesianStandardIsolateForm = () ->
  $(".iso-field-primary , .indonesian-isolate , .not-use-ivm-standard , .isolate-form-btn , .naming").removeClass('hide')

#show notification isolate's fields form
window.isolateFieldsNotification = (field,msg) ->
  $("#isolate_"+field).closest('.form-group').addClass('has-error')
  errorMessageBlock = $("#isolate_"+field).closest('.form-group').find('.error-message')
  errorMessage = "<div class='error-message alert alert-danger alert-small'>"+msg+"</div>"
  if errorMessageBlock.length > 0
    errorMessageBlock.replaceWith(errorMessage)
  else
    if field != 'is_indonesian_isolate_false'
      $("#isolate_"+field).after(errorMessage)
    else
      $("#isolate_"+field).closest('.form-group').append(errorMessage)

#show notification isolate's tabs form
window.isolateTabNotification = (tab, errors_num) ->
  $("#isolate_tab").find('a[href="#' + tab + '"]').addClass('tab-error')
  tabErrorField = $("#isolate_tab").find('a[href="#' + tab + '"] .tab-error-count')
  tabErrorContent = '<span class="tab-error-count"> <span class="badge badge-pill badge-danger">' + errors_num + '</span></span>'
  if errors_num > 0
    if tabErrorField.length > 0
      tabErrorField.replaceWith(tabErrorContent)
    else
      $("#isolate_tab").find('a[href="#' + tab + '"]').append(tabErrorContent)
  else
    $("##{tab}_tab").find("a").removeClass("tab-error")
    $("##{tab}_tab").find(".tab-error-count").remove()

window.resetIsolateForm = () ->
  form_type = $('#isolate_form').data 'form-type'
  data = $('#isolate_form').data 'isolate-name'
  if form_type == 'new'
    data.original_name = ''
    data.species = ''
    data.location = ''
    data.reference = ''
    data.province = ''
    data.year = ''

    #reset isolate form
    $('.input-on-change').val('')
    $('.select-on-change').find('option:first').prop('selected', true)
    $('.radio-on-change').prop('checked', false)
    $("input:radio[name='isolate[ivm_standard_name]']").prop('checked', false)
    $('#isolate_is_manual_input').prop('checked', false)
    $('.ui-autocomplete-input').closest('.form-group').find('input').val('')

    $('.hidden-isolate-field-collection').addClass('hide')
    $('.select-on-change, .input-on-change').closest('.form-group').removeClass('hide')
    $('.select-on-change, .input-on-change').removeClass('hide')
    $('.iso-field-all , .isolate-form-btn , .disabled-field-collection , .naming').addClass('hide')
  else if form_type == 'edit'
    $('#isolate_is_manual_input').prop('checked', false)
    $('.iso-field-all , .isolate-form-btn, .naming').addClass('hide')
    vet_lab_number = $("#isolate_vet_lab_id :selected").data('vet-lab-number')
    $('#isolate_epi_lab_number').val vet_lab_number

  #reset all error notifications
  $('#isolate_form').find('.nav-tabs li a').removeClass('tab-error')
  $('#isolate_form').find('.nav-tabs li').find('.tab-error-count').remove()
  $('#isolate_form').find('.tab-content .tab-pane').find('.form-group').removeClass('has-error')
  $('#isolate_form').find('.tab-content .tab-pane').find('.error-message').remove()

  #reset more info tab selected
  $('#more_info').find('input[type="checkbox"]:checked').each ->
    $(this).click()
  $(".save-isolate-btn").attr("data-saved", false)
  #reset next - previous - submit  button
  active_tab = "naming"
  resetIsolateFormButton(active_tab)

#validasi reference number yang digunakan di origin name isolate
window.originNameReferenceValidation = (value, type) ->
  origin_name_obj = {}
  isolating_labs = []
  $("#isolate_vet_lab_id").find('option').not(':first').each ->
    isolating_labs.push($(this).attr('data-vet-lab-number'))
  if (type == 1) or (type == 4)
    if value.length >= 7
      if isNaN(value.substr(0,1)) == true
        epi_prefix = value.substr(0,1).toUpperCase()
        lab_number = value.substr(1,2)
        if $.inArray(lab_number, isolating_labs) != -1
          year = value.substr(3,2)
          lab_ref_number = value.split("-")[0].substr(5)
          virology_lab_identifier = value.split("-")[1]
          originNameReference(origin_name_obj, epi_prefix, lab_number, year, lab_ref_number, virology_lab_identifier)
      else
        epi_prefix = ""
        lab_number = value.substr(0,2)
        if $.inArray(lab_number, isolating_labs) != -1
          year = value.substr(2,2)
          lab_ref_number = value.split("-")[0].substr(4)
          virology_lab_identifier =  value.split("-")[1]
          originNameReference(origin_name_obj, epi_prefix, lab_number, year, lab_ref_number, virology_lab_identifier)
  else
    if value.length > 0
      reference_obj =
        lab_name: value
      Object.assign(origin_name_obj, reference_obj)
  return origin_name_obj

#create an object contain data reference epi number
window.originNameReference = (origin_name_obj, epi_prefix, lab_number, year, lab_ref_number, virology_lab_identifier) ->
  reference_obj =
    epi_prefix: epi_prefix
    epi_lab_number: lab_number
    epi_year: year
    epi_lab_ref_number: lab_ref_number
    virology_lab_identifier: virology_lab_identifier
  Object.assign(origin_name_obj, reference_obj)

#validasi district/country yang digunakan di origin name isolate
window.originNameDistrictValidation = (value, type) ->
  origin_name_obj = {}
  countries = []
  url = $(".district-autocomplete").data("source")

  $("#isolate_country_id").find('option').not(':first').each ->
    countries.push($(this).attr('data-country'))
  if (type == 3) or (type == 6)
    if $.inArray(value, countries) != -1
      data =
        country_id: value
      Object.assign(origin_name_obj, data)
  else
    data =
      district_id: value
    Object.assign(origin_name_obj, data)
  return origin_name_obj

#get value from original name
window.getIsolateOriginalNameValue = (type, value) ->
  origin_pattern =/^([a,A]{1})([/]{1})([a-zA-Z\s]{1,})([/]{1})([a-zA-Z\s]{1,})([/]{1})([a-zA-Z0-9._-\d\s]{1,})([/]{1})([0-9]{4,})$/.test(value)

  if origin_pattern == true
    origin_name_obj = {}
    current_year = (new Date).getFullYear()
    original_name_parts = value.split('/')
    flu_type = ['a', 'A']
    species = []

    $("#isolate_species_id").find('option').not(':first').each ->
      species_name = $(this).attr('data-species')
      species.push(species_name)

    $.each original_name_parts, (i,value) ->
      if i == 0 #validasi flu type
        data =
          flu_type_id: value
        Object.assign(origin_name_obj, data)
      else if i == 1 #validasi species
        data =
          species_id: value
        if $.inArray(value, species) != -1
          Object.assign(origin_name_obj, data)
      else if i == 2 #validasi district_id/country_id
        data = originNameDistrictValidation(value, type)
        Object.assign(origin_name_obj, data)
      else if i == 3 #validasi reference
        data = originNameReferenceValidation(value, type)
        Object.assign(origin_name_obj, data)
      else if i == 4 #validasi year
        data =
          year: value
        if (value <= current_year) && (value > 0) && (value > 1000)
          Object.assign(origin_name_obj, data)
  assignOriginNameToIsolateForm(origin_name_obj, type)

#input value from isolate original name to naming form
window.assignOriginNameToIsolateForm = (origin_name_obj, type) ->
  $.each origin_name_obj, (field, value) ->
    if field != 'flu_type_id'
      if field == 'species_id'
        $('#isolate_species_id option[data-species="'+value+'"]').prop('selected', true)
      else if field == 'epi_lab_number'
        $('#isolate_vet_lab_id option[data-vet-lab-number="'+value+'"]').prop('selected', true)
        $("#isolate_#{field}").val(value)
      else if field == 'district_id'
        $( "#district_autocomplete" ).autocomplete( "search", "#{value}")
      else if field == 'country_id'
        $('#isolate_country_id option[data-country="'+value+'"]').prop('selected', true)
      else
        $("#isolate_#{field}").val(value)
        if field == 'year'
          $("#isolate_date_collected").val(value)

  data = $('#isolate_form').data 'isolate-name'
  data.species = $("#isolate_species_id :selected").data 'species'
  data.year = $("#isolate_year").val()
  data.original_name = $('#isolate_original_name').val()
  if (type == 1) || (type == 4)
    dataReference()
  else
    data.reference = $('#isolate_lab_name').val()
    data.location = $('#isolate_country_id :selected').data 'country'
  generateIsolateName(data)

#menampilkan link "visit tab" pada list more info isolate naming tab
window.showMoreInfoLink = () ->
  $("#more_info").find(".edit-tabs:checked").each ->
    tab_name = $(this).attr 'tab_name'
    if $("#" + tab_name + "_info").length is 0
      $(this).closest(".custom-control").append('<div class="tab-link" id="'+tab_name+'_info" tab-name="'+tab_name+'"><a aria-controls="#'+tab_name+'" data-toggle="tab" href="#'+tab_name+'" role="tab">Visit tab</a></div>')

window.renderIsolateFormEdit = (isolate_name) ->
  active_tab = $('#isolate_tab').find('.active').closest('li').attr('id').split('_tab')[0]
  generateIsolateName()
  resetIsolateFormButton(active_tab)
  $("input:radio[name='isolate[ivm_standard_name]']").each ->
    name = $(this).val()
    if name == isolate_name
      $(this).prop('checked', true)
  $('.save-isolate-btn').removeAttr('disabled')
  showMoreInfoLink()

#reset all field naming tab when click input-manually-button
window.resetIsolateNamingForm = () ->
  form_type = $('#isolate_form').data 'form-type'
  $('#generated_isolate_naming').html('')
  if form_type == 'new'
    $('.input-on-change').val('')
    $('.select-on-change').find('option:first').prop('selected', true)
    $('.radio-on-change').prop('checked', false)
    $('.ui-autocomplete-input').closest('.form-group').find('input').val('')
    data =
      flu_type: "A"
      location: ""
      original_name: ""
      province: ""
      reference: ""
      species: ""
      year: ""
      h_type: ""
      n_type: ""
    content = ""
    type = getIsolateType()
    if (type == 1) || (type == 3)
      content = setIsolateName(data)
    else if (type == 2) || (type == 4)
      content = setIsolateNameAbroad(data)
    else if (type == 5) || (type == 6)
      content = setIsolateNameIndonesian(data)
    $("#generated_isolate_naming").html("<div class='radio_buttons optional isolate_isolate_name'><div>" + content + "</div></div>")
  else if form_type == 'edit'
    generateIsolateName()
  $("input[type='submit']").attr('disabled', true)

#know whether the isolate has the original name or not
window.haveOriginalName = () ->
  original_name = $("input:text[name='isolate[original_name]']").val()
  if original_name != ""
    #have origin name
    $(".origin-name-radio").removeClass("hide")
  else
    #haven't origin name
    $(".origin-name-radio").addClass("hide")

#non isolated sample form
window.nonIsolatedSampleForm = () ->
  type = 0
  $("#isolate_is_isolated_false").attr("checked", true)
  $(".isolate_is_isolated").addClass("hide")
  $(".is-indonesian-isolate-option").removeClass("hide")

#show isolate form based on isolate type
window.showIsolateForm = (type) ->
  dataReference()
  $(".isolate-form-btn , .naming, .collection-indonesian-isolate").removeClass('hide')
  if (type == 1) || (type == 4) #use_ivm_standard
    $(".iso-field-primary , .use-ivm-standard").removeClass('hide')
  else if (type == 2) || (type == 5) #indonesian_isolate - not use_ivm_standard
    $(".iso-field-primary , .indonesian-isolate , .not-use-ivm-standard").removeClass('hide')
  else if (type == 3) || (type == 6) #world_standard
    $(".iso-field-primary , .not-indonesian-isolate , .not-use-ivm-standard").removeClass('hide')
    $(".collection-indonesian-isolate").addClass('hide')

window.getIsolateNameSelected = () ->
  form_type = $('#isolate_form').data 'form-type'
  if form_type == 'new'
    if $("input:radio[name='isolate[ivm_standard_name]']:checked").length > 0
      isolate_name = $("input:radio[name='isolate[ivm_standard_name]']:checked").val()
    else
      isolate_name = ""
    $(".generated-isolate-naming-preview").html("<h4>#{isolate_name}</h4>")

#show isolate original name
window.showIsolateOriginalField = (type) ->
  $(".existing-name , .naming").removeClass('hide')
  if (type == 1) or (type == 2) or (type == 3) #isolated
    $(".original-wrapper , .isolated-second-step").removeClass('hide')
    $(".non-isolated-first-step , .na-field-wrapper").addClass('hide')
  else if (type == 4) or (type == 5) or (type == 6) #NOT_isolated
    $(".non-isolated-first-step , .na-field-wrapper").removeClass('hide')
    $(".original-wrapper , .isolated-second-step").addClass('hide')
    showIsolateForm(type)
  resetIsolateNamingForm()
  haveOriginalName()

ready = ->
  # isolateDataTablesLoad()
  # checkIt()
  isolateDistrictAutocomplete()
  isolateSubDistrictAutocomplete()
  showMoreInfoLink()

  # Remove 'More Filters' form when the body clicked
  $('#content').on "click", (e)->
    unless $(e.target).closest('.new-slide-panel').length or e.target.class == "new-slide-panel"
      $('.new-slide-panel').css 'display', 'none'

  $("body").on "click", "#submit_isolate_advanced_filter_btn, #submit_isolate_filter_btn", (e)->
    e.preventDefault()
    isolateDataTablesReset()

  $("body").on "keypress focus", "#keyword_search", (e)->
    if e.which is 13 or e.keyCode is 13
      value = $(this).val()
      $("#by_isolate_name_keyword").val(value)
      isolateDataTablesReset()

  $("body").on "click", "#submit_keyword", (e)->
    e.preventDefault()
    value = $("#keyword_search").val()
    $("#by_isolate_name_keyword").val(value)
    isolateDataTablesReset()

  $("body").on "click", ".isolate-data-options", ->
    $this = $(this)
    $(".hidden-data-options").val("")
    if $this.is(":checked")
      filterDataOptionOnClick($this)
      isolateDataTablesReset()

  $("body").on "click", ".side-isolate-data-options", ->
    $this = $(this)
    $(".hidden-data-options").val("")
    if $this.is(":checked")
      filterDataOptionOnClick($this)

  $('body').on 'mouseover', '.isolate-progress', ->
    $(this).popover({
      trigger : 'hover'
    })

  $('#isolate_group_modal_').on 'shown.bs.modal', () ->
    $('#isolate_group_name').focus()

  # .cancel-isolate-group-modal is used in
  $('body').on 'click', '.cancel-isolate-group-modal', ->
    # Reset Form New
    form_wrapper = $(this).closest('form')
    form_wrapper.find('#isolate_group_name, #isolate_group_note').val('')
    form_wrapper.find('.form-group').removeClass('has-error')
    form_wrapper.find(".error-message").remove()

    # Reset selected item
    resetCheckedIsolatesOnForm()
    for checkedIsolate in checkedIsolates
      window.checkedIsolatesOnForm.push(checkedIsolate)
    renderCheckedIsolates()

  $("body").off("change", 'input:checkbox[name=\'isolate_group[isolate_ids][]\']').on "change", 'input:checkbox[name=\'isolate_group[isolate_ids][]\']', (e)->
    isolateId = $(this).val()
    isolateName = $(this).data('isolate-name')
    if $(this).prop('checked')
      checked_item = {
        id: isolateId,
        name: isolateName
      }
      checkedIsolates.push(checked_item)
      checkedIsolatesOnForm.push(checked_item)
    else
      if $(this).closest('.isolate-table').length > 0
        removeCheckedIsolate(isolateId)
    renderCheckedIsolates();
    counterCheck()

  $('body').on 'click', '.clear-select-isolate', (e) ->
    e.preventDefault()
    resetCheckedIsolates()
    resetCheckedIsolatesOnForm()
    renderCheckedIsolates()
    counterCheck()

  $('body').on 'change', '.control-table-column', ->
    showHideTableColumn()

  # == ISOLATE FORM

  form_type = $('#isolate_form').data 'form-type'

  # The isIndonesianIsolate function is used when has_existing_name value is TRUE.
  # This function will show or hide 'div's
  # based on .isolate_is_indonesian_isolate value.
  # The <tt>val</tt> parameter is ".isolate_is_indonesian_isolate" value
  isIndonesianIsolate = (val) ->
    $('.use-ivm-standard').addClass 'hide'
    $('.existing-name').removeClass 'hide'
    $('.not-use-ivm-standard').addClass 'hide'
    if val == 'true'
      $('.new-isolate-and-indonesian-isolate').removeClass 'hide'
      $('.existing-name-and-not-use-ivm-standard').removeClass 'hide'
      $('.naming').removeClass 'hide'
      $('.indonesian-isolate').removeClass 'hide'
      $('.not-indonesian-isolate').addClass 'hide'
    else if val == 'false'
      $('.existing-name-and-not-use-ivm-standard').removeClass 'hide'
      $('.new-isolate-and-indonesian-isolate').addClass 'hide'
      $('.naming').removeClass 'hide'
      $('.indonesian-isolate').addClass 'hide'
      $('.not-indonesian-isolate').removeClass 'hide'
    else
      $('.use-ivm-standard').addClass 'hide'
      $('.naming').addClass 'hide'
    return

  # The useIvmStandard function is used when has_existing_name value is FALSE.
  # This function will show or hide 'div's
  # based on .isolate_use_ivm_standard value.
  # The <tt>val</tt> parameter is ".isolate_use_ivm_standard" value
  useIvmStandard = (val) ->
    $('.naming').removeClass 'hide'
    $('.new-isolate-and-indonesian-isolate').removeClass 'hide'
    if val == 'true'
      $('.exeption').removeClass 'hide'
      $('.use-ivm-standard').removeClass 'hide'
      $('.not-use-ivm-standard').addClass 'hide'
      $('.existing-name').addClass 'hide'
      $('.existing-name-and-not-use-ivm-standard').addClass 'hide'
      $('#isolate_epi_lab_number').val($("#isolate_vet_lab_id").find(":selected").data("vet-lab-number"))
    else if val == 'false'
      $('.exeption').removeClass 'hide'
      $('.use-ivm-standard').addClass 'hide'
      $('.not-use-ivm-standard').removeClass 'hide'
      $('.existing-name').addClass 'hide'
      $('.existing-name-and-not-use-ivm-standard').removeClass 'hide'
    else
      $('.use-ivm-standard').addClass 'hide'
      $('.naming').addClass 'hide'
    return

  # used for new record (new)
  # show class based on what it's value (have existing name or not)
  window.hasExistingNameNew = (a) ->
    $('.isolate_use_ivm_standard').find('input[type="radio"]').each ->
      $(this).prop('checked', false)
    $('.isolate_is_indonesian_isolate').find('input[type="radio"]').each ->
      $(this).prop('checked', false)
    generateIsolateName()
    if a == 'true'
      $('.not-existing-name-first').addClass 'hide'
      $('.existing-name-first').removeClass 'hide'
      $("input:radio[name='isolate[is_indonesian_isolate]']").click ->
        isolate_is_indonesian_isolate = $(this).val()
        isIndonesianIsolate(isolate_is_indonesian_isolate)
    else if a == 'false'
      $('.not-existing-name-first').removeClass 'hide'
      $('.existing-name-first').addClass 'hide'
      $("input:radio[name='isolate[use_ivm_standard]']").click ->
        isolate_use_ivm_standard = $(this).val()
        useIvmStandard(isolate_use_ivm_standard)
    else
      generateIsolateName()
      $('.not-existing-name-first').removeClass 'hide'
      $('.existing-name-first').removeClass 'hide'
    return

  # this function use for hide previous dependencies fields if has_existing_name value changed
  window.changeHasExistingNameValue = (a) ->
    generateIsolateName()
    if a == 'true'
      $('.not-existing-name-first').addClass 'hide'
      $('.existing-name-first').removeClass 'hide'
      indonesian_isolate = $("input:radio[name='isolate[is_indonesian_isolate]']:checked").val()
      isIndonesianIsolate(indonesian_isolate)
      $("input:radio[name='isolate[is_indonesian_isolate]']").click ->
        indonesian_isolate = $(this).val()
        isIndonesianIsolate(indonesian_isolate)
    else if a == 'false'
      $('.not-existing-name-first').removeClass 'hide'
      $('.existing-name-first').addClass 'hide'
      ivm_standard = $("input:radio[name='isolate[use_ivm_standard]']:checked").val()
      useIvmStandard(ivm_standard)
      $("input:radio[name='isolate[use_ivm_standard]']").click ->
        ivm_standard = $(this).val()
        useIvmStandard(ivm_standard)
    else
      generateIsolateName()
      $('.not-existing-name-first').removeClass 'hide'
      $('.existing-name-first').removeClass 'hide'
    return

  # when vet lab change, it'll change vet lab number on isolate epi number
  window.vetLabChange = () ->
    $("#isolate_vet_lab_id").change ->
      vet_lab_number = $("#isolate_vet_lab_id :selected").data('vet-lab-number')
      # alert vet_lab_number
      $('#isolate_epi_lab_number').val vet_lab_number

  # show content of generate name, this always used in every action.
  window.generateIsolateName = (data) ->
    content = ""
    type = getIsolateType()
    data = $('#isolate_form').data 'isolate-name'
    # flu type
    flu_type = $('#isolate_flu_type_id').data 'flu-type'
    data.flu_type = flu_type

    if (type == 1) || (type == 4)
      content = setIsolateName(data)
    else if (type == 3) || (type == 6)
      content = setIsolateNameAbroad(data)
    else if (type == 2) || (type == 5)
      content = setIsolateNameIndonesian(data)
    $(".save-isolate-btn").attr('disabled', true)
    $("#generated_isolate_naming").html(content)
    haveOriginalName()
    getIsolateNameSelected()

  # Use in making data.reference for standard naming
  window.dataReference = () ->
    type = getIsolateType()
    data = $('#isolate_form').data 'isolate-name'
    lab_number = $("#isolate_vet_lab_id :selected").data "vet-lab-number"
    if lab_number == undefined
      lab_number = $("#isolate_epi_lab_number").val()
    epi_year = $("#isolate_epi_year").val()
    epi_lab_ref_number = $("#isolate_epi_lab_ref_number").val()
    virology_lab_identifier = $("#isolate_virology_lab_identifier").val()
    lab_name = $("#isolate_lab_name").val()
    epi_prefix = $("#isolate_epi_prefix").val()
    if (type == 1) or (type == 4)
      reference = epi_prefix unless epi_prefix is undefined
      reference += lab_number unless lab_number is undefined
      reference += epi_year unless epi_year is ''
      reference += epi_lab_ref_number unless epi_lab_ref_number is ''
      reference += "-" + virology_lab_identifier unless virology_lab_identifier is ''
    else
      reference = lab_name
    data.reference = reference
    generateIsolateName(data)

  window.moreInfoTab = () ->
    $("#more_info").find(".edit-tabs").each ->
      tab_name = $(this).attr 'tab_name'
      if $(this).is ":checked"
        $("##{tab_name}_info").removeClass 'hide'
      else
        $("##{tab_name}_info").addClass 'hide'

  # check idk checkbox and its effect
  checkDontKnowButton = () ->
    $("body").find(".idk-effect").each ->
      value = $(this).attr('value') or $(this).is (":selected")
      id = $(this).attr('id')
      if value == undefined or value == ''
        $("##{id}_checkbox").attr('checked', true)
        $(".#{id}_hidden").removeClass 'hide'
        $("##{id}_hidden").removeClass 'hide'
        $(this).addClass 'hide'
        $(this).closest('.form-group').addClass 'hide'
        $(".#{id}").addClass 'hide'

    $("body").find(".idk-button-collection, .na-checkbox-collection").each ->
      id = $(this).attr('id')
      field_effect = $(this).data 'field-effect'
      value = $(this).attr('value')
      if $(this).is ":checked"
        $(".#{field_effect}").addClass 'hide'
        $("##{field_effect}").addClass 'hide'
        $("##{field_effect}").closest('.form-group').addClass 'hide'
        $(".#{field_effect}_hidden").removeClass 'hide'
        $("##{field_effect}_hidden").removeClass 'hide'
        if field_effect == 'isolate_age_unit'
          $(".isolate_age , .isolate_age_unit , #isolate_age_unit_id").addClass 'hide'
          $(".age_hidden , .age_unit_hidden , #age_hidden , #age_unit_hidden").removeClass 'hide'

  # check missing value checkbox and its effect
  checkMissingValue = () ->
    age_value = $("#isolate_age").attr('value')
    age_unit_value = $("#isolate_age_unit_id").attr('value')
    # age_value == '-1' (Don't know)
    # age_value == '-2' (N/A)
    if age_value == '-1' or age_value == '-2'
      $(".isolate_age").addClass 'hide'
      $("#isolate_age").val(age_value);
      $(".age_hidden").removeClass 'hide'
      $("#age_hidden").removeClass 'hide'
      $("#isolate_age_unit_id").attr("disabled", true);
      $("#isolate_age_unit_id").val('');
      if age_value == '1'
        $("#idk_isolate_age_unit").attr("checked", true);
      else if age_value == '-2'
        $("#na_isolate_age_unit").attr("checked", true);


  # This will checked isolate name based on db
  window.checkGeneratedName = (isolate_name) ->
    value = isolate_name
    radio = $("input[name='isolate[ivm_standard_name]'][value='" + value + "']")
    unless radio is undefined
      return radio.attr('checked', 'checked')

  # This will show selected vaccine(s) list if vaccination value is '1' / 'yes'
  checkVaccinationValue = (value) ->
    if value == "1"
      $(".vaccine-yes").removeClass 'hide'
    else
      $(".vaccine-yes").addClass 'hide'

  checkStorageSharingValue = (value, select_effect, class_effect) ->
    if value == 'true'
      $("##{class_effect}_hide").removeClass 'hide'
    else if value == 'false'
      $("##{class_effect}_hide").addClass 'hide'
      $("select[name='isolate[#{select_effect}]']").attr('value', null)
    else if value == null
      $("#storage_method_hide").addClass 'hide'
      $("#shared_to_hide").addClass 'hide'
      $("select[name='isolate[storage_method_id]']").attr('value', null)
      $("select[name='isolate[shared_to]']").attr('value', null)

  # get form_type after save and check more info tab (remote: true)
  $("input[value='Save']").on 'click', ->
    form_type = 'edit'

  # Use in edit isolate form, when checkbox on #more_info checked true, it'll show its tab.
  $("body").on "click", ".edit-tabs", ->
    showMoreInfoLink()
    tab_name = $(this).attr('tab_name')
    iso_year = $("#isolate_year").val()
    if $(this).is ":checked"
      $("##{tab_name}_tab").removeClass 'hide'
      $("##{tab_name}_info").removeClass 'hide'
      $("##{tab_name}").removeClass 'hide'
      if tab_name == "collection_data"
        $("#isolate_date_collected").val(iso_year)
    else
      $("##{tab_name}_tab").addClass 'hide'
      $("##{tab_name}_info").addClass 'hide'
      $("##{tab_name}").addClass 'hide'
    active_tab = "naming"
    resetIsolateFormButton(active_tab)

  $("body").on "click", ".tab-link", ->
    tab_name_clicked = $(this).attr('tab-name')
    $("#isolate_tab li").removeClass 'active'
    active_tab = $(this).attr('tab-name')
    resetIsolateFormButton(active_tab)
    $(".isolate-previous-btn").removeClass('hide')

    $("#more_info").find(".edit-tabs").each ->
      tab_name = $(this).attr 'tab_name'
      if tab_name == tab_name_clicked
        $("##{tab_name_clicked}_tab").addClass 'active'

  $("body").on "click", ".naming-tab-link", ->
    $("#isolate_tab li").removeClass 'active'
    tab_name_clicked = $(this).attr('tab-name')
    $("##{tab_name_clicked}_tab").addClass 'active'

  # action that will trigger generateIsolateName()
  $("body").on "change", "#isolate_species_id", ->
    data = $('#isolate_form').data 'isolate-name'
    data.species = $("#isolate_species_id :selected").data 'species'
    species_id = $(this).val()
    if species_id is "8" # wildbird
      $(".na-sector-id-wrapper").removeClass('hide')
    else if species_id is "11" # wildbird
      $(".na-env-wrapper").removeClass('hide')
    else
      $(".isolate_sector_id , #isolate_sector_id").removeClass 'hide'
      $("#isolate_sector_id").closest('.form-group').removeClass 'hide'
      $("#na_isolate_sector_id").prop('checked', false)
      $(".na-sector-id-wrapper , #isolate_sector_id_hidden").addClass('hide')
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "keyup", "#isolate_epi_year, #isolate_epi_lab_ref_number, #isolate_virology_lab_identifier, #isolate_lab_name", ->
    epi_prefix = $('#isolate_epi_prefix').val()
    dataReference()
    unless epi_prefix == undefined
      $('#isolate_epi_prefix').val ->
        @value.toUpperCase()

  $("body").on "change", "#isolate_epi_prefix", ->
    epi_prefix = $('#isolate_epi_prefix').val()
    dataReference()
    unless epi_prefix == undefined
      $('#isolate_epi_prefix').val ->
        @value.toUpperCase()

  # action that will trigger generateIsolateName()
  $("body").on "keyup", "#isolate_year", ->
    data = $('#isolate_form').data 'isolate-name'
    data.year = $("#isolate_year").val()
    txt_year = $("#isolate_year").val()
    $("#isolate_date_collected").val("#{txt_year}")
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "keyup", "#isolate_location_name", ->
    data = $('#isolate_form').data 'isolate-name'
    data.location = $('#isolate_location_name').val()
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "keyup", "#isolate_lab_name", ->
    data = $('#isolate_form').data 'isolate-name'
    data.reference = $('#isolate_lab_name').val()
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "change", "#isolate_country_id", ->
    data = $('#isolate_form').data 'isolate-name'
    data.location = $('#isolate_country_id :selected').data 'country'
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "change", "#isolate_htype", ->
    data = $('#isolate_form').data 'isolate-name'
    data.h_type = $('#isolate_htype :selected').val()
    generateIsolateName(data)

  # action that will trigger generateIsolateName()
  $("body").on "change", "#isolate_ntype", ->
    data = $('#isolate_form').data 'isolate-name'
    data.n_type = $('#isolate_ntype :selected').val()
    generateIsolateName(data)

  # Show necessary fields for isolate (edit/new) form
  has_existing_name_val = $("input:radio[name='isolate[has_existing_name]']:checked").val()
  if form_type == 'edit'
    generateIsolateName()
    $('.naming').removeClass 'hide'
    # generate isolate name
    isolate_name = $('#isolate_set').val()
    checkGeneratedName(isolate_name)
    $("input[type='submit']").removeAttr('disabled')
    # check vaccination value
    vaccination_value = $("#isolate_vaccination_id :selected").attr('value')
    checkVaccinationValue(vaccination_value)
    # when vet lab change, it will change vet lab number on isolate epi number
    vetLabChange()
    # show selected info tab
    moreInfoTab()
    created_at = $("#isolate_created_at").attr 'value'
    updated_at = $("#isolate_updated_at").attr 'value'
    unless created_at == updated_at
      # check idk button
      checkDontKnowButton()
      # check mv button
      checkMissingValue()
      # check yes value on storage and sharing tab

      # # check real time pcr value
      # real_time_pcr_val = $("#isolate_real_time_pcr_id :selected").attr('value')
      # checkRealTimePcrValue(real_time_pcr_val)
    active_tab = "naming"
    resetIsolateFormButton(active_tab)

  else if (form_type == 'new')
    $("input:radio[name='isolate[is_isolated]']").prop("checked", false)

  # when I don't know button clicked it'll affect to its value
  $("body").on "click", ".idk-button-collection, .na-checkbox-collection", ->
    id = $(this).attr('id')
    field_effect = $(this).data 'field-effect'
    value = $(this).attr('value')
    if $(this).is ":checked"
      $(".#{field_effect}").addClass 'hide'
      $("##{field_effect}").addClass 'hide'
      $("##{field_effect}").closest('.form-group').addClass 'hide'
      $(".#{field_effect}_hidden").removeClass 'hide'
      $("##{field_effect}_hidden").removeClass 'hide'
      if value == '-1'
        $("#na_#{field_effect}").prop('checked', false)
      else if value == '-2'
        $("#idk_#{field_effect}").prop('checked', false)
      $("##{field_effect}").attr('value', value)
      $("##{field_effect}").val(value)
    else
      $(".#{field_effect}").removeClass 'hide'
      $("##{field_effect}").removeClass 'hide'
      $("##{field_effect}").closest('.form-group').removeClass 'hide'
      $(".#{field_effect}_hidden").addClass 'hide'
      $("##{field_effect}_hidden").addClass 'hide'
      $("##{field_effect}").attr('value', null)
      $("##{field_effect}").val(null)
    isolateFormValidation()

  # when missing value button clicked, it'll affect to age and age_unit field value
  $("body").on "click", ".age-mv", ->
    value = $(this).attr('value')
    old_age = $("#isolate_age").attr('value')
    old_age_unit = $("#isolate_age_unit_id").attr('value')
    if $(this).is ":checked"
      $(".isolate_age , .isolate_age_unit , #isolate_age_unit_id").addClass 'hide'
      $(".age_hidden , .age_unit_hidden , #age_hidden , #age_unit_hidden").removeClass 'hide'
      # $("#isolate_age").val(value);
      $("#isolate_age_unit_id").val('');
      $("#isolate_age").val(null);
    else
      $(".isolate_age , .isolate_age_unit , #isolate_age_unit_id").removeClass 'hide'
      $(".age_hidden , .age_unit_hidden , #age_hidden , #age_unit_hidden").addClass 'hide'

  $("body").on "change", "#isolate_age, #isolate_passage", ->
    val = $(this).val()
    if val < 1
      $(this).val(1)

  $("body").on "keyup change", "#isolate_epi_year", ->
    val = $(this).val()
    if val.length > 2
      new_val = "#{val[0]}#{val[1]}"
      $(this).val(new_val)

  # when is_stored_in_dic value is true, it'll enable storage_method_id field
  $("body").on "click", "input:radio[name='isolate[is_stored_in_dic]'], input:radio[name='isolate[has_been_shared]']", ->
    value = $(this).val()
    select_effect = $(this).attr 'select_effect'
    class_effect = $(this).attr 'class_effect'
    checkStorageSharingValue(value, select_effect, class_effect)


  # if vaccination value is yes, it'll show browse vaccine button
  # if browse vaccine clicked, it'll show modal of vaccine list with checkbox in each vaccine.
  $("body").on "change", "#isolate_vaccination_id", ->
    value = $("#isolate_vaccination_id :selected").attr('value')
    checkVaccinationValue(value)

  # Every one of vaccine checked true, it'll append to div which show list of selected vaccine.
  $("body").on "click", "#vaccine_finish_button", ->
    $(".vaccine-list .list").removeClass 'hide'
    $(".vaccine-list .list").html ''
    $("#browse_vaccine_modal").find(".vaccine-list-modal:checked").each ->
      id =  $(this).attr("value")
      vaccine_name = $(this).data "vaccine"
      manufacture = $(this).data "manufacture"
      # if $(this).is ":checked"
      $(".vaccine-list .no-vaccine").addClass 'hide'
      $("#isolate_vaccine_ids").closest('.form-group').find(".error-message").remove()
      vaccine_list = "<div id='vaccine_#{id}' class='selected-vaccine'>"
      vaccine_list += "<input type='hidden' value='#{id}' name='isolate[vaccine_ids][]'/>"
      vaccine_list += "<div class='d-flex align-items-center'>"
      vaccine_list += "<div>#{vaccine_name}</div>"
      vaccine_list += "<a href='#' id='remove_vaccine_#{id}' class='remove-vaccine-list ml-2' data-value='#{id}' data-manufacture='#{manufacture}'>"
      vaccine_list += "<small>Remove</small>"
      vaccine_list += "</a>"
      vaccine_list += "</div>"
      vaccine_list += "</div>"
      $(".vaccine-list .list").append(vaccine_list)
      selected_vaccine_number = $(".vaccine-list").find(".selected-vaccine").length
      if selected_vaccine_number == 0
        $(".vaccine-list .list").append '
            <input type="hidden" value="" name="isolate[vaccine_ids][]" />'
        $(".vaccine-list .list").addClass 'hide'
        $(".vaccine-list .no-vaccine").removeClass 'hide'
    $("#browse_vaccine_modal").modal("hide")

  # if remove vaccine link clicked, it'll remove its div.
  $("body").on "click", ".remove-vaccine-list", (e) ->
    e.preventDefault()
    vaccine_id = $(this).data 'value'
    $(".vaccine-list").find("#vaccine_#{vaccine_id}").remove()
    $("#browse_vaccine_modal").find(".vaccine-list-modal").each ->
      if $(this).attr('id') == "vaccine_#{vaccine_id}"
        $(this).attr("checked", false)
    selected_vaccine_number = $(".vaccine-list").find(".selected-vaccine").length
    if selected_vaccine_number == 0
      $(".vaccine-list .list").append '
          <input type="hidden" value="" name="isolate[vaccine_ids][]" />'
      $(".vaccine-list .list").addClass 'hide'
      $(".vaccine-list .no-vaccine").removeClass 'hide'
    isolateFormValidation()

  $("body").off("change", '.select-on-change').on "change", ".select-on-change", ->
    $(this).closest('.form-group').find(".error-message").remove()
    $(this).closest('.form-group').removeClass('has-error')
    isolateFormValidation()

  $("body").off("keyup", '.input-on-change').on "keyup", ".input-on-change", ->
    $(this).closest('.form-group').find(".error-message").remove()
    $(this).closest('.form-group').removeClass('has-error')
    isolateFormValidation()

  $("body").off("click", '.radio-on-change').on "click", ".radio-on-change", ->
    $(this).closest('.col-md-6').find(".error-message").remove()
    $(this).closest('.form-group').removeClass('has-error')
    isolateFormValidation()

  # Isolate form
  # Collection data
  # toggle isolate_is_approximate_date depend on #isolate_date_collected_checkbox
  $("body").on "click", "#isolate_date_collected_checkbox", ->
    if $(this).is ":checked"
      $(".is-approximate-date-wrapper").addClass 'hide'
      $("input[name='isolate[is_approximate_date]']").prop('checked', false)
    else
      $(".is-approximate-date-wrapper").removeClass 'hide'

  # Isolate form
  # Collection data
  # validate the content of date_collected
  $("body").on "keypress keydown keyup", "#isolate_date_collected", ->
    # default initialize
    error = false
    $date_coll = $(this)
    # default action
    $date_coll.closest(".form-group").removeClass("has-error")
    $date_coll.closest(".form-group").find(".error-message").remove()
    # process
    value = $date_coll.val()
    value_arr = value.split("-")
    value_arr.forEach (entry) ->
      numbers = /\d+/.exec(entry)
      unless numbers is null
        length = numbers[0].length
        if length % 2 == 1
          error = true
        else
          error = false
      return
    if (error is true)
      $date_coll.closest(".form-group").addClass("has-error")
      $date_coll.after('<div class="error-message alert alert-danger alert-small">date is not right</div>')
    else
      $date_coll.closest(".form-group").removeClass("has-error")
      $date_coll.closest(".form-group").find(".error-message").remove()

  #isolate tab
  $('#isolates_tab a').click (e) ->
    e.preventDefault()
    $(this).tab 'show'
    return

  # show more info side when input name='isolate[ivm_standard_name]' has value
  $("body").on "click", "input[name='isolate[ivm_standard_name]']", ->
    $("#sequence_without_isolate_form").find("#more_info").addClass("hide")
    if $("input[name='isolate[ivm_standard_name]']").is ":checked"
      $("#more_info").removeClass 'hide'
      $("input[type='submit']").removeAttr('disabled')
      getIsolateNameSelected()

  #close isolate-group tab
  $('.tab-content').on 'click', '.close-tab-group', (e) ->
    e.preventDefault()
    groupId = $(this).data('id')
    $('#tab_group_'+groupId).remove()
    $('.tab-content #group_'+groupId).remove()
    $('#isolate_group_tab').addClass 'in active'
    $('#isolates_tab a[href=\'#isolate_group_tab\']').tab 'show'

  $("body").on "click", ".manage-pcr-opts", (e)->
    checked = false
    link = $(".manage-pcr-link")
    $("#pcr_management_modal").find(".tab-pane").removeClass("active")
    $(this).closest('.row').find('.idk-pcr-checkbox').prop('checked', false)
    type = $(this).data('type')
    $(".manage-pcr-opts").each ->
      if $(this).prop('checked')
        if $(this).val() == 'true'
          checked = true
          $("##{type}_tab").removeClass("hidden")
        else
          $("##{type}_tab").addClass("hidden")

    if checked is true
      link.removeClass("hidden")
    else if checked is false
      link.addClass("hidden")

  $("body").on "click", ".manage-pcr-link", (e)->
    e.preventDefault()
    $("#pcr_tab").find("li").removeClass("active")
    active = $("#pcr_tab").find("li:not(.hidden):first")
    active.addClass("active")
    tab_content = active.find("a").attr("href")
    $("#{tab_content}").addClass('active')
    $("#pcr_management_modal").modal("show")

  $("body").off("click", ".pcr-result-btn").on "click", ".pcr-result-btn", (e)->
    e.preventDefault()
    form = $(this).closest(".pcr-detail-form")
    template = $("#pcr_result").html()
    # data
    number = form.find(".pcr-results").find("tr").not(".hidden").length + 1
    test_type = form.find("#test_type").val()
    dot = form.find("#dot").val()
    operator = form.find("#operator").val()
    # pcr test type
    pcr_test_type_id = form.find("#pcr_test_type").val()
    pcr_test_type_label = form.find("#pcr_test_type option:selected").html()
    # pcr machine
    pcr_machine_id = form.find("#pcr_machine").val()
    pcr_machine_label = form.find("#pcr_machine option:selected").html()
    # ct values / gel pattern
    # control value
    control_value_id = form.find("#control_value").val()
    if test_type == 'real_time_pcr'
      control_value_label = ""
    else
      control_value_label = form.find("#control_value option:selected").html()
    # isolate value
    isolate_value_id = form.find("#isolate_value").val()
    if test_type == 'real_time_pcr'
      isolate_value_label = ""
    else
      isolate_value_label = form.find("#isolate_value option:selected").html()
    # interpretation
    # control_interpretation
    control_interpretation_id = form.find("#control_interpretation").val()
    control_interpretation_label = form.find("#control_interpretation option:selected").html()
    # isolate_interpretation
    isolate_interpretation_id = form.find("#isolate_interpretation").val()
    isolate_interpretation_label = form.find("#isolate_interpretation option:selected").html()

    if dot == "" or operator == "" or pcr_test_type_id == "" or pcr_machine_id == "" or control_value_id == "" or isolate_value_id == "" or control_interpretation_id == "" or isolate_interpretation_id == ""
      alert("Fill all data!")
      form.find("#dot").focus()
    else
      if test_type == 'real_time_pcr'
        is_real_time_pcr = true
      else
        is_real_time_pcr = false

      if test_type == 'conventional_pcr'
        is_conventional_pcr = true
      else
        is_conventional_pcr = false

      data =
        id: (new Date).getTime()
        number: number
        test_type: test_type
        dot: dot
        operator: operator
        pcr_test_type_id: pcr_test_type_id
        pcr_test_type_label: pcr_test_type_label
        pcr_machine_id: pcr_machine_id
        pcr_machine_label: pcr_machine_label
        is_real_time_pcr: is_real_time_pcr
        is_conventional_pcr: is_conventional_pcr
        control_value_id: control_value_id
        control_value_label: control_value_label
        isolate_value_id: isolate_value_id
        isolate_value_label: isolate_value_label
        control_interpretation_id: control_interpretation_id
        control_interpretation_label: control_interpretation_label
        isolate_interpretation_id: isolate_interpretation_id
        isolate_interpretation_label: isolate_interpretation_label
      form.find(".pcr-results").append(Mustache.render(template, data))

      resetFormPCR()

  $("body").off("click", ".remove-pcr-result").on "click", ".remove-pcr-result", (e)->
    e.preventDefault()
    row = $(this).closest(".pcr-test-result")
    content = row.closest(".pcr-results")
    row.find(".test-result-destroy").val("1")
    row.addClass("hidden")
    num = 1
    content.find(".pcr-test-result").not(".hidden").each ->
      $(this).find(".pcr-result-number").html(num)
      num = num + 1

  $("body").off("click", ".pcr-management-modal-btn").on "click", ".pcr-management-modal-btn", (e)->
    resetFormPCR()
    $("#pcr_management_modal").modal('hide');

  $('body').on 'hidden.bs.modal', "#pcr_management_modal", ->
    $("#pcr_tab").find("li").removeClass("active")
    $("#pcr_management_modal").find(".tab-pane").removeClass('active')

  $("body").on "click", "#isolate_subdistrict_dont_know_checkbox", ->
    if $(this).is ":checked"
      $("#isolate_sub_district_id").val(null)
      $("#sub_district_not_found").remove()

  $("body").on "change", "input[name='isolate[passage]']", ->
    value = $(this).val()
    if value < 1
      $(this).val(1)

  $("body").on "click", "#isolate_subdistrict_dont_know_checkbox", ->
    if $(this).is ":checked"
      $("#isolate_sub_district_id").val(null)
      $("#sub_district_not_found").remove()

  $("body").on "click", "input[name='isolate[vaccination_id]']", ->
    value = $("#isolate_vaccination_id :selected").attr('value')
    checkVaccinationValue(value)

  $("body").off("click", '#isolate_tab li').on "click", "#isolate_tab li", ->
    active_tab = $(this).attr("id").split("_tab")[0]
    resetIsolateFormButton(active_tab)

  $("body").off("click", '.isolate-next-btn').on "click", ".isolate-next-btn", (e) ->
    e.preventDefault()
    isolateFormValidation()
    next_tab = $(this).attr("data-next")
    $('#isolate_tab li a[href="#'+next_tab+'"]').tab("show")
    resetIsolateFormButton(next_tab)

  $("body").off("click", '.isolate-previous-btn').on "click", ".isolate-previous-btn", (e) ->
    e.preventDefault()
    previous_tab = $(this).attr("data-prev")
    $('#isolate_tab li a[href="#'+previous_tab+'"]').tab("show");
    resetIsolateFormButton(previous_tab)

  $("body").off("click", '.save-isolate-btn').on "click", ".save-isolate-btn", ->
    isolateFormValidation()

  $("body").on "keypress focus click keyup", "input[name='isolate[original_name]']", (e)->
    type = getIsolateType()
    value = $(this).val()
    if e.which is 13 or e.keyCode is 13
      e.preventDefault()
      if value != ''
        showIsolateForm(type)
    getIsolateOriginalNameValue(type, value)
    haveOriginalName()

  # action that will trigger generateIsolateName()
  $("body").on "change", "#isolate_vet_lab_id", ->
    dataReference()
    vet_lab_number = $("#isolate_vet_lab_id :selected").data('vet-lab-number')
    $('#isolate_epi_lab_number').val vet_lab_number

  $("body").on "click", "input:radio[name='isolate[is_isolated]']", () ->
    is_isolated = $(this).val()
    $(".is-indonesian-isolate-option").removeClass('hide')
    $(".use-ivm-standard-option").addClass('hide')
    $("input:radio[name='isolate[is_indonesian_isolate]']").prop('checked', false)
    $("input:radio[name='isolate[use_ivm_standard]']").prop("checked", false)
    resetIsolateForm()

  $("body").off("click", '.vaccine-elm-collection').on "click", ".vaccine-elm-collection", ->
    isolateFormValidation()

  #event menampilkan isi form berdasarkan tipe isolate
  $("body").on "click", "#isolate_is_manual_input", ->
    type = getIsolateType()
    if $(this).prop('checked') == true
      showIsolateForm(type)
    else if $(this).prop('checked') == false
      $(".isolate-form-btn , .iso-field-all").addClass('hide')


  $("body").on "click", "input:radio[name='isolate[is_indonesian_isolate]']", ->
    resetIsolateForm()
    is_indonesian_isolate = $(this).val()
    if is_indonesian_isolate is "true"
      $("input:radio[name='isolate[use_ivm_standard]']").prop("checked", false)
      $(".use-ivm-standard-option").removeClass('hide')
    else
      $(".use-ivm-standard-option").addClass('hide')
      $("#isolate_use_ivm_standard_false").prop("checked", true)
      type = getIsolateType()
      showIsolateOriginalField(type)

  $("body").on "click", "input:radio[name='isolate[use_ivm_standard]']", ->
    resetIsolateForm()
    type = getIsolateType()
    showIsolateOriginalField(type)

  # #event ketika klik checkbox N/A isolate form
  # $("body").on "click", ".na-checkbox-collection", ->
  #   field_name = $(this).data('na-field')
  #   if $(this).is(":checked")
  #     if field_name is "sector_id"
  #       $("#idk_sector_id").prop('checked', false)

  #event ketika klik checkbox I don't know pcr options
  $("body").on "click", ".idk-pcr-checkbox", ->
    type = $(this).data('type')
    checked = $(this).prop('checked')
    if checked == true
      $("#pcr_#{type}_opts").find(".manage-pcr-opts").prop('checked', false)

  $("body").on "change keyup", ".isolate-ha-titre", (e) ->
    val = $(this).val()
    if val < 0
      $(this).val(2)

  $("body").off("click", ".add-ha-titre-btn").on "click", ".add-ha-titre-btn", (e) ->
    e.preventDefault()
    template = $("#ha_titre_template").html()
    wrapper = $("#ha_titre_wrapper")
    wrapper.find(".ha-titre-row:first").find(".remove-ha-titre-btn").removeClass "hide"
    data =
      idx: (new Date).getTime()
    wrapper.append(Mustache.render(template, data))

  $("body").off("click", ".remove-ha-titre-btn").on "click", ".remove-ha-titre-btn", (e) ->
    e.preventDefault()
    $(this).closest(".ha-titre-row").remove()
    wrapper = $("#ha_titre_wrapper")
    ha_length = wrapper.find(".ha-titre-row").length
    if ha_length < 2
      wrapper.find(".remove-ha-titre-btn").addClass "hide"

$(document).on 'turbolinks:load', ready