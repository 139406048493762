window.aiSituationCheckNumberOfIsolateData = (data, kode_kab) ->
  counter = 0
  $.each data, (key, val) ->
    dis_code = "#{val.district_code}"
    if dis_code == kode_kab
      counter += 1

  return counter

window.loadAISituationIsolateMap = (isolate_data, lab_test_data, highest_isolate_data_by_district, highest_data_by_district, map_id) ->

  # initialize size
  w = $(".col-md-12").width()
  size =
    width: w,
    height: 450
  center = [size.width/2 , size.height/2]

  highest_isolate_data = 12
  array_isolate_data = isolate_data
  if highest_isolate_data_by_district[0].total_isolate > 12
    highest_isolate_data = highest_isolate_data_by_district[0].total_isolate

  highest_data = 12
  array_data = lab_test_data
  if highest_data_by_district[0].total_test > 12
    highest_data = highest_data_by_district[0].total_test
    # array_data = jQuery.parseJSON(lab_test_data)
    # array_highest_data = jQuery.parseJSON(highest_data_by_district)


  # initialize legend's data
  pengali_data = parseInt(highest_data/4)
  if highest_data % 4 != 0
    pengali_data += 1
  if highest_data < 12
    data_legend_max = 12
  else
    data_legend_max = 4 * pengali_data
  beda = Math.round((parseInt(data_legend_max) + 1) / 4)

  batas_bawah = 1
  data_color_domain = [0]
  data_ext_color_domain = [0]
  data_legend_labels = ["0"]
  while batas_bawah <= data_legend_max
    data_color_domain.push(batas_bawah)
    data_ext_color_domain.push(batas_bawah)
    data_legend_labels.push("#{batas_bawah} - #{batas_bawah+beda-1}")
    batas_bawah = batas_bawah + beda

  # initialize legend's isolate data
  isolate_pengali_data = parseInt(highest_isolate_data/4)
  if highest_isolate_data % 4 != 0
    isolate_pengali_data += 1
  if highest_isolate_data < 12
    isolate_data_legend_max = 12
  else
    isolate_data_legend_max = 4 * isolate_pengali_data
  isolate_beda = Math.round((parseInt(isolate_data_legend_max) + 1) / 4)

  isolate_batas_bawah = 1
  isolate_data_color_domain = [0]
  isolate_data_ext_color_domain = [0]
  isolate_data_legend_labels = ["0"]
  while isolate_batas_bawah <= isolate_data_legend_max
    isolate_data_color_domain.push(isolate_batas_bawah)
    isolate_data_ext_color_domain.push(isolate_batas_bawah)
    isolate_data_legend_labels.push("#{isolate_batas_bawah} - #{isolate_batas_bawah+isolate_beda-1}")
    isolate_batas_bawah = isolate_batas_bawah + isolate_beda


  # initialize color
  color = d3.scale.threshold()
    .domain(data_color_domain)
    .range(["#ffffff", "#f9f9f9", "#a3ff87", "#3cff00", "#2ec300", "#1a6d00"])

  # initialize isolate color
  isolate_color = d3.scale.threshold()
    .domain(isolate_data_color_domain)
    .range(["#ffffff", "#f9f9f9", "#a8acff", "#0024ff", "#002bb7", "#000261"])


  # create canvas
  canvas = d3.select("##{map_id}").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#{mapColors['sea']}")
    .attr("cursor", "grab")

  # create tooltip
  tooltip = d3.select("body").append("div")
    .attr("class", "tooltip-isolate")
    .style("opacity", 0);

  # ----------------------------------------------- Generate MAP --------------------------------------------------------
  d3.json "uploads/indonesia_districts_2018.json", (data) ->
    group = canvas.selectAll("g.dis")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis")

    projection = d3.geo.mercator().scale(w).translate([-size.width*1.655, (size.height)/2.9]);
    path = d3.geo.path().projection(projection);

    # write(coloring) data on map
    areas = group.append("path")
      .attr("d", path)
      .attr("class", (d,i) ->
        return "area "+d.properties.CC_2;
      )
      .style("stroke", "#{mapColors['landBorder']}")
      .style("stroke-width", "0.1")
      .attr("fill", (d,i) ->
        total_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
        return color(total_data);
      )
      # .on('mouseover', (d,i) ->
      #   total_lab_test_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
      #   total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
      #   string_details = "<b>"+d.properties.NAME_2+"</b>. <b>"+total_lab_test_data+" tests</b>, <b>"+total_isolate_data+" isolates</b>";
      #   $("##{map_id}_detail i").html(string_details)
      # )
      # .on('mouseout', (d,i) ->
      #   $("##{map_id}_detail i").html("District Details")
      # )

    circle = canvas.selectAll("g.dis.marker")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "marker dis")
      .attr("id", (d,i) ->
        d.realx = path.centroid(d)[0]
        d.realy = path.centroid(d)[1]
        return d.properties.CC_2;
      )


    circle.append('image')
      .attr('xlink:href','uploads/mapmarker.png')
      .attr('class', 'pico')
      .attr('height', 20)
      .attr('width', 20)
      .attr('x', (d) ->
        return path.centroid(d)[0]-10
      )
      .attr('y', (d) ->
        return path.centroid(d)[1]-20
      )
      .style('display', (d,i) ->
        total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
        if total_isolate_data > 0
          return 'visible'
        else
          return 'none'
      )
      .style('cursor', 'pointer')
      .on('mouseover', (d,i) ->
        total_lab_test_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
        total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
        string_details = "<b>"+d.properties.NAME_2+"</b> <br /> <b>"+total_isolate_data+" isolates</b>";
        # $("##{map_id}_detail i").html(string_details)
        tooltip.transition()
          .duration(200)
          .style("opacity", .9);
        tooltip.html(string_details)
          .style("left", (d3.event.pageX) + "px")
          .style("top", (d3.event.pageY - 28) + "px");
      )
      .on('mouseout', (d,i) ->
        # $("##{map_id}_detail i").html("District Details")
        tooltip.transition()
          .duration(500)
          .style("opacity", 0);
      )

    # circle.append('svg:circle')
    #   .attr('cx', (d) -> path.centroid(d)[0])
    #   .attr('cy', (d) -> path.centroid(d)[1])
    #   .attr('r', (d,i) ->
    #     total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
    #     if total_isolate_data >= isolate_data_color_domain[4]
    #       return 1.5
    #     else if total_isolate_data >= isolate_data_color_domain[3]
    #       return 1.2
    #     else if total_isolate_data >= isolate_data_color_domain[2]
    #       return 0.9
    #     else if total_isolate_data >= isolate_data_color_domain[1]
    #       return 0.6
    #     else if total_isolate_data >= isolate_data_color_domain[0]
    #       return 0.2
    #     else
    #       return 0
    #   )
    #   .style("opacity", "0.9")
    #   .style("fill", (d,i) ->
    #     total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
    #     return isolate_color(total_isolate_data);
    #   )
    #   .on('mouseover', (d,i) ->
    #     total_lab_test_data = checkNumberOfLabTestData(array_data, d.properties.CC_2)
    #     total_isolate_data = aiSituationCheckNumberOfIsolateData(array_isolate_data, d.properties.CC_2)
    #     string_details = "<b>"+d.properties.NAME_2+"</b>. <b>"+total_lab_test_data+" tests</b>, <b>"+total_isolate_data+" isolates</b>";
    #     $("##{map_id}_detail i").html(string_details)
    #   )
    #   .on('mouseout', (d,i) ->
    #     $("##{map_id}_detail i").html("District Details")
    #   )

  # ----------------------------------------------- END OF MAP --------------------------------------------------------

    # ----------------------------------------------- ZOOM BEHAVIOR -----------------------------------------------------
    ai_situationZoomed = () ->
      zoom_scale = parseFloat(1)/zoom.scale()
      canvas.selectAll(".dis").attr("transform", "translate("+zoom.translate()+")scale("+zoom.scale()+")");

      canvas.selectAll(".pico")
        .attr("width", 20*zoom_scale)
        .attr("height", 20*zoom_scale)
        .attr('x', (d) ->
          newx = parseFloat(d.realx) - parseFloat(((20*zoom_scale)/parseFloat(2)))
          return newx;
        )
        .attr('y', (d) ->
          newy = parseFloat(d.realy) - parseFloat(20*zoom_scale)
          return newy;
        )


    # zoom behavior
    zoom = d3.behavior.zoom()
          .on("zoom", ai_situationZoomed);

    # calling zoom behavior and
    # disabling double-click-action-to-zoom
    canvas.call(zoom).on("dblclick.zoom", null);

    # function for zoom on click (for button)
    ai_situation_zoom_on_click = (obj_id) ->
      scale = zoom.scale()
      extent = zoom.scaleExtent()
      translate = zoom.translate()
      # console.log("translate = #{translate}")
      x = translate[0]
      y = translate[1]
      if obj_id == 'zoom_reset'
        target_scale = 1
        x = 0
        y = 0
      else
        if obj_id == 'zoom_in'
          factor = 1.1
        else if obj_id == 'zoom_out'
          factor = 1/1.1

        target_scale = scale * factor
        # Center each vector, stretch, then put back
        x = (x - center[0]) * factor + center[0];
        y = (y - center[1]) * factor + center[1];

      d3.transition().duration(150).tween("zoom", () ->
        interpolate_scale = d3.interpolate(scale, target_scale)
        interpolate_trans = d3.interpolate(translate, [x,y])
        return (t) ->
          zoom.scale(interpolate_scale(t)).translate(interpolate_trans(t))
          ai_situationZoomed()
      )

    # action for zoom-control
    d3.selectAll('.zoom-control').on('click', () ->
      zoom_status = d3.select(this).attr("data-zoom")
      ai_situation_zoom_on_click(zoom_status)
    )

    # scale(w*1.2).translate([-size.width*1.98, (size.height)/2.7])
    window.ai_situationZoomDic = (cx, cy, dis_id, zoom_scale) ->
      # zoom.scale( zoom.scale()/2 )
      # zoom_scale = 4
      canvas.selectAll(".dis").transition()
        .duration(350)
        .attr("transform", "translate(" + size.width/2 + "," + size.height/2 + ")scale(" + zoom_scale + ")translate(" + -cx + "," + -cy + ")")

      translate = zoom.scale(zoom_scale).translate([size.width/2 - cx * zoom_scale, size.height/2 - cy * zoom_scale]);

    if map_id != "ai_situation_page_lab_test_and_isolate_map_all_indonesia"
      dic_active = $("#dic_active").data('dic')
      if dic_active == 1
        cx = $("#5311 circle").attr('cx')
        cy = $("#5311 circle").attr('cy')
        dis_id = 5311
        zoom_scale = 4
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 2
        cx = $("#3516 circle").attr('cx')
        cy = $("#3516 circle").attr('cy')
        dis_id = 3516
        zoom_scale = 7
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 3
        cx = $("#9410 circle").attr('cx')
        cy = $("#9410 circle").attr('cy')
        dis_id = 9410
        zoom_scale = 1.25
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 4
        cx = parseInt($("#1217 circle").attr('cx')) + 75
        cy = parseInt($("#1217 circle").attr('cy'))
        dis_id = 1217
        zoom_scale = 2.8
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 5
        cx = $("#1902 circle").attr('cx')
        cy = parseInt($("#1902 circle").attr('cy')) + 20
        dis_id = 1902
        zoom_scale = 3.2
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
        console.log("#{cx} | #{cy} | #{dis_id} | #{zoom_scale}")
      else if dic_active == 6
        cx = $("#2102 circle").attr('cx')
        cy = parseInt($("#2102 circle").attr('cy')) + 20
        dis_id = 2102
        zoom_scale = 2.2
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 7
        cx = parseInt($("#7205 circle").attr('cx')) - 30
        cy = parseInt($("#7205 circle").attr('cy')) - 22
        dis_id = 7205
        zoom_scale = 2.1
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)
      else if dic_active == 8
        cx = $("#3277 circle").attr('cx')
        cy = $("#3277 circle").attr('cy')
        dis_id = 3277
        zoom_scale = 7
        ai_situationZoomDic(cx, cy, dis_id, zoom_scale)

    # zoom by autocomplete-search
    window.ai_situationZoomTo = (cx, cy, dis_id) ->
      zoom.scale( zoom.scale()/2 )
      zoom_scale = 30
      canvas.selectAll(".dis").transition()
        .duration(350)
        .attr("transform", "translate(" + size.width/2 + "," + size.height/2 + ")scale(" + zoom_scale + ")translate(" + -cx + "," + -cy + ")")

      $(".#{dis_id}").fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800)

      translate = zoom.scale(zoom_scale).translate([size.width/2 - cx * zoom_scale, size.height/2 - cy * zoom_scale]);

    # ------------------------------------------- END OF ZOOM -------------------------------------------------------

window.renderAiForm = () ->
  if $(".ai-situation-form").length > 0
    $(".content-type-ai-situation:checked").each ->
      val = $(this).val()
      $("##{val}").removeClass "hide"

window.showAiSituationMap = () ->
  isolate_data = jQuery.parseJSON($("input[name='isolates_data']").val())
  highest_isolate_data_by_district = $("#home_page_highest_isolate_data_by_district").data("highestisolate")
  lab_test_data = []
  highest_lab_test_data = [ {total_test: 0} ]

  if highest_isolate_data_by_district.length == 0
    highest_isolate_data_by_district = [ {total_isolate: 0} ]

  loadAISituationIsolateMap(isolate_data, lab_test_data, highest_isolate_data_by_district, highest_lab_test_data, "ai_situation_map")


ready = ->
  renderAiForm()


  $("body").on "change", ".content-type-ai-situation", (e) ->
    e.preventDefault()
    val = $(this).val()
    console.log val
    if $(this).prop('checked')
      $("##{val}").removeClass "hide"
    else
      $("##{val}").addClass "hide"

$(document).on 'turbolinks:load', ready