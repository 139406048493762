import { Controller } from 'stimulus'

let selectedSequences = localStorage.selectedSequences == undefined ? [] : JSON.parse(localStorage.selectedSequences)

export default class extends Controller {
  connect() {
    this._renderSelectedSequenceLength()
    this._toggleSequenceCollectionCard()
    this._applyCheckedSequences()
    this._renderSelectedSequence()
  }

  onSelectedSequence(e) {
    const element = $(e.target)
    const id = element.val()
    if (e.target.checked) {
      const segment = element.data('segment')
      const name = element.data('sequence')
      selectedSequences.push({id: id, name: name, segment: segment})
    } else {
      selectedSequences = selectedSequences.filter(e => e.id !== id)
    }

    localStorage.selectedSequences = JSON.stringify(selectedSequences)
    this._renderSelectedSequenceLength()
    this._toggleSequenceCollectionCard()
    this._renderSelectedSequence()
  }

  onClearSelectedSequences(e) {
    e.preventDefault()
    this._clearSelectedSequences()
  }

  onSelectAllSequences(e) {
    if (e.target.checked) {
      $('.sequence-item').map((i, element) => {
        $(element).prop('checked', true)
        const id = $(element).val()
        const segment = $(element).data('segment')
        const name = $(element).data('sequence')

        const otherSelected = selectedSequences.filter(d => d.id !== id)
        selectedSequences = [...otherSelected, {id: id, name: name, segment: segment}]
      })
      localStorage.selectedSequences = JSON.stringify(selectedSequences)
      this._renderSelectedSequenceLength()
      this._toggleSequenceCollectionCard()
      this._renderSelectedSequence()
    } else {
      this._clearSelectedSequences()
    }
  }

  onRemoveFormList(e) {
    const element = $(e.target)
    const sequenceId = element.data('sequence-id')
    const wrapper = element.closest('.card')
    $(`#new_sequence_collection_ids_${sequenceId}`).prop('checked', false)
    selectedSequences = selectedSequences.filter(d => d.id != sequenceId)
    localStorage.selectedSequences = JSON.stringify(selectedSequences)
    this._renderSelectedSequenceLength()
    this._toggleSequenceCollectionCard()
    wrapper.remove()
  }

  // private

  _clearSelectedSequences() {
    selectedSequences = []
    localStorage.selectedSequences = '[]'
    $('.sequence-item').prop('checked', false)
    $('.select-all-sequence').prop('checked', false)
    $('.selected-size').html('0 selected')
    $('.collection-list').empty()
    $('#sequence_group_selection').addClass('d-none')
  }

  _toggleSequenceCollectionCard() {
    const card = $('#sequence_group_selection')
    selectedSequences.length > 0 ? card.removeClass('d-none') : card.addClass('d-none')
  }

  _applyCheckedSequences() {
    selectedSequences.map(sequence => $(`#new_sequence_collection_ids_${sequence.id}`).prop('checked', true) )
  }

  // render selected sequence to collections form modal
  _renderSelectedSequence() {
    const collectionList = $('.collection-list')
    collectionList.empty()

    selectedSequences.map(sequence => {
      let checkbox = `<div class='card my-2 border-0'>`
      checkbox += `<div class='card-body p-2'>`
      checkbox += `<input type='hidden' id='collection_form_id_${sequence.id}' name='collection[sequence_ids][]' value='${sequence.id}'>`
      checkbox += `<button type='button' class='btn btn-sm btn-link text-danger float-right' title='Remove sequence' data-sequence-id='${sequence.id}' data-action='click->sequences#onRemoveFormList'>&times;</button>`
      checkbox += `<div>${sequence.name}</div>`
      checkbox += `<div class='font-weight-bold'>Segment ${sequence.segment}</div>`
      checkbox += `</div>`
      checkbox += `</div>`

      collectionList.append(checkbox)
    })
  }

  _renderSelectedSequenceLength() {
    $('.selected-size').html(`${selectedSequences.length} selected`)
  }
}