# ==================================== FORM ===========================================
#

window.giveChecked = ''
window.asBatch = []
window.agBatch = []
# window.ref_antiseras_ids = []
# window.ref_antigens_ids = []
window.refAntiseraMax = 0
window.refAntigenMax = 0
window.runisolatecounter = 0
window.subkit_ids = []

# max isolate size from setup tab
# used in check the size of selected isolates
window.maxisolate = 0

# control data and test data tab
# check log value
window.isTitration = (val) ->
  return true if val is 0

  if val is 1
    return true
   else
    if val % 2 is 0
      val /= 2
      isTitration(val)
    else
      return false

# select isolates tab
# ajax
window.searchIsolate = (element) ->
  keyword = element.val()
  url = element.data "url"
  role = element.data("role")
  vet_lab = element.data("vetlab")
  if keyword == ""
    target = $("#isolate_search_result");
    target.closest(".container-fluid").find(".card-header").html("<b>0 Isolate found..</b>")
    target.html("")
    element.focus()
  else
    $.ajax
      url: url
      data: { title: {keyword: keyword, vet_lab: vet_lab, role: role} }
      dataType: "script"
      method: "GET"
      beforeSend: ->
        wrapper = $("#select_isolates").find(".input-group").parent()
        wrapper.after("<span class='load-isolates-label'><i>Searching...</i></span>")

# select isolates tab
window.validateCheckedIsolate = ->
  valueArr = []
  # .remove-run-isolate = selected isolate (the selected)
  $('.remove-run-isolate').each ->
    $this = $(this)
    if $this.is(":checked")
      valueArr.push($this.val())

  # .run-isolate = list isolates found after search use searchIsolate()
  $(".run-isolate").each ->
    $this = $(this)
    if $.inArray($this.val(), valueArr) is -1
      $this.prop("checked", false)
    else
      $this.prop("checked", true)

# count selected isolate
window.isolate_counter_check = ->
  max = $("#submission_isolate_qty").val()
  isolate_count = $('#run_isolate_selected li').length
  if isolate_count > 0
    # window.runisolatecounter += isolate_count
    $('#count_isolate_select').text(' ('+isolate_count+' of '+max+')')
  else
    window.runisolatecounter = 0
    $('#count_isolate_select').text(' ('+runisolatecounter+' of '+max+')')
  error_fields_check()

# add success/danger panel if errors/not
# used on select references tab and select isolates tab
window.error_fields_check = () ->
  isolate_max = $("#submission_isolate_qty").val()
  isolate_count = $('#run_isolate_selected li').length
  ref_antisera_max = $("#submission_ref_antisera_qty").val()
  ref_antisera_count = $("#ref_antisera_ids_table tr").length
  ref_antigen_max = $("#submission_ref_antigen_qty").val()
  ref_antigen_count = $("#ref_antigen_ids_table tr").length

  if isolate_count != parseInt(isolate_max)
    $("#wrapper_run_isolate_selected").closest('.card').find('.card-header').removeClass('bg-success').addClass('bg-danger')
  else
    $("#wrapper_run_isolate_selected").closest('.card').find('.card-header').removeClass('bg-danger').addClass('bg-success')

  if ref_antisera_count != parseInt(ref_antisera_max)
    $("#ref_antisera_ids_table").closest('.card').find('.card-header').removeClass('bg-success').addClass('bg-danger')
  else
    $("#ref_antisera_ids_table").closest('.card').find('.card-header').removeClass('bg-danger').addClass('bg-success')
    $("#ref_antisera_ids_table").closest('.card').find('.error-message').remove()

  if ref_antigen_count != parseInt(ref_antigen_max)
    $("#ref_antigen_ids_table").closest('.card').find('.card-header').removeClass('bg-success').addClass('bg-danger')
  else
    $("#ref_antigen_ids_table").closest('.card').find('.card-header').removeClass('bg-danger').addClass('bg-success')
    $("#ref_antigen_ids_table").closest('.card').find('.error-message').remove()

#antisera counter check
window.selectedAntiseraCounterCheck = () ->
  refAntiseraMax = $("#submission_ref_antisera_qty").val()
  $('#antisera_selected_counter').text('('+asBatch.length+' of '+refAntiseraMax+')')
  $('#ref_antisera_ids_table tbody').find('tr').each ->
    $(this).children('td:first-child').html $(this).index()+1
  antiseraAntigenCheck()

#antigen counter check
window.selectedAntigenCounterCheck = () ->
  refAntigenMax = $("#submission_ref_antigen_qty").val()
  $('#antigen_selected_counter').text('('+agBatch.length+' of '+refAntigenMax+')')
  $('#ref_antigen_ids_table tbody').find('tr').each ->
    $(this).children('td:first-child').html $(this).index()+1
  antiseraAntigenCheck()

#initialize active step
window.initializeActiveStep = () ->
  $('#submission_active_step').val($('#submission_tab .active').attr('id'))
  $('a[data-toggle=\'tab\']').on 'shown.bs.tab', (e) ->
    $('#submission_active_step').val($('#submission_tab .active').attr('id'))

window.loadKits = () ->
  $('#ref_antiseras .all-references').empty()
  $('#ref_antigens .all-references').empty()

  $('input:checkbox[name=\'submission[kit_ids][]\']:checked').each ->
    submissionKitId = $(this).val()
    if window.subkit_ids.indexOf(submissionKitId) == -1 # kalau tidak ada di array
      window.subkit_ids.push(submissionKitId)

  $.ajax
    url: '/kits/show_multiple'
    dataType: 'json'
    data:
      by_ids: window.subkit_ids
    success: (data) ->
      $.each data.kits, (t, kit) ->
        data = getDataKitDetail(kit)
        $(".kit-check-box").removeAttr("disabled")

        ref_antisera_batch_no_string = ""
        for i of data[0].antisera_ids

          # variables
          antisera_id = data[0].antisera_ids[i]
          antisera_name = data[0].antiseras[i].antisera_name

          for j of data[0].antiseras[i].batches
            batches = data[0].antiseras[i].batches[j]
            ref_antiseras_id = batches.ref_antiseras_id
            batch_no = batches.batch_no

            batch_arr = "#{kit.id}_#{data[0].antiseras[i].batches[j].ref_antiseras_id}"
            if asBatch.indexOf(batch_arr) == -1
              giveChecked = ''
            else
              giveChecked = 'checked'

            ref_antisera_batch_no_string += batchRefAntiseraTemplate(ref_antiseras_id, antisera_id, antisera_name, batch_no, kit.id,  kit.kit_name, giveChecked)

        #append list ref with batch_no checkboxes
        addRefAntiseraDetail(kit.id, ref_antisera_batch_no_string)

        ref_antigen_batch_no_string = ""
        for i of data[0].antigen_ids

          # variables
          antigen_id = data[0].antigen_ids[i]
          antigen_name = data[0].antigens[i].antigen_name
          anchor = data[0].antigens[i].anchor

          #save batch_no chekboxes as string
          for j of data[0].antigens[i].batches
            batches = data[0].antigens[i].batches[j]
            ref_antigens_id = batches.ref_antigens_id
            batch_no = batches.batch_no

            batch_arr = "#{kit.id}_#{batches.ref_antigens_id}"
            if agBatch.indexOf(batch_arr) == -1
              giveChecked = ''
            else
              giveChecked = 'checked'

            ref_antigen_batch_no_string += batchRefAntigenTemplate(ref_antigens_id, antigen_id, antigen_name, batch_no, kit.id, kit.kit_name, giveChecked, anchor)

        #append list ref with batch_no checkboxes
        addRefAntigenDetail(kit.id, ref_antigen_batch_no_string)
      tooltipAndPopoverShow();

window.loadKit = (kit_id) ->
  submissionKitId = kit_id
  window.subkit_ids.push(submissionKitId)

  $.ajax
    url: '/kits/'+submissionKitId
    dataType: 'json'
    method: 'GET'
    beforeSend: () ->
      $(".kit-check-box").attr("disabled", "disabled")
    success: (kitsData) ->
      data = getDataKitDetail(kitsData)
      $(".kit-check-box").removeAttr("disabled")

      ref_antisera_batch_no_string = ""
      for i of data[0].antisera_ids

        # variables
        antisera_id = data[0].antisera_ids[i]
        antisera_name = data[0].antiseras[i].antisera_name

        for j of data[0].antiseras[i].batches
          batches = data[0].antiseras[i].batches[j]
          ref_antiseras_id = batches.ref_antiseras_id
          batch_no = batches.batch_no

          batch_arr = "#{submissionKitId}_#{data[0].antiseras[i].batches[j].ref_antiseras_id}"
          if asBatch.indexOf(batch_arr) == -1
            giveChecked = ''
          else
            giveChecked = 'checked'

          ref_antisera_batch_no_string += batchRefAntiseraTemplate(ref_antiseras_id, antisera_id, antisera_name, batch_no, kitsData.id,  kitsData.kit_name, giveChecked)

      #append list ref with batch_no checkboxes
      addRefAntiseraDetail(submissionKitId, ref_antisera_batch_no_string)

      ref_antigen_batch_no_string = ""
      for i of data[0].antigen_ids

        # variables
        antigen_id = data[0].antigen_ids[i]
        antigen_name = data[0].antigens[i].antigen_name
        anchor = data[0].antigens[i].anchor

        #save batch_no chekboxes as string
        for j of data[0].antigens[i].batches
          batches = data[0].antigens[i].batches[j]
          ref_antigens_id = batches.ref_antigens_id
          batch_no = batches.batch_no

          batch_arr = "#{submissionKitId}_#{batches.ref_antigens_id}"
          if agBatch.indexOf(batch_arr) == -1
            giveChecked = ''
          else
            giveChecked = 'checked'

          ref_antigen_batch_no_string += batchRefAntigenTemplate(ref_antigens_id, antigen_id, antigen_name, batch_no, kitsData.id, kitsData.kit_name, giveChecked, anchor)

      #append list ref with batch_no checkboxes
      addRefAntigenDetail(submissionKitId, ref_antigen_batch_no_string)
      tooltipAndPopoverShow();

window.selectedBatchRefAntiseraTemplate = (kit_id, kit_name, ref_antiseras_id, antisera_name, batch_no, selected_batch_length) ->
  index = (new Date).getTime()
  '<tr id="ref_antisera_'+kit_id+'_'+ref_antiseras_id+'" data-ref-id="'+ref_antiseras_id+'" data-kit-id="'+kit_id+'">
    <td>'+(selected_batch_length)+'</td>
    <td>'+antisera_name+'</td>
    <td class="text-right">
      <span>'+batch_no+' <strong>('+kit_name+')</strong></span>
    </td>
    <td>
      <a class="remove-ref-antisera" ref_id="'+ref_antiseras_id+'" kit_id="'+kit_id+'">
        x
      </a>
    </td>
    <input type="hidden" name="submission[sub_ref_antiseras_attributes]['+index+'][id]">
    <input type="hidden" name="submission[sub_ref_antiseras_attributes]['+index+'][ref_antisera_id]" value="'+ref_antiseras_id+'">
    <input type="hidden" name="submission[sub_ref_antiseras_attributes]['+index+'][kit_id]" value="'+kit_id+'">
  </tr>'

window.selectedBatchRefAntigenTemplate = (kit_id, kit_name, ref_antigens_id, antigen_name, batch_no, selected_batch_length, anchor) ->
  anchor_string = ''
  if anchor
    anchor_string = "<span class='badge badge-pill badge-warning' data-toggle='tooltip' data-placement='bottom' title='Anchor must be included if you want to build an Antigenic Map from this Run'>Anchor</span>"

  index = (new Date).getTime()
  '<tr id="ref_antigen_'+kit_id+'_'+ref_antigens_id+'" data-ref-id="'+ref_antigens_id+'" data-kit-id="'+kit_id+'">
    <td>'+(selected_batch_length)+'</td>
    <td>'+antigen_name+' '+anchor_string+'</td>
    <td class="text-right">
      <span>'+batch_no+' <strong>('+kit_name+')</strong></span>
    </td>
    <td>
      <a class="remove-ref-antigen" ref_id="'+ref_antigens_id+'" kit_id="'+kit_id+'">
        x
      </a>
    </td>
    <input type="hidden" name="submission[sub_ref_antigens_attributes]['+index+'][id]">
    <input type="hidden" name="submission[sub_ref_antigens_attributes]['+index+'][ref_antigen_id]" value="'+ref_antigens_id+'">
    <input type="hidden" name="submission[sub_ref_antigens_attributes]['+index+'][kit_id]" value="'+kit_id+'">
  </tr>'

window.batchRefAntiseraTemplate = (ref_id, antisera_id, antisera_name, batch_no, kit_id, kit_name, checked) ->
  '<div class="checkbox" id="ascheckbox_'+kit_id+ref_id+'">
    <div class="row">
      <div class="col-lg-8 col-md-7 col-xs-12 col-sm-12">
        <label>
          <input type="checkbox" name="ref_antiseras_batch[]" value="'+batch_no+'" data-refantiserasid="'+ref_id+'" data-antiseraid="'+antisera_id+'" data-antiseraname="'+antisera_name+'" data-kit-id="'+kit_id+'" data-kit-name="'+kit_name+'" '+checked+'>
          <span>'+antisera_name+' </span>
        </label>
      </div>
      <div class="col-lg-4 col-md-5 col-xs-12 col-sm-12">
        <span>'+batch_no+' <strong>('+kit_name+')</strong></span>
      </div>
    </div>
  </div>'

window.batchRefAntigenTemplate = (ref_id, antigen_id, antigen_name, batch_no, kit_id, kit_name, checked, anchor) ->
  anchor_string = ''
  if anchor
    anchor_string = "<span class='badge badge-pill badge-warning' data-toggle='tooltip' data-placement='bottom' title='Anchor must be included if you want to build an Antigenic Map from this Run'>Anchor</span>"

  '<div class="checkbox" id="agcheckbox_'+kit_id+ref_id+'">
    <div class="row">
      <div class="col-lg-8 col-md-7 col-xs-12 col-sm-12">
        <label>
          <input type="checkbox" name="ref_antigens_batch[]" value="'+batch_no+'" data-refantigensid="'+ref_id+'" data-antigenid="'+antigen_id+'" data-antigenname="'+antigen_name+'" data-kit-id="'+kit_id+'" data-kit-name="'+kit_name+'" '+checked+' data-anchor="'+anchor+'">
          <span>'+antigen_name+' '+anchor_string+'</span>
        </label>
      </div>
      <div class="col-lg-4 col-md-5 col-xs-12 col-sm-12">
        <span>'+batch_no+' <strong>('+kit_name+')</strong></span>
      </div>
    </div>
  </div>'

#append list antisera with batch_no checkboxes
window.addRefAntiseraDetail = (kit_id, batch_no_string) ->
  list = '<div class="row custom-grid-list-container" id="kit_'+kit_id+'_ref_antiseras">
            <div class="col-md-12">'+batch_no_string+'</div>
          </div>'
  $('#ref_antiseras .panel-group').append(list)

#append list antigen with batch_no checkboxes
window.addRefAntigenDetail = (kit_id, batch_no_string) ->
  list = '<div class="row custom-grid-list-container" id="kit_'+kit_id+'_ref_antigens">
          <div class="col-md-12">'+batch_no_string+'</div>
        </div>'
  $('#ref_antigens .panel-group').append(list)

window.showNotificationTestDetail = (field, form_group, val) ->
  msg = "Should have same "+field+". Click<a class= 'btn btn-link link-setup-"+field+"' id='link_to_setup'>Here</a>"
  if (val == 'false')
    form_group.closest('.check-size').find('.error-check').html("<div class='error-msg text-danger'>"+msg+"</div>")
    form_group.addClass('is-invalid')

#test details
window.testDetailsButtonStatus = () ->
  iso_val = $('input[name=\'submission[is_same_isolate_size]\']:checked').val()
  ref_antigen_val = $('input[name=\'submission[is_same_reference_size_antigen]\']:checked').val()
  ref_antisera_val = $('input[name=\'submission[is_same_reference_size_antisera]\']:checked').val()
  if ("#{iso_val}" is "1") and ("#{ref_antigen_val}" is "1") and ("#{ref_antisera_val}" is "1")
    $(".size-confirmation-submit-btn").removeClass('hide')
    $(".change-reference-btn").addClass('hide')
  else
    $(".size-confirmation-submit-btn").addClass('hide')
    $(".change-reference-btn").removeClass('hide')

window.editIsolateAndReferenceNextStep = (next_step) ->
  if next_step == "select_references"
    $("#antigenic_run_tabs li a[href='#select_references']").tab("show");
    $("#submission_next_step").val("test_detail")
  else if next_step == "select_isolates"
    $("#antigenic_run_tabs li a[href='#select_isolates']").tab("show");
    $("#submission_next_step").val("test_detail")
  else if next_step == "test_detail"
    $("#antigenic_run_tabs li a[href='#test_detail']").tab("show");

# add error on isolate panel and ref panels
window.tabPanelNotification = (error_tab, max, list, ref_name) ->
  disabled = $("#antigenic_run_tabs li a[href='#"+error_tab+"']").closest('li').hasClass('disabled')
  if !disabled
    list.closest('.panel').find('.error-message').remove()
    wrapper = $('#submission_tab').find("##{error_tab}")
    wrapper.find(".error-tab").remove()
    list.closest('.panel').find(".error-message").remove()
    $("#antigenic_run_tabs li a[href='#"+error_tab+"']").append("<span style='color:red' class='error-tab'> *</span>")
  list.closest('.panel').append('<div class="error-message alert alert-danger"><b>ERROR! </b>Reference '+ref_name+' must be equal to '+parseInt(max)+' </div>')

  # $("#run_isolate_selected").closest('.panel').append("<div class='error-message alert alert-danger'><b>ERROR! </b> You've reached the maximum allowed quantity for Isolate</p>")

window.checkTotalRefAndIsolate = () ->
  run_type = $("#submission_run_type").val()
  max_ref_antisera = $("#submission_ref_antisera_qty").val()
  max_ref_antigen = $("#submission_ref_antigen_qty").val()
  max_isolate = $("#submission_isolate_qty").val()
  ref_antisera_count = $("#ref_antisera_ids_table tbody tr").length
  ref_antigen_count = $("#ref_antigen_ids_table tbody tr").length
  isolate_count = $('#run_isolate_selected li').length
  valid = true
  if (parseInt(max_ref_antigen) != ref_antigen_count)
    error_tab = "select_references"
    max = max_ref_antigen
    ref_name = "antigen"
    list_group = $("#ref_antigen_ids_table")
    tabPanelNotification(error_tab, max, list_group, ref_name)
    valid = false
  if (parseInt(max_ref_antisera) != ref_antisera_count)
    error_tab = "select_references"
    max = max_ref_antisera
    ref_name = "antisera"
    list_group = $("#ref_antisera_ids_table")
    tabPanelNotification(error_tab, max, list_group, ref_name)
    valid = false
  if (parseInt(max_isolate) != isolate_count)
    error_tab = "select_isolates"
    max = max_isolate
    ref_name = "isolate"
    list_group = $("#run_isolate_selected")
    tabPanelNotification(error_tab, max, list_group, ref_name)
    valid = false
  return valid

# Validate form per step and give errors
window.submissionFormStepValidation = (step) ->
  # Validate Steps :
  # 1. setup (Client)
  # 2. select ref (Client)
  # 3. select isolates (Server, includes step 1 & 2)
  # 4. test detail (Client)
  # 5. control data (Server, includes step 1, 2, 3, 4, 6(if 6 already exist) )
  # 6. test data (Server)

  errors = {}
  if step == 'setup'
    validate_tab = ['setup']
    errors = setupTabValidation(errors)
  else if step == 'select_references'
    validate_tab = ['setup', 'select_references']
    errors = setupTabValidation(errors)
    errors = selectReferencesTabValidation(errors)
  else if step == 'select_isolates'
    validate_tab = ['setup', 'select_references', 'select_isolates']
    errors = setupTabValidation(errors)
    errors = selectReferencesTabValidation(errors)
    errors = selectIsolatesTabValidation(errors)
  else if step == 'test_detail'
    validate_tab = ['test_detail']
    errors = testDetailTabValidation(errors)


  $.each validate_tab, (i, tab) ->
    # if step == 'setup'
    #   errors = setupTabValidation(errors)
    # else if step == 'select_references'
    #   errors = selectReferencesTabValidation(errors)
    # else if step == 'select_isolates'
    #   errors = selectIsolatesTabValidation(errors)
    # else if step == 'test_detail'
    #   errors = testDetailTabValidation(errors)

    tab_wrapper = $("#submission_tab").find("##{tab}")
    # remove all error
    tab_wrapper.find(".form-group").removeClass("is-invalid");
    tab_wrapper.find(".is-invalid").removeClass("is-invalid");
    tab_wrapper.find("a[role='tab']").removeClass("tab-error");
    tab_wrapper.find(".error-message").remove();
    tab_wrapper.find(".tab-error-count").remove();
    tab_wrapper.find(".list-group-item-danger").remove();

    # remove error on current tab
    $("a[href='##{tab}']").find('.error-tab').remove()

  if Object.keys(errors).length > 0
    submissionErrorsFormWarning(errors)
    return true
  else
    initializeSubmissionForm()
    countRefAntiseraAndRefAntigen();
    selectedAntiseraCounterCheck();
    selectedAntigenCounterCheck();
    return false

# add errors warning to current tab's fields
window.submissionErrorsFormWarning = (errors) ->
  keys = Object.keys(errors) # get key of errors

  $.each keys, (k, field) ->
    key = errors[field] # run_type, isolate_qty

    if field is 'ref_antigens' or field is 'ref_antiseras' or field is 'isolates'
      # A panel
      error_tab = key.error_tab
      max = key.max
      ref_name = key.ref_name
      list_group = key.list_group

      tabPanelNotification(error_tab, max, list_group, ref_name)
      error_fields_check()
    else
      first_message = key[0] # ["can't be blank", "must a number"]

      if field is 'staff_ids'
        # list-unstyled
        form_group_wrapper = $("#submission_#{field}")
        # form_group_wrapper.addClass("is-invalid")
        error_tag = "<li class='list-group-item-danger alert'><i>#{first_message}</i></li>"
      else
        fields = ["input[name='submission[#{field}]']", "select[name='submission[#{field}]']", "select[name='submission[#{field}_id]']"]
        field_tag = fields.join(',')

        # get closest .form-group
        form_group_wrapper =  $("#{field_tag}").closest('.form-group')
        error_tag = "<div class='error-message alert alert-danger alert-small'>#{first_message}</div>"

      form_group_wrapper.addClass('is-invalid')
      form_group_wrapper.append(error_tag)

      # add error on current tab
      error_tab = form_group_wrapper.closest('.tab-pane').attr('id')
      $("#antigenic_run_tabs li a[href='#"+error_tab+"']").append("<span style='color:red' class='error-tab'> *</span>")

window.setupTabValidation = (errors) ->
  # Validasi:
  # Run Type (presence)
  # Flu Type (presence)
  # Source (presence)
  # Ref antiseras qty (presence, numeric)
  # Ref antigens qty (presence, numeric)
  # Isolates qty (presence, numeric)

  presence_fields = ['flu_type', 'run_type', 'source', 'ref_antisera_qty', 'ref_antigen_qty', 'isolate_qty']

  numericality_fields = ['ref_antisera_qty', 'ref_antigen_qty', 'isolate_qty']
  numericality_fields =
    ref_antisera_qty:
      greater_than_or_equal_to: 1
    ref_antigen_qty:
      greater_than_or_equal_to: 1
    isolate_qty:
      greater_than_or_equal_to: 1

  validation =
    presence_fields: presence_fields
    numericality_fields: numericality_fields

  # errors = []
  $.each validation, (validation_type, fields) ->
    if "#{validation_type}" is 'presence_fields'
      $.each fields, (key, field) ->
        errors = presenceFieldsValidation("#{field}", '', errors, 'submission')

    if "#{validation_type}" is 'numericality_fields'
      $.each fields, (field, terms) ->
        errors = numericalityFieldsValidation(field, terms, errors, 'submission')

  return errors

window.selectReferencesTabValidation = (errors) ->
  run_type = $("#submission_run_type").val()
  max_ref_antisera = $("#submission_ref_antisera_qty").val()
  max_ref_antigen = $("#submission_ref_antigen_qty").val()
  ref_antisera_count = $("#ref_antisera_ids_table tbody tr").length
  ref_antigen_count = $("#ref_antigen_ids_table tbody tr").length
  if (parseInt(max_ref_antigen) != ref_antigen_count)
    errors['ref_antigens'] =
      error_tab: "select_references"
      max: max_ref_antigen
      ref_name: "antigen"
      list_group: $("#ref_antigen_ids_table")
  if (parseInt(max_ref_antisera) != ref_antisera_count)
    errors['ref_antiseras'] =
      error_tab: "select_references"
      max: max_ref_antisera
      ref_name: "antisera"
      list_group: $("#ref_antisera_ids_table")
  return errors

window.selectIsolatesTabValidation = (errors) ->
  isolate_max = $("#submission_isolate_qty").val()
  isolate_count = $('#run_isolate_selected li').length

  if isolate_count != parseInt(isolate_max)
    errors['isolates'] =
      error_tab: "select_isolates"
      max: isolate_max
      ref_name: "Isolates"
      list_group: $("#run_isolate_selected")

  return errors

window.testDetailTabValidation = (errors) ->
  # presence_fields = ['test_date', 'staff_ids', 'is_same_reference_size', 'is_same_isolate_size']
  presence_fields =
    test_date:
      message: ''
    staff_ids:
      message: 'Choose at least 1 staff'
    is_same_reference_size:
      message: 'Must be same reference size'
    is_same_isolate_size:
      message: 'Must be same isolate size'

    # errors.add(:test_date, :blank) if self.test_date.blank?
    # errors.add(:staff_ids, 'Choose at least 1 staff') if self.staff_ids.size < 1
    # errors.add(:is_same_reference_size, 'Must be same reference size') if self.is_same_reference_size == false
    # errors.add(:is_same_isolate_size, 'Must be same isolate size') if self.is_same_isolate_size == false


    # $.each presence_fields, (key, field) ->
  $.each presence_fields, (f, msg) ->
    field = f
    message = ""
    $.each msg, (k, custom_msg) ->
      message = custom_msg

    errors = presenceFieldsValidation("#{field}", message, errors, 'submission')

  return errors
  # errors["test"] = $.merge( $.merge( [], errors["test"] ), ["can't be blank"] )

window.fieldValidatedValue = (field_validate, model =  null) ->
  # ===================== MODEL=====================
  # NULL                    |  Presence (model = 'submission')
  # input[name='run_type']  |  input[name='submission[run_type]']

  if model == null
    field = "#{field_validate}"
    select_field = field
  else
    field = "#{model}[#{field_validate}]"
    select_field = "#{model}[#{field_validate}_id]"

  fields = ["input[name='#{field}']", "select[name='#{field}']", "select[name='#{select_field}']"]

  if field_validate is 'staff_ids'
    fields.push("input[name='#{field}[]']:checked")

  field_tag = fields.join(',')

  field_value = $("#{field_tag}").val()

  return field_value

# Validate the presence of the field
window.presenceFieldsValidation = (field_validate, custom_message, errors, model =  null) ->
  field_value = fieldValidatedValue(field_validate, model)
  if custom_message is ''
    custom_message = "can't be blank"

  # check blank
  if field_value is undefined or field_value is ''
    if errors["#{field_validate}"] is undefined  # no errors field before
      errors["#{field_validate}"] = []

    errors["#{field_validate}"] = $.merge( $.merge( [], errors["#{field_validate}"] ), ["#{custom_message}"] )

  return errors

# Validate the numaricality of the field
window.numericalityFieldsValidation = (field_validate, terms, errors, model =  null) ->
  field_value = fieldValidatedValue(field_validate, model)

  messages = [] # all messages in here

  if isNaN(field_value) # string
    messages.push('must be a number')
  else
    $.each terms, (term, value) ->
      # greater_than_or_equal_to
      if "#{term}" is "greater_than_or_equal_to"
        unless field_value >= value
          messages.push("must be greater than or equal to #{value}")
      # less_than_or_equal_to
      if "#{term}" is "less_than_or_equal_to"
        unless field_value <= value
          messages.push("must be less than or equal to #{value}")

  if messages.length > 0 #if message length > 0 will assume that there's errors
    if errors["#{field_validate}"] is undefined # no errors field before
      errors["#{field_validate}"] = []
    errors["#{field_validate}"] = $.merge( $.merge( [], errors["#{field_validate}"] ), messages )

  return errors

#antisera and antigen check (for disabling update button)
window.antiseraAntigenCheck = () ->
  if asBatch.length >= refAntiseraMax and agBatch.length >= refAntigenMax
    $('input:submit[name=\'reference_tab\']').removeAttr('disabled')
  else
    $('input:submit[name=\'reference_tab\']').attr('disabled', 'disabled')

window.initializeSubmissionForm = () ->
  window.asBatch = []
  window.agBatch = []
  window.subkit_ids = []

  window.refAntiseraMax = $("#submission_ref_antisera_qty").val()
  window.refAntigenMax = $("#submission_ref_antigen_qty").val()
  window.maxisolate = $("#submission_isolate_qty").val()
  window.runisolatecounter = $('#run_isolate_selected li').length

  isolate_counter_check()
  error_fields_check()

window.nextToTestDetailStep = () ->
  # Disable 3 steps -> setup, select reference, and select isolate
  disabled = ['#setup', '#select_references', '#select_isolates']
  $("#antigenic_run_tabs").find('li').each ->
    this_li = $(this)
    link_href = this_li.find('a').attr('href')
    if disabled.includes("#{link_href}")
      this_li.attr('disabled', true)
      this_li.addClass('disabled')
    else
      this_li.removeClass('disabled')

window.countRefAntiseraAndRefAntigen = () ->
  #count ref_antisera, and save antiseras to array
  refAntiseraCount = $('#ref_antisera_ids_table tr').length
  if refAntiseraCount > 0
    $('#ref_antisera_ids_table tr').each ->
      ref_id = $(this).data('ref-id')
      kit_id = $(this).data('kit-id')
      window.asBatch.push("#{kit_id}_#{ref_id}")

  #count ref_antigen, and save antigens to array
  refAntigenCount = $("#ref_antigen_ids_table tr").length
  if refAntigenCount > 0
    $('#ref_antigen_ids_table tr').each ->
      ref_id = $(this).data('ref-id')
      kit_id = $(this).data('kit-id')
      window.agBatch.push("#{kit_id}_#{ref_id}")

  # $('#ref_antisera_ids_table tbody').sortable(
  #   axis: "y"
  #   update: (event, ui) ->
  #     $(this).parents('table').find('tr').each ->
  #       $(this).children('td:first-child').html $(this).index()+1
  #       return
  #     return
  #   ).disableSelection()

  # $('#ref_antigen_ids_table tbody').sortable(
  #   axis: "y"
  #   update: (event, ui) ->
  #     $(this).parents('table').find('tr').each ->
  #       $(this).children('td:first-child').html $(this).index()+1
  #       return
  #     return
  #   ).disableSelection()

window.removeSelectedBatch = ($this_wrapper, ref_type) ->
  # this_wrapper = $('#ref_antisera_'+batch_arr)
    ref_wrapper = $this_wrapper.closest('.selected-references').parent()
    if $this_wrapper.hasClass("saved-ref")
      sub_ref_id = $this_wrapper.data('id')
      destroy_template = "<input type='hidden' name='submission[sub_ref_#{ref_type}s_attributes][#{sub_ref_id}][id]' value='#{sub_ref_id}'><input type='hidden' name='submission[sub_ref_#{ref_type}s_attributes][#{sub_ref_id}][_destroy]' value='1'>"
      ref_wrapper.find('.destroy-selected').append(destroy_template)
    $this_wrapper.remove()

# ==================================== SUBMISSIONS CHART ===========================================

window.getSubmissionsData = () ->
  submissions_data = {}
  if typeof $("input[name=\"submissionsData\"]").val() == 'undefined'
    submissions_data = {}
  else
    submissions_data = $.parseJSON($("input[name=\"submissionsData\"]").val())
  return submissions_data

window.loadSubmissionsVetlab = () ->
  submissions_vet_lab_data = {}
  submissions_vet_lab_master = []
  if typeof $("input[name=\"runVetlabsData\"]").val() == 'undefined'
    # nothing
  else
    submissions_vet_lab_data = $.parseJSON($("input[name=\"runVetlabsData\"]").val())
  $.each submissions_vet_lab_data, (key_vl, value_vl) ->
    submissions_vet_lab_master.push(value_vl.vet_lab_code)
  return submissions_vet_lab_master

window.submissions_vet_lab_master = loadSubmissionsVetlab()

window.getNumberOfSubmissions  = (y, m, v) ->
  reports_data = getSubmissionsData()
  loi = 0
  $.each reports_data, (key_reports, value_reports) ->
    if parseInt(y) == parseInt(value_reports.yyyy) and v == value_reports.vet_lab_code
      loi += value_reports.counter
  return loi

window.initializeSubmissionReport = () ->
  submissions_month_array = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  # $("#active_chart").val("0")
  submissions_min_year = 0
  if typeof $("input[name=\"runYearsData\"]").val() == 'undefined'
    # nothing
  else
    submissions_years_data = $.parseJSON($("input[name=\"runYearsData\"]").val())
    submissions_min_year = submissions_years_data[0].years
  submissions_max_year = new Date().getFullYear()

  return { submissions_month_array: submissions_month_array, submissions_min_year: submissions_min_year, submissions_years_data: submissions_years_data, submissions_max_year: submissions_max_year }

window.createSubmissionsReports = (start_month, end_month, start_year, end_year, vetlab) ->
  init = initializeSubmissionReport()
  submissions_month_array = init.submissions_month_array
  submissions_min_year = init.submissions_min_year
  submissions_years_data = init.submissions_years_data
  submissions_max_year = init.submissions_max_year

  submissions_data = getSubmissionsData()
  submissions_string_reports = "["

  year = submissions_min_year
  submissions_max_year = new Date().getFullYear()
  if start_year != 0 and end_year !=0
    year = start_year
    submissions_max_year = end_year

  month = 1
  max_month = 12
  total_month = 12
  if start_month != 0 and end_month !=0
    month = start_month
    total_month = (end_year - start_year)*12 + end_month - start_month + 1
    max_month = end_month

  vet_lab_each = submissions_vet_lab_master
  if vetlab != 0
    vet_lab_each = vetlab

  while year <= submissions_max_year
    # $.each submissions_month_array, (key_month, val_month) ->
    while year <= submissions_max_year or month <= max_month
      submissions_string_reports += "{ \"year\" : "+year+","
      submissions_string_reports += "\"month\" : \""+submissions_month_array[(month-1)]+"\","
      $.each vet_lab_each, (key_vl, val_vl) ->
        number_of_submissions = getNumberOfSubmissions(year, submissions_month_array[(month-1)], val_vl)
        submissions_string_reports += "\""+val_vl+"\" : "+number_of_submissions+","
      submissions_string_reports = submissions_string_reports.substring(0, submissions_string_reports.length - 1);
      submissions_string_reports += " },"
      if year == submissions_max_year and month == max_month
        break
      month++
      if month == 13
        break
    year++
    month = 1
  submissions_string_reports = submissions_string_reports.substring(0, submissions_string_reports.length - 1);
  submissions_string_reports += "]"
  return submissions_string_reports

window.createSubmissionsYearChart = (start_month, end_month, start_year, end_year, vetlab) ->
  reports_string = createSubmissionsReports(start_month, end_month, start_year, end_year, vetlab)

  # Set Margin
  margin =
    top: 20
    right: 20
    bottom: 40
    left: 60

  w = $(window).width() - 100

  width = w - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  # x scale
  x0 = d3.scale.ordinal()
    .rangeRoundBands([0, width], .1)
  x1 = d3.scale.ordinal()

  # y scale
  # y = d3.scale.linear()
    # .rangeRound([height, 0])
  y = d3.scale.linear()
    .range([height, 0]);

  # color
  color = d3.scale.ordinal()
    .range(["#f39c12", "#c0392b", "#16a085", "#2980b9", "#d35400", "#2c3e50", "#8e44ad", "#27ae60", "#a0a0a0"])

  # set bottom axis
  xAxis = d3.svg.axis()
    .scale(x0)
    .orient("bottom")

  # set left axis
  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")
    # .tickFormat(d3.format(".2s"))

  #create chart template
  canvas = d3.select("#run_reports_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.bottom + margin.top)
    .append("g")
    .attr("transform", "translate("+margin.left+","+margin.top+")")

  data = []
  if typeof reports_string == 'undefined'
    data = []
  else if reports_string.length > 2
    data = jQuery.parseJSON(reports_string)

  dicName = d3.keys(data[0]).filter((key) -> key != "year" and key != "month")
  data.forEach (d) ->
    d.dic = dicName.map((name) ->
      {
        name: name,
        counter: +d[name]
      }
    )
    return

  x0.domain(data.map((d) -> d.year))
  x1.domain(dicName).rangeRoundBands([0, x0.rangeBand()])
  y.domain([0, (10 + d3.max(data, (d) -> d3.max(d.dic, (d) -> d.counter) ))])

  canvas.append("line")
    .attr('x1', 1)
    .attr('x2', 100)
    .attr('y1', 1)
    .attr('y2', 100)

  canvas.append("g")
    .attr("class", "x axis year")
    .attr("transform", "translate(0,"+height+")")
    .call(xAxis)

  canvas.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Number of Submissions")

  bars = canvas.selectAll(".dic")
    .data(data)
    .enter().append("g")
      .attr("class", "dic")
      .attr("transform", (d) -> "translate("+x0(d.year)+",0)")

  bars.selectAll("rect")
    .data((d) -> d.dic)
    .enter().append("rect")
      .attr("width", x1.rangeBand())
      .attr("x", (d) -> x1(d.name))
      .attr("y", (d) -> y(d.counter))
      .attr("height", (d) -> height - y(d.counter))
      .style("fill", (d) -> color(d.name))
      .attr("data-toggle", "tooltip")
      .attr("data-placement", "top")
      .attr("title", (d) -> d.counter)
      .on("click", (d) -> alert(d.counter))

  bars.selectAll("text")
    .data((d) -> d.dic)
    .enter().append("text")
      # .attr("width", x1.rangeBand())
      .attr("transform", "rotate(-90)")
      .attr("text-anchor", "middle")
      .attr("x", (d,i) -> -y(d.counter) + 40)
      .attr("y", (d) -> x1(d.name) + 20)
      # .attr("height", (d) -> height - y(d.counter))
      # .style("fill", (d) -> color(d.name))
      .text((d) -> "#{d.name} (#{d.counter})")

  # create legend
  legend = canvas.selectAll(".legend")
    .data(dicName.slice().reverse())
    .enter().append("g")
    .attr("class", "legend")
    .attr("transform", (d, i) -> "translate(0,"+(i*20)+")")

  legend.append("rect")
    .attr("x", width - 18)
    .attr("width", 18)
    .attr("height", 18)
    .style("fill", color)

  legend.append("text")
    .attr("x", width - 24)
    .attr("y", 9)
    .attr("dy", ".35em")
    .style("text-anchor", "end")
    .text((d) -> d)

  #add link to x axis
  # d3.selectAll(".x.axis.year text")
  #   .style("cursor", "pointer")
  #   .style("text-decoration", "underline")
  #   .attr("transform", "translate(-20,10) rotate(-45)")
  #   .on("click", (d) ->
  #     $("#reports_chart").toggle("hide")
  #     $("#chart_status").append("<span>"+d+"</span>")
  #     $("#active_chart").val(d)
  #     $("#chart_status").show()
  #     if (d != start_year)
  #       start_month = 1
  #     createMonthChart(start_month, end_month, d, d, vetlab)
  #   )

# ==================================== STACKED YEAR CHART ====================================

# getnumberofstackedSubmissionsdata by year, month, vetlab and field
window.getNumberOfStackedSubmissionsData  = (y, m, v, f) ->
  submissions_reports_data = getSubmissionsData()
  invalid_run = 0
  valid_run = 0
  $.each submissions_reports_data, (key_reports, value_reports) ->
    if parseInt(y) == parseInt(value_reports.yyyy) and v == value_reports.vet_lab_code
      if value_reports.invalid_run > 0
        invalid_run++
      else if value_reports.valid_run > 0
        valid_run++
  return [valid_run, invalid_run]

window.createReportsForSubmissionsStackedChart = (start_month, end_month, start_year, end_year, vetlab, missing_data) ->
  init = initializeSubmissionReport()
  submissions_month_array = init.submissions_month_array
  submissions_min_year = init.submissions_min_year
  submissions_years_data = init.submissions_years_data
  submissions_max_year = init.submissions_max_year

  submissions_data = getSubmissionsData()
  submissions_string_reports = "["

  year = submissions_min_year
  submissions_max_year = new Date().getFullYear()
  if start_year != 0 and end_year !=0
    year = start_year
    submissions_max_year = end_year

  month = 1
  max_month = 12
  total_month = 12
  if start_month != 0 and end_month !=0
    month = start_month
    total_month = (end_year - start_year)*12 + end_month - start_month + 1
    max_month = end_month

  vet_lab_each = submissions_vet_lab_master
  if vetlab != 0
    vet_lab_each = vetlab

  while year <= submissions_max_year
    while year <= submissions_max_year or month <= max_month
      submissions_string_reports += "{ \"year\" : "+year+","
      submissions_string_reports += "\"month\" : \""+submissions_month_array[(month-1)]+"\","
      number_of_submissions = getNumberOfStackedSubmissionsData(year, submissions_month_array[(month-1)], vetlab, missing_data)
      submissions_string_reports += "\"valid_run\" : "+number_of_submissions[0]+","
      submissions_string_reports += "\"invalid_run\" : "+number_of_submissions[1]+","

      submissions_string_reports = submissions_string_reports.substring(0, submissions_string_reports.length - 1);
      submissions_string_reports += " },"
      if year == submissions_max_year and month == max_month
        break
      month++
      if month == 13
        break
    year++
    month = 1
  submissions_string_reports = submissions_string_reports.substring(0, submissions_string_reports.length - 1);
  submissions_string_reports += "]"
  return submissions_string_reports
# createReportsForSubmissionsStackedChart(0,0,0,0,"BBVD", "control_data_issue")

window.createStackedChartForSubmissions = (start_month, end_month, start_year, end_year, vet_lab_filter, missing_data) ->
  string_reports = createReportsForSubmissionsStackedChart(start_month, end_month, start_year, end_year, vet_lab_filter, missing_data)

  margin =
    top: 20
    right: 20
    bottom: 30
    left: 40

  w = $(window).width() - 100
  width = w - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  x = d3.scale.ordinal()
    .rangeRoundBands([0, width], 0.1)

  y = d3.scale.linear()
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")
    # .tickFormat(d3.format(".2s"))

  color = d3.scale.ordinal()
    .range(["#2980b9", "#c0392b"])

  svg = d3.select("#run_reports_chart_"+vet_lab_filter).append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

  data = []
  if typeof string_reports == 'undefined'
    data = []
  else if string_reports.length > 2
    data = jQuery.parseJSON(string_reports)
  console.log(string_reports)

  color.domain(d3.keys(data[0]).filter((key) -> key != "year" and key != "month"));

  data.forEach((d) ->
    y0 = 0;
    d.vetlab = color.domain().map((name) -> {
        name: name,
        y0: y0,
        y1: y0 += +d[name]
    })
    d.counter = d.vetlab[d.vetlab.length - 1].y1
  )

  # data.sort((a, b) -> b.counter - a.counter)

  x.domain(data.map((d) -> d.year ))
  y.domain([0, d3.max(data, (d) -> d.counter )])

  svg.append("g")
    .attr("class", "x axis stacked-year")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", 6)
    .attr("dy", ".71em")
    .style("text-anchor", "end")
    .text("Number of Submissions");

  year = svg.selectAll(".year")
    .data(data)
    .enter().append("g")
    .attr("class", "g")
    .attr("transform", (d) -> "translate(" + x(d.year) + ",0)" );

  year.selectAll("rect")
    .data((d) -> d.vetlab)
    .enter().append("rect")
    .attr("width", x.rangeBand())
    .attr("y", (d) -> y(d.y1))
    .attr("height", (d) -> y(d.y0) - y(d.y1))
    .style("fill", (d) -> color(d.name))
    # .attr("data-toggle", "tooltip")
    # .attr("data-placement", "top")
    # .attr("title", (d) -> d.counter)
    # .on("click", (d) -> alert(d.counter))

  #add link to x axis
  # d3.selectAll(".x.axis.stacked-year text")
  #   .style("cursor", "pointer")
  #   .style("text-decoration", "underline")
  #   .attr("transform", "translate(-20,10) rotate(-45)")
  #   .on("click", (d) ->
  #     console.log("#reports_chart_"+vet_lab_filter+" svg")
      # $("#reports_chart_"+vet_lab_filter+" svg").remove()
      # $("#stacked_chart_status"+vet_lab_filter).append("<span>"+d+"</span>")
      # $("#active_chart"+vet_lab_filter).val(d)
      # if (d != start_year)
      #   start_month = 1
      # createStackedChartForSubmissionsByMonth(start_month, end_month, d, d, vet_lab_filter, missing_data)
      # createMonthChart(start_month, end_month, d, d, vetlab)
    # )

  # create legend
  legend = svg.selectAll(".legend")
    .data(color.domain().slice().reverse())
    .enter().append("g")
    .attr("class", "legend")
    .attr("transform", (d, i) -> "translate(0," + (i+1) * 20 + ")");

  legend.append("rect")
    .attr("x", width - 18)
    .attr("width", 18)
    .attr("height", 18)
    .style("fill", color);

  legend.append("text")
    .attr("x", width - 24)
    .attr("y", 9)
    .attr("dy", ".35em")
    .style("text-anchor", "end")
    .text((d) -> d)

  vet_lab_data = []
  vet_lab_data.push(vet_lab_filter)
  dic_legend = svg.selectAll(".vl-legend")
    .data(vet_lab_data)
    .enter().append("g")
    .attr("class", "vl-legend")
    .attr("transform", (d, i) -> "translate(0,0)");

  dic_legend.append("text")
    .attr("x", width)
    .attr("y", 9)
    .attr("dy", ".35em")
    .style("text-anchor", "end")
    .text((d) -> "Vetlab : "+d)

# Send ajax to controller for get data to build lv chart
window.sendAjaxLvChart = (d, control_type) ->
  url_target = d.data "url"
  submission_id = d.data "sub-id"
  serum_type = d.data "serum-type"
  if serum_type == 1
    control_name = "hyperimmune"
  else if serum_type == 2
    control_name = "prime_serum"

  if control_type == "select"
    value = d.val()
    lab_id = $(".lab-option-#{control_name}:checked").val()
  else if control_type == "radio"
    value = $("##{control_name}_select option:selected").val()
    lab_id = d.val()

  $.ajax
    url: url_target
    data: {
      id: value,
      lab: lab_id,
      sub_id: submission_id,
      serum_type: serum_type
    }
    dataType: "script"
    method: "GET"

# Function for show lv chart
# This function will call from controller respond js
window.callLvChart = (json_data, sub_id, control) ->
  overideColor = (subid) ->
    if subid.toString() == sub_id
      "red"
    else
      "#2f7ed8"

  true_json = $.parseJSON(json_data)
  antigen = true_json.antigen_name
  antisera = true_json.antisera_name
  data = true_json.data_array
  sd_data = true_json.standard_dev.line_std

  margin = {top: 20, right: 250, bottom: 100, left: 50}
  width = 1000 - margin.left - margin.right
  height = 400 - margin.top - margin.bottom

  x = d3.scale.ordinal()
    .rangeRoundBands([0, width], 1.0)

  y = d3.scale.linear()
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")
    .ticks(3)

  tip = d3.tip()
    .attr("class", "d3-tip")
    .offset([-10, 0])
    .html((d) -> "<small>"+d.test_date+"</small><br/>"+antigen+": "+d.value)

  svg = d3.select("##{control}_chart_box")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.call(tip)

  x.domain(data.map((d) -> d.test_date))
  y.domain([0, 15])

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)

  hline = d3.svg.line()
    .x((d) -> x(d.test_date))
    .y((d) -> y(d.value))

  std_line = svg.append("g")
    .selectAll(".std-line")
    .data(sd_data)
    .enter().append("g")
    .attr("class", "std-line")

  std_line.append("line")
    .attr("class", "line")
    .attr("x1", 0)
    .attr("x2", width)
    .attr("y1", (d) -> y(d.position))
    .attr("y2", (d) -> y(d.position))
    .style("stroke", (d) -> d.color)
    .style("stroke-dasharray", "3,3")

  std_line.append("text")
    .attr("x", width+5)
    .attr("y", (d) -> y(d.position))
    .attr("dy", ".30em")
    .text((d) -> d.title+" = "+d.position)

  svg.append("g")
    .append("path")
      .attr("fill", "none")
      .attr("d", hline(data))
      .attr("stroke", "#2f7ed8")

  svg.append("g")
    .selectAll(".dot")
      .data(data)
    .enter()
      .append("circle")
      .attr("class", "std-dot")
      .attr("r", 4)
      .attr("cx", (d) -> x(d.test_date))
      .attr("cy", (d) -> y(d.value))
      .attr("fill", (d) -> overideColor(d.subid))
      .on("mouseover", tip.show)
      .on("mouseout", tip.hide)

  legend = svg.append("g")
  legen_ag = legend.append("g")
  legen_ag.append("circle")
    .attr("r", 5)
    .attr("cx", width+5)
    .attr("cy", height-15)
    .attr("fill", "#2f7ed8")
  legen_ag.append("text")
    .attr("x", width+11)
    .attr("y", height-10)
    .text(antigen)

  legen_cag = legend.append("g")
  legen_cag.append("circle")
    .attr("r", 5)
    .attr("cx", width+5)
    .attr("cy", height-30)
    .attr("fill", "red")
  legen_cag.append("text")
    .attr("x", width+11)
    .attr("y", height-25)
    .text("Current Submission")

  chart_title = svg.append("g")
  chart_title.append("text")
    .attr("x", width/2)
    .attr("y", height-height)
    .attr("text-anchor", "middle")
    .attr("style", "font-weight: bold;font-size: 12pt")
    .attr("fill", "#2f7ed8")
    .text(antisera)

  y_axis_title = svg.append("g")
  y_axis_title.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -height/2)
    .attr("text-anchor", "middle")
    .attr("fill", "#2f7ed8")
    .style("font-weight", "bold")
    .text("Titre")

  x_axis_title = svg.append("g")
  x_axis_title.append("text")
    .attr("y", height+100)
    .attr("x", width/2)
    .attr("text-anchor", "middle")
    .attr("fill", "#2f7ed8")
    .style("font-weight", "bold")
    .text("Date of Tested")

# ==================================== SUBMISSIONS LIST ===========================================
#

# |=== Options ===| #

# Add active class on tr if submission has antigenic map issue
window.hasAntigenicMapIssueCheck = () ->
  # .has-antigenic-map-issue terletak di divider option
  # .has-antigenic-map-issue ditambahkan saat load data dataTable (helper)
  tr = $(".has-antigenic-map-issue").closest('tr')
  tr.addClass('active')

# Add title on tr if submission has no anchor selected
window.hasNoAnchorCheck = () ->
  # .has-no-anchor terletak di divider option
  # .has-no-anchor ditambahkan saat load data dataTable (helper)
  tr = $(".has-no-anchor").closest('tr')
  tr.attr('title', 'No antigens anchor selected')

# |=== Filters ===| #

# Reset group filter
window.clearSubmissionGroupFilter = () ->
  $("#group_option").closest(".radio").remove()
  $("#title").html("Run List")
  $(".side-submission-data-options").prop("disabled", false)
  $("#submission_group_id").val('')
  $(".submission-group-btn").addClass('hide')

# Reset all filter
window.clearFormFilterSubmission = () ->
  $(".submission-group-btn").replaceWith('<a class="submission-group-btn btn btn-info" data-remote="true" data-toggle="slide-panel" href="#">Create run from current group.</a>')
  $(".submission-group-btn").addClass('hide')
  form = $("#form_filter_submission_data")
  form.find("#by_start_created_at_start").val("")
  form.find("#by_end_created_at_end").val("")
  form.find("#by_start_test_date_start").val("")
  form.find("#by_end_test_date_end").val("")
  form.find("#by_submission_lab_ option:selected").removeAttr("selected")
  form.find("#my_data").val("")
  lab_data_id = $(".my-lab-data-option").data('options')
  form.find("#my_lab_data").val(lab_data_id)
  form.find("#all_data").val("")
  $("#submission_group_id").val("")
  $(".my-lab-data-option").prop('checked',true)
  form.find('input:radio[name=\'by_submission_type\']:checked').removeAttr('checked')
  form.find('input:radio[name=\'test_data\']:checked').removeAttr('checked')
  form.find('input:radio[name=\'control_data\']:checked').removeAttr('checked')
  $(".control-table-column").prop('checked', true)

# adjust checked data option on index (not side panel)
# used after do filter on side panel filter
window.filterDataSubmissionOption = () ->
  my_data = $("#my_data").val()
  my_lab_data = $("#my_lab_data").val()
  all_data = $("#all_data").val()

  if my_data != "" && my_lab_data == "" && all_data == ""
    $(".my-data-option").prop('checked', true)

  if my_data == "" && my_lab_data != "" && all_data == ""
    $(".my-lab-data-option").prop('checked', true)

  if my_data == "" && my_lab_data == "" && all_data != ""
    $(".all-data-option").prop('checked', true)

# Fill data option value on advance filter's form
# used after data option on index clicked
window.filterDataOptionSubmissionOnClick = ($this) ->
  # reset group filter
  clearSubmissionGroupFilter()
  # reset all filter
  # clearFormFilterSubmission()

  # remove values before fill the new value
  $(".hidden-data-options").val("")

  if $this.val() is "my_data"
    options = $this.data("options")
    $("#my_data").val(options)
    $("#all_data").val("")
  else if $this.val() is "my_lab_data"
    options = $this.data("options")
    $("#my_lab_data").val(options)
    $("#all_data").val("")
  else
    $("#my_data").val("")
    $("#my_lab_data").val("")
    $("#all_data").val("true")

# Show custom label for filters is being used
window.submissionFilterLabel = () ->
  label = "Showing "
  label = label + "all "

  # data option
  my_data = $("#my_data").val()
  my_lab_data = $("#my_lab_data").val()
  all_data = $("#all_data").val()
  if my_data != "" && my_lab_data == "" && all_data == ""
    data = filterLabelTemplate("runs you've uploaded")
  else if my_data == "" && my_lab_data != "" && all_data == ""
    data = filterLabelTemplate("runs from your lab")
  else if my_data == "" && my_lab_data == "" && all_data != ""
    data = filterLabelTemplate("runs from all lab")
  else
    name = $("#submission_group_id").attr("sub_name")
    data = filterLabelTemplate('runs from run group "' + name + '"')
  label = label + data

  #date of test
  test_start_date = $("input[name='by_start_test_date[start]']").val()
  test_end_date = $("input[name='by_end_test_date[end]']").val()
  if test_start_date.trim().length > 0 and test_end_date.trim().length == 0
    label = label + filterLabelTemplate("test from #{formatDate(test_start_date)}")
  else if test_start_date.trim().length == 0 and test_end_date.trim().length > 0
    label = label + filterLabelTemplate("test until #{formatDate(test_end_date)}")
  else if test_start_date.trim().length > 0 and test_end_date.trim().length > 0
    label = label + filterLabelTemplate("test between #{formatDate(test_start_date)} until #{formatDate(test_end_date)}")

  #date of setup
  setup_start_date = $("input[name='by_start_created_at[start]']").val()
  setup_end_date = $("input[name='by_end_created_at[end]']").val()
  if setup_start_date.trim().length > 0 and setup_end_date.trim().length == 0
    label = label + filterLabelTemplate("setup from #{formatDate(setup_start_date)}")
  else if setup_start_date.trim().length == 0 and setup_end_date.trim().length > 0
    label = label + filterLabelTemplate("setup until #{formatDate(setup_end_date)}")
  else if setup_start_date.trim().length > 0 and setup_end_date.trim().length > 0
    label = label + filterLabelTemplate("setup between #{formatDate(setup_start_date)} until #{formatDate(setup_end_date)}")

  #submission type
  type = $("input[name='by_submission_type']:checked").val()
  if type == "1"
    label = label + filterLabelTemplate("type: Pre Screening")
  else if type == "2"
    label = label + filterLabelTemplate("type: Full Panel")

  # vet lab
  vet_lab = []
  vet_lab_wrapper = $("#form_filter_submission_data")
  vet_lab_wrapper.find("#by_submission_lab_ option:selected").each ->
    vet_lab.push($(this).text())
  unless vet_lab.length == 0
    label = label + filterLabelTemplate("by Vetlab: (#{vet_lab.join(', ')})")

  # has test issue
  test_data_val = $("input[name='test_data']:checked").val()
  if test_data_val == "true"
    label = label + filterLabelTemplate("with test data issue")
  else if test_data_val == "false"
    label = label + filterLabelTemplate("with no test data issue")
  else if test_data_val == "na"
    label = label + filterLabelTemplate("not Available test data issue")

  # has control issue
  control_data_val = $("input[name='control_data']:checked").val()
  if control_data_val == "true"
    label = label + filterLabelTemplate("with control data issue")
  else if control_data_val == "false"
    label = label + filterLabelTemplate("with no control data issue")
  else if control_data_val == "na"
    label = label + filterLabelTemplate("not Available control data issue")

  $("#data_option_detail").html(label)

# |=== Checkbox submission ===| #

# Array for checked submissions
window.checkedSubmissions = []
window.checkedSubmissionsOnForm = []

# re-check the checked submission list
window.checkSubmissionInput = () ->
  wrapper = $("#submissions").find('tbody').find('tr')
  for checkIsolate, index in checkedSubmissions
    wrapper.find("#submission_group_submission_ids_#{checkIsolate.id}").prop("checked", true)

# Reset the array for selected submission
# empty array
window.resetSubmissionSelected = () ->
  window.checkedSubmissions = []
  $('#submissions').find('input:checkbox[name=\'submission_group[submission_ids][]\']').attr('checked', false);
  # submissionCounterCheck()

# Reset the array for selected submission on form
# empty array
window.resetSubmissionSelectedOnForm = () ->
  window.checkedSubmissionsOnForm = []

# append html for checked submission to submission group form
window.renderCheckedSubmissions = () ->
  $submissionTable = $(".submission-table")
  $submissionGroupTable = $(".submission-group-table-modal")
  $isolateGroupList = $(".isolate-group-list")
  $submissionGroupTable.find('tbody tr').remove()
  $isolateGroupList.empty();

  for x in checkedSubmissionsOnForm
    id = x.id
    submissionName = "Submission_"+id+""
    date = x.date
    lab = x.lab
    type = x.type
    total_isolate = x.total_isolate

    # SUBMISSION GROUP LIST
    $submissionGroupRow = $("<tr><td style='width: 40px'>"+"<input submission-id='" + id + "' id='submission_group_submission_ids_' type='checkbox' submission-id='"+id+"' name='submission_group[submission_ids][]' value='" + id + "' submission-name='" + submissionName + "' checked>"+"</td><td style='width: 130px;'>"+date+"</td><td style='width: 130px;'>"+lab+"</td><td style='width: 130px;'>"+type+"</td><td style='width: 120px; text-align: center'>"+total_isolate+"</td></tr>")
    $submissionGroupRow.find("input:checkbox").click ->
      isolateId = $(this).attr("submission-id")
      if !$(this).prop('checked')
        # $isolateTable.find("input:checkbox[name='isolate_group[isolate_ids][]'][value='" + isolateId + "']").prop("checked", false)
        $(this).closest('tr').remove()

    $submissionGroupTable.append($submissionGroupRow)
  submissionCounterCheck()

# check the checked submisison for show/hide objects
window.submissionCounterCheck = () ->
  if (window.checkedSubmissions.length > 0)
    $("#submission_selected").find(".selected-size").html("#{checkedSubmissions.length} selected")
    # $("#submission_selected").find("small").html("<div class='selected-size'>"+checkedSubmissions.length+" selected</div>")
    $("#submission_selected").removeClass('hide')
  else
    $("#submission_selected").addClass('hide')
    $("#isolate_group_modal_").modal('hide')
  return
# remove unchecked submission from array of checked submission
window.removeCheckedSubmissions = (submissionId) ->
  for checkSubmission, index in checkedSubmissions
    if "#{checkSubmission.id}" == "#{submissionId}"
      window.checkedSubmissions.splice(index, 1)
      window.checkedSubmissionsOnForm.splice(index, 1)
      break
  $("#submission_group_submission_ids_#{submissionId}").prop('checked', false)

# |=== Submission Table ===| #

window.submissionDataTablesLoad = () ->
  url = $("#submissions").data('url')
  $("#submissions").DataTable
    'destroy': true
    'searching': false
    'ajax':
      'url': url
      'data': $("#submission_filter_slide_panel").find("input, select").serializeJSON()
    'processing': true
    'serverSide': true
    'fnDrawCallback': ->
      # options
      hasAntigenicMapIssueCheck()
      hasNoAnchorCheck()

      # columns
      showHideTableColumn() # isolates.coffee

      # adjust checked data option on index
      filterDataSubmissionOption()

      # Show custom label for filters is being used
      submissionFilterLabel()

      # re-check the checked submission list
      checkSubmissionInput()

      # close side panel
      # $('.new-slide-panel').css 'display', 'none' #desprecated, now using modal for slide panel
      $(".submission-modal").modal('hide')
      return
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        # no sorting for these columns below
        'col-checkbox-column'
        'col-options-column'
        'col-spesification'
        'col-control-issue'
        'col-test-issue'
        'col-antigen-qty'
        'col-antisera-qty'
      ]
    } ]
    "order": [[ 10, "desc" ]] # sorting table using column 10 on load (created at)
    start: 0

# Re-load the table
window.submissionDataTablesReset = () ->
  table = submissionDataTablesLoad()
  table.ajax.reload()

# |=== Submission Group ===| #

window.getIsolateFromSubmissionGroup = (arr) ->
  $("#submission_group_isolates_modal").find('.submission-isolate-list input:checked').each ->
    isolateId = $(this).val()
    isolateName = $(this).data('isolate-name')
    isolate = {id: isolateId, name: isolateName}
    arr.push(isolate)
  return arr

window.submissionGroupIsolates = (arr, tmp) ->
  $.each arr, (k, v) ->
    id = v.id
    name = v.name
    isolateList = "<li id='run_isolate_"+id+"'><label><input type='checkbox' name='submission[isolate_ids][]' id='remove_run_isolate_result_"+id+"' class='selected-run-isolate remove-run-isolate' value='"+id+"' data-isolate-name='"+name+"' data-isolate-id="+id+" checked='checked'> "+name+"</label></li>"
    tmp.append(isolateList)

# |=== Submission Sneak Peek ===| #

window.produceSubmissionSneakPeek = (sneak_peek_json, json_link, tr_wrapper) ->
  template_wrapper = $("#sneak_peeks_wrapper")
  sneak_peek_template = template_wrapper.find("#sneak_peek_template").html()
  has_prev = not tr_wrapper.is(":first-child")
  has_next = not tr_wrapper.is(":last-child")

  if sneak_peek_json is undefined
    $.ajax
      url: json_link
      dataType: 'json'
      method: 'GET'
      success: (data_retrieved) ->
        json_data = JSON.stringify(data_retrieved)
        tr_wrapper.attr('data-sneak-peak-json', json_data)

        template_data =
          submission: data_retrieved
        template_data["submission"]["has_prev"] = has_prev
        template_data["submission"]["has_next"] = has_next

        template_wrapper.find("#sneak_peek_wrapper").html(Mustache.render(sneak_peek_template, template_data))
        unless $("#submission_sneak_peek_modal").hasClass("in")
          $("#submission_sneak_peek_modal").modal('show')
  else
    data = sneak_peek_json

    template_data =
      submission: data
    template_data["submission"]["has_prev"] = has_prev
    template_data["submission"]["has_next"] = has_next

    template_wrapper.find("#sneak_peek_wrapper").html(Mustache.render(sneak_peek_template, template_data))
    unless $("#submission_sneak_peek_modal").hasClass("in")
      $("#submission_sneak_peek_modal").modal('show')

ready = ->
  # createSubmissionsYearChart(0,0,0,0,0)

  # ==================================== SUBMISSIONS LIST ===========================================
  #

  # |=== Filters ===| #

  # filter list using submission group
  $('body').on 'click', ".submission-group", (e) ->
    e.preventDefault()
    id = $(this).data('ida')
    submission_group_name = $(this).data('name')

    # Change title
    $("#title").html("Run Group")

    # Reset
    $("input:radio[name=\'data_options\']:checked").removeAttr('checked')
    $("#my_data , #my_lab_data , #all_data").val("")
    $("#group_option").closest(".radio").remove()
    $(".side-submission-data-options").attr("checked", false)
    $(".submission-data-options").prop("checked", false)
    $(".side-submission-data-options").prop("disabled", true)

    $('.new-slide-panel').css 'display', 'none'

    $("#submission_group_id").val(id)
    $("#submission_group_id").attr("sub_name", submission_group_name)

    $(".submission-group-btn").removeClass('hide').attr("href", "/submission_groups/#{id}")
    $("#submission_data_option").append('<div class="radio"><label><input type="radio" name="group_option" id="group_option" value="" checked="checked">This group data</label></div>')

    # reset array for selected submission
    # empty the array
    resetSubmissionSelected();
    resetSubmissionSelectedOnForm();
    renderCheckedSubmissions();

    # Reload the submission table
    submissionDataTablesReset()

  # reset list after filter use group (show group)
  # reset list using current filter (not group filter)
  $("body").on "click", ".submission-data-options", ->
    $(".submission-group-btn").replaceWith('<a class="submission-group-btn btn btn-info" data-remote="true" data-toggle="slide-panel" href="#">Create run from current group.</a>')
    $(".submission-group-btn").addClass('hide')
    $this = $(this)
    # remove group filter
    $("#submission_group_id").val("")

    if $this.is(":checked")
      # Fill data option value on advance filter's form
      filterDataOptionSubmissionOnClick($this)
      # Reload the submission table
      submissionDataTablesReset()

  # |=== Advanced Filters ===| #

  # apply filter button (#submit_run_filter_btn) on advanced filter (side panel)
  $("body").on "click", "#submit_run_filter_btn", (e) ->
    e.preventDefault()
    submissionDataTablesReset()

  # data option radios (.side-submission-data-options) on advanced filter (side panel)
  $("body").on "click", ".side-submission-data-options", ->
    $this = $(this)
    if $this.is(":checked")
      # Fill data option value on advance filter's form
      filterDataOptionSubmissionOnClick($this)

  $("body").on "click", ".clear-select-submission", (e) ->
    e.preventDefault()
    resetSubmissionSelected()
    resetSubmissionSelectedOnForm()
    renderCheckedSubmissions()
    submissionCounterCheck()

  # |=== Sneak peek ===| #
  $('body').on 'click', '.submission-sneak-peek-btn', (e) ->
    e.preventDefault()
    tr_wrapper = $(this).closest('tr')
    sneak_peek_json = tr_wrapper.data('sneak-peak-json')
    json_link = $(this).data('json-link')

    produceSubmissionSneakPeek(sneak_peek_json, json_link, tr_wrapper)

  $('body').on 'click', '.show-previous-submission-sneak-peek', (e) ->
    e.preventDefault()
    submission_id = $(this).data("submission-id")
    table_wrapper = $("#submissions_wrapper")
    current_tr = table_wrapper.find("#submission_#{submission_id}").closest("tr")

    previous_tr = $(current_tr.prev()[0])
    sneak_peek_json = previous_tr.data('sneak-peak-json')
    json_link = previous_tr.find(".submission-sneak-peek-btn").data('json-link')

    produceSubmissionSneakPeek(sneak_peek_json, json_link, previous_tr)

  $('body').on 'click', '.show-next-submission-sneak-peek', (e) ->
    e.preventDefault()
    submission_id = $(this).data("submission-id")
    table_wrapper = $("#submissions_wrapper")
    current_tr = table_wrapper.find("#submission_#{submission_id}").closest("tr")

    next_tr = $(current_tr.next()[0])
    sneak_peek_json = next_tr.data('sneak-peak-json')
    json_link = next_tr.find(".submission-sneak-peek-btn").data('json-link')

    produceSubmissionSneakPeek(sneak_peek_json, json_link, next_tr)

  # ==================================== SUBMISSIONS GROUP ===========================================

  # disabled submit button on submission group form if no checked submission
  # $('body').on 'change', '.submission-group-table-modal input:checkbox[name=\'submission_group[submission_ids][]\']', ->
  #   submissionId = $(this).val()
  #   if $(this).prop('checked', false)
  #     $(this).closest('tr').remove()
  #     removeCheckedSubmissions(submissionId)
  #     submissionCounterCheck()
  #     renderCheckedSubmissions()

  #   # disabled the submit button
  #   submission_count = $(".submission-group-table-modal").find('tbody tr').length
  #   if submission_count == 0
  #     $('input:submit[name=\'commit\']').attr('disabled', 'disabled')

  # push or remove from submission group form and array
  $('body').off('change', 'input:checkbox[name=\'submission_group[submission_ids][]\']').on 'change', 'input:checkbox[name=\'submission_group[submission_ids][]\']', ->
    id = $(this).data('id')
    date = $(this).data('date')
    lab = $(this).data('lab')
    type = $(this).data('type')
    totalIsolate = $(this).data("total-isolate")
    if $(this).prop('checked')
      checked_item =
        id: id,
        date: date,
        lab: lab,
        type: type,
        total_isolate: totalIsolate
      checkedSubmissions.push(checked_item)
      checkedSubmissionsOnForm.push(checked_item)
    else
      if $(this).closest('#submissions').length > 0
        removeCheckedSubmissions(id)
    renderCheckedSubmissions()
    submissionCounterCheck()

  # close button (.close-submission-as-group) on submission group modal
  $("body").on "click", ".close-submission-as-group", ->
    # Reset Form New
    form_wrapper = $(this).closest('form')
    form_wrapper.find("#submission_group_name, #submission_group_note").val('')
    form_wrapper.find('.form-group').removeClass('is-invalid')
    form_wrapper.find(".error-message").remove()

    # Reset selected item
    resetSubmissionSelectedOnForm()
    for x in checkedSubmissions
      window.checkedSubmissionsOnForm.push(x)
    renderCheckedSubmissions()

  $("body").on "change", ".submission-isolate-list input:checkbox[name=\'submission_isolate_group[isolate_ids][]\']", () ->
    isolate = $('.submission-isolate-list input:checked').length
    if isolate is 0
      $("#submission_isolate_group_btn").attr("disabled", "disabled")
      $(".create-submission-isolates-run").attr("disabled", "disabled")
    else
      $("#submission_isolate_group_btn").removeAttr("disabled")
      $(".create-submission-isolates-run").removeAttr("disabled")
    error_fields_check()

  $("body").on "click", "#submission_isolate_group_btn", () ->
    $isolateGroupList = $(".isolate-group-list")
    $isolateGroupList.empty()
    # Mereset array untuk isolate yang terpilih
    resetCheckedIsolates()

    $("#submission_group_isolates_modal").find('.submission-isolate-list input:checked').each ->
      isolateId = $(this).val()
      isolateName = $(this).data('isolate-name')
      $isolateGroupListElement = $("<div class='checkbox'>\
        <label>\
          <input isolate-id='" + isolateId + "' id='isolate_group_form_id_" + isolateId + "' type='checkbox' name='isolate_group[isolate_ids][]' value='" + isolateId + "' isolate-name='" + isolateName + "' checked>" + isolateName +
        "</label>\
      </div>")
      $isolateGroupList.append($isolateGroupListElement)

      # memasukkan isolate yang terpilih ke dalam array
      checkedIsolates.push({ id: isolateId, name: isolateName });

  # ==================================== SUBMISSIONS FORM ===========================================

  if $('.edit_submission, .new_submission').length > 0
    initializeSubmissionForm()
    countRefAntiseraAndRefAntigen()

    # testDetailsButtonStatus()
    initializeActiveStep()
    # isolate_counter_check()
    selectedAntiseraCounterCheck()
    selectedAntigenCounterCheck()
    # load kit when page refreshed

    if $('.edit_submission').length > 0
      loadKits()

  # header tab
  $('body').on 'click', "#antigenic_run_tabs li", ->
    step = $("#antigenic_run_tabs").find(".disabled").length
    # step stop on test detail
    validate_step = 3
    if step != validate_step
      $(".error-tab").remove()
      checkIsoRefCount = checkTotalRefAndIsolate()
      if checkIsoRefCount is false
        # could be step less than test detail
        # only disable the submit button
        $('input:submit[name=\'tes_detail_tab\']').attr('disabled', 'disabled')
    else
      # disable radio for isolate and references qty confirmation
      # because cant back to setup tab (cant edit number and the references or isolates selected)
      # need to fill test data and/or control data
      $("#test_detail :input[type='radio']").attr("disabled", true)

  # save button (#submission_form_btn) on setup tab
  $('body').on 'click', '#submission_form_btn', ->
    next_step = $('#submission_next_step').val()
    if next_step is "select_references"
      $('#submission_active_step').val("select_references")
    else if next_step is "select_isolates"
      $('#submission_active_step').val("select_isolates")

  # set maxisolate value same as #submission_isolate_qty value
  $("body").on "keyup", "#submission_isolate_qty", ->
    window.maxisolate = $(this).val()
    $("#submission_isolate_qty").attr("value", maxisolate)

  # .run-isolate = list isolates found after search use searchIsolate()
  $('body').on 'click', '.run-isolate', ->
    $(".error-message").remove()

    name = $(this).data('isolate-name')
    id = $(this).data('isolate-id')

    existing_isolate = $("#run_isolate_selected li").length
    runisolatecounter = existing_isolate

    if $(this).is(':checked')
      $('input:submit[name=\'isolate_tab\']').removeAttr('disabled')
      runisolatecounter++
      if runisolatecounter <= maxisolate # maxisolate is function
        # selected isolates less than max selected isolates
        # add object to selected isolates wrapper
        $('#run_isolate_selected').append("<li id='run_isolate_"+id+"'><label><input type='checkbox' name='submission[isolate_ids][]' class='selected-run-isolate remove-run-isolate' value='"+id+"' checked='checked'> "+name+"</label></li>")
      else
        # selected isolates =/more then max selected isolates
        # add warning reach maximum
        alert "You've reached the maximum allowed quantity for Isolates"
        # $("#run_isolate_selected").closest('.panel').append("<div class='error-message alert alert-danger'><b>ERROR! </b> You've reached the maximum allowed quantity for Isolate</p>")
        runisolatecounter--
        $('#run_isolate_result_'+id).attr('checked', false)
    else
      runisolatecounter--
      $("#run_isolate_"+id).remove()
      $("#remove_run_edit_isolate_"+id).remove()
    error_fields_check()
    isolate_counter_check()

  # form
  # select isolates
  # search isolate
  $("body").on "keypress", "#run_search_isolate", (e) ->
    $this = $(this)
    if e.keyCode == 13
      e.preventDefault()
      searchIsolate($this)

  if runisolatecounter > 0
    $("#run_preview_modal_button").removeClass("hide");
    $("#view_run_list").removeClass("hide");
  # maxisolate = parseInt($("#submission_isolate_qty").val())

  # add selected ref.antiseras to table
  $('body').on 'change', 'input:checkbox[name=\'ref_antiseras_batch[]\']', ->
    batch_no = $(this).val()
    asBatch = window.asBatch
    ref_antiseras_id = parseInt($(this).data('refantiserasid'))
    kit_id = parseInt($(this).data('kit-id'))
    kit_name = $(this).data('kit-name')
    batch_arr = "#{kit_id}_#{ref_antiseras_id}"
    antiseraName = $(this).data('antiseraname')
    if $(this).prop('checked')
      if asBatch.length >= window.refAntiseraMax
        alert "You've reached the maximum allowed quantity for Ref. Antisera"
        $(this).removeAttr('checked')
      else
        selected_batch_length = asBatch.length+1
        $('#ref_antiseras table tbody').append(selectedBatchRefAntiseraTemplate(kit_id, kit_name, ref_antiseras_id, antiseraName, batch_no, selected_batch_length))
        asBatch.push batch_arr
        selectedAntiseraCounterCheck()
    else
      this_wrapper = $('#ref_antisera_'+batch_arr)
      removeSelectedBatch(this_wrapper, 'antisera')
      batchIndex = asBatch.indexOf(batch_arr)
      asBatch.splice(batchIndex,1)
      selectedAntiseraCounterCheck()
    error_fields_check()

  # add selected ref.antigens to table
  $('body').on 'change', 'input:checkbox[name=\'ref_antigens_batch[]\']', ->
    batch_no = $(this).val()
    ref_antigens_id = parseInt($(this).data('refantigensid'))
    kit_id = parseInt($(this).data('kit-id'))
    kit_name = $(this).data('kit-name')
    batch_arr = "#{kit_id}_#{ref_antigens_id}"
    antigenName = $(this).data('antigenname')
    anchor = $(this).data('anchor')
    agBatch = window.agBatch
    if $(this).prop('checked')
      if agBatch.length >= refAntigenMax
        alert "You've reached the maximum allowed quantity for Ref. Antigen"
        $(this).removeAttr('checked')
      else
        selected_batch_length = agBatch.length+1
        $('#ref_antigens table tbody').append(selectedBatchRefAntigenTemplate(kit_id, kit_name, ref_antigens_id, antigenName, batch_no, selected_batch_length, anchor))
        agBatch.push batch_arr
        selectedAntigenCounterCheck()
        tooltipAndPopoverShow()
    else
      this_wrapper = $('#ref_antigen_'+batch_arr)
      removeSelectedBatch(this_wrapper, 'antigen')

      batchIndex = agBatch.indexOf(batch_arr)
      agBatch.splice(batchIndex,1)
      selectedAntigenCounterCheck()
    error_fields_check()

  #remove ref antiseras
  $('body').on 'click', '.remove-ref-antisera', (e) ->
    e.preventDefault()
    ref_id = $(this).attr('ref_id')
    kit_id = $(this).attr('kit_id')
    batch_arr = "#{kit_id}_#{ref_id}"
    input_id = "#{kit_id}#{ref_id}"

    this_wrapper = $('#ref_antisera_'+batch_arr)
    removeSelectedBatch(this_wrapper, 'antisera')
    batchIndex = asBatch.indexOf(batch_arr)
    window.asBatch.splice(batchIndex,1)
    selectedAntiseraCounterCheck()

    $('#ascheckbox_'+input_id+' input').prop('checked', false)
    error_fields_check()

  #remove ref antigens
  $('body').on 'click', '.remove-ref-antigen', (e) ->
    e.preventDefault()
    ref_id = $(this).attr('ref_id')
    kit_id = $(this).attr('kit_id')
    batch_arr = "#{kit_id}_#{ref_id}"
    input_id = "#{kit_id}#{ref_id}"

    this_wrapper = $('#ref_antigen_'+batch_arr)
    removeSelectedBatch(this_wrapper, 'antigen')

    batchIndex = agBatch.indexOf(batch_arr)
    window.agBatch.splice(batchIndex,1)
    selectedAntigenCounterCheck()

    $('#agcheckbox_'+input_id+' input').prop('checked', false)
    error_fields_check()

  $("body").on "click focusin", ".input-titres", ->
    $(this).parent().popover("show")

  $("body").on "focusout", ".input-titres", ->
    $(this).parent().popover("hide")

  # popover
  # $("body").on "click", ".submission-input-titre [data-toggle='popover']", ->
  #   $(this).popover("show")

  # HI Titre popover
  $("body").on "click focus", "#hi_titres [data-toggle='popover']", ->
    $(this).popover("show")

  $("body").on "change", ".submission-setup-value", ->
    field = $(this).attr('id')
    value = $(this).val()
    if value < 1
      $(this).val(1)
    if field is "submission_ref_antisera_qty"
      antisera_counter = $("#antisera_selected_counter").text().split(" ")
      $("#antisera_selected_counter").text(antisera_counter[0]+" of "+value+")")
    else if field is "submission_ref_antigen_qty"
      antigen_counter = $("#antigen_selected_counter").text().split(" ")
      $("#antigen_selected_counter").text(antigen_counter[0]+" of "+value+")")
    else
      isolate_counter = $("#count_isolate_select").text().split(" ")
      $("#count_isolate_select").text(isolate_counter[0]+" of "+value+")")

  # show kitsantigen and kitsantisera
  $('body').on 'click','input:checkbox[name=\'submission[kit_ids][]\']', ->
    kit_id = $(this).val()
    kit_selected = $('input:checkbox[name=\'submission[kit_ids][]\']:checked').length
    wrapper = $(".submission_kits")
    if kit_selected == 0
      wrapper.append('<div class="error-message alert alert-danger"><b>ERROR!</b> Should be selected at least one Kit.</div>')
      alert("Should be selected at least one Kit!")
      wrapper.find(".error-message").remove()
      $("#submission_kit_ids_"+kit_id).prop('checked', true)
    else
      selected_antisera_batch = $("#ref_antiseras").find('.selected-references')
      as_batch_count = selected_antisera_batch.find("tr[data-kit-id='#{kit_id}']").length
      selected_antigen_batch = $("#ref_antigens").find('.selected-references')
      ag_batch_count = selected_antigen_batch.find("tr[data-kit-id='#{kit_id}']").length
      if as_batch_count > 0 or ag_batch_count > 0
        alert("This member of kit already chosen!")
        wrapper.find(".error-message").remove()
        $("#submission_kit_ids_"+kit_id).prop('checked', true)
      else
        wrapper.find('.error-message').remove()
        if $(this).prop('checked')
          loadKit(kit_id)
        else
          $("#kit_#{kit_id}_ref_antiseras").remove()
          $("#kit_#{kit_id}_ref_antigens").remove()
          window.subkit_ids.splice(window.subkit_ids.indexOf(kit_id))

  # unchecked selected isolate checkbox
  $('body').on 'click', '.selected-run-isolate', ->
    value = $(this).val()
    unless $(this).is(':checked')
      $(this).closest("li").remove()
      $('#run_isolate_result_'+value).attr('checked', false)
      runisolatecounter--
      isolate_counter_check()

  # unchecked selected isolate checkbox (edit)
  $('body').on 'click', '.remove-run-isolate', ->
    id = $(this).data('isolate-id')
    isolate_row = $('#remove_run_edit_isolate_'+id)
    $(isolate_row).remove()
    length = $("#run_isolate_selected li").length
    if length == 0
      $('input:submit[name=\'isolate_tab\']').attr('disabled', 'disabled')
    runisolatecounter--
    isolate_counter_check()
    validateCheckedIsolate()
    # error_fields_check()

  $("body").on "click", ".btn-submit-ref-titre", (e) ->
    e.preventDefault()
    $this = $(this)
    url = $this.attr "href"
    data = $this.closest("tr").find("input").serializeJSON()

    $.ajax
      url: url
      data: data
      dataType: "script"
      method: "PUT"

  $("body").on "change", ".input-titres", ->
    $this = $(this)
    val = parseInt($this.val())

    $this.popover()

    if isTitration(val)
      $this.removeClass("is-invalid")
    else
      $this.addClass("is-invalid")
    each_val = []
    $this.closest("tr").find("input[type='text']").each ->
      each_val.push(isTitration($(this).val()))
    if $.inArray(false, each_val) != -1
      $this.closest("tr").find(".btn-submit-titre").removeClass("btn-success").addClass("btn-light").attr("disabled", true)
      $this.closest("tr").find(".btn-submit-ref-titre").removeClass("btn-success").addClass("btn-light").attr("disabled", true)
    else
      $this.closest("tr").find(".btn-submit-titre").removeClass("btn-light").addClass("btn-success").removeAttr("disabled")
      $this.closest("tr").find(".btn-submit-ref-titre").removeClass("btn-light").addClass("btn-success").removeAttr("disabled")

  $("body").on "click", ".btn-submit-titre", (e) ->
    e.preventDefault()
    $this = $(this)
    url = $this.attr "href"
    data = $this.closest("tr").find("input").serializeJSON()

    $.ajax
      url: url
      data: data
      dataType: "script"
      method: "PUT"

  $('body').on 'change', 'input[name=\'submission[is_same_reference_size_antigen]\']', ->
    testDetailsButtonStatus()

  $('body').on 'change', 'input[name=\'submission[is_same_reference_size_antisera]\']', ->
    testDetailsButtonStatus()

  $('body').on 'change', 'input[name=\'submission[is_same_isolate_size]\']', ->
    testDetailsButtonStatus()

  # && (checkIsoRefCount is 'true')
  #link to setup tab
  $('body').on 'click', '#link_to_setup', ->
    $('#submission_active_step').val("setup")
    $("#submission_ref_antisera_qty , #submission_ref_antigen_qty , #submission_isolate_qty").removeAttr("readonly")
    if $(this).hasClass('link-setup-reference')
      $("#submission_isolate_qty").attr("readonly", "readonly")
      $('#submission_next_step').val("select_references")
    else if $(this).hasClass('link-setup-isolate')
      $("#submission_ref_antigen_qty , #submission_ref_antisera_qty").attr("readonly", "readonly")
      $('#submission_next_step').val("select_isolates")
      $("#submission_ref_antisera_qty").attr("readonly", "readonly")
      $("#submission_ref_antigen_qty").attr("readonly", "readonly")

    elm = $("#link_to_setup").closest(".tab-pane")
    $(this).closest('.error-msg').remove()
    elm.find('.form-group').removeClass('is-invalid')
    $(".check-size").find('input').removeAttr('checked')
    $("#antigenic_run_tabs li a[href='#setup']").tab("show");

    $(".nav-tabs").find("li").removeClass('active')
    $(".tab-pane").removeClass('active')
    $(".nav-tabs").find("li").eq(0).addClass('active')
    $(".tab-pane").eq(0).addClass('active')

  $('body').on 'click', '.submission-form-next-btn', (e) ->
    e.preventDefault()
    # wrapper
    nav_tab_wrapper = $("#antigenic_run_tabs")
    content_tab_wrapper = $('#submission_tab')

    $("#download_run_template").modal("hide")

    # values
    step = $(this).attr('active_step')
    next_step = $(this).attr('next_step')

    any_errors = submissionFormStepValidation(step)

    if any_errors is false
      # submit on 'select_isolates'
      if next_step is 'submit'
        $('.select-isolates-submit-btn').click()
      # show modal on 'select_isolates'
      else if next_step is 'size_confirmation_modal'
        unless $(this).closest('#download_run_template').length == 1
          $("#download_run_template").modal("show")
        else
          $("#download_run_template").modal("hide").on 'hidden.bs.modal', ->
            $("#reference_size_confirmation_modal").modal('show').on 'shown.bs.modal', ->
              # remove/unbind/die the effect/bind of hidden.bs.modal on #download_run_template
              $("#download_run_template").off('hidden.bs.modal')
      else
        nav_tab_wrapper.find('a').removeClass('active')
        nav_tab_wrapper.find("a[href='##{next_step}']").closest('li').removeClass('disabled')
        nav_tab_wrapper.find("a[href='##{next_step}']").addClass('active')

        content_tab_wrapper.find('.tab-pane').removeClass('active')
        content_tab_wrapper.find("##{next_step}").addClass('active')

  $('body').on 'click', ".change-reference-btn", (e) ->
    e.preventDefault()

    nav_tab_wrapper = $("#antigenic_run_tabs")
    content_tab_wrapper = $('#submission_tab')
    next_step = 'setup'

    nav_tab_wrapper.find('li').removeClass('active')
    nav_tab_wrapper.find("a[href='##{next_step}']").closest('li')
      .removeClass('disabled')
      .addClass('active')

    content_tab_wrapper.find('.tab-pane').removeClass('active')
    content_tab_wrapper.find("##{next_step}").addClass('active')

    $("#submission_select_isolates_step_btn").attr('next_step', 'submit');

    $('#reference_size_confirmation_modal').modal('hide')

  $('body').on 'click', ".disabled a", (e) ->
    e.preventDefault()
    return false

  $('body').on 'click', '.add-titre-on-arrival', (e) ->
    e.preventDefault()
    $("#titre_on_arrival_form_modal").modal('show');

  # Show titre on arrival of selected kit based on ref_antisera_id / ref_antigen_id
  $('body').on 'change', '.select-kit-titre', (e) ->
    e.preventDefault()
    wrapper = $(this)
    form_wrapper = wrapper.closest('form')
    row_wrapper = wrapper.closest('.row')
    titre_on_arrival_content = row_wrapper.find('.ref-titre-on-arrival-value')

    data_titre = form_wrapper.find("#titre_on_arrival_data").data('titre')
    ref_id = row_wrapper.data('ref-id')
    kit_id = wrapper.val()

    titre = null

    if wrapper.hasClass('sub-ref-antisera-titre')
      unless data_titre["#{kit_id}"] is undefined
        json_data = data_titre["#{kit_id}"]['antiseras']

        $.each json_data, (idx, ref_titre) ->
          if "#{ref_titre['ref_antisera_id']}" is "#{ref_id}"
            titre = ref_titre['titre']

    if wrapper.hasClass('sub-ref-antigen-titre')
      unless data_titre["#{kit_id}"] is undefined
        json_data = data_titre["#{kit_id}"]['antigens']

        $.each json_data, (idx, ref_titre) ->
          if "#{ref_titre['ref_antigen_id']}" is "#{ref_id}"
            titre = ref_titre['titre']

    if titre is null or "#{titre}".trim().length < 1
      titre = "<div class='text-muted'><i>not set</i></div"

    titre_on_arrival_content.html(titre)

  # ==================================== SUBMISSIONS SHOW ===========================================

  $("body").on "click", ".create-collection-btn", (e) ->
    e.preventDefault()
    sequences = JSON.parse $(this).attr("data-sequences")
    $.each sequences, (i, seq) ->
      checked_item = {
        id: seq["id"],
        name: seq["isolate_name"]
      }
      checkedSequences.push(checked_item)
      checkedSequencesOnForm.push(checked_item)

    renderCheckedSequences()

  # ==================================== STACKED YEAR CHART ===========================================

  # Show levey jennings chart event on change
  $("body").on "change", ".lv-chart-control", ->
    $this = $(this)
    type = $this.data "control-type"
    sendAjaxLvChart($this, type)

  $("body").on "click", ".prevent-link", (e) ->
    e.preventDefault()

$(document).on 'turbolinks:load', ready
