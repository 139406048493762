window.isikhnasTreatmentReportDataTablesLoad = () ->
  url = $("#isikhnas_treatment_report_table").data('url')
  $("#isikhnas_treatment_report_table").DataTable
    "destroy": true
    'searching': true
    'ajax':
      'url': url
      'data': uploadedFilterTreatmentData()
    "processing": true
    "serverSide": true
    "fnDrawCallback": ->
      showHideTableColumn()
    start: 0

window.uploadedFilterTreatmentData = () ->
  $("#form_filter_treatment_report_data").find("input,select").serializeJSON()

window.retrieveData = (url, start_date, end_date) ->
  $.ajax
    url: url
    dataType: "script"
    data:
      start_date: start_date
      end_date: end_date
      url: url

ready = ->
  $('body').on 'focus', '.isikhnas-datepicker', ->
    $(this).datepicker
      format: 'yyyy-mm-dd'
      todayHighlight: true
      todayBtn: 'linked'
      weekStart: 1
      autoclose: true

$(document).on 'turbolinks:load', ready