# initialzie
month_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

window.labReportsIsolatesChart = (data, additional) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#lab_monthly_report_end_year").val())
  end_month = parseInt($("#lab_monthly_report_end_month").val())
  start_year = parseInt($("#lab_monthly_report_start_year").val())
  start_month = parseInt($("#lab_monthly_report_start_month").val())
  # get vetlabs
  vet_labs = $("#by_isolate_lab_").val();
  vet_labs_name = $("#by_isolate_lab_ option:selected").map( () ->
    return $(this).text()
  ).get();
  vet_labs_color = $("#by_isolate_lab_ option:selected").map( () ->
    return $(this).data("primarycolor")
  ).get();

  # get additional_data master data
  if additional == "production_systems"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#production_systems_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.production_system_eng
      })
  else if additional == "species"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#species_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.species_eng
      })
  else if additional == "sectors"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#sectors_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.sector_eng
      })
  else if additional == "specimen_types"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#specimen_types_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.specimen_type_eng
      })
  else
    additional_master_data = null

  # create month data for x axis
  array_month = []
  i = start_year
  j = start_month
  while i <= end_year
    # create month format (ex. march -> 03)
    array_month.push("#{month_arr[(j-1)]} #{i}")
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  margin = {top: 20, right: 30, bottom: 510, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 1000 - margin.top - margin.bottom

  max_data = 50
  array_last = vet_labs.length - 1
  max_data = d3.max(trueJson, (d) -> (d.counter[array_last]+5))
  x = d3.scale.ordinal()
    .domain(array_month)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#lab_reports_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/4))
    .text("Number of Isolates")

  # color_test = ['#3365ff', '#cc99ff']
  # color_test = ['#1f79b5', '#b0c8ea', '#fe7d05', '#febb75', '#339f28', '#92e384', '#da2828', '#ff9794', '#9564bd', '#c0b0d7', '#905648', '#eb77be', '#f8b8d0', '#847e80', '#c4cabc', '#bcbd21', '#d8de84', '#16bad3', '#a0dbe9']
  color_test = []
  $.each vet_labs_color, (key_col, val_col) ->
    color_test.push(val_col)

  $.each vet_labs_name, (key_vet, val_vet) ->
    barIsolatesPerDIC = svg.append("g")
      .attr("class", "g-isolate-per-dic-#{key_vet}")
    barIsolatesPerDIC.selectAll(".bar-isolate-per-dic")
      .data(trueJson)
      .enter().append("rect")
        .attr("class", "bar-isolate-per-dic")
        .attr("x", (d) -> return x(d.month) )
        .attr("width", x.rangeBand())
        .attr("y", (d) -> return y(d.counter[key_vet]))
        .attr("height", (d) ->
          if key_vet == 0
            return height-y(d.counter[key_vet])
          else
            return height-y(d.counter[key_vet]-d.counter[key_vet-1])
        )
        .style("fill", color_test[key_vet])
        .tooltip (d) ->
          if key_vet == 0
            counter = d.counter[key_vet]
          else
            counter = d.counter[key_vet]-d.counter[key_vet-1]
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>#{val_vet} = #{counter} isolate(s)</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(d.month)), parseFloat(y(d.counter[key_vet]))],
            displacement: [50, 0],
            mousemove: false
          }

  color_additional = ['#800000','#6633cc', '#009900', '#f9e55b', '#f1219d', '#000177', '#ff0000', '#ff8c00', '#3ebcf7', '#fecce5', '#c5ec1f', '#fe0000']
  if additional_master_data != null
    $.each additional_master_data, (key_add, val_add) ->
      poultryData = d3.svg.line()
        .x((d) -> x(d.month)+(x.rangeBand()/2))
        .y((d) -> y(d.additional_counter[key_add]))

      linePoultry = svg.append("g")
        .attr("class", "line-poultry")
      linePoultry.append("path")
        .attr("fill", "none")
        .attr("d", poultryData(trueJson))
        .attr("stroke", color_additional[key_add])
        .attr("stroke-width", 3)

  legend = svg.append("g")
    .attr("class", "legend")

  $.each vet_labs_name, (key_vet, val_vet) ->
    legendIsolatesPerDIC = legend.append("g")
      .attr("class", "g-legend-#{key_vet}")
    legendIsolatesPerDIC.append("rect")
      .attr("class", "bar-legend-#{key_vet}")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left)
      .attr("y", height+55+(key_vet*25))
      .style("fill", color_test[key_vet])
    legendIsolatesPerDIC.append("text")
      .attr("class", "txt-legend-#{key_vet}")
      .text("#{val_vet}")
      .attr("dx", margin.left+40)
      .attr("dy", height+65+(key_vet*25))

  if additional_master_data != null
    $.each additional_master_data, (key_add, val_add) ->
      legendAdditional = legend.append("g")
        .attr("class", "g-legend-additional-#{key_add}")
      legendAdditional.append("line")
        .attr("class", "bar-legend-additional-#{key_add}")
        .attr("x1", margin.left+450)
        .attr("x2", margin.left+480)
        .attr("y1", height+65+(key_add*25))
        .attr("y2", height+65+(key_add*25))
        .style("stroke", color_additional[key_add])
        .style("stroke-width", 3)
      legendAdditional.append("text")
        .attr("class", "txt-legend-additional-#{key_add}")
        .text("#{val_add.name}")
        .attr("dx", margin.left+490)
        .attr("dy", height+70+(key_add*25))


window.checkNumberOfLabReportsIsolates = (data, m, vet_lab_id, dis_code) ->
  noi = 0; # number of isolates
  if (dis_code == 0)
    if (vet_lab_id == 0)
      # get data per month
      $.each data, (key_vet, val_vet) ->
        if (m == val_vet.month)
          noi += 1
          return noi
    else
      if (m == 0)
        $.each data, (key_vet, val_vet) ->
          if (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += 1
            return noi
      else
        $.each data, (key_vet, val_vet) ->
          if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += 1
            return noi
  else
    $.each data, (key_vet, val_vet) ->
      district_code = "#{val_vet.province_code}#{val_vet.district_code}"
      if (district_code == dis_code)
        noi += 1
        return noi
  return noi

window.checkNumberOfLabReportsAdditional = (data, m, additional, additional_id) ->
  noa = 0; # number of additional
  if additional == "production_systems"
    $.each data, (key_vet, val_vet) ->
      if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.production_system_id) == parseInt(additional_id))
        noa += 1
        return noa
  else if additional == "species"
    $.each data, (key_vet, val_vet) ->
      if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.species_id) == parseInt(additional_id))
        noa += 1
        return noa
  else if additional == "sectors"
    $.each data, (key_vet, val_vet) ->
      if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.sector_id) == parseInt(additional_id))
        noa += 1
        return noa
  else if additional == "specimen_types"
    $.each data, (key_vet, val_vet) ->
      if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.specimen_type_id) == parseInt(additional_id))
        noa += 1
        return noa
  return noa


window.createLabReportsIsolateJSON = (data, additional) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#lab_monthly_report_end_year").val())
  end_month = parseInt($("#lab_monthly_report_end_month").val())
  start_year = parseInt($("#lab_monthly_report_start_year").val())
  start_month = parseInt($("#lab_monthly_report_start_month").val())
  # get vetlabs
  vet_labs = []
  $("#by_isolate_lab_:checked").each () ->
    vet_labs.push($(this).val())
  # get additional_data master data
  if additional == "production_systems"
    additional_master_data = $.parseJSON($("#production_systems_master").val())
  else if additional == "species"
    additional_master_data = $.parseJSON($("#species_master").val())
  else if additional == "sectors"
    additional_master_data = $.parseJSON($("#sectors_master").val())
  else if additional == "specimen_types"
    additional_master_data = $.parseJSON($("#specimen_types_master").val())
  else
    additional_master_data = null

  i = start_year
  j = start_month
  json_for_lab_reports_isolates = "["
  while i <= end_year
    new_j = ""
    if j < 10
      new_j = "0" + j
    else
      new_j = j
    json_for_lab_reports_isolates += "{ \"month\": \"#{i}-"+new_j+"-01T00:00:00.000Z\", "
    if additional_master_data != null
      json_for_lab_reports_isolates += "\"additional_counter\": ["
      $.each additional_master_data, (key_add, val_add) ->
        total_additional_per_month = checkNumberOfLabReportsAdditional(trueJson, (i+"-"+new_j), additional, val_add.id)
        json_for_lab_reports_isolates += ""+total_additional_per_month+","
      json_for_lab_reports_isolates = json_for_lab_reports_isolates.substring(0, json_for_lab_reports_isolates.length - 1)
      json_for_lab_reports_isolates += "],"

    json_for_lab_reports_isolates += " \"counter\": ["
    total_isolate_per_month = 0
    $.each vet_labs, (key_vet, val_vet) ->
      isolate_counter = checkNumberOfLabReportsIsolates(trueJson, (i+"-"+new_j), val_vet, 0)
      total_isolate_per_month = total_isolate_per_month + isolate_counter
      json_for_lab_reports_isolates += ""+total_isolate_per_month+","
    json_for_lab_reports_isolates = json_for_lab_reports_isolates.substring(0, json_for_lab_reports_isolates.length - 1)
    json_for_lab_reports_isolates += "]},"

    # if this is the last loop, exit!
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  json_for_lab_reports_isolates = json_for_lab_reports_isolates.substring(0, json_for_lab_reports_isolates.length - 1)
  json_for_lab_reports_isolates += "]"

  # json_for_lab_reports_isolates = $.parseJSON(json_for_lab_reports_isolates)
  # console.log json_for_lab_reports_isolates

  return json_for_lab_reports_isolates

window.labReportsIsolatesTable = (data, additional) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#lab_monthly_report_end_year").val())
  end_month = parseInt($("#lab_monthly_report_end_month").val())
  start_year = parseInt($("#lab_monthly_report_start_year").val())
  start_month = parseInt($("#lab_monthly_report_start_month").val())
  # get vetlabs
  vet_labs = $("#by_isolate_lab_").val();
  vet_labs_name = $("#by_isolate_lab_ option:selected").map( () ->
      return $(this).text()
  ).get();
  # get additional_data master data
  if additional == "production_systems"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#production_systems_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.production_system_eng
      })
  else if additional == "species"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#species_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.species_eng
      })
  else if additional == "sectors"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#sectors_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.sector_eng
      })
  else if additional == "specimen_types"
    additional_master_data = []
    additional_master_data_temp = $.parseJSON($("#specimen_types_master").val())
    $.each additional_master_data_temp, (key, val) ->
      additional_master_data.push({
        id: val.id
        name: val.specimen_type_eng
      })
  else
    additional_master_data = null

  string_table = "<table class='table table-hover table-stripped table-bordered'>"
  string_table += "<thead>"
  string_table += "<tr>"
  string_table += "<th>Month</th>"
  $.each vet_labs_name, (key_vet, val_vet) ->
    string_table += "<th class='text-center'>" + val_vet + "</th>"
  string_table += "</tr>"
  string_table += "</thead>"

  string_table += "<tbody>"
  $.each trueJson, (key, val) ->
    string_table += "<tr>"
    yyyy = val.month.substring(0, 4)
    mm = val.month.substring(5, 7)
    string_table += "<td>" + month_arr[parseInt(mm)-1] + " " + yyyy + "</td>"
    $.each vet_labs, (key_vet, val_vet) ->
      iso_count = 0
      if key_vet == 0
        iso_count =  val.counter[key_vet]
      else
        iso_count =  val.counter[key_vet]-val.counter[key_vet-1]
      string_table += "<td class='text-center'>" + iso_count + "</td>"
    string_table += "</tr>"

  string_table += "</tbody>"
  string_table += "</table>"

  $("#lab_reports_table").html(string_table)

window.labReportsIsolatesMap = (data, additional, summary_data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
    trueJsonSummary = jQuery.parseJSON(summary_data)
  else
    trueJson = data
    trueJsonSummary = summary_data

  # console.log trueJsonSummary

  # initialize isolates_color on map
  array_max_iso = []
  if trueJsonSummary is null or  trueJsonSummary.length == 0
    isolate_color_domain = [1, 25, 50, 75, 100]
    isolate_ext_color_domain = [0, 1, 25, 50, 75, 100]
    isolate_legend_labels = ["0", "1+", "25+", "50+", "75+", "> 100"]
  else
    # get max value from isolates in each district
    $.each trueJsonSummary, (key_iso, val_iso) ->
      array_counter = val_iso.counter
      array_counter.sort((a,b) -> b-a)
      array_max_iso.push(parseInt(array_counter[0]))
    array_max_iso.sort((a,b) -> b-a)
    max_iso_counter = array_max_iso[0]
    if max_iso_counter < 20
      max_iso_counter = 25

    # initialize isolate_color on map
    isolate_color_domain = []
    isolate_ext_color_domain = []
    isolate_legend_labels = []
    isolate_legend = 0
    beda = max_iso_counter / 4
    isolate_color_domain.push(1)
    isolate_ext_color_domain.push(0)
    isolate_ext_color_domain.push(1)
    isolate_legend_labels.push("0")
    isolate_legend_labels.push("1")
    i = 1
    while i <= 3
      isolate_legend += beda
      isolate_legend = Math.round(isolate_legend/5)*5
      isolate_color_domain.push(isolate_legend)
      isolate_ext_color_domain.push(isolate_legend)
      isolate_legend_labels.push("#{isolate_legend}+")
      i++
    max_iso_counter = Math.round(max_iso_counter/5)*5
    isolate_color_domain.push(max_iso_counter)
    isolate_ext_color_domain.push(max_iso_counter)
    isolate_legend_labels.push("#{max_iso_counter}")

  isolate_color = d3.scale.threshold()
    .domain(isolate_color_domain)
    .range(["#ffffff", "#7fff60", "#1dfe00", "#9999df", "#3636c1", "#000091"])

  # initialize size
  w = $(".col-md-9").width()
  # alert(w)
  size =
    width: w,
    height: w/2.5

  center = [size.width/2 , size.height/2]

  # create canvas
  canvas = d3.select("#lab_reports_map").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "lightblue")
    .attr("cursor", "grab")

  # Generate MAP
  d3.json "/outbreaks/indonesia_districts_2018.json", (data) ->
    group = canvas.selectAll("g.dis")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis")

    projection = d3.geo.mercator().scale(w*1.2).translate([-size.width*1.98, (size.height)/2.7]);
    path = d3.geo.path().projection(projection);

    # write(coloring) isolates data on map
    areas = group.append("path")
      .attr("d", path)
      # .attr("class", "area")
      .attr("class", (d,i) ->
        return "area "+d.properties.CC_2;
      )
      .style("stroke", "gray")
      .style("stroke-width", "0.1")
      .attr("fill", (d,i) ->
        total_isolates = checkNumberOfLabReportsIsolates(trueJson, 0, 0, d.properties.CC_2);
        return isolate_color(total_isolates,i);
      )
      .on('mouseover', (d,i) ->
        total_isolates = checkNumberOfLabReportsIsolates(trueJson, 0, 0, d.properties.CC_2);
        string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" isolate(s)</b>";
        $("#lab_reports_map_district_details i").html(string_details)
      )
      .on('mouseout', (d,i) ->
        $("#lab_reports_map_district_details i").html("District Details")
      )
      # .on('dblclick', (d,i) ->
      #   alert("Halo!")
      # )

     # legend title
    unless trueJson is null
      legend_title_data = ["Number of Isolates"]
      legend_title = canvas.selectAll("g.title")
        .data(legend_title_data)
        .enter().append("g")
        .attr("class", "title")

      legend_title.append("text")
        .attr("x", -(size.height - 20))
        .attr("y", (d,i) -> 30 + (i*100))
        .attr("transform", "rotate(-90)")
        .text((d) -> d)

      # add isolate legend
      isolate_legend = canvas.selectAll("g.isolate_legend")
        .data(isolate_ext_color_domain)
        .enter().append("g")
        .attr("class", "isolate_legend")

      leg_w = 20
      leg_h = 20

      isolate_legend.append("rect")
        .attr("x", 35)
        .attr("y", (d,i) -> size.height - (i*leg_h) - (2*leg_h) )
        .attr("width", leg_w)
        .attr("height", leg_h)
        .style("fill", (d,i) -> isolate_color(d) )
        .style("opacity", "0.8")

      isolate_legend.append("text")
        .attr("x", 60)
        .attr("y", (d,i) -> size.height - (i*leg_h) - leg_h - 4 )
        .text((d,i) -> isolate_legend_labels[i] )

    zoomed = () ->
      canvas.selectAll(".dis").attr("transform", "translate("+zoom.translate()+")scale("+zoom.scale()+")");
    # zoom behavior
    zoom = d3.behavior.zoom()
          .on("zoom", zoomed);
    canvas.call(zoom).on("dblclick.zoom", null);

    # add rectangle for map control
    zoomBehavior = ['zoom_in', 'zoom_out']
    canvas.selectAll('.zoom_button')
      .data(zoomBehavior)
    .enter().append('rect')
      .attr("class", "zoom_button")
      .attr("x", (d,i) -> return 35*i + 5)
      .attr("y", 5)
      .attr('width', '30px')
      .attr('height', '30px')
      .attr("id", (d,i) -> d)
      .attr('fill', '#eaeaea')
      .style('border', '1')
      .style('cursor', 'pointer')
      .style('box-shadow', '5px 5px 5px #888888')

    canvas.selectAll('.zoom_text')
      .data(zoomBehavior)
    .enter().append('svg:foreignObject')
      .attr("class", "zoom_text")
      .attr("width", 20)
      .attr("height", 20)
      .attr("id", (d,i) -> d)
      .attr("x", (d,i) -> return 33*i + 13.5)
      .attr("y", 12)
      .style('cursor', 'pointer')
    .append('xhtml:span')
      .attr("class", (d,i) ->
        if i
          return "glyphicon glyphicon-minus"
        else
          return "glyphicon glyphicon-plus"
      )

    zoom_on_click = (obj_id) ->
      scale = zoom.scale()
      extent = zoom.scaleExtent()
      translate = zoom.translate()
      # console.log("translate = #{translate}")
      x = translate[0]
      y = translate[1]
      if obj_id == 'zoom_in'
        factor = 1.1
      else
        factor = 1/1.1
      target_scale = scale * factor

      # If we're already at an extent, done
      # if (target_scale == extent[0] || target_scale == extent[1])
      #   return false

      # If the factor is too much, scale it down to reach the extent exactly
      # clamped_target_scale = Math.max(extent[0], Math.min(extent[1], target_scale))
      # if (clamped_target_scale != target_scale)
      #   target_scale = clamped_target_scale
      #   factor = target_scale / scale

      # Center each vector, stretch, then put back
      x = (x - center[0]) * factor + center[0];
      y = (y - center[1]) * factor + center[1];

      d3.transition().duration(150).tween("zoom", () ->
        interpolate_scale = d3.interpolate(scale, target_scale)
        interpolate_trans = d3.interpolate(translate, [x,y])
        return (t) ->
          zoom.scale(interpolate_scale(t)).translate(interpolate_trans(t))
          zoomed()
      )


    d3.selectAll('.zoom_button').on('click', () ->
      zoom_on_click(this.id)
    )
    d3.selectAll('.zoom_text').on('click', () ->
      zoom_on_click(this.id)
    )

    window.zoomTo = (cx, cy, dis_id) ->
      zoom.scale( zoom.scale()/2 )
      zoom_scale = 30
      canvas.selectAll(".dis").transition()
        .duration(350)
        .attr("transform", "translate(" + size.width/2 + "," + size.height/2 + ")scale(" + zoom_scale + ")translate(" + -cx + "," + -cy + ")")

      $(".#{dis_id}").fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800)

      translate = zoom.scale(zoom_scale).translate([size.width/2 - cx * zoom_scale, size.height/2 - cy * zoom_scale]);

# =====================================================================================================================================================================
# =====================================================================================================================================================================
# =====================================================================================================================================================================
# =====================================================================================================================================================================

window.checkNumberOfLabReportsSubmissionsFullPanelAndPreScreen = (data, m) ->
  noi_full_panel = 0; # number of isolates
  noi_pre_screen = 0; # number of isolates
  $.each data, (key_vet, val_vet) ->
    if (m == val_vet.month)
      if val_vet.run_type == "full_panel"
        noi_full_panel += val_vet.isolate_qty
      else
        noi_pre_screen += val_vet.isolate_qty
      return [noi_full_panel, noi_pre_screen]
  return [noi_full_panel, noi_pre_screen]

window.checkNumberOfLabReportsSubmissionDataIssues = (data, m) ->
  noi = 0; # number of isolates
  $.each data, (key_vet, val_vet) ->
    if (m == val_vet.month)
      noi += 1
      return noi
  return noi

window.checkNumberOfLabReportsSubmissions = (data, m, vet_lab_id, dis_code) ->
  noi = 0; # number of isolates
  if (dis_code == 0)
    if (vet_lab_id == 0)
      # get data per month
      $.each data, (key_vet, val_vet) ->
        if (m == val_vet.month)
          noi += val_vet.isolate_qty
          return noi
    else
      if (m == 0)
        $.each data, (key_vet, val_vet) ->
          if (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += val_vet.isolate_qty
            return noi
      else
        $.each data, (key_vet, val_vet) ->
          if (m == val_vet.month) and (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += val_vet.isolate_qty
            return noi
  else
    $.each data, (key_vet, val_vet) ->
      district_code = "#{val_vet.province_code}#{val_vet.district_code}"
      if (district_code == dis_code)
        noi += val_vet.isolate_qty
        return noi
  return noi

window.createLabReportsSubmissionsJSON = (data, control_data_issues, test_data_issues) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
    trueJsonControlDataIssues = jQuery.parseJSON(control_data_issues)
    trueJsonTestDataIssues = jQuery.parseJSON(test_data_issues)
  else
    trueJson = data
    trueJsonControlDataIssues = control_data_issues
    trueJsonTestDataIssues = test_data_issues

  # get filter's date
  end_year = parseInt($("#submission_date_end_year").val())
  end_month = parseInt($("#submission_date_end_month").val())
  start_year = parseInt($("#submission_date_start_year").val())
  start_month = parseInt($("#submission_date_start_month").val())
  # get vetlabs
  vet_labs = $("#submission_vet_labs_").val();
  data_issues_selected = []
  $('input:checkbox[name="missing_data[]"]:checked').each () ->
    data_issues_selected.push $(this).val()

  i = start_year
  j = start_month
  json_for_lab_reports_submissions = "["
  while i <= end_year
    new_j = ""
    if j < 10
      new_j = "0" + j
    else
      new_j = j
    json_for_lab_reports_submissions += "{ \"month\": \"#{i}-"+new_j+"-01T00:00:00.000Z\", "
    full_panel_pre_screen_submissions_counter = checkNumberOfLabReportsSubmissionsFullPanelAndPreScreen(trueJson, (i+"-"+new_j+"-01T00:00:00.000Z"))
    json_for_lab_reports_submissions += " \"full_panel\": "
    json_for_lab_reports_submissions += " #{full_panel_pre_screen_submissions_counter[0]}, "
    json_for_lab_reports_submissions += " \"pre_screen\": "
    json_for_lab_reports_submissions += " #{full_panel_pre_screen_submissions_counter[1]}, "
    submissions_control_issue = checkNumberOfLabReportsSubmissionDataIssues(trueJsonControlDataIssues, (i+"-"+new_j+"-01T00:00:00.000Z"))
    submissions_test_issue = checkNumberOfLabReportsSubmissionDataIssues(trueJsonTestDataIssues, (i+"-"+new_j+"-01T00:00:00.000Z"))
    json_for_lab_reports_submissions += " \"control_data_issues\": "
    json_for_lab_reports_submissions += " #{submissions_control_issue}, "
    json_for_lab_reports_submissions += " \"test_data_issues\": "
    json_for_lab_reports_submissions += " #{submissions_test_issue}, "
    json_for_lab_reports_submissions += " \"counter\": ["
    submissions_isolate_qty_per_month = 0
    $.each vet_labs, (key_vet, val_vet) ->
      isolate_submissions_counter = checkNumberOfLabReportsSubmissions(trueJson, (i+"-"+new_j+"-01T00:00:00.000Z"), val_vet, 0)
      submissions_isolate_qty_per_month = submissions_isolate_qty_per_month + isolate_submissions_counter
      json_for_lab_reports_submissions += ""+submissions_isolate_qty_per_month+","

    json_for_lab_reports_submissions = json_for_lab_reports_submissions.substring(0, json_for_lab_reports_submissions.length - 1)
    json_for_lab_reports_submissions += "]},"

    # if this is the last loop, exit!
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  json_for_lab_reports_submissions = json_for_lab_reports_submissions.substring(0, json_for_lab_reports_submissions.length - 1)
  json_for_lab_reports_submissions += "]"

  # json_for_lab_reports_submissions = $.parseJSON(json_for_lab_reports_submissions)
  # console.log json_for_lab_reports_submissions

  return json_for_lab_reports_submissions

window.labReportsSubmissionsChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#submission_date_end_year").val())
  end_month = parseInt($("#submission_date_end_month").val())
  start_year = parseInt($("#submission_date_start_year").val())
  start_month = parseInt($("#submission_date_start_month").val())
  # get vetlabs
  vet_labs = $("#submission_vet_labs_").val();
  data_issues_selected = []
  $('input:checkbox[name="missing_data[]"]:checked').each ->
    data_issues_selected.push $(this).val()

  run_type_selected = []
  $('input:checkbox[name="run_type[]"]:checked').each ->
    run_type_selected.push $(this).val()

  vet_labs_name = $("#submission_vet_labs_ option:selected").map( () ->
    return $(this).text()
  ).get();
  vet_labs_color = $("#submission_vet_labs_ option:selected").map( () ->
    return $(this).data("primarycolor")
  ).get();

  # create month data for x axis
  array_month = []
  i = start_year
  j = start_month
  while i <= end_year
    # create month format (ex. march -> 03)
    array_month.push("#{month_arr[(j-1)]} #{i}")
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  margin = {top: 20, right: 30, bottom: 510, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 1000 - margin.top - margin.bottom

  max_data = 50
  array_last = vet_labs.length - 1
  max_data = d3.max(trueJson, (d) -> (d.counter[array_last]+5))
  x = d3.scale.ordinal()
    .domain(array_month)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#lab_reports_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/4))
    .text("Number of Isolates")

  # color_test = ['#3365ff', '#cc99ff']
  # color_test = ['#1f79b5', '#b0c8ea', '#fe7d05', '#febb75', '#339f28', '#92e384', '#da2828', '#ff9794', '#9564bd', '#c0b0d7', '#905648', '#eb77be', '#f8b8d0', '#847e80', '#c4cabc', '#bcbd21', '#d8de84', '#16bad3', '#a0dbe9']
  color_test = []
  $.each vet_labs_color, (key_col, val_col) ->
    color_test.push(val_col)


  $.each vet_labs_name, (key_vet, val_vet) ->
    barIsolatesPerDIC = svg.append("g")
      .attr("class", "g-isolate-per-dic-#{key_vet}")
    barIsolatesPerDIC.selectAll(".bar-isolate-per-dic")
      .data(trueJson)
      .enter().append("rect")
        .attr("class", "bar-isolate-per-dic")
        .attr("x", (d) -> return x(d.month) )
        .attr("width", x.rangeBand())
        .attr("y", (d) -> return y(d.counter[key_vet]))
        .attr("height", (d) ->
          if key_vet == 0
            return height-y(d.counter[key_vet])
          else
            return height-y(d.counter[key_vet]-d.counter[key_vet-1])
        )
        .style("fill", color_test[key_vet])
        .tooltip (d) ->
          if key_vet == 0
            counter = d.counter[key_vet]
          else
            counter = d.counter[key_vet]-d.counter[key_vet-1]
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>#{val_vet} = #{counter} isolate(s)</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(d.month)), parseFloat(y(d.counter[key_vet]))],
            displacement: [50, 0],
            mousemove: false
          }

  unless run_type_selected.length == 0
    unless run_type_selected.indexOf("full_panel") == -1
      # Full Panel LINE CHART
      full_panel_data = d3.svg.line()
        .x((d) -> x(d.month)+(x.rangeBand()/2))
        .y((d) -> y(d.full_panel))

      linePoultry = svg.append("g")
        .attr("class", "line-poultry")
      linePoultry.append("path")
        .attr("fill", "none")
        .attr("d", full_panel_data(trueJson))
        .attr("stroke", "#00ff00")
        .attr("stroke-width", 3)
        .style("stroke-dasharray", ("3, 3"))

    unless run_type_selected.indexOf("pre_screen") == -1
      # Pre Screen LINE CHART
      pre_screen_data = d3.svg.line()
        .x((d) -> x(d.month)+(x.rangeBand()/2))
        .y((d) -> y(d.pre_screen))

      linePoultry = svg.append("g")
        .attr("class", "line-poultry")
      linePoultry.append("path")
        .attr("fill", "none")
        .attr("d", pre_screen_data(trueJson))
        .attr("stroke", "#00ffff")
        .attr("stroke-width", 3)
        .style("stroke-dasharray", ("3, 3"))

  # Data Issues LINE CHART
  unless data_issues_selected.length == 0
    unless data_issues_selected.indexOf("control_data_issue") == -1
      control_data = d3.svg.line()
        .x((d) -> x(d.month)+(x.rangeBand()/2))
        .y((d) -> y(d.control_data_issues))

      lineControlIssues = svg.append("g")
        .attr("class", "line-data-issues")
      lineControlIssues.append("path")
        .attr("fill", "none")
        .attr("d", control_data(trueJson))
        .attr("stroke", "#ff0000")
        .attr("stroke-width", 3)

    unless data_issues_selected.indexOf("test_data_issue") == -1
      test_data = d3.svg.line()
        .x((d) -> x(d.month)+(x.rangeBand()/2))
        .y((d) -> y(d.test_data_issues))

      lineTestIssues = svg.append("g")
        .attr("class", "line-data-issues")
      lineTestIssues.append("path")
        .attr("fill", "none")
        .attr("d", test_data(trueJson))
        .attr("stroke", "#0000ff")
        .attr("stroke-width", 3)


  # LEGEND!
  legend = svg.append("g")
    .attr("class", "legend")

  $.each vet_labs_name, (key_vet, val_vet) ->
    legendIsolatesPerDIC = legend.append("g")
      .attr("class", "g-legend-#{key_vet}")
    legendIsolatesPerDIC.append("rect")
      .attr("class", "bar-legend-#{key_vet}")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left)
      .attr("y", height+55+(key_vet*25))
      .style("fill", color_test[key_vet])
    legendIsolatesPerDIC.append("text")
      .attr("class", "txt-legend-#{key_vet}")
      .text("#{val_vet}")
      .attr("dx", margin.left+40)
      .attr("dy", height+65+(key_vet*25))

  unless run_type_selected.length == 0
    unless run_type_selected.indexOf("full_panel") == -1
      legendFullPanel = legend.append("g")
        .attr("class", "g-legend-full-panel")
      legendFullPanel.append("line")
        .attr("class", "bar-legend-full-panel")
        .attr("x1", margin.left+450)
        .attr("x2", margin.left+480)
        .attr("y1", height+65)
        .attr("y2", height+65)
        .style("stroke", "#00ff00")
        .style("stroke-width", 3)
        .style("stroke-dasharray", ("3, 3"))
      legendFullPanel.append("text")
        .attr("class", "txt-legend-full-panel")
        .text("Full Panel")
        .attr("dx", margin.left+490)
        .attr("dy", height+70)

    unless run_type_selected.indexOf("pre_screen") == -1
      legendPreScreen = legend.append("g")
        .attr("class", "g-legend-pre-screen")
      legendPreScreen.append("line")
        .attr("class", "bar-legend-pre-screen")
        .attr("x1", margin.left+450)
        .attr("x2", margin.left+480)
        .attr("y1", height+65+25)
        .attr("y2", height+65+25)
        .style("stroke", "#00ffff")
        .style("stroke-width", 3)
        .style("stroke-dasharray", ("3, 3"))
      legendPreScreen.append("text")
        .attr("class", "txt-legend-pre-screen")
        .text("Pre Screen")
        .attr("dx", margin.left+490)
        .attr("dy", height+70+25)

  unless data_issues_selected.length == 0
    unless data_issues_selected.indexOf("control_data_issue") == -1
      legendControlData = legend.append("g")
        .attr("class", "g-legend-control-data")
      legendControlData.append("line")
        .attr("class", "bar-legend-control-data")
        .attr("x1", margin.left+650)
        .attr("x2", margin.left+680)
        .attr("y1", height+65)
        .attr("y2", height+65)
        .style("stroke", "#ff0000")
        .style("stroke-width", 3)
      legendControlData.append("text")
        .attr("class", "txt-legend-control-data")
        .text("Control Data Issue")
        .attr("dx", margin.left+690)
        .attr("dy", height+70)

    unless data_issues_selected.indexOf("test_data_issue") == -1
      legendTestData = legend.append("g")
        .attr("class", "g-legend-test-data")
      legendTestData.append("line")
        .attr("class", "bar-legend-test-data")
        .attr("x1", margin.left+650)
        .attr("x2", margin.left+680)
        .attr("y1", height+65+25)
        .attr("y2", height+65+25)
        .style("stroke", "#0000ff")
        .style("stroke-width", 3)
      legendTestData.append("text")
        .attr("class", "txt-legend-test-data")
        .text("Test Data Issue")
        .attr("dx", margin.left+690)
        .attr("dy", height+70+25)

window.labReportsSubmissionsTable = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

 # get filter's date
  end_year = parseInt($("#submission_date_end_year").val())
  end_month = parseInt($("#submission_date_end_month").val())
  start_year = parseInt($("#submission_date_start_year").val())
  start_month = parseInt($("#submission_date_start_month").val())
  # get vetlabs
  vet_labs = $("#submission_vet_labs_").val();
  vet_labs_name = $("#submission_vet_labs_ option:selected").map( () ->
      return $(this).text()
  ).get();

  string_table = "<table class='table table-hover table-stripped table-bordered'>"
  string_table += "<thead>"
  string_table += "<tr>"
  string_table += "<th>Month</th>"
  $.each vet_labs_name, (key_vet, val_vet) ->
    string_table += "<th class='text-center'>" + val_vet + "</th>"
  string_table += "</tr>"
  string_table += "</thead>"

  string_table += "<tbody>"
  $.each trueJson, (key, val) ->
    string_table += "<tr>"
    yyyy = val.month.substring(0, 4)
    mm = val.month.substring(5, 7)
    string_table += "<td>" + month_arr[parseInt(mm)-1] + " " + yyyy + "</td>"
    $.each vet_labs, (key_vet, val_vet) ->
      iso_count = 0
      if key_vet == 0
        iso_count =  val.counter[key_vet]
      else
        iso_count =  val.counter[key_vet]-val.counter[key_vet-1]
      string_table += "<td class='text-center'>" + iso_count + "</td>"
    string_table += "</tr>"

  string_table += "</tbody>"
  string_table += "</table>"

  $("#lab_reports_table").html(string_table)

window.showHideReportsFilter = () ->
  if $('input[name="report_type"]:checked').val() == 'quantity_reports'
    $("#quantity_reports").show("slide")
    $("#quality_reports").hide()
  else
    $("#quantity_reports").hide()
    $("#quality_reports").show("slide")

window.createReportsMissingDataJSON = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  json_for_lab_reports_missing_data = "["
  $.each trueJson, (key_vet, val_vet) ->
    iso_not_null = val_vet.iso_not_null
    iso_null = val_vet.iso_null + iso_not_null
    iso_idk = val_vet.iso_idk + iso_null
    json_for_lab_reports_missing_data += "{ \"vet_lab_code\": "
    json_for_lab_reports_missing_data += "\"#{val_vet.vet_lab_code}\", "
    json_for_lab_reports_missing_data += "\"counter\": ["
    json_for_lab_reports_missing_data += "#{iso_not_null},"
    json_for_lab_reports_missing_data += "#{iso_null},"
    json_for_lab_reports_missing_data += "#{iso_idk}"
    json_for_lab_reports_missing_data += "] },"
  json_for_lab_reports_missing_data = json_for_lab_reports_missing_data.substring(0, json_for_lab_reports_missing_data.length - 1)
  json_for_lab_reports_missing_data += "]"

  # console.log json_for_lab_reports_missing_data
  return json_for_lab_reports_missing_data

window.labReportsMissingDataChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # create array of vetlab for x axis
  vet_lab_array = []
  $.each trueJson, (key_vet, val_vet) ->
    vet_lab_array.push("#{val_vet.vet_lab_code}")

  margin = {top: 20, right: 30, bottom: 510, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 1000 - margin.top - margin.bottom

  max_data = 50
  max_data = d3.max(trueJson, (d) -> (d.counter[2]+5))

  x = d3.scale.ordinal()
    .domain(vet_lab_array)
    .rangeRoundBands([0, width],.1)

  y = d3.scale.linear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.svg.axis()
    .scale(x)
    .orient("bottom")

  yAxis = d3.svg.axis()
    .scale(y)
    .orient("left")

  svg = d3.select("#lab_reports_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/4))
    .text("Number of Isolates")

  color_test = ['#339f28', '#f24d34', '#9564bd']

  isolateCategoryArray = ["Completed", "Null", "I dont Know"]
  $.each isolateCategoryArray, (key, val) ->
    barIsolatesPerCategory = svg.append("g")
      .attr("class", "g-isolate-per-category-#{key}")
    barIsolatesPerCategory.selectAll(".bar-isolate-per-category")
      .data(trueJson)
      .enter().append("rect")
        .attr("class", "bar-isolate-per-category")
        .attr("x", (d) -> return x(d.vet_lab_code) )
        .attr("width", x.rangeBand())
        .attr("y", (d) -> return y(parseInt(d.counter[key])))
        .attr("height", (d) ->
          if key == 0
            return height-y(parseInt(d.counter[key]))
          else
            return height-y(parseInt(d.counter[key])-parseInt(d.counter[key-1]))
        )
        .style("fill", color_test[key])
        .on('click', (d,i) ->
          move_tab_to_table(d.vet_lab_code, val)
        )
        .tooltip (d) ->
          if key == 0
            counter = d.counter[key]
          else
            counter = d.counter[key]-d.counter[key-1]
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>#{val} = #{counter} isolate(s)</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(d.vet_lab_code)), parseFloat(y(d.counter[key]))],
            displacement: [50, 0],
            mousemove: false
          }


  # LEGEND!
  legend = svg.append("g")
    .attr("class", "legend")

  $.each isolateCategoryArray, (key, val) ->
    legendIsolatesPerDIC = legend.append("g")
      .attr("class", "g-legend-#{key}")
    legendIsolatesPerDIC.append("rect")
      .attr("class", "bar-legend-#{key}")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left)
      .attr("y", height+55+(key*25))
      .style("fill", color_test[key])
    legendIsolatesPerDIC.append("text")
      .attr("class", "txt-legend-#{key}")
      .text("#{val}")
      .attr("dx", margin.left+40)
      .attr("dy", height+65+(key*25))

window.move_tab_to_table = (vet_lab_code, data_type) ->
  url = $("#iso_list_link").val()
  field = $('input[name="completeness_isolates_data"]:checked').val()

  $.ajax
    url: url
    dataType: "script"
    method: "GET"
    data:
      vet_lab_code: vet_lab_code
      data_type: data_type
      field: field

window.createIsolateListMissingDataTable = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  edit_path = $("#iso_edit_link").val()

  string_table = "<table style='width:100%;' class='table table-hover table-stripped table-bordered' id='isolates_missing_data_table'>"
  string_table += "<thead>"
  string_table += "<tr>"
  string_table += "<th>No</th>"
  string_table += "<th>Isolate Name</th>"
  string_table += "<th>Option</th>"
  string_table += "</tr>"
  string_table += "</thead>"

  string_table += "<tbody>"
  $.each trueJson, (key, val) ->
    # console.log val
    string_table += "<tr>"
    string_table += "<td class='text-center'>" + (key+1) + "</td>"
    string_table += "<td>" + val.ivm_standard_name + "</td>"
    string_table += "<td><a href='#{edit_path}/#{val.id}/edit' target='_blank'>edit</a></td>"
    string_table += "</tr>"

  string_table += "</tbody>"
  string_table += "</table>"

  $("#lab_reports_table").html(string_table)
  $("#isolates_missing_data_table").dataTable();

ready = ->

  $("body").on "change", ".lab-reports-isolatedate-type", ->
    total_checked = $("input[name='date_type[]']:checked").length
    if total_checked == 0
      alert("Please select at least 1 date type")
      $(".lab-reports-isolatedate-type").prop('checked', true);

  $("#quality_reports").hide()
  $("#quantity_reports").hide()
  showHideReportsFilter();
  $('body').on 'change', 'input[name="report_type"]', ->
    showHideReportsFilter();

  # QUANTITY AND QUALITY REPORTS
  d = new Date()
  current_year = d.getFullYear()
  current_month = d.getMonth() + 1
  $("body").on "keyup", "#lab_monthly_report_start_year", (e) ->
    $("#lab_monthly_report_end_year").val($(this).val())
    if parseInt($(this).val()) == current_year
      $("#lab_monthly_report_end_month").val(current_month)
    else
      $("#lab_monthly_report_end_month").val(12)

  $("body").on "change", "#lab_monthly_report_start_year", (e) ->
    $("#lab_monthly_report_end_year").val($(this).val())
    if parseInt($(this).val()) == current_year
      $("#lab_monthly_report_end_month").val(current_month)
    else
      $("#lab_monthly_report_end_month").val(12)

  $("body").on "click", "#quantity_reports_xlsx", (e) ->
    e.preventDefault()
    end_year = parseInt($("#lab_monthly_report_end_year").val())
    end_month = parseInt($("#lab_monthly_report_end_month").val())
    start_year = parseInt($("#lab_monthly_report_start_year").val())
    start_month = parseInt($("#lab_monthly_report_start_month").val())
    total_month = (12 * end_year + end_month) - (12 * start_year + start_month)
    vet_labs = []
    quantity_report_type = []
    $('input:checkbox[name="by_isolate_lab_for_quantity[]"]:checked').each () ->
      vet_labs.push($(this).val())
    $('input:checkbox[name="quantity_report_type[]"]:checked').each () ->
      quantity_report_type.push($(this).val())
    window.location.href = "/en/reports/quantity_filter.xlsx?end_year=#{end_year}&end_month=#{end_month}&start_year=#{start_year}&start_month=#{start_month}&total_month=#{total_month}&vet_labs=#{vet_labs}&quantity_report_type=#{quantity_report_type}"

$(document).on 'turbolinks:load', ready