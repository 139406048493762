window.isolateGroupDataTablesLoad = () ->
  url = $(".isolate-group-table").data('url')
  $(".isolate-group-table").DataTable
    'destroy': true
    'searching': true
    'ajax':
      'url': url
    'processing': true
    'serverSide': true
    'aoColumnDefs': [ {
      'bSortable': false
      'aTargets': [
        'col-options-column'
      ]
    } ]
    start: 0
