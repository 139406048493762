import './surveillance/isikhnas_treatments'
import './ai_situations'
import './antigenic_mapping'
import './antigens'
import './antiseras'
import './application_role'
import './bioinformatic'
import './collections'
import './collector_types'
import './comments'
import './departments'
import './isikhnas_connect'
import './isolate_groups'
import './isolates'
import './kits'
import './locations'
import './manufactures'
import './news_feed_pictures'
import './outbreaks'
import './production_systems'
import './quantity_and_quality_reports'
import './reports'
import './sectors'
import './sequences'
import './species'
import './specimen_types'
import './staffs'
import './submissions'
import './titre_on_arrivals'
import './upcoming_isolates'
import './user_activities'
import './vaccines'
import './vet_labs'
import './welcome'
import './wikis'

window.mapColors = {
  sea: "#61c7dd",
  land: "#f9f9f9",
  landBorder: "#2aa8c4"
};

var greenScaledColors = [mapColors.land, mapColors.land, "#A7FFEB", "#64FFDA", "#1DE9B6", "#00BFA5"]
var yellowScaledColors = [mapColors.land, mapColors.land, "#FFE57F", "#FFD740", "#FFC400", "#FFAB00"]
var redScaledColors = [mapColors.land, mapColors.land, "#ff6556", "#e51400", "#ab0e00", "#720900"]
var brownScaledColors = [mapColors.land, mapColors.land, "#f9c068", "#f09609", "#b47006", "#774b04"]
var purpleScaledColors = [mapColors.land, mapColors.land, "#B388FF", "#7C4DFF", "#651FFF", "#6200EA"]

window.animateNotification = function(){
  $("#notifications .notice").hide().fadeIn(200);
  $("#notifications .notice").delay(5000).fadeOut(500);
  $("#notifications .alert").delay(5500).fadeOut(500);
}

function loadingNotification(type,message){
  if (message === "undefined") {
    message = ""
  }

  if (type === "show") {
    $(".loading-backdrop").show();
  }
  else if (type === "hide"){
    $(".loading-backdrop").hide();
  }
  $(".loading-message-block").find(".loading-message").html(message);
}

function chosen_jquery() {
  $(".chosen-select").chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched',
    width: '100%'
  });
  $(".default").css("width", "100%");
}

window.showHideTableColumn = function() {
  return $(".control-table-column").each(function() {
    var $this, value;
    $this = $(this);
    value = $(this).val();
    return $("table").find("tr").each(function() {
      var column;
      column = $(this).find("th, td").eq(value);
      if ($this.is(":checked")) {
        return column.removeClass("hide");
      } else {
        return column.addClass("hide");
      }
    });
  });
};

function dataTablesLoad() {
  $("table[role='datatable']").each(function(){
    var url = $(this).data('url');

    if($(this).hasClass("isolate-table")){
      isolateDataTablesLoad();
      $(".dataTables_filter").remove();
      resetCheckedIsolates();
      resetCheckedIsolatesOnForm();
      renderCheckedIsolates();
      counterCheck();
    } else if($(this).attr("id") == "submissions"){
      submissionDataTablesLoad();
      $(".dataTables_filter").remove();
      resetSubmissionSelected();
      resetSubmissionSelectedOnForm();
      submissionCounterCheck();
    } else if($(this).attr("id") == "isikhnas_lab_report_table"){
      isikhnasLabReportDataTablesLoad();
    } else if($(this).attr("id") == "isikhnas_treatment_report_table"){
      isikhnasTreatmentReportDataTablesLoad();
    } else if($(this).hasClass("sequence-table")){
      sequenceDataTablesLoad();
      resetCheckedSequencesOnForm();
      resetCheckedSequences();
      renderCheckedSequences();
      seqCounterCheck();
    } else if($(this).hasClass("antigen-table")){
      antigenDataTablesLoad();
    } else if($(this).hasClass("antisera-table")){
      antiseraDataTablesLoad();
    } else if($(this).hasClass("removed-kit-table")){
      removedKitDataTablesLoad();
    } else if($(this).hasClass("isolate-group-table")){
      isolateGroupDataTablesLoad();
    } else {
      $(this).DataTable({
        "destroy": true,
        "ajax": url,
        "processing": true,
        "serverSide": true,
        "fnDrawCallback": function () {
          showHideTableColumn();
        },
        "aoColumnDefs" : [{
          'bSortable' : false,
          'aTargets' : [ 'col-checkbox-column', 'col-options-column' ]
        }],
        start: 0
      });
    }
  });
}

// jquery mask for date_collected
window.inputMaskActivator = function() {
  $('.date-mask').inputmask('yyyy-mm-dd', {
    removeMaskOnSubmit: true,
    placeholder: "yyyy-mm-dd",
    showMaskOnHover: false
  });
};

window.tooltipAndPopoverShow = function() {
  $('[data-toggle="tooltip"]').tooltip();
  var is_titre = $('[data-toggle="popover"]').hasClass('popover-titre-form');
  if (is_titre == false) {
    $('[data-toggle="popover"]').popover({ trigger: "hover", html: true });
  }
  // $('[data-toggle="popover"]').popover({ trigger: "hover", html: "true", container: "body"});
};

window.filterLabelTemplate = function(label) {
  return "<span class='badge badge-pill badge-info filter-label'>" + label + "</span>  "
};

window.simpleDatatable = function() {
  $('.simple-data-table').DataTable()
}

function formatDate(date) {
  var monthNames = [
    "Jan", "Feb", "Mar",
    "Apr", "May", "Jun", "Jul",
    "Aug", "Sep", "Oct",
    "Nov", "Dec"
  ];

  var date = new Date(date);
  var day = date.getDate();
  var monthIndex = date.getMonth();
  var year = date.getFullYear();

  return day + ' ' + monthNames[monthIndex] + ' ' + year;
}

var ready;
ready = function() {
  dataTablesLoad();
  chosen_jquery();
  showHideTableColumn();
  animateNotification();
  inputMaskActivator();
  tooltipAndPopoverShow();
  simpleDatatable();
  // defaultDatePicker();

  $('.modal').on('shown.bs.modal', function(e){
    $('body').css('padding-right', 0);
  });

  // Default date picker to all input with .datepicker class
  $('body').on("click focus", ".datepicker", function(){
    $(this).datepicker({
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      todayBtn: 'linked',
      weekStart: 1,
      autoclose: true
    });
  });

  $('body').on("click", ".glyphicon-calendar", function(){
    var $wrapper = $(this).closest(".form-group");
    $wrapper.find('.datepicker').eq(0).focus();
  });

  $('body').on('keypress', '.prevent-input', function (e){
    if (e.keyCode == 13) {
      e.preventDefault();
    };
  });

  $('body').on('click', 'ul li.disabled a', function(e){
    e.preventDefault();
  });

  // Toggle new slide panel
  // Tambahkan element trigger dengan attribute data-toggle="slide-panel" dan href="#target_id_slide_panel"
  // Tambahkan ID di element target sesuai dengan attribute href di element trigger
  $("body").on("click", "[data-toggle='slide-panel']", function(e) {
    e.preventDefault();
    var $this = $(this);
    var target = $this.attr("href");
    $(target).toggle('slide', {easing: 'easeOutQuint', direction: 'right'}, 300);
  });

  $(document).mouseup(function (e){
    var container = $(".popover");
    if (!container.is(e.target)
      && container.has(e.target).length === 0)
    {
      container.hide();
    }
  });

  // re enable data-disable-with button
  $(document).on('page:change', function() {
    $.rails.enableElement($('a[data-disable-with]'));
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();


  });

  $("body").on("click", ".btn-clear-filter", function(e)
    {
    e.preventDefault();
    if ($(".isolate-table").length > 0){
      clearIsolateGroupFilter();
      clearFormFilterIsolate();
      isolateDataTablesReset();
    }
    if ($("#submissions").length > 0) {
      clearSubmissionGroupFilter();
      clearFormFilterSubmission();
      submissionDataTablesReset();
    }
    if ($(".sequence-table").length > 0) {
      clearFormFilterSequence();
      sequenceDataTablesReset();
    }
  });

  $(".table-collection-list").attr("style", "width:100%").DataTable({"order": [[ 3, "asc"]]});

};

$(document).on('turbolinks:load', ready);
$(document).on('turbolinks:click', function(){
  loadingNotification("show","Loading...");
});
$(document).on('turbolinks:render', function(){
  loadingNotification("hide");
});
