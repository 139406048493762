# initialzie
month_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']

window.labReportsQuantityChart = (data, quantity_report_type) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#lab_monthly_report_end_year").val())
  end_month = parseInt($("#lab_monthly_report_end_month").val())
  start_year = parseInt($("#lab_monthly_report_start_year").val())
  start_month = parseInt($("#lab_monthly_report_start_month").val())
  # get vetlabs
  vet_labs = []
  vet_labs_name = []
  vet_labs_color = []
  vet_labs_code = []
  $(".filter-by-isolate-lab-for-quantity:checked").each () ->
    vet_labs.push($(this).val())
  $(".filter-by-isolate-lab-for-quantity:checked").each () ->
    vet_labs_name.push($(this).data("vetlabname"))
  $(".filter-by-isolate-lab-for-quantity:checked").each () ->
    vet_labs_color.push($(this).data("primarycolor"))
  $(".filter-by-isolate-lab-for-quantity:checked").each () ->
    vet_labs_code.push($(this).data("vetlabcode"))

  # get additional_data master data
  additional_master_data = null

  # create month data for x axis
  array_month = []
  i = start_year
  j = start_month
  while i <= end_year
    # create month format (ex. march -> 03)
    array_month.push("#{month_arr[(j-1)]} #{i}")
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  quantity_report_type_as_header = (quantity_report_type.substr(0,1).toUpperCase()+quantity_report_type.substr(1)).replace(/_/g, ' ');

  legend_width = $(".col-md-9").width() * 0.15

  margin = {top: 70, right: 30, bottom: 100, left: 40}
  width = 900 - margin.left - margin.right
  height = 600 - margin.top - margin.bottom

  max_data = 50
  array_last = vet_labs.length - 1
  max_data = d3.max(trueJson, (d) -> return (Math.max(...d.counter)+5))
  x = d3.scaleBand()
    .domain(array_month)
    .rangeRound([0, (width - legend_width)],.1)

  y = d3.scaleLinear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.axisBottom()
    .scale(x)

  yAxis = d3.axisLeft()
    .scale(y)

  svg = d3.select("#lab_reports_#{quantity_report_type}_quantity_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "chart-title-breadcrumb-#{quantity_report_type}")
    .attr("transform", "translate(0,0)")
    .append("text")
      .attr("x", -40)
      .attr("y", -50)
      .style("font-size", "18px")
      .text("#{quantity_report_type_as_header} Chart")

  svg.append("g")
    .attr("class", "chart-date-range-breadcrumb-#{quantity_report_type}")
    .attr("transform", "translate(0,0)")
    .append("text")
      .attr("x", -40)
      .attr("y", -30)
      .text("From #{month_arr[start_month-1]} #{start_year} to #{month_arr[end_month-1]} #{end_year}")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width-legend_width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height))
    .text("Number of #{quantity_report_type_as_header}")

  color_test = []
  $.each vet_labs_color, (key_col, val_col) ->
    color_test.push(val_col)

  $.each vet_labs_name, (key_vet, val_vet) ->
    barIsolatesPerDIC = svg.append("g")
      .attr("class", "g-#{quantity_report_type}-per-dic-#{key_vet}")
    barIsolatesPerDIC.selectAll(".bar-#{quantity_report_type}-per-dic")
      .data(trueJson)
      .enter().append("rect")
        .attr("class", "bar-#{quantity_report_type}-per-dic")
        .attr("x", (d) -> return x(getShortMonth(d.month)) )
        .attr("width", x.bandwidth())
        .attr("y", (d) -> return y(d.counter[key_vet]))
        .attr("height", (d) ->
          if key_vet == 0
            bar_height = height-y(d.counter[key_vet])
          else
            bar_height = height-y(d.counter[key_vet]-d.counter[key_vet-1])
          if bar_height < 0
            bar_height = 0
          return bar_height
        )
        .style("fill", color_test[key_vet])
        .tooltip (d) ->
          if key_vet == 0
            counter = d.counter[key_vet]
          else
            counter = d.counter[key_vet]-d.counter[key_vet-1]
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>#{val_vet} = #{counter}</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(getShortMonth(d.month))), parseFloat(y(d.counter[key_vet]))],
            displacement: [50, 0],
            mousemove: false
          }

  legend = svg.append("g")
    .attr("class", "legend")

  legend_title = legend.append("g")
    .attr("class", "g-legend-title-#{quantity_report_type}")
  legend_title.append("text")
    .attr("x", width+20-legend_width)
    .attr("y", 0)
    .text("LEGEND")

  $.each vet_labs_code, (key_vet, val_vet) ->
    legend_DIC = legend.append("g")
      .attr("class", "g-legend-#{quantity_report_type}-#{key_vet}")
    legend_DIC.append("rect")
      .attr("class", "bar-legend-#{quantity_report_type}-#{key_vet}")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", width+20-legend_width)
      .attr("y", 10+key_vet*20)
      .style("fill", color_test[key_vet])
    legend_DIC.append("text")
      .attr("class", "txt-legend-#{quantity_report_type}-#{key_vet}")
      .text("#{val_vet}")
      .attr("dx", width+60-legend_width)
      .attr("dy", 20+(key_vet*20))

window.checkNumberOfLabReportsQuantityData = (data, m, vet_lab_id, dis_code) ->
  noi = 0; # number of isolates
  if (dis_code == 0)
    if (vet_lab_id == 0)
      # get data per month
      $.each data, (key_vet, val_vet) ->
        if (m == val_vet.month)
          noi += 1
          return noi
    else
      if (m == 0)
        $.each data, (key_vet, val_vet) ->
          if (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += 1
            return noi
      else
        $.each data, (key_vet, val_vet) ->
          if ("#{m}" == "#{val_vet.month.slice(0,7)}") and (parseInt(val_vet.vet_lab_id) == parseInt(vet_lab_id))
            noi += 1
            return noi
  else
    $.each data, (key_vet, val_vet) ->
      district_code = "#{val_vet.province_code}#{val_vet.district_code}"
      if (district_code == dis_code)
        noi += 1
        return noi
  return noi

window.createLabReportQuantityJSON = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # get filter's date
  end_year = parseInt($("#lab_monthly_report_end_year").val())
  end_month = parseInt($("#lab_monthly_report_end_month").val())
  start_year = parseInt($("#lab_monthly_report_start_year").val())
  start_month = parseInt($("#lab_monthly_report_start_month").val())
  # get vetlabs
  vet_labs = []
  $(".filter-by-isolate-lab-for-quantity:checked").each () ->
    vet_labs.push($(this).val())

  i = start_year
  j = start_month
  json_for_lab_reports_isolates = []
  while i <= end_year
    new_j = ""
    if j < 10
      new_j = "0" + j
    else
      new_j = j
    obj_json_for_lab_reports_isolates = {}
    obj_json_for_lab_reports_isolates['month'] = "#{i}-#{new_j}-01T00:00:00.000Z"
    total_isolate_per_month = []
    $.each vet_labs, (key_vet, val_vet) ->
      isolate_counter = checkNumberOfLabReportsQuantityData(trueJson, (i+"-"+new_j), val_vet, 0)
      # total_isolate_per_month = isolate_counter
      total_isolate_per_month.push(isolate_counter)
    # json_for_lab_reports_isolates = json_for_lab_reports_isolates.substring(0, json_for_lab_reports_isolates.length - 1)
    obj_json_for_lab_reports_isolates['counter'] = total_isolate_per_month
    json_for_lab_reports_isolates.push(obj_json_for_lab_reports_isolates)

    # if this is the last loop, exit!
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  # json_for_lab_reports_isolates = json_for_lab_reports_isolates.substring(0, json_for_lab_reports_isolates.length - 1)
  # json_for_lab_reports_isolates += "]"

  return JSON.stringify(json_for_lab_reports_isolates)

window.labReportsQuantityMap = (data, total_all_isolates) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # menginisialisasi data legends
  pengali_isolates = parseInt(total_all_isolates/4)

  if total_all_isolates % 4 != 0
    pengali_isolates += 1

  if total_all_isolates < 12
    isolates_legend_max = 12
  else
    isolates_legend_max = 4 * pengali_isolates

  beda_isolates = Math.round((parseInt(isolates_legend_max) + 1) / 4)

  batas_bawah_isolates = 1
  isolate_color_domain = []
  isolate_ext_color_domain = []
  isolate_legend_labels = []
  isolate_color_domain.push(0)
  isolate_ext_color_domain.push(0)
  isolate_legend_labels.push("0")
  while batas_bawah_isolates <= isolates_legend_max
    isolate_color_domain.push(batas_bawah_isolates)
    isolate_ext_color_domain.push(batas_bawah_isolates)
    isolate_legend_labels.push("#{batas_bawah_isolates} - #{batas_bawah_isolates+beda_isolates-1}")
    batas_bawah_isolates = batas_bawah_isolates + beda_isolates

  isolate_color = d3.scaleThreshold()
    .domain(isolate_color_domain)
    .range(["#ffffff", "#f9f9f9", "#a8acff", "#0024ff", "#002bb7", "#000261"])
    # .range(["#f9f9f9", "#7fff60", "#31fe13", "#94adc6", "#455eae", "#193387"])

  # initialize size
  w = $(".col-md-9").width() * 9.5/12
  # alert(w)
  size =
    width: w,
    height: w/2.5

  center = [size.width/2 , size.height/2]

  # create canvas
  canvas = d3.select("#quantity_reports_map").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#{mapColors['sea']}")
    .attr("cursor", "grab")

  group = canvas.append('g')

  projection = d3.geoMercator()
    .scale(w*1.2)
    .translate([-size.width*1.98, (size.height)/2.7])

  path = d3.geoPath().projection(projection)
  # Generate MAP
  d3.json("/outbreaks/indonesia_districts_2018.json").then (data) ->

    # write(coloring) isolates data on map
    areas = group.selectAll("path")
      .data(data.features)
      .enter()
        .append('path')
        .attr("d", path)
        # .attr("class", "area")
        .attr("class", (d,i) ->
          return "area "+d.properties.CC_2;
        )
        .style("stroke", "#{mapColors['landBorder']}")
        .style("stroke-width", "0.1")
        .attr("fill", (d,i) ->
          total_isolates = checkNumberOfLabReportsQuantityData(trueJson, 0, 0, d.properties.CC_2);
          return isolate_color(total_isolates,i);
        )
        .on('mouseover', (d,i) ->
          total_isolates = checkNumberOfLabReportsQuantityData(trueJson, 0, 0, d.properties.CC_2);
          string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" isolate(s)</b>";
          $("#quantity_reports_map_district_details").html(string_details)
        )
        .on('mouseout', (d,i) ->
          $("#quantity_reports_map_district_details").html("District Details")
        )

    circle = canvas.selectAll("g.dis.marker")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis marker")
      .attr("id", (d,i) ->
        d.realx = path.centroid(d)[0]
        d.realy = path.centroid(d)[1]
        return d.properties.CC_2;
      )

    circle.append('svg:circle')
      .attr('cx', (d) -> path.centroid(d)[0])
      .attr('cy', (d) -> path.centroid(d)[1])
      .attr('r', 0.0001)
      .style("opacity", 0)

    # circle = canvas.selectAll("g.dis.cir")
    #   .data(data.features)
    #   .enter()
    #   .append("g")
    #   .attr("class", "dis cir")
    #   .attr("id", (d,i) ->
    #     return d.properties.CC_2;
    #   )

    # # write(coloring) isolate data on map
    # circle.append('svg:circle')
    #   .attr('cx', (d) -> path.centroid(d)[0])
    #   .attr('cy', (d) -> path.centroid(d)[1])
    #   .attr('r', 0.01)
    #   .style("opacity", "0.1")
    #   .style("fill", (d, i) ->
    #     total_isolates = checkNumberOfLabReportsQuantityData(trueJson, 0, 0, d.properties.CC_2);
    #     return isolate_color(total_isolates,i);
    #   )
    #   .on('mouseover', (d,i) ->
    #     total_isolates = checkNumberOfLabReportsQuantityData(trueJson, 0, 0, d.properties.CC_2);
    #     string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" isolate(s)</b>";
    #     $("#quantity_reports_map_district_details").html(string_details)
    #   )
    #   .on('mouseout', (d,i) ->
    #     $("#quantity_reports_map_district_details").html("District Details")
    #   )
  zoomed = () ->
    canvas.selectAll("g").attr("transform", d3.event.transform);

  zoom = d3.zoom().on("zoom", zoomed);

  canvas.call(zoom);
  canvas.call(zoom).on("dblclick.zoom", null);
  # ------------- end of Zooming Map (by scroll) -------------------------

  # ------------- Zooming Map (by control button) -------------------------
  d3.selectAll('.zoom-control').on('click', () ->
    if (this.id == 'zoom_in')
      zoom.scaleBy(canvas.transition().duration(350), 1.3);
    else if (this.id == 'zoom_out')
      zoom.scaleBy(canvas.transition().duration(350), 0.7);
    else
      canvas.call(zoom.transform,d3.zoomIdentity)
  )
  # ------------- end of Zooming Map (by control button) ------------------

  # ------------- Zooming Map (by selected DIC) -------------------------
  window.zoomDic = (cx, cy, zoom_scale) ->
    canvas.transition().duration(750).call(
      zoom.transform,
      d3.zoomIdentity.translate(size.width / 2, size.height / 2).scale(zoom_scale).translate(-cx, -cy)
    );
  # ------------- end of Zooming Map (by selected DIC) ------------------


window.qualityReportChart = (data, report_type) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  # create array of vetlab for x axis
  vet_lab_array = []
  $.each trueJson, (key_vet, val_vet) ->
    vet_lab_array.push("#{val_vet.vet_lab_code}")

  margin = {top: 50, right: 30, bottom: 100, left: 40}
  width = $(".col-md-9").width() - margin.left - margin.right
  height = 600 - margin.top - margin.bottom

  legend_width = $(".col-md-9").width() * 0.15

  max_data = 50
  max_data = d3.max(trueJson, (d) -> (d.counter[2]+5))

  x = d3.scaleBand()
    .domain(vet_lab_array)
    .rangeRound([0, width-legend_width],.1)

  y = d3.scaleLinear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.axisBottom()
    .scale(x)

  yAxis = d3.axisLeft()
    .scale(y)

  quality_report_type_as_header = (report_type.substr(0,1).toUpperCase()+report_type.substr(1));
  field = $("input[name='completeness_#{report_type}_data']:checked").val()
  field_as_header = (field.substr(0,1).toUpperCase()+field.substr(1)).replace(/_/g, ' ');
  if field == 'sequencing_date' or field == 'clade' or field == 'accession_number'
    field_as_header = field_as_header + " Availability"
  if field == 'sequence_segment_id'
    field_as_header = "Sequence segment Availability"

  svg = d3.select("#lab_reports_#{report_type}_quantity_chart").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "chart-title-breadcrumb-#{report_type}")
    .attr("transform", "translate(0,0)")
    .append("text")
      .attr("x", -20)
      .attr("y", -30)
      .style("font-size", "18px")
      .text("#{quality_report_type_as_header} Chart")

  svg.append("g")
    .attr("class", "chart-date-range-breadcrumb-#{report_type}")
    .attr("transform", "translate(0,0)")
    .append("text")
      .attr("x", -20)
      .attr("y", -10)
      .text("#{field_as_header}")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
      .selectAll("line")
        .attr("x2", width-legend_width)
        .attr("x1", -6)
    .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", -30)
      .attr("x", -(height/2))
      .style("text-anchor", "middle")

  svg.append("text")
    .attr("transform", "rotate(-90)")
    .attr("y", -30)
    .attr("x", -(height/2))
    .text("Number of #{report_type}")

  if report_type == "isolate"
    color_test = ['#339f28', '#f24d34']
    categoryArray = ["Completed", "Uncompleted"]
  else if report_type == "submission"
    # color_test = ['#339f28', '#f24d34', '#9564bd']
    color_test = ['#f24d34', '#339f28']
    categoryArray = ["true", "false"]
  else if report_type == "sequence"
    field = $('input[name="completeness_sequence_data"]:checked').val()
    color_test = ['#339f28', '#f24d34', '#9564bd']
    if field == 'sequence_segment_id' or field == 'sequencing_date' or field == 'clade' or field == 'accession_number'
      color_test = ['#339f28', '#f24d34']
      categoryArray = ["Completed", "Uncompleted"]
    else if field == 'is_in_genbank' or field == 'is_complete_coding'
      categoryArray = ["Yes", "No", "Not Sure"]
  $.each categoryArray, (key, val) ->
    barIsolatesPerCategory = svg.append("g")
      .attr("class", "g-isolate-per-category-#{key}")
    barIsolatesPerCategory.selectAll(".bar-isolate-per-category")
      .data(trueJson)
      .enter().append("rect")
        .attr("class", "bar-isolate-per-category")
        .attr("x", (d) -> return x(d.vet_lab_code) )
        .attr("width", x.bandwidth())
        .attr("y", (d) -> return y(parseInt(d.counter[key])))
        .attr("height", (d) ->
          if key == 0
            return height-y(parseInt(d.counter[key]))
          else
            return height-y(parseInt(d.counter[key])-parseInt(d.counter[key-1]))
        )
        .style("fill", color_test[key])
        .on('click', (d,i) ->
          showQualityTable(d.vet_lab_code, val, report_type)
        )
        .tooltip (d) ->
          if key == 0
            counter = d.counter[key]
          else
            counter = d.counter[key]-d.counter[key-1]
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>#{val} = #{counter} #{quality_report_type_as_header}(s)</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(d.vet_lab_code)), parseFloat(y(d.counter[key]))],
            displacement: [50, 0],
            mousemove: false
          }
    barIsolatesPerCategory.on({
      "mouseover": (d) ->
        d3.select(this).style("cursor", "pointer")
      ,
      "mouseout": (d) ->
        d3.select(this).style("cursor", "")
    })

  legend = svg.append("g")
    .attr("class", "legend")

  legend_title = legend.append("g")
    .attr("class", "g-legend-title-#{report_type}")
  legend_title.append("text")
    .attr("x", width+20-legend_width)
    .attr("y", 0)
    .text("LEGEND")

  $.each categoryArray, (key_vet, val_vet) ->
    legend_DIC = legend.append("g")
      .attr("class", "g-legend-#{report_type}-#{key_vet}")
    legend_DIC.append("rect")
      .attr("class", "bar-legend-#{report_type}-#{key_vet}")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", width+20-legend_width)
      .attr("y", 10+key_vet*20)
      .style("fill", color_test[key_vet])
    legend_DIC.append("text")
      .attr("class", "txt-legend-#{report_type}-#{key_vet}")
      .text("#{val_vet}")
      .attr("dx", width+60-legend_width)
      .attr("dy", 20+(key_vet*20))

window.showQualityTable = (vet_lab_code, data_type, report_type) ->
  url = $("#get_quality_table_path").val()
  if report_type == "isolate"
    field = $('input[name="completeness_isolate_data"]:checked').val()
  else if report_type == "submission"
    field = $('input[name="completeness_submission_data"]:checked').val()
  else if report_type == "sequence"
    field = $('input[name="completeness_sequence_data"]:checked').val()

  $.ajax
    url: url
    dataType: "script"
    method: "GET"
    data:
      vet_lab_code: vet_lab_code
      data_type: data_type
      field: field
      report_type: report_type

window.createQualityReportTable = (data, report_type) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data

  if report_type == "isolate"
    edit_path = $("#isolate_edit_path").val()

    string_table = "<table style='width:100%;' class='table table-hover table-stripped table-bordered' id='isolates_quality_report_table'>"
    string_table += "<thead>"
    string_table += "<tr>"
    string_table += "<th>No</th>"
    string_table += "<th>Isolate Name</th>"
    string_table += "<th class='text-right'>Option</th>"
    string_table += "</tr>"
    string_table += "</thead>"

    string_table += "<tbody>"
    $.each trueJson, (key, val) ->
      string_table += "<tr>"
      string_table += "<td class='text-center'>" + (key+1) + "</td>"
      string_table += "<td>" + val.ivm_standard_name + "</td>"
      string_table += "<td class='text-right'><a href='#{edit_path}/#{val.id}' target='_blank'>Show</a></td>"
      string_table += "</tr>"

    string_table += "</tbody>"
    string_table += "</table>"
  else if report_type == "submission"
    edit_path = $("#submission_show_path").val()
    array_step = ['start', 'setup', 'select_references', 'select_isolates', 'test_detail', 'control_data', 'test_data']
    string_table = "<table style='width:100%;' class='table table-hover table-stripped table-bordered' id='isolates_quality_report_table'>"
    string_table += "<thead>"
    string_table += "<tr>"
    string_table += "<th>No</th>"
    string_table += "<th>Run Type</th>"
    string_table += "<th>Test Date</th>"
    string_table += "<th>Step</th>"
    string_table += "<th>Test Issue</th>"
    string_table += "<th>Control Issue</th>"
    string_table += "<th>Created at</th>"
    string_table += "<th class='text-right'>Option</th>"
    string_table += "</tr>"
    string_table += "</thead>"

    string_table += "<tbody>"
    $.each trueJson, (key, val) ->
      string_table += "<tr>"
      string_table += "<td class='text-center'>" + (key+1) + "</td>"
      string_table += "<td>" + val.run_type.substr(0,1).toUpperCase()+val.run_type.substr(1).replace(/_/g, ' '); + "</td>"

      if val.test_date is null
        string_table += "<td></td>"
      else
        test_date_year = val.test_date.substring(0,4)
        test_date_month = parseInt(val.test_date.substring(5,7))
        test_date_day = parseInt(val.test_date.substring(8,10))
        string_table += "<td class='text-center'>" + month_arr[(test_date_month-1)] + " " + test_date_day + ", " + test_date_year + "</td>"

      string_table += "<td>" + array_step.indexOf(val.step) + " of 6</td>"

      if val.has_test_data_issue is null
        string_table += "<td></td>"
      else
        string_table += "<td class='text-center'>" + val.has_test_data_issue + "</td>"

      if val.has_control_data_issue is null
        string_table += "<td></td>"
      else
        string_table += "<td class='text-center'>" + val.has_control_data_issue + "</td>"

      created_at_year = val.created_at.substring(0,4)
      created_at_month = parseInt(val.created_at.substring(5,7))
      created_at_day = parseInt(val.created_at.substring(8,10))

      string_table += "<td class='text-center'>" + month_arr[(created_at_month-1)] + " " + created_at_day + ", " + created_at_year + "</td>"
      string_table += "<td class='text-right'><a href='#{edit_path}/#{val.id}' target='_blank'>Show</a></td>"
      string_table += "</tr>"

    string_table += "</tbody>"
    string_table += "</table>"
  else if report_type == "sequence"
    edit_path = $("#sequence_viewer_path").val()
    string_table = "<table style='width:100%;' class='table table-hover table-stripped table-bordered' id='isolates_quality_report_table'>"
    string_table += "<thead>"
    string_table += "<tr>"
    string_table += "<th>No</th>"
    string_table += "<th>Isolate Name</th>"
    string_table += "<th>Sequncing Date</th>"
    string_table += "<th>Clade</th>"
    string_table += "<th>Sequence Segment</th>"
    string_table += "<th>Is in Genbank</th>"
    string_table += "<th>Accession Number</th>"
    string_table += "<th class='text-right'>Option</th>"
    string_table += "</tr>"
    string_table += "</thead>"

    string_table += "<tbody>"
    is_in_genbank_array = ["", "Yes", "No", "Not Sure"]
    $.each trueJson, (key, val) ->
      link = edit_path.replace("1", "#{val.id}" )
      string_table += "<tr>"
      string_table += "<td class='text-center'>" + (key+1) + "</td>"
      string_table += "<td>" + val.ivm_standard_name + "</td>"
      string_table += "<td>" + val.sequencing_date + "</td>"
      string_table += "<td>" + val.clade + "</td>"
      string_table += "<td>" + val.sequence_segment_id + "</td>"
      string_table += "<td>" + is_in_genbank_array[val.is_in_genbank] + "</td>"
      string_table += "<td>" + val.accession_number + "</td>"
      string_table += "<td class='text-right'><a href='#{link}' target='_blank'>Show</a></td>"
      string_table += "</tr>"

    string_table += "</tbody>"
    string_table += "</table>"

  $("#quality_report_table").html(string_table)
  $("#isolates_quality_report_table").DataTable()

window.createReportsQualityDataJSON = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
  else
    trueJson = data


  json_for_lab_reports_missing_data = "["
  $.each trueJson, (key_vet, val_vet) ->
    true_value = val_vet.true_value
    false_value = val_vet.false_value + true_value
    null_value = val_vet.null_value + false_value
    json_for_lab_reports_missing_data += "{ \"vet_lab_code\": "
    json_for_lab_reports_missing_data += "\"#{val_vet.vet_lab_code}\", "
    json_for_lab_reports_missing_data += "\"counter\": ["
    json_for_lab_reports_missing_data += "#{true_value},"
    json_for_lab_reports_missing_data += "#{false_value},"
    json_for_lab_reports_missing_data += "#{null_value}"
    json_for_lab_reports_missing_data += "] },"
  json_for_lab_reports_missing_data = json_for_lab_reports_missing_data.substring(0, json_for_lab_reports_missing_data.length - 1)
  json_for_lab_reports_missing_data += "]"

  # console.log json_for_lab_reports_missing_data
  return json_for_lab_reports_missing_data

window.constructLabReportsQuantitySVG = (report_type) ->
  svg = $("#lab_reports_#{report_type}_quantity_chart").html();
  $buffer = $("#lab_reports_quantity_chart_svg_buffer");
  $buffer.html(svg);
  $buffer.find("svg").attr({ version: '1.1' , xmlns:"http://www.w3.org/2000/svg" });
  $buffer.find("svg > g").attr("transform", "");

  $buffer.find("svg")
    .attr("width", "1549")

  $("#lab_reports_quantity_chart_svg_input").html($buffer.html());

window.constructLabReportsQuantityChartCanvas = (report_type) ->
  constructLabReportsQuantitySVG(report_type)
  svg = $("#lab_reports_quantity_chart_svg_input").val();
  c = document.getElementById('lab_reports_quantity_chart_canvas');
  ctx = c.getContext('2d');
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.fillRect(0,0,1549,600);
  ctx.fillStyle = "rgb(0,0,0)";
  ctx.drawSvg(svg, 75, 65, 1549, 600);

window.downloadLabReportsQuantityChartMap = (type, report_type) ->
  mimes = {
    "jpg": "image/jpeg",
    "png": "image/png",
    "pdf": "application/pdf"
  }
  constructLabReportsQuantityChartCanvas(report_type)
  canvas = document.getElementById("lab_reports_quantity_chart_canvas")
  if type == "svg"
    svg = $("#lab_reports_quantity_chart_svg_input").val()
    b64 = btoa(svg)
    data_uri = "data:image/svg+xml;base64,\n" + b64
  else if type == "pdf"
    jpg = canvas.toDataURL(mimes["jpg"])
    doc = new jsPDF("l", "px", [1549, 600])
    doc.addImage(jpg, 'JPEG', 0, 0, 1549, 600)
    data_uri = doc.output("datauristring")
  else
    data_uri = canvas.toDataURL(mimes[type])
  filename = $("#filename").val();
  link = document.createElement('a');
  link.download = filename + "." + type;
  link.href = data_uri;
  link.click();

ready = ->

  $("body").on "change", "input:radio[name='quality_report_type']", () ->
    selected_filter = $(this).val()
    if selected_filter == 'isolate'
      $("#isolate_quality_report_filter").removeClass("hide")
      $("#submission_quality_report_filter").addClass("hide")
      $("#sequence_quality_report_filter").addClass("hide")
    else if selected_filter == 'submission'
      $("#isolate_quality_report_filter").addClass("hide")
      $("#submission_quality_report_filter").removeClass("hide")
      $("#sequence_quality_report_filter").addClass("hide")
    else if selected_filter == 'sequence'
      $("#isolate_quality_report_filter").addClass("hide")
      $("#submission_quality_report_filter").addClass("hide")
      $("#sequence_quality_report_filter").removeClass("hide")

  $("body").on "click", ".download-lab-reports-quantity-chart-as-pdf", (e) ->
    e.preventDefault()
    report_type = $(this).data('reporttype')
    # console.log report_type
    downloadLabReportsQuantityChartMap("pdf", report_type)

  $("body").on "click", ".download-lab-reports-quantity-chart-as-png", (e) ->
    e.preventDefault()
    report_type = $(this).data('reporttype')
    # console.log report_type
    downloadLabReportsQuantityChartMap("png", report_type)

  $("body").on "click", "#quantity_report_type_", () ->
    len = $("input:checkbox[name='quantity_report_type[]']:checked").length
    if len == 0
      $(this).prop("checked", true)

  $("#check_all_check_box_in_quantity_report").hide()
  $("#check_all_check_box_in_quality_report").hide()

  $("body").on "click", "#clear_check_box_in_quantity_report", (e) ->
    e.preventDefault()
    $("input:checkbox[name='by_isolate_lab_for_quantity[]']").prop("checked", false)
    $("#clear_check_box_in_quantity_report").hide()
    $("#check_all_check_box_in_quantity_report").show()

  $("body").on "click", "#check_all_check_box_in_quantity_report", (e) ->
    e.preventDefault()
    $("input:checkbox[name='by_isolate_lab_for_quantity[]']").prop("checked", true)
    $("#clear_check_box_in_quantity_report").show()
    $("#check_all_check_box_in_quantity_report").hide()

  $("body").on "click", "#clear_check_box_in_quality_report", (e) ->
    e.preventDefault()
    $("input:checkbox[name='by_isolate_lab_for_quality[]']").prop("checked", false)
    $("#clear_check_box_in_quality_report").hide()
    $("#check_all_check_box_in_quality_report").show()

  $("body").on "click", "#check_all_check_box_in_quality_report", (e) ->
    e.preventDefault()
    $("input:checkbox[name='by_isolate_lab_for_quality[]']").prop("checked", true)
    $("#clear_check_box_in_quality_report").show()
    $("#check_all_check_box_in_quality_report").hide()

$(document).on 'turbolinks:load', ready