isolates_data = [] # global variabel
outbreaks_data = [] # global variabel
month_arr = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
full_month_arr = ['January','February','March','April','May','June','July','August','September','October','November','December']

window.getShortMonth = (month) ->
  dt = new Date(month)
  return month_arr[dt.getMonth()] + " " + dt.getFullYear()

window.isolatesOutbreaksChart = (data) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
    outbreaksData = jQuery.parseJSON(trueJson.outbreaks)
    isolatesData = jQuery.parseJSON(trueJson.isolates)
  else
    trueJson = data
    outbreaksData = trueJson.outbreaks
    isolatesData = trueJson.isolates

  # get filter's date
  end_year = parseInt($("#monthly_report_end_year").val())
  end_month = parseInt($("#monthly_report_end_month").val())
  start_year = parseInt($("#monthly_report_start_year").val())
  start_month = parseInt($("#monthly_report_start_month").val())

  # if $("input[name='surveillance_area_displayed']:checked").val() == 'all_indonesia'
  #   $("#surveillance_chart_breadcrumb").html("<a href='#' id='indonesia_breadcrumb'>Reset</a>");

  # create month data for x axis
  array_month = []
  i = start_year
  j = start_month
  while i <= end_year
    # create month format (ex. march -> 03)
    j_push_to_array = ""
    if j < 10
      j_push_to_array = "0" + j
    else
      j_push_to_array = j

    # push month data for x axis
    array_month.push("#{month_arr[(j-1)]} #{i}")
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  margin = {top: 70, right: 30, bottom: 110, left: 40}
  width = $("#surveillances").width() * (8/12)
  # width = $(".col-md-10").width() - margin.left - margin.right
  height = 480 - margin.top - margin.bottom

  max_data = 50
  outbreaks_max_data = d3.max(outbreaksData, (d) -> (d.sms_reports+d.pdsr_reports+d.sms_pdsr_reports))
  outbreaks_line_max_data = d3.max(outbreaksData, (d) -> (d.all_species))
  isolates_max_data = d3.max(isolatesData, (d) -> (d.counter))
  if outbreaks_max_data > outbreaks_line_max_data
    max_data = outbreaks_max_data
  else
    max_data = outbreaks_line_max_data

  if max_data < isolates_max_data
    max_data = isolates_max_data

  x = d3.scaleBand()
    .domain(array_month)
    .rangeRound([0, width],.1)

  y = d3.scaleLinear()
    .domain([0, max_data])
    .range([height, 0])

  xAxis = d3.axisBottom()
    .scale(x)

  yAxis = d3.axisLeft()
    .scale(y)

  svg = d3.select("#outbreaks_chart_on_surveillance").append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(#{margin.left},#{margin.top})")

  svg.append("g")
    .attr("class", "chart-title-breadcrumb")
    .attr("transform", "translate(0,0)")
    .append("text")
      .style("text-anchor", "end")
      .text("")

  svg.append("g")
    .attr("class", "chart-date-range-breadcrumb")
    .attr("transform", "translate(0,0)")
    .append("text")
      .style("text-anchor", "end")
      .text("")

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0,#{height})")
    .call(xAxis)
      .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)")

  svg.append("g")
    .attr("class", "y axis")
    .call(yAxis)
    .selectAll("line")
      .attr("x2", width)
      .attr("x1", -6)

    # .append("text")
    #   .attr("transform", "rotate(-90)")
    #   .attr("y", -30)
    #   .attr("x", -(height/2))
    #   .style("text-anchor", "middle")
    #   .text("Number of HPAI outbreaks")

  array_data_displayed = []
  $("input[name='surveillance_data_displayed[]']:checked").each () ->
    array_data_displayed.push($(this).val())

  if array_data_displayed.indexOf('outbreaks') != -1
    barSms = svg.append("g")
      .attr("class", "g-sms")
    barSms.selectAll(".bar-sms")
      .data(outbreaksData)
      .enter().append("rect")
        .attr("class", "bar-sms")
        .attr("x", (d) -> return x(getShortMonth(d.month)) )
        .attr("width", x.bandwidth())
        .attr("y", (d) -> return y(d.sms_reports) )
        .attr("height", (d) ->
          h = height-y(d.sms_reports)
          if d.sms_reports == 0
            h = 0
          return h)
        .style("fill", "#800080")
        .tooltip (d) ->
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>SMS Report = #{d.sms_reports}</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(getShortMonth(d.month))), parseFloat(y(d.sms_reports))],
            displacement: [50, 0],
            mousemove: false
          }

    barPdsr = svg.append("g")
      .attr("class", "g-pdsr")
    barPdsr.selectAll(".bar-pdsr")
      .data(outbreaksData)
      .enter().append("rect")
        .attr("class", "bar-pdsr")
        .attr("x", (d) -> return x(getShortMonth(d.month)) )
        .attr("width", x.bandwidth())
        .attr("y", (d) -> return y(d.sms_reports+d.pdsr_reports) )
        .attr("height", (d) ->
          h = height-y(d.pdsr_reports)
          if d.pdsr_reports == 0
            h = 0
          return h )
        .style("fill", "#cc99ff")
        .tooltip (d) ->
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>PDSR Report = #{d.pdsr_reports}</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(getShortMonth(d.month))), parseFloat(y(d.sms_reports+d.pdsr_reports))],
            displacement: [50, 0],
            mousemove: false
          }

    barSmsPdsr = svg.append("g")
      .attr("class", "g-sms-pdsr")
    barSmsPdsr.selectAll(".bar-sms-pdsr")
      .data(outbreaksData)
      .enter().append("rect")
        .attr("class", "bar-sms-pdsr")
        .attr("x", (d) -> return x(getShortMonth(d.month)) )
        .attr("width", x.bandwidth())
        .attr("y", (d) -> return y(d.sms_pdsr_reports+d.sms_reports+d.pdsr_reports))
        .attr("height", (d) ->
          h = height-y(d.sms_pdsr_reports)
          if d.sms_pdsr_reports == 0
            h = 0
          return h)
        .style("fill", "#3365ff")
        .tooltip (d) ->
          return {
            type: "tooltip",
            title: "Object",
            text: "<div>PDSR and SMS Report = #{d.sms_pdsr_reports}</div>",
            trigger: "mouseover",
            detection: "shape",
            placement: "fixed",
            gravity: "right",
            position: [parseFloat(x(getShortMonth(d.month))), parseFloat(y(d.sms_reports+d.pdsr_reports+d.sms_pdsr_reports))],
            displacement: [50, 0],
            mousemove: false
          }

    poultryData = d3.line()
      .x((d) -> x(getShortMonth(d.month))+(x.bandwidth()/2))
      .y((d) -> y(d.all_species))

    linePoultry = svg.append("g")
      .attr("class", "line-poultry")
    linePoultry.append("path")
      .attr("fill", "none")
      .attr("d", poultryData(outbreaksData))
      .attr("stroke", "#000")
      .attr("stroke-width", 3)

    chickenData = d3.line()
      .x((d) -> x(getShortMonth(d.month))+(x.bandwidth()/2))
      .y((d) -> y(d.chicken_species))

    lineChicken = svg.append("g")
      .attr("class", "line-chicken")
    lineChicken.append("path")
      .attr("fill", "none")
      .attr("d", chickenData(outbreaksData))
      .attr("stroke", "red")
      .attr("stroke-width", 3)

    duckData = d3.line()
      .x((d) -> x(getShortMonth(d.month))+(x.bandwidth()/2))
      .y((d) -> y(d.duck_species))

    lineDuck = svg.append("g")
      .attr("class", "line-duck")
    lineDuck.append("path")
      .attr("fill", "none")
      .attr("d", duckData(outbreaksData))
      .attr("stroke", "#99cc00")
      .attr("stroke-width", 3)

  if array_data_displayed.indexOf('isolates') != -1
    # isolates line
    isoData = d3.line()
      .x((d) -> x(getShortMonth(d.month))+(x.bandwidth()/2))
      .y((d) -> y(d.counter))

    lineIsolates = svg.append("g")
      .attr("class", "line-isolates")
    lineIsolates.append("path")
      .attr("fill", "none")
      .attr("d", isoData(isolatesData))
      .attr("stroke", "#F39C12")
      .attr("stroke-width", 3)
      .style("stroke-dasharray", ("3, 3"))

  legend = svg.append("g")
    .attr("class", "legend")

  if array_data_displayed.indexOf('outbreaks') != -1
    legendSms = legend.append("g")
      .attr("class", "g-legend-sms")
    legendSms.append("rect")
      .attr("class", "bar-legend-sms")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left)
      .attr("y", height+margin.bottom-35)
      .style("fill", "#800080")
    legendSms.append("text")
      .attr("class", "txt-legend-sms")
      .text("SMS Report")
      .attr("dx", margin.left+40)
      .attr("dy", height+margin.bottom-25)

    legendPdsr = legend.append("g")
      .attr("class", "g-legend-pdsr")
    legendPdsr.append("rect")
      .attr("class", "bar-legend-pdsr")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left+40+95)
      .attr("y", height+margin.bottom-35)
      .style("fill", "#cc99ff")
    legendPdsr.append("text")
      .attr("class", "txt-legend-sms")
      .text("PDSR Report")
      .attr("dx", margin.left+40+95+40)
      .attr("dy", height+margin.bottom-25)

    legendSmsPdsr = legend.append("g")
      .attr("class", "g-legend-sms-pdsr")
    legendSmsPdsr.append("rect")
      .attr("class", "legend-sms-pdsr")
      .attr("height", "10px")
      .attr("width", "30px")
      .attr("x", margin.left+40+95+40+91+10)
      .attr("y", height+margin.bottom-35)
      .style("fill", "#3365ff")
    legendSmsPdsr.append("text")
      .attr("class", "txt-legend-sms")
      .text("PDSR and SMS Report")
      .attr("dx", margin.left+40+95+40+91+10+40)
      .attr("dy", height+margin.bottom-25)

    legendAllSpecies = legend.append("g")
      .attr("class", "g-legend-species")
    legendAllSpecies.append("line")
      .attr("class", "bar-legend-species")
      .attr("x1", margin.left)
      .attr("x2", margin.left+30)
      .attr("y1", height+margin.bottom-10)
      .attr("y2", height+margin.bottom-10)
      .style("stroke", "black")
      .style("stroke-width", 3)
    legendAllSpecies.append("text")
      .attr("class", "txt-legend-species")
      .text("All Poultry")
      .attr("dx", margin.left+40)
      .attr("dy", height+margin.bottom-5)

    legendChicken = legend.append("g")
      .attr("class", "g-legend-chicken")
    legendChicken.append("line")
      .attr("class", "bar-legend-chicken")
      .attr("x1", margin.left+40+95)
      .attr("x2", margin.left+40+95+30)
      .attr("y1", height+margin.bottom-10)
      .attr("y2", height+margin.bottom-10)
      .style("stroke", "red")
      .style("stroke-width", 3)
    legendChicken.append("text")
      .attr("class", "txt-legend-chicken")
      .text("Chicken")
      .attr("dx", margin.left+40+95+40)
      .attr("dy", height+margin.bottom-5)

    legendDuck = legend.append("g")
      .attr("class", "g-legend-duck")
    legendDuck.append("line")
      .attr("class", "bar-legend-duck")
      .attr("x1", margin.left+40+95+40+91+10)
      .attr("x2", margin.left+40+95+40+91+10+30)
      .attr("y1", height+margin.bottom-10)
      .attr("y2", height+margin.bottom-10)
      .style("stroke", "#99cc00")
      .style("stroke-width", 3)
    legendDuck.append("text")
      .attr("class", "txt-legend-duck")
      .text("Duck")
      .attr("dx", margin.left+40+95+40+91+10+40)
      .attr("dy", height+margin.bottom-5)

  if array_data_displayed.indexOf('isolates') != -1
    legendIsolate = legend.append("g")
      .attr("class", "g-legend-isolate")
    legendIsolate.append("line")
      .attr("class", "bar-legend-isolate")
      .attr("x1", margin.left+40+95+40+91+10+190)
      .attr("x2", margin.left+40+95+40+91+10+190+30)
      .attr("y1", height+margin.bottom-10)
      .attr("y2", height+margin.bottom-10)
      .attr("stroke", "#F39C12")
      .attr("stroke-width", 3)
      .style("stroke-dasharray", ("3, 3"))
    legendIsolate.append("text")
      .attr("class", "txt-legend-isolate")
      .text("Isolate")
      .attr("dx", margin.left+40+95+40+91+10+40+190)
      .attr("dy", height+margin.bottom-5)

  $(".chart-title-breadcrumb").html("<text dx='-20' dy='-40' style='font-size: 18px;'>All Indonesia Districts</text>");
  $(".chart-date-range-breadcrumb").html("<text dx='-20' dy='-20'>From #{full_month_arr[start_month-1]} #{start_year} to #{full_month_arr[end_month-1]} #{end_year}</text>");
  if $("input[name='surveillance_area_displayed']:checked").val() == 'dic'
    dic_selected = $("#dic_displayed option:selected").text()
    $(".chart-title-breadcrumb").html("<text dx='-20' dy='-40' style='font-size: 18px;'>#{dic_selected}</text>");

window.move_tab_to_chart = (nama_kab) ->
  $(".surveillance-district-autocomplete").val nama_kab
  $(".surveillance-district-chart-autocomplete").val nama_kab
  $("#surveillance_report_tab_list a[href='#surveillance_chart_tab']").tab("show");

window.show_chart = (kab_code, nama_kab, method) ->
  isolates_chart_json = isolates_data
  outbreaks_chart_json = outbreaks_data
  new_chart_json = { outbreaks: outbreaks_chart_json, isolates: isolates_chart_json }

  new_chart = createIsolatesOutbreaksJSON(new_chart_json, kab_code)

  # crate new chart
  $("#outbreaks_chart_on_surveillance svg").remove()
  isolatesOutbreaksChart(new_chart)
  if $("input[name='surveillance_area_displayed']:checked").val() == 'dic'
    dic_selected = $("#dic_displayed option:selected").text()
    $(".chart-title-breadcrumb").html("<text dx='-20' dy='-40' style='font-size: 18px;'>#{dic_selected} / #{nama_kab}</text>");
  else
    $(".chart-title-breadcrumb").html("<text dx='-20' dy='-40' style='font-size: 18px;'>All Indonesia Districts / #{nama_kab}</text>");
  # $("#surveillance_chart_breadcrumb").html("<a href='#' id='indonesia_breadcrumb'>Reset</a></h4>");

  $("#outbreaks_table_on_surveillance").DataTable().search(nama_kab).draw();
  $("#isolates_table_on_surveillance").DataTable().search(nama_kab).draw();
  $("#lab_test_data_table_on_surveillance").DataTable().search(nama_kab).draw();

  if method == "by_search"
  else if method == "by_dbl_click"
    move_tab_to_chart(nama_kab);

window.loadIsolatesData = () ->
  if typeof $("input[name=\"isolates_data\"]").val() == "undefined"
    isolates_data = []
  else
    isolates_data = jQuery.parseJSON($("input[name=\"isolates_data\"]").val())
  return isolates_data

window.loadOutbreaksData = () ->
  if typeof $("input[name=\"outbreaks_data\"]").val() == "undefined"
    outbreaks_data = []
  else
    outbreaks_data = jQuery.parseJSON($("input[name=\"outbreaks_data\"]").val())
  return outbreaks_data

window.loadLabTestData = () ->
  if typeof $("input[name=\"lab_test_data\"]").val() == "undefined"
    lab_test_data = []
  else
    lab_test_data = jQuery.parseJSON($("input[name=\"lab_test_data\"]").val())
  return lab_test_data


window.checkNumberOfOutbreaks = (outbreaksData, m, dis_code) ->
  noo = 0; # number of outbreaks
  sms_reports = 0
  pdsr_reports = 0
  sms_pdsr_reports = 0
  media_reports = 0
  isikhnas_reports = 0
  chicken_species = 0
  duck_species = 0
  all_species = 0
  if (dis_code == 0)
    # get data per month
    $.each outbreaksData, (key_out, val_out) ->
      if m == val_out.month
        noo += 1
        sms_reports += val_out.sms_reports
        pdsr_reports += val_out.pdsr_reports
        sms_pdsr_reports += val_out.sms_pdsr_reports
        media_reports += val_out.media_reports
        isikhnas_reports += val_out.isikhnas_reports
        chicken_species += val_out.chicken_species
        duck_species += val_out.duck_species
        all_species += val_out.all_species
        return [sms_reports, pdsr_reports, sms_pdsr_reports, media_reports, isikhnas_reports, chicken_species, duck_species, all_species, noo]
  else
    # get data per month
    if m == 0
      $.each outbreaksData, (key_out, val_out) ->
        district_code = val_out.province_code + val_out.district_code
        if district_code == dis_code
          noo += 1
          sms_reports += val_out.sms_reports
          pdsr_reports += val_out.pdsr_reports
          sms_pdsr_reports += val_out.sms_pdsr_reports
          media_reports += val_out.media_reports
          isikhnas_reports += val_out.isikhnas_reports
          chicken_species += val_out.chicken_species
          duck_species += val_out.duck_species
          all_species += val_out.all_species
          return [sms_reports, pdsr_reports, sms_pdsr_reports, media_reports, isikhnas_reports, chicken_species, duck_species, all_species, noo]
    else
      $.each outbreaksData, (key_out, val_out) ->
        district_code = val_out.province_code + val_out.district_code
        if m == val_out.month and district_code == dis_code
          noo += 1
          sms_reports += val_out.sms_reports
          pdsr_reports += val_out.pdsr_reports
          sms_pdsr_reports += val_out.sms_pdsr_reports
          media_reports += val_out.media_reports
          isikhnas_reports += val_out.isikhnas_reports
          chicken_species += val_out.chicken_species
          duck_species += val_out.duck_species
          all_species += val_out.all_species
          return [sms_reports, pdsr_reports, sms_pdsr_reports, media_reports, isikhnas_reports, chicken_species, duck_species, all_species, noo]
  return [sms_reports, pdsr_reports, sms_pdsr_reports, media_reports, isikhnas_reports, chicken_species, duck_species, all_species, noo]

window.checkNumberOfIsolates = (isolatesData, m, dis_code) ->
  noi = 0; # number of isolates
  if (dis_code == 0)
    # get data per month
    $.each isolatesData, (key_iso, val_iso) ->
      if (m == val_iso.month)
        noi += 1
        return noi
  else
    if (m == 0) # khusus untuk map, karena tidak menggunakan patokan bulan
      $.each isolatesData, (key_iso, val_iso) ->
        district_code = val_iso.province_code + val_iso.district_code
        if (district_code == dis_code)
          noi += 1
          return noi
    else
      $.each isolatesData, (key_iso, val_iso) ->
        district_code = val_iso.province_code + val_iso.district_code
        if (m == val_iso.month) and (district_code == dis_code)
          noi += 1
          return noi
  return noi


window.checkNumberOfLabTestDataInSurveillance = (labTestData, m, dis_code) ->
  nold = 0; # number of lab test data
  if (dis_code == 0)
    # get data per month
    $.each labTestData, (key_ltd, val_ltd) ->
      if (m == val_ltd.month)
        nold += 1
        return nold
  else
    if (m == 0) # khusus untuk map, karena tidak menggunakan patokan bulan
      $.each labTestData, (key_ltd, val_ltd) ->
        if (val_ltd.area_code == dis_code)
          nold += 1
          return nold
    else
      $.each labTestData, (key_ltd, val_ltd) ->
        if (m == val_ltd.month) and (val_ltd.area_code == dis_code)
          nold += 1
          return nold
  return nold


window.createIsolatesOutbreaksJSON = (data, dis_code) ->
  if (typeof(data) == "string")
    trueJson = jQuery.parseJSON(data)
    outbreaksData = jQuery.parseJSON(trueJson.outbreaks)
    isolatesData = jQuery.parseJSON(trueJson.isolates)
  else
    trueJson = data
    outbreaksData = trueJson.outbreaks
    isolatesData = trueJson.isolates

  # get filter's date
  end_year = parseInt($("#monthly_report_end_year").val())
  end_month = parseInt($("#monthly_report_end_month").val())
  start_year = parseInt($("#monthly_report_start_year").val())
  start_month = parseInt($("#monthly_report_start_month").val())

  i = start_year
  j = start_month
  new_json_for_outbreaks = "["
  new_json_for_isolates = "["
  while i <= end_year
    new_j = ""
    if j < 10
      new_j = "0" + j
    else
      new_j = j
    new_json_for_outbreaks += "{ \"month\": \"#{i}-"+new_j+"-01T00:00:00.000+08:00\", "
    outbreaks_counter = checkNumberOfOutbreaks(outbreaksData, (i+"-"+new_j+"-01T00:00:00.000+08:00"), dis_code)
    new_json_for_outbreaks += " \"sms_reports\": "+outbreaks_counter[0]+", "
    new_json_for_outbreaks += " \"pdsr_reports\": "+outbreaks_counter[1]+", "
    new_json_for_outbreaks += " \"sms_pdsr_reports\": "+outbreaks_counter[2]+", "
    new_json_for_outbreaks += " \"media_reports\": "+outbreaks_counter[3]+", "
    new_json_for_outbreaks += " \"isikhnas_reports\": "+outbreaks_counter[4]+", "
    new_json_for_outbreaks += " \"chicken_species\": "+outbreaks_counter[5]+", "
    new_json_for_outbreaks += " \"duck_species\": "+outbreaks_counter[6]+", "
    new_json_for_outbreaks += " \"all_species\": "+outbreaks_counter[7]+", "
    new_json_for_outbreaks += " \"counter\": "+outbreaks_counter[8]+" "
    new_json_for_outbreaks += "},"

    new_json_for_isolates += "{ \"month\": \"#{i}-"+new_j+"-01T00:00:00.000+08:00\", "
    isolates_counter = checkNumberOfIsolates(isolatesData, (i+"-"+new_j+"-01T00:00:00.000+08:00"), dis_code)
    new_json_for_isolates += " \"counter\": "+isolates_counter+" "
    new_json_for_isolates += "},"

    # if this is the last loop, exit!
    if i == end_year and j == end_month
      break

    j += 1 # to the next month!
    if j > 12 # is this the last month?
      i++ # to the next year!
      j=1 # start from first month again

  new_json_for_outbreaks = new_json_for_outbreaks.substring(0, new_json_for_outbreaks.length - 1)
  new_json_for_isolates = new_json_for_isolates.substring(0, new_json_for_isolates.length - 1)
  new_json_for_outbreaks += "]"
  new_json_for_isolates += "]"

  new_json_for_outbreaks = $.parseJSON(new_json_for_outbreaks)
  new_json_for_isolates = $.parseJSON(new_json_for_isolates)

  new_json_for_surveillance_chart = { outbreaks: new_json_for_outbreaks, isolates: new_json_for_isolates }
  # console.log new_json_for_surveillance_chart
  return new_json_for_surveillance_chart

# ================================= Map configuration ====================================
window.loadMap = (total_all_isolates, total_all_outbreaks, data_displayed, vet_lab_selected, isolates_with_missing_data, total_all_lab_test_data) ->
  # This is for summary report
  dwo = [] # District with outbreaks only
  dwi = [] # District with isolates only
  total_district_counted = 0

  data_displayed_string = data_displayed.replace(/&quot;/g, '').replace(/ /g, '')
  data_displayed_array = data_displayed_string.substr(1).slice(0, -1).split(',')

  # Isolates with missing data
  if typeof isolates_with_missing_data == "undefined"
    isolates_wmd = []
  else
    isolates_wmd = jQuery.parseJSON(isolates_with_missing_data)


  isolates_data = loadIsolatesData()
  outbreaks_data = loadOutbreaksData()
  lab_test_data = loadLabTestData()
  # surveillance_data =  { isolates: isolates_data, outbreaks: outbreaks_data }

  # isolates_outbreaks_json = createIsolatesOutbreaksJSON(surveillance_data, 0)

  # json_isolates_data = isolates_outbreaks_json.isolates
  # json_outbreaks_data = isolates_outbreaks_json.outbreaks

  # get list of provinces if area displayed by vet_lab
  province_list = []
  unless !vet_lab_selected
    province_list = vet_lab_selected

  # menginisialisasi data legends
  pengali_outbreaks = parseInt(total_all_outbreaks/4)
  pengali_isolates = parseInt(total_all_isolates/4)
  pengali_lab_test_data = parseInt(total_all_lab_test_data/4)

  if total_all_outbreaks % 4 != 0
    pengali_outbreaks += 1
  if total_all_isolates % 4 != 0
    pengali_isolates += 1
  if total_all_lab_test_data % 4 != 0
    pengali_lab_test_data += 1

  if total_all_outbreaks < 12
    outbreaks_legend_max = 12
  else
    outbreaks_legend_max = 4 * pengali_outbreaks

  if total_all_isolates < 12
    isolates_legend_max = 12
  else
    isolates_legend_max = 4 * pengali_isolates

  if total_all_lab_test_data < 12
    lab_test_data_legend_max = 12
  else
    lab_test_data_legend_max = 4 * pengali_lab_test_data

  beda_outbreaks = Math.round((parseInt(outbreaks_legend_max) + 1) / 4)
  beda_isolates = Math.round((parseInt(isolates_legend_max) + 1) / 4)
  beda_lab_test_data = Math.round((parseInt(lab_test_data_legend_max) + 1) / 4)

  batas_bawah_outbreaks = 1
  color_domain = []
  ext_color_domain = []
  legend_labels = []
  color_domain.push(0)
  ext_color_domain.push(0)
  legend_labels.push("0")
  while batas_bawah_outbreaks <= outbreaks_legend_max
    color_domain.push(batas_bawah_outbreaks)
    ext_color_domain.push(batas_bawah_outbreaks)
    legend_labels.push("#{batas_bawah_outbreaks} - #{batas_bawah_outbreaks+beda_outbreaks-1}")
    batas_bawah_outbreaks = batas_bawah_outbreaks + beda_outbreaks

  batas_bawah_isolates = 1
  isolate_color_domain = []
  isolate_ext_color_domain = []
  isolate_legend_labels = []
  isolate_color_domain.push(0)
  isolate_ext_color_domain.push(0)
  isolate_legend_labels.push("0")
  while batas_bawah_isolates <= isolates_legend_max
    isolate_color_domain.push(batas_bawah_isolates)
    isolate_ext_color_domain.push(batas_bawah_isolates)
    isolate_legend_labels.push("#{batas_bawah_isolates} - #{batas_bawah_isolates+beda_isolates-1}")
    batas_bawah_isolates = batas_bawah_isolates + beda_isolates


  batas_bawah_lab_test_data = 1
  lab_test_data_color_domain = []
  lab_test_data_ext_color_domain = []
  lab_test_data_legend_labels = []
  lab_test_data_color_domain.push(0)
  lab_test_data_ext_color_domain.push(0)
  lab_test_data_legend_labels.push("0")
  while batas_bawah_lab_test_data <= lab_test_data_legend_max
    lab_test_data_color_domain.push(batas_bawah_lab_test_data)
    lab_test_data_ext_color_domain.push(batas_bawah_lab_test_data)
    lab_test_data_legend_labels.push("#{batas_bawah_lab_test_data} - #{batas_bawah_lab_test_data+beda_lab_test_data-1}")
    batas_bawah_lab_test_data = batas_bawah_lab_test_data + beda_lab_test_data

  color = d3.scaleThreshold()
    .domain(color_domain)
    .range(["#ffffff", "#f9f9f9", "#fefeb4", "#fd8c3c", "#f03b20", "#bd0126"])
    # .range(["#f9f9f9", "#fefeb4", "#fecc5d", "#fd8c3c", "#f03b20", "#bd0126"])

  isolate_color = d3.scaleThreshold()
    .domain(isolate_color_domain)
    .range(["#ffffff", "#f9f9f9", "#a8acff", "#0024ff", "#002bb7", "#000261"])
    # .range(["#f9f9f9", "#7fff60", "#31fe13", "#94adc6", "#455eae", "#193387"])

  lab_test_data_color = d3.scaleThreshold()
    .domain(lab_test_data_color_domain)
    .range(["#ffffff", "#f9f9f9", "#a3ff87", "#3cff00", "#2ec300", "#1a6d00"])
    # .range(["#f9f9f9", "#7fff60", "#31fe13", "#94adc6", "#455eae", "#193387"])

  # initialize size
  w = $("#surveillances").width() * (4.8/8)
  # alert(w)
  size =
    width: w,
    height: w/2.5

  center = [size.width/2 , size.height/2]

  total_isolates_on_map = 0
  total_outbreaks_on_map = 0
  isolates_not_in_map = []
  outbreaks_not_in_map = []
  all_district_code_in_map = []

  # create canvas
  canvas = d3.select("#surveillance_map").append("svg")
    .attr("width", "100%")
    .attr("height", "#{size.height}px")
    .style("background-color", "#{mapColors['sea']}")
    .attr("cursor", "grab")

  projection = d3.geoMercator().scale(w*1.2).translate([-size.width*1.98, (size.height)/2.7]);
  path = d3.geoPath().projection(projection);

  group = canvas.append("g")

  # Generate MAP
  max_outbreaks = 0
  d3.json("/outbreaks/indonesia_districts_2018.json").then (data) ->

    # write(coloring) outbreaks data on map
    areas = group.selectAll("path")
      .data(data.features)
      .enter()
      .append('path')
      .attr("d", path)
      .attr("class", (d,i) ->
        return "map-item area "+d.properties.CC_2;
      )
      .style("stroke", "#{mapColors['landBorder']}")
      .style("stroke-width", "0.1")
      .attr("fill", (d,i) ->
        total_outbreaks = checkNumberOfOutbreaks(outbreaks_data, 0, d.properties.CC_2);
        total_outbreaks_on_map += total_outbreaks[8]
        if province_list.length > 0 # if area displayed: Per DIC
          if province_list.includes(d.properties.CC_2.substring(0,2))
            total_district_counted += 1
            if data_displayed_array.indexOf('outbreaks') == -1
              return "#f9f9f9"
            else
              if total_outbreaks[8] > 0 # if outbreaks available
                dwo.push(d.properties.CC_2) # add to list district with outbreaks
              return color(total_outbreaks[8],i);
          else
            return "#63b0d6"
        else  # if area displayed : all indonesia districts
          total_district_counted += 1
          if data_displayed_array.indexOf('outbreaks') == -1
            return "#f9f9f9"
          else
            # total_outbreaks = checkNumberOfOutbreaks(outbreaks_data, 0, d.properties.CC_2);
            if total_outbreaks[8] > 0 # if outbreaks available
              dwo.push(d.properties.CC_2) # add to list district with outbreaks
            return color(total_outbreaks[8],i);
      )
      .on('mouseover', (d,i) ->
        total_isolates = checkNumberOfIsolates(isolates_data, 0,  d.properties.CC_2);
        total_outbreaks = checkNumberOfOutbreaks(outbreaks_data, 0, d.properties.CC_2);
        total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
        string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" </b>, Total outbreaks = <b>"+total_outbreaks[8]+",</b> Total Lab Test Data = <b>"+total_lab_test_data+"</b>";
        $("#map_district_details i").html(string_details)
      )
      .on('mouseout', (d,i) ->
        $("#map_district_details i").html("District Details")
      )
      .on('dblclick', (d,i) ->
        show_chart(d.properties.CC_2, d.properties.NAME_2, "by_dbl_click")
      )

    circle = canvas.selectAll("g.dis.cir")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis cir")
      .attr("id", (d,i) ->
        return d.properties.CC_2;
      )

    # write(coloring) isolate data on map
    circle.append('svg:circle')
      .attr('cx', (d) -> path.centroid(d)[0])
      .attr('cy', (d) -> path.centroid(d)[1])
      .attr('r', (d,i) ->
        total_isolates = checkNumberOfIsolates(isolates_data, 0, d.properties.CC_2);
        counter_isolates = parseInt(total_isolates)
        total_isolates_on_map += counter_isolates
        all_district_code_in_map.push(d.properties.CC_2) # mengambil data district dari peta
        if province_list.length > 0
          if province_list.includes(d.properties.CC_2.substring(0,2))
            if data_displayed_array.indexOf('isolates') == -1
              return 0
            else
              if counter_isolates > 0 # if isolates available
                dwi.push(d.properties.CC_2) # add to list district with isolates
              if counter_isolates >= isolate_color_domain[4]
                return 1.5
              else if counter_isolates >= isolate_color_domain[3]
                return 1.4
              else if counter_isolates >= isolate_color_domain[2]
                return 1.3
              else if counter_isolates >= isolate_color_domain[1]
                return 1.2
              else if counter_isolates >= isolate_color_domain[0]
                return 1.1
              else
                return 0

          else
            return 0
        else
          if data_displayed_array.indexOf('isolates') == -1
            return 0
          else
            if counter_isolates > 0 # if isolates available
              dwi.push(d.properties.CC_2) # add to list district with isolates
            if counter_isolates >= isolate_color_domain[4]
              return 1.5
            else if counter_isolates >= isolate_color_domain[3]
              return 1.2
            else if counter_isolates >= isolate_color_domain[2]
              return 0.9
            else if counter_isolates >= isolate_color_domain[1]
              return 0.6
            else if counter_isolates >= isolate_color_domain[0]
              return 0.3
            else
              return 0

      )
      .style("opacity", "0.9")
      .style("fill", (d) ->
        total_isolates = checkNumberOfIsolates(isolates_data, 0, d.properties.CC_2);
        isolate_color(total_isolates)
      )
      .on('mouseover', (d,i) ->
        total_isolates = checkNumberOfIsolates(isolates_data, 0,  d.properties.CC_2);
        total_outbreaks = checkNumberOfOutbreaks(outbreaks_data, 0, d.properties.CC_2);
        total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
        string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" </b>, Total outbreaks = <b>"+total_outbreaks[8]+",</b> Total Lab Test Data = <b>"+total_lab_test_data+"</b>";
        $("#map_district_details i").html(string_details)
      )
      .on('mouseout', (d,i) ->
        $("#map_district_details i").html("District Details")
      )
      .on('dblclick', (d,i) ->
        show_chart(d.properties.CC_2, d.properties.NAME_2, "by_dbl_click")
      )

    rectangle = canvas.selectAll("g.dis.rec")
      .data(data.features)
      .enter()
      .append("g")
      .attr("class", "dis rec")
      .attr("id", (d,i) ->
        return d.properties.CC_2;
      )
    ls_w = 0.5
    ls_h = 0.5
    # write(coloring) lab test data data on map
    rectangle.append('svg:rect')
      .attr("x", (d) -> path.centroid(d)[0])
      .attr("y", (d) -> path.centroid(d)[1])
      .attr("width", (d) ->
        if data_displayed_array.indexOf('lab_test_data') == -1
          return 0
        else
          total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
          if total_lab_test_data >= lab_test_data_color_domain[4]
            return 1.5
          else if total_lab_test_data >= lab_test_data_color_domain[3]
            return 1.2
          else if total_lab_test_data >= lab_test_data_color_domain[2]
            return 0.9
          else if total_lab_test_data >= lab_test_data_color_domain[1]
            return 0.6
          else
            return 0
        )
      .attr("height", (d) ->
        if data_displayed_array.indexOf('lab_test_data') == -1
          return 0
        else
          total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
          if total_lab_test_data >= lab_test_data_color_domain[4]
            return 1.5
          else if total_lab_test_data >= lab_test_data_color_domain[3]
            return 1.2
          else if total_lab_test_data >= lab_test_data_color_domain[2]
            return 0.9
          else if total_lab_test_data >= lab_test_data_color_domain[1]
            return 0.6
          else
            return 0
        )
      .style("fill", (d,i) ->
        total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
        lab_test_data_color(total_lab_test_data)
      )
      .style("opacity", "0.8")
      .on('mouseover', (d,i) ->
        total_isolates = checkNumberOfIsolates(isolates_data, 0,  d.properties.CC_2);
        total_outbreaks = checkNumberOfOutbreaks(outbreaks_data, 0, d.properties.CC_2);
        total_lab_test_data = checkNumberOfLabTestDataInSurveillance(lab_test_data, 0, d.properties.CC_2);
        string_details = "<b>"+d.properties.NAME_2+"</b>. Total isolates = <b>"+total_isolates+" </b>, Total outbreaks = <b>"+total_outbreaks[8]+",</b> Total Lab Test Data = <b>"+total_lab_test_data+"</b>";
        $("#map_district_details i").html(string_details)
      )
      .on('mouseout', (d,i) ->
        $("#map_district_details i").html("District Details")
      )
      .on('dblclick', (d,i) ->
        show_chart(d.properties.CC_2, d.properties.NAME_2, "by_dbl_click")
      )

    # Zooming map based on filter by DIC
    if $("input[name='surveillance_area_displayed']:checked").val() == 'dic'
      dic_id = $("#dic_displayed option:selected").val()
      array_dis_id = [0, '5315', '3522', '8103', '1217', '1609', '2101', '6213', '3277']
      array_zoomscale = [0, 4, 5, 1.25, 2.8, 3.2, 2.2, 2.1, 7]
      cx = $("##{array_dis_id[dic_id]} circle").attr('cx')
      cy = $("##{array_dis_id[dic_id]} circle").attr('cy')
      zoomDic(cx, cy, array_zoomscale[dic_id])

    dwoo = dwo.length - dwi_dwo.length
    dwio = dwi.length - dwi_dwo.length

    $("#dwi_dwo").html("#{dwi_dwo.length}") # District with isolates and with outbreaks
    $("#dwo").html("#{dwoo}") # District with outbreaks only
    $("#dwi").html("#{dwio}") # District with isolates only
    $("#no_dwi_dwo").html("#{total_district_counted - dwoo - dwio - dwi_dwo.length}") # District with no isolates and no outbreaks

    missing_isolates = (isolates_data.length - total_isolates_on_map) + isolates_wmd.length
    missing_outbreaks = outbreaks_data.length - total_outbreaks_on_map
    $("#isolates_not_shown").html("#{missing_isolates}")
    $("#outbreaks_not_shown").html("#{missing_outbreaks}")

    districts_comparassion(isolates_data, all_district_code_in_map)

  # ------------- Zooming Map (by scroll) --------------------------------
  zoomed = () ->
    canvas.selectAll("g").attr("transform", d3.event.transform);

  zoom = d3.zoom().on("zoom", zoomed);

  canvas.call(zoom);
  canvas.call(zoom).on("dblclick.zoom", null);
  # ------------- end of Zooming Map (by scroll) -------------------------

  # ------------- Zooming Map (by control button) -------------------------
  d3.selectAll('.zoom-control').on('click', () ->
    if (this.id == 'zoom_in')
      zoom.scaleBy(canvas.transition().duration(350), 1.3);
    else if (this.id == 'zoom_out')
      zoom.scaleBy(canvas.transition().duration(350), 0.7);
    else
      canvas.call(zoom.transform,d3.zoomIdentity)
  )
  # ------------- end of Zooming Map (by control button) ------------------

  # ------------- Zooming Map (by selected DIC) -------------------------
  window.zoomDic = (cx, cy, zoom_scale) ->
    canvas.transition().duration(750).call(
      zoom.transform,
      d3.zoomIdentity.translate(size.width / 2, size.height / 2).scale(zoom_scale).translate(-cx, -cy)
    );
  # ------------- end of Zooming Map (by selected DIC) ------------------


window.districts_comparassion = (isolates_data, all_district_code_in_map) ->
  without_district_code = []
  without_province_code = []
  not_match_with_map = []
  $.each isolates_data, (key_iso, val_iso) ->
    if val_iso.province_code is null
      without_province_code.push(val_iso)
      $("#isolates_table_with_missing_data_on_surveillance tbody").append("
          <tr class='isolate_#{val_iso.province_code}#{val_iso.district_code}'>
            <td>
              <a href='/en/isolates/#{val_iso.id}' target='_blank'>
                #{val_iso.ivm_standard_name}
              </a>
            </td>
            <td>
              #{val_iso.vet_lab_name}
            </td>
            <td>
              Tidak memiliki Kode Provinsi
            </td>
            <td>
              <a href='/en/isolates/#{val_iso.id}/edit' target='_blank'>
                Edit
              </a>
            </td>
          </tr>
        ")

    if val_iso.district_code is null
      without_district_code.push(val_iso)
      $("#isolates_table_with_missing_data_on_surveillance tbody").append("
          <tr class='isolate_#{val_iso.province_code}#{val_iso.district_code}'>
            <td>
              <a href='/en/isolates/#{val_iso.id}' target='_blank'>
                #{val_iso.ivm_standard_name}
              </a>
            </td>
            <td>
              #{val_iso.vet_lab_name}
            </td>
            <td>
              Tidak memiliki Kode Kabupaten
            </td>
            <td>
              <a href='/en/isolates/#{val_iso.id}/edit' target='_blank'>
                Edit
              </a>
            </td>
          </tr>
        ")

    unless (val_iso.province_code is null) or (val_iso.district_code is null)
      district_code = "#{val_iso.province_code}#{val_iso.district_code}"
      if(jQuery.inArray(district_code, all_district_code_in_map) < 0)
        not_match_with_map.push(val_iso)
        $("#isolates_table_with_missing_data_on_surveillance tbody").append("
          <tr class='isolate_#{val_iso.province_code}#{val_iso.district_code}'>
            <td>
              <a href='/en/isolates/#{val_iso.id}' target='_blank'>
                #{val_iso.ivm_standard_name}
              </a>
            </td>
            <td>
              #{val_iso.vet_lab_name}
            </td>
            <td>
              Kode Kabupaten tidak ada di peta
            </td>
            <td>
              <a href='/en/isolates/#{val_iso.id}/edit' target='_blank'>
                Edit
              </a>
            </td>
          </tr>
        ")

  $("#isolates_table_with_missing_data_on_surveillance").DataTable();

window.district_focus = (dis_id) ->
  cx = $("##{dis_id} circle").attr('cx')
  cy = $("##{dis_id} circle").attr('cy')
  $(".#{dis_id}").fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800).fadeOut(800).fadeIn(800)
  zoomDic(cx, cy, 30)

window.district_autocomplete = (elementClass, src) ->
  $(elementClass).typeahead({
    minLength: 3,
    highlight: true
  },
  {
    name: 'districts',
    limit: 10,
    display: 'area_name'
    templates: {
      notFound: "<div class='tt-suggestion'>District not found</div>"
    },
    source: (keyword_search, sync_process, async_process) ->
      $.ajax
        url: $(elementClass).data("source")
        dataType: 'json'
        data:
          by_area_name_on_surveillance:
            keyword: keyword_search
            level: 2
        success: (data) ->
          async_process(data)
  })
  $(elementClass).bind('typeahead:select', (ev, suggestion) ->
    $(elementClass).val(suggestion.area_name)
    district_focus("#{suggestion.province_code}#{suggestion.district_code}")
    if src == 'outbreaks'
      show_chart("#{suggestion.province_code}#{suggestion.district_code}", "#{suggestion.area_name}", "by_search")
  )

window.constructSurveillanceSVG = () ->
  svg = $("#outbreaks_chart_on_surveillance").html();
  $buffer = $("#svg_buffer");
  $buffer.html(svg);
  $buffer.find("svg").attr({ version: '1.1' , xmlns:"http://www.w3.org/2000/svg" });
  $buffer.find("svg > g").attr("transform", "");

  $buffer.find("svg")
    .attr("width", "1400")

  # Insert Surveillance Information
  # $runInfo = $buffer.find(".run-info");
  # $trs = $(".antigenic-run-table .run-row");
  # infos = []
  # max_length = 0
  # $trs.each ->
  #   label = $(this).find(".run-label").html();
  #   value = $(this).find(".run-value").html();
  #   max_length = label.length if label.length > max_length
  #   infos.push({
  #     label: label,
  #     value: value
  #   });
  # for i in [0...infos.length]
  #   label = infos[i].label
  #   $runInfo.append("<tspan x='0' dy='20'>" + label + "</tspan>" + "<tspan dx='" + max_length * 8 + "' dy='0'> : " + infos[i].value + "</tspan>");

  # $buffer.find(".overlay").css("fill", "none");
  # $buffer.find(".h, .v").css
  #   "stroke" : "rgb(20, 20, 20)",
  #   "stroke-dasharray" : "4 4",
  #   "stroke-width" : 1,
  #   "stroke-opacity" : 0.5
  # $buffer.find(".diag").css
  #   "stroke": "blue"
  #   "stroke-dasharray": "10 10"
  #   "stroke-width": 1;
  #   "stroke-opacity": 0.5
  # $buffer.find(".axis-label").css
  #   "font-weight": "bold"
  #   "fill": "#333333"
  # $buffer.find(".axis path, .axis line").css
  #   "fill": "none"
  #   "stroke": "black"
  #   "shape-rendering": "crispEdges"
  # $buffer.find("g.sample text").css
  #   "display": "none"
  #   "fill": "blue"
  #   "font-size": "14px"

  $("#surveillance_svg_input").html($buffer.html());

window.constructSurveillanceChartCanvas = () ->
  constructSurveillanceSVG()
  svg = $("#surveillance_svg_input").val();
  c = document.getElementById('outbreaks_chart_on_surveillance_canvas');
  ctx = c.getContext('2d');
  ctx.fillStyle = "rgb(255,255,255)";
  ctx.fillRect(0,0,1400,480);
  ctx.fillStyle = "rgb(0,0,0)";
  ctx.drawSvg(svg, 60, 60, 1400, 480);

window.downloadSurveillanceChartMap = (type) ->
  mimes = {
    "jpg": "image/jpeg",
    "png": "image/png",
    "pdf": "application/pdf"
  }
  constructSurveillanceChartCanvas()
  canvas = document.getElementById("outbreaks_chart_on_surveillance_canvas")
  if type == "svg"
    svg = $("#surveillance_svg_input").val()
    b64 = btoa(svg)
    data_uri = "data:image/svg+xml;base64,\n" + b64
  else if type == "pdf"
    jpg = canvas.toDataURL(mimes["jpg"])
    doc = new jsPDF("l", "px", [1400, 480])
    doc.addImage(jpg, 'JPEG', 0, 0, 1400, 480)
    data_uri = doc.output("datauristring")
  else
    data_uri = canvas.toDataURL(mimes[type])
  filename = $("#filename").val();
  link = document.createElement('a');
  link.download = filename + "." + type;
  link.href = data_uri;
  link.click();

ready = ->

  $("body").on "click", ".reset-surveilance-filter", () ->
    # e.preventDefault()
    # $("input:radio[name='surveillance_area_displayed']").prop('checked', true);
    $("#surveillance_chart_breadcrumb").html("")
    # $("#surveillance_filter_form").submit()
    $("input[name='outbreaks_fil']").click()

  $("body").on "click", "#surveilance_import_pdsr_data_tab", (e) ->
    e.preventDefault()
    path = $(this).data("path")
    Turbolinks.visit(path);

  if $("input[name='surveillance_area_displayed']:checked").val() == "dic"
    $("#surveillance_vet_lab_filter").show("slow");

  $("body").on "change", "input[name='surveillance_area_displayed']", ->
    if $(this).val() == "dic"
      $("#surveillance_vet_lab_filter").show("slow");
    else
      $("#surveillance_vet_lab_filter").hide("slow");

  $("body").on "click", "#download_surveillance_chart_as_pdf", (e) ->
    e.preventDefault()
    downloadSurveillanceChartMap("pdf")

  $("body").on "click", "#download_surveillance_chart_as_png", (e) ->
    e.preventDefault()
    downloadSurveillanceChartMap("png")

  d = new Date()
  current_year = d.getFullYear()
  current_month = d.getMonth() + 1
  $("body").on "keyup", "#monthly_report_start_year", (e) ->
    $("#monthly_report_end_year").val($(this).val())
    if parseInt($(this).val()) == current_year
      $("#monthly_report_end_month").val(current_month)
    else
      $("#monthly_report_end_month").val(12)

  $("body").on "change", "#monthly_report_start_year", (e) ->
    $("#monthly_report_end_year").val($(this).val())
    if parseInt($(this).val()) == current_year
      $("#monthly_report_end_month").val(current_month)
    else
      $("#monthly_report_end_month").val(12)

  $("body").on "click", "#isolates_not_shown", (e) ->
    e.preventDefault();
    # alert "yeee sakit kulit"
    $("#surveillance_report_tab_list a[href='#surveillance_isolates_table_tab']").tab("show");
    $("#isolates_with_missing_data_tab_list a[href='#isolate_with_missing_data_tab']").tab("show");


  $("body").on "click", "#surveillance_data_displayed_", () ->
    len = $("input:checkbox[name='surveillance_data_displayed[]']:checked").length
    if len == 0
      $(this).prop("checked", true)

$(document).on 'turbolinks:load', ready